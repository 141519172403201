/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import validate from 'validate.js';
import { DialogDefault,  UsuarioSelect } from '../../components';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  }, 
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  messageContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  icon: {
    color: 'red'
  }, 
  span: {
    paddingLeft: 10
  },
  contentClassName: {
    overflow: 'visible'
  }
}));

const UsuarioModal = ({ open, handlerCancel, handlerConfirm, usuarioId, message, grupos, isDigitador }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ usuario: null });
  
  useEffect(() => {
    if (usuarioId) setValues({ usuario: usuarioId })
  }, [usuarioId])

  const clearForm = () => {
    setErrors({});
    setValues({ usuario: null })
  }

  const onCancelLocal = () => {
    clearForm();
    handlerCancel();
  }

  const onConfirmLocal = () => {
    const errors = validate(values, {
      usuario: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      }
    });
    setErrors(errors);
    if (!errors || (errors && Object.keys(errors).length === 0)) {
      clearForm();
      handlerConfirm(values.usuario);
    }
  }
  
  return (
    <DialogDefault
      open={open}
      handlerCancel={onCancelLocal}
      handlerConfirm={onConfirmLocal}
      title={isDigitador ? 'Vincular Digitador' : 'Vincular Inspetor'}
      confirmMessage="Salvar"
      maxWidth="md"
      contentClassName={classes.contentClassName}
    >
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.fieldContainer}>
          <UsuarioSelect
            grupos={grupos}
            isRequired
            ativo
            label={isDigitador ? 'Digitador' : 'Inspetor'}
            error={errors && errors.usuario? true : false}
            onSelect={usuario => setValues({ usuario })}
            value={values.usuario}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          {usuarioId && message && 
            <>
              <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center" className={classes.messageContainer}>
                <WarningIcon className={classes.icon}/> <span className={classes.span}> {message} </span> 
              </Grid>
            </>
          }
        </Grid>
      </Grid>
    </DialogDefault>
  );
};

UsuarioModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handlerCancel: PropTypes.func.isRequired, 
  handlerConfirm: PropTypes.func.isRequired,
  usuarioId: PropTypes.string,
  message: PropTypes.string,
  grupos: PropTypes.array.isRequired,
  isDigitador: PropTypes.bool
};

UsuarioModal.defaultProps = {
  usuarioId: null,
  message: null,
  isDigitador: true
}

export default UsuarioModal;