import * as Type from './actionsTypes';

const INITIAL_STATE = {
  drones: [],
  selectedDrone: null,
  options: [],
  selectedHistoricoDrone: null,
};

const ordernarPorDataHistorico = (a, b) => {
  return a.dataHistorico.localeCompare(b.dataHistorico);
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_DRONES_SUCCESS: {
      return {
        ...state,
        drones: action.data
      }
    }
    case Type.DELETE_DRONE_SUCCESS: {
      return {
        ...state,
        drones: state.drones.filter(s => s.id !== action.id)
      }
    }
    case Type.SAVE_DRONE_SUCCESS: {
      return {
        ...state,
        drones: [...state.drones, action.data]
      }
    }
    case Type.UPDATE_DRONE_SUCCESS: {
      return {
        ...state,
        drones: [...state.drones.filter(s => s.id !== action.data.id), action.data]
      }
    }
    case Type.DRONE_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedDrone: action.data
      }
    }
    case Type.HISTORICO_DRONE_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedHistoricoDrone: action.data
      }
    }
    case Type.HISTORICO_DRONE_UPSERT_SUCCESS: {
      return {
        ...state,
        selectedDrone: {
          ...state.selectedDrone,
          historico: [...state.selectedDrone.historico.filter(s => s.id !== action.data.id), action.data].sort(ordernarPorDataHistorico)
        }
      }
    }
    case Type.CLEAR_DRONE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getDrones = state => state.rootState.drone.drones;
export const getSelectedDrone = state => state.rootState.drone.selectedDrone;
export const getDronesOptions = state => state.rootState.drone.options;
export const getSelectedHistoricoDrone = state => state.rootState.drone.selectedHistoricoDrone;
