/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import {
  Input,
  Widget,
  DatePicker,
  TrueFalseSelect,
  NumberInput
} from '../../components';
import * as Roles from '../../constants/role';
import * as actionsNsirs from '../../store/nsir/actions';
import * as selectorsAuth from '../../store/auth/reducer';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: theme.spacing(2),
    paddingTop: 20,
    paddingBottom: 30
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const FinanceiroTab = ({ nsir, authData, updateFinanceiro, nsirTitle }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});

  useEffect(() => {
    if (nsir && nsir.id) {
      setValues({
        id: nsir.id,
        usProgramada: nsir.usProgramada,
        usExecutada: nsir.usExecutada,
        usPaga: nsir.usPaga,
        nsirPaga: nsir.nsirPaga,
        apro: nsir.apro,
        hd: nsir.hd,
        nsirPagaInspetor: nsir.nsirPagaInspetor,
        dataPagamento: nsir.dataPagamento,
        observacaoFinanceiro: nsir.observacaoFinanceiro,
      });
    }
  }, [nsir]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getActions = () => {
    return [{
      onClick: () => updateFinanceiro({
        ...values,
        dataPagamento: values.dataPagamento ? values.dataPagamento : null
      }),
      title: 'Salvar',
      show: isAdmin()
    }];
  }

  return (
    <Widget title={`Financeiro > ${nsirTitle}`} actions={getActions()}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={4} className={classes.fieldContainer}>
          <NumberInput
            label="US Programada"
            name="usProgramada"
            onChange={valor => setValues({
              ...values,
              usProgramada: valor
            })}
            value={values.usProgramada}
            format="##,###"
            decimalScale={3}
            prefix=""
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <NumberInput
            label="US Executada"
            name="usExecutada"
            onChange={valor => setValues({
              ...values,
              usExecutada: valor
            })}
            value={values.usExecutada}
            disabled={!isAdmin()}
            format="##,###"
            decimalScale={3}
            prefix=""
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <NumberInput
            label="US Apropriada"
            name="usPaga"
            onChange={valor => setValues({
              ...values,
              usPaga: valor
            })}
            value={values.usPaga}
            disabled={!isAdmin()}
            format="##,###"
            decimalScale={3}
            prefix=""
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            label="APRO"
            name="apro"
            onChange={valor => setValues({
              ...values,
              apro: valor
            })}
            value={values.apro}
            disabled={!isAdmin()}
            decimalScale={0}
            prefix=""
            thousandSeparator=""
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            label="HD"
            name="hd"
            onChange={valor => setValues({
              ...values,
              hd: valor
            })}
            value={values.hd}
            disabled={!isAdmin()}
            decimalScale={0}
            prefix=""
            thousandSeparator=""
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <TrueFalseSelect
            showEmpty={false}
            label="Nsir Paga ao Inspetor/Ajudante"
            name="nsirPagaInspetor"
            onChange={handleChange}
            value={values.nsirPagaInspetor || false}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DatePicker
            label="Data do Pagamento"
            onChange={data => setValues({
              ...values,
              dataPagamento: data.valueOf()
            })}
            value={values.dataPagamento || null}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Observação"
            name="observacaoFinanceiro"
            onChange={handleChange}
            value={values.observacaoFinanceiro}
            rows={4}
            multiline
            disabled={!isAdmin()}
          />
        </Grid>
      </Grid>
    </Widget>
  );
};

FinanceiroTab.propTypes = {
  nsir: PropTypes.object.isRequired,
  authData: PropTypes.object.isRequired,
  updateFinanceiro: PropTypes.func.isRequired,
  nsirTitle: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionsNsirs
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceiroTab);
