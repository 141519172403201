/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import * as selectorsDrones from '../../store/drone/reducer';
import * as actionsDrones from '../../store/drone/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import * as Role from '../../constants/role';
import moment from 'moment';

import { Input, UsuarioSelect, DialogDefault, ArquivoContainer, WidgetTab } from '../../components';
import HistoricoTab from './HistoricoTab';

const schema = {
  modelo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  numSerieDrone: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const Formulario = ({ saveDrone, updateDrone, selectedDrone, open, id, onClose, findDroneById, authData }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setValues({
        criadoPor: authData.id,
        criadoPorName: authData.nome,
        dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss"),
      });
    } else {
      setValues({});
    }
  }, [authData, open]);

  useEffect(() => {
    if (id) findDroneById(id);
  }, [id, findDroneById]);

  useEffect(() => {
    if (id && selectedDrone && id === selectedDrone.id) {
      setValues(selectedDrone);
    }
  }, [id, selectedDrone]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateDrone(values, saveCallback);
      else saveDrone(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      setValues({});
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Drone"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <WidgetTab
        widgets={[
          {
            element: <Grid container spacing={3}>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Input
                  error={errors && errors.modelo}
                  isRequired
                  label="Modelo"
                  name="modelo"
                  onChange={handleChange}
                  value={values.modelo}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Input
                  error={errors && errors.numSerieDrone}
                  isRequired
                  label="Série do drone"
                  name="numSerieDrone"
                  onChange={handleChange}
                  value={values.numSerieDrone}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Input
                  label="Série do rádio"
                  name="numSerieRadio"
                  onChange={handleChange}
                  value={values.numSerieRadio}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Input
                  label="Código SISANT"
                  name="codigoSisant"
                  onChange={handleChange}
                  value={values.codigoSisant}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Input
                  label="Código ANATEL"
                  name="codigoAnatel"
                  onChange={handleChange}
                  value={values.codigoAnatel}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Input
                  label="Apólice de seguro"
                  name="numApoliceSeguro"
                  onChange={handleChange}
                  value={values.numApoliceSeguro}
                />
              </Grid>
              <Grid item xs={6} className={classes.fieldContainer}>
                <UsuarioSelect
                  ativo
                  grupos={[Role.ADMIN, Role.INSPETOR]}
                  onSelect={inspetorId => {
                    setValues({
                      ...values,
                      inspetorId
                    });
                  }}
                  value={values.inspetorId}
                  label="Inspetor"
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Input
                  label="Criado Por"
                  disabled
                  value={values.criadoPorName}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Input
                  label="Data Inclusão"
                  disabled
                  value={values.dataInclusao}
                />
              </Grid>
              <Grid item xs={12} className={classes.fieldContainer}>
                <Input
                  label="Outras informações"
                  name="informacao"
                  onChange={handleChange}
                  value={values.informacao}
                  rows={3}
                  multiline
                />
              </Grid>
              <ArquivoContainer 
                arquivos={values.arquivos}
                onSave={arquivos => setValues({ ...values, arquivos })}
              />
            </Grid>,
            label: "Informações Básicas",
            disabled: false
          },
          {
            element: <HistoricoTab idDrone={id} historico={values.historico? values.historico : []} />,
            label: "Histórico",
            disabled: !id
          },
        ]}
      />
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  selectedDrone: selectorsDrones.getSelectedDrone(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsDrones,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
