import * as Type from './actionsTypes';

const INITIAL_STATE = {
  nsmps: [],
  selected: {}
};

const ordenarPorNsmp = (a, b) => {
  if (isNaN(Number(a)) || isNaN(Number(b))) {
    const aSplit = a.codigo.split("_");
    const bSplit = b.codigo.split("_");

    if (aSplit.length > 1 && bSplit.length > 1 && aSplit[0] === bSplit[0]) {
      return Number(aSplit[1]) - Number(bSplit[1]);
    }

    return Number(aSplit[0]) - Number(bSplit[0]);
  }

  return Number(a) - Number(b);
} 

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.NSMPS_DELETE_SUCCESS:
    case Type.NSMPS_FIND_SUCCESS: {
      return {
        ...state,
        nsmps: action.data.sort(ordenarPorNsmp)
      };
    }
    case Type.NSMPS_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selected: action.data
      };
    }
    case Type.NSMPS_CLONE_SUCCESS:
    case Type.NSMPS_SAVE_SUCCESS: {
      return {
        ...state,
        nsmps: [...state.nsmps, action.data].sort(ordenarPorNsmp),
        selected: {}
      };
    }
    case Type.NSMPS_DELETE_CLONE_SUCCESS: {
      const referenceIds = action.data.map(d => d.id);
      return {
        ...state,
        nsmps: [
          ...state.nsmps.filter(s => !referenceIds.includes(s.id) && s.id !== action.id), 
          ...action.data
        ].sort(ordenarPorNsmp),
        selected: {}
      };
    }
    case Type.NSMPS_UPDATE_SUCCESS: {
      return {
        ...state,
        nsmps: [
          ...state.nsmps.filter(s => s.id !== action.data.id),
          action.data
        ].sort(ordenarPorNsmp),
        selected: {}
      };
    }
    case Type.NSMPS_CLEAN: {
      return INITIAL_STATE;
    }
    case Type.NSMPS_CLEAN_SELECTED: {
      return {
        ...state,
        selected: {}
      };
    }
    default:
      return state;
  }
}

export const getNsmps = state => state.rootState.nsmp.nsmps;
export const getNsmpSelected = state => state.rootState.nsmp.selected;
