/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import { Divider, Button, Grid, InputAdornment, IconButton } from '@material-ui/core';
import { Input, Widget } from '../../../../components';
import * as actionsAuth from '../../../../store/auth/actions';
import * as selectorsAuth from '../../../../store/auth/reducer';
import clsx from 'clsx';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const schema = {
  password: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    equality: 'password'
  }
};

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 15
  },
  button: {
    marginTop: 8
  },
  divider: {
    marginTop: 10,
    background: theme.palette.bar.grayMiddle
  },
  containerItem: {
    paddingTop: '0px !important'
  },
  passwordValidation: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  gridContainer: {
    padding: theme.spacing(2),
    paddingTop: 30,
    paddingBottom: 30
  },
  validationTitle: {
    fontSize: 22,
  },
  validationDescription: {
    fontSize: 14,
  },
  validate: {
    color: '#00884b'
  },
  invalidate: {
    color: '#ba293a'
  },
  loginIcon: {
    paddingRight: 11,
    paddingLeft: 11
  },
  icon: {
    color: '#546e7a'
  },
  title: {
    padding: 0,
    paddingLeft: 12,
    paddingBottom: 6,
    fontSize: 14
  }
}));

const Password = ({ updatePassword, auth }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [viewPassword, setViewPassword] = useState(false);
  const [validation, setValidation] = useState({
    caracters: false,
    maiuscula: false,
    minuscula: false,
    numero: false,
    especial: false,
  });
  
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const getValidation = value => {
    return value && value.length >= 8 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value) && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
  }

  const handlePassword = event => {
    const password = event.target.value;

    setValidation({
      caracters: password && password.length >= 8,
      maiuscula: password && /[A-Z]/.test(password),
      minuscula: password && /[a-z]/.test(password),
      numero: password && /\d/.test(password),
      especial: password && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
    }); 

    if (!getValidation(password)) {
      setErrors({ password: true });
    } else {
      let error = errors;
      delete error.password;
      setErrors(error);
    }

    setValues({
      ...values,
      password
    });
  };

  const callback = () => {
    setErrors({});
    setValues({});
    setViewPassword(false);
    setValidation({
      caracters: false,
      maiuscula: false,
      minuscula: false,
      numero: false,
      especial: false,
    });
  }

  const onClick = () => {
    let errors = validate(values, schema);

    if (!getValidation(values.password)) {
      if (errors) {
        errors.password = true;
      } else {
        errors = {
          password: true
        }
      }
    }

    setErrors(errors)
    if (!errors) {
      updatePassword(auth.id, values, callback, true);
    }
  }

  const getClassName = key =>{
    if (values.password) {
      return validation[key] ? classes.validate : classes.invalidate;
    }

    return null;
  }

  return (
    <Widget title={"Alterar Senha"}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} className={classes.containerItem}>
          <Input
            error={errors && errors.password}
            hideHelperText
            label="Nova Senha"
            name="password"
            onChange={handlePassword}
            isRequired
            value={values.password || ''}
            type={viewPassword ? 'text' : 'password'}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setViewPassword(!viewPassword)}>
                    {viewPassword ? <Visibility className={classes.icon}/> : <VisibilityOff className={classes.icon}/>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} className={clsx(classes.containerItem, classes.title)}>
          Critérios para a senha:
        </Grid>
        <Grid item xs={4} className={clsx(classes.containerItem, classes.passwordValidation)}>
          <div className={clsx(classes.validationTitle, getClassName("caracters"))}>8</div>
          <div className={clsx(classes.validationDescription, getClassName("caracters"))}>caracteres</div>
        </Grid>
        <Grid item xs={4} className={clsx(classes.containerItem, classes.passwordValidation)}>
          <div className={clsx(classes.validationTitle, getClassName("maiuscula"))}>A</div>
          <div className={clsx(classes.validationDescription, getClassName("maiuscula"))}>MAIÚSCULA</div>
        </Grid>
        <Grid item xs={4} className={clsx(classes.containerItem, classes.passwordValidation)}>
          <div className={clsx(classes.validationTitle, getClassName("minuscula"))}>a</div>
          <div className={clsx(classes.validationDescription, getClassName("minuscula"))}>minúscula</div>
        </Grid>
        <Grid item xs={6} className={clsx(classes.containerItem, classes.passwordValidation)}>
          <div className={clsx(classes.validationTitle, getClassName("numero"))}>0 - 9</div>
          <div className={clsx(classes.validationDescription, getClassName("numero"))}>número</div>
        </Grid>
        <Grid item xs={6} className={clsx(classes.containerItem, classes.passwordValidation)}>
          <div className={clsx(classes.validationTitle, getClassName("especial"))}>#@?&%!$</div>
          <div className={clsx(classes.validationDescription, getClassName("especial"))}>caracter especial</div>
        </Grid>
        <Grid item xs={12} className={classes.containerItem}>
          <Input
            classNameInputGroup={classes.input}
            error={errors && errors.confirmPassword}
            helperText={errors && errors.confirmPassword ? 'As senhas não são iguais' : null}
            label="Confirmar Nova Senha"
            name="confirmPassword"
            onChange={handleChange}
            isRequired
            value={values.confirmPassword || ''}
            type="password"
          />
        </Grid>
        <Grid item xs={12} className={classes.containerItem}>
          <Divider className={classes.divider}/>
          <Button color="primary" variant="contained" onClick={onClick} className={classes.button}> 
            Atualizar 
          </Button>
        </Grid>
      </Grid>
    </Widget>
  );
};

Password.propTypes = {
  updatePassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: selectorsAuth.getAuth(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsAuth
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Password);
