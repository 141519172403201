import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as selectors from '../../store/malha/reducer';
import * as actions from '../../store/malha/actions';
import DefaultSelect from '../DefaultSelect';
import MultiSelect from '../MultiSelect';

const MalhaSelect = ({ malhas, onSelect, value, isRequired, error, helperText, findOptions, disabled, multiSelect }) => {
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState([]);
  const handleChange = (event) => onSelect(event.target.value);

  useEffect(() => {
    findOptions();
  }, [findOptions]);

  useEffect(() => {
    if (malhas) {
      setOptions(malhas.map(m => ({value: m.id, label: m.nome})));
      setValues(value ? malhas.filter(i => value.includes(i.id)).map(m => ({value: m.id, label: m.nome})) : []);
    } else {
      setOptions([]);
      setValues(value ? value : []);
    }
  }, [malhas, value]);

  return (
    multiSelect ?
      <MultiSelect
        label="Malhas"
        onChange={values => onSelect(values ? values.map(v => v.value) : null)}
        defaultValue={values}
        options={options}
      /> 
      :
      <DefaultSelect 
        error={error}
        helperText={helperText}
        isRequired={isRequired}
        label="Malha"
        onSelect={handleChange}
        options={malhas}
        value={value}
        disabled={disabled}
      />
  );
}

MalhaSelect.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
  malhas: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  findOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  multiSelect: PropTypes.bool
};

MalhaSelect.defaultProps = {
  error: false,
  helperText: null,
  isRequired: false,
  disabled: false,
  multiSelect: false
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
}, dispatch);

const mapStateToProps = state => ({
  malhas: selectors.getMalhasOptions(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(MalhaSelect);