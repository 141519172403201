import * as Type from './actionsTypes';

const loadVeiculos = filtro => ({ type: Type.FETCH_VEICULOS, filtro });

const deleteVeiculo = id => ({ type: Type.DELETE_VEICULO, id });

const saveVeiculo = (data, callback) => ({ type: Type.SAVE_VEICULO, data, callback });

const updateVeiculo = (data, callback) => ({ type: Type.UPDATE_VEICULO, data, callback });

const findVeiculoById = id => ({ type: Type.VEICULO_FIND_BY_ID, id });

const findVeiculosOptions = ativo => ({ type: Type.VEICULO_FIND_OPTIONS, ativo });

const exportVeiculo = (filtro, callback) => ({ type: Type.VEICULO_EXPORT, filtro, callback });

const findHistoricoById = id => ({ type: Type.HISTORICO_VEICULO_FIND_BY_ID, id });

const upsertHistorico = (data, callback) => ({ type: Type.HISTORICO_VEICULO_UPSERT, data, callback });

const deleteHistoricoVeiculo = (id, callback) => ({ type: Type.HISTORICO_VEICULO_DELETE, id, callback });

export {
  loadVeiculos,
  deleteVeiculo,
  saveVeiculo,
  updateVeiculo,
  findVeiculoById,
  findVeiculosOptions,
  exportVeiculo,
  findHistoricoById,
  upsertHistorico,
  deleteHistoricoVeiculo,
}
