/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import * as selectorsLoader from '../../store/loader/reducer';
import { Grid, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'fixed',
    display: 'block',
    opacity: 0.7,
    backgroundColor: '#fff',
    zIndex: 9999,
    textAlign: 'center',
  },
  progress: {
    position: 'absolute',
    top: '45%',
    left: '40%',
    zIndex: 99999,
    [theme.breakpoints.up('sm')]: {
      left: '45%',
    }
  },
}));

const Toast = ({ loading }) => {
  const [showLoading, setShowLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setShowLoading(loading);
  }, [loading]);

  return (
    <>
      {showLoading && <Grid className={classes.root}>
          <CircularProgress size={75} className={classes.progress}/>
        </Grid>
      }
    </>
  )
};

Toast.propTypes = {
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loading: selectorsLoader.getLoading(state),
});

export default connect(mapStateToProps, null)(Toast);
