/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as selectorsUsuarioDocumentos from '../../store/usuarioDocumento/reducer';
import * as actionsUsuarioDocumentos from '../../store/usuarioDocumento/actions';
import { withRouter } from 'react-router-dom';

import { Input, DialogDefault, ArquivoContainer, TrueFalseSelect, DefaultSelect, DatePicker, NumberInput } from '../../components';

const schema = {
  tipo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  possuiValidade: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const Formulario = ({ saveUsuarioDocumento, updateUsuarioDocumento, selectedUsuarioDocumento, findUsuarioDocumentoById, open, id, onClose }) => {
  const classes = useStyles();

  const [values, setValues] = useState({
    tipo: "DOCUMENTO",
    possuiValidade: "true"
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) findUsuarioDocumentoById(id);
  }, [id, findUsuarioDocumentoById]);

  useEffect(() => {
    setValues({
      tipo: "DOCUMENTO",
      possuiValidade: "true"
    })
  }, [open]);

  useEffect(() => {
    if (id && selectedUsuarioDocumento) {
      setValues(selectedUsuarioDocumento);
    }
  }, [id, selectedUsuarioDocumento]);

  const onSave = () => {
    let schemaData = schema;
    if (!values.validadeEmAnos && (values.possuiValidade === "true" || values.possuiValidade === true)) {
      schemaData = { ...schemaData, validadeEmAnos: { presence: { allowEmpty: false, message: 'é obrigatório' } } }
    }

    const erros = validate(values, schemaData);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateUsuarioDocumento(values, () => onCloseLocal(true));
      else saveUsuarioDocumento(values, () => onCloseLocal(true));
    }
  }

  const onCloseLocal = (isSave) => {
    setErrors({});
    setValues({})
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Documento / Certificado"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <form autoComplete="off" noValidate>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item md={4}>
            <DefaultSelect
              label="Tipo"
              name="tipo"
              showEmpty={false}
              isRequired
              options={[
                { id: 'DOCUMENTO', nome: 'Documento' },
                { id: 'CERTIFICADO', nome: 'Certificado' },
              ]}
              onSelect={handleChange}
              value={values.tipo}
            />
          </Grid>
          <Grid item xs={8} className={classes.fieldContainer}>
            <Input
              error={errors && errors.nome}
              helperText={errors && errors.nome ? errors.nome : null}
              isRequired
              label="Nome"
              name="nome"
              onChange={handleChange}
              placeholder="Nome"
              value={values.nome}
            />
          </Grid>
          <Grid item xs={4} className={classes.fieldContainer}>
            <TrueFalseSelect
              label="Possui Validade"
              name="possuiValidade"
              showEmpty={false}
              isRequired
              onChange={handleChange}
              value={values.possuiValidade}
            />
          </Grid>
          {(values.possuiValidade === "true" || values.possuiValidade === true) && <Grid item xs={4} className={classes.fieldContainer}>
            <NumberInput
              error={errors && errors.validadeEmAnos}
              isRequired={values.possuiValidade === "true" || values.possuiValidade === true}
              label="Validade (em anos)"
              onChange={valor => setValues({
                ...values,
                validadeEmAnos: valor
              })}
              value={values.validadeEmAnos}
              decimalScale={0}
              prefix=""
              thousandSeparator=""
            />
          </Grid>}
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              label="Descrição"
              name="descricao"
              onChange={handleChange}
              placeholder="descricao"
              value={values.descricao}
              rows={4}
              multiline
            />
          </Grid>
          {/* TODO: VALIDAR O PORQUE O ARQUIVO NÃO ESTÃO SENDO SALVOS CORRETAMENTE */}
          {/* <ArquivoContainer
            arquivos={values.arquivos}
            onSave={arquivos => setValues({ ...values, arquivos })}
          /> */}
        </Grid>
      </form>
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  selectedUsuarioDocumento: selectorsUsuarioDocumentos.getSelectedUsuarioDocumento(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsUsuarioDocumentos,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
