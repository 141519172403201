import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isLogin, isPublicRouter } from '../../utils';

const RouteWithLayout = ({ layout: Layout, isPublic, history, component: Component, ...rest }) => {
  const isLoged = isLogin();
  const isRouterPublic = isPublicRouter(history.location.pathname);
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          { !isPublic && !isLoged && !isRouterPublic ? <Redirect to="/login" /> : 
            <Component {...matchProps} />}
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  path: PropTypes.string,
  isPublic: PropTypes.bool,
};

RouteWithLayout.defaultValue = {
  isPublic: false
}

export default withRouter(RouteWithLayout);
