/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault } from '../../../components';
import Formulario from './Formulario';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  iconValidate: {
    marginRight: theme.spacing(1),
    color: 'green'
  },
  iconInvalidate: {
    marginRight: theme.spacing(1),
    color: 'red'
  }
}));

const Pedidos = ({ exportNota }) => {
  const [isEntrada, setIsEntrada] = useState(false);
  const [open, setOpen] = useState(false);

  const [id, setId] = useState(null);
  const [titles] = useState(['Data do Pedido', 'Tipo', 'Local', 'Fornecedor', 'Repasse Colaborador']);
  const [localFilter, setLocalFilter] = useState({
    inicio: moment().subtract(2, 'months').startOf('month').valueOf(),
    fim: moment().endOf('day').valueOf()
  });
  const [openReport, setOpenReport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [reload, setReload] = useState(null);

  const classes = useStyles();
  
  const renderRows = p => {
    return (
      <>
        <TableCell size="small">{moment(p[1]).format("DD/MM/YYYY") }</TableCell>
        <TableCell size="small">{p[2] === "SAIDA" ? "Saída" : "Entrada"}</TableCell>
        <TableCell size="small">{p[3]}</TableCell>
        <TableCell size="small">{p[4] || '-'}</TableCell>
        <TableCell size="small">{p[5] || '-'}</TableCell>
      </>
    )
  }

  const onClose = isSave => {
    if (isSave) {
      setReload(!reload);
    }

    setId(null);
    setOpen(false);
  }

  const getHeaderActions = () => {
    let actions = [
      { 
        title: 'Nova Entrada',
        onClick: () => {
          setIsEntrada(true);
          setOpen(true);
        } 
      },
      { 
        title: 'Nova Saída',
        onClick: () => {
          setIsEntrada(false);
          setOpen(true);
        } 
      }
    ];

    return actions;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useProduto
            useLocal
            useColaborador
            useTipoPedido
            useFornecedor
            filterData={localFilter}
            onFilter={filter => {
              setLocalFilter(filter);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          titles={titles}
          headerTitle="Pedidos"
          hasAdd={false}
          renderRows={renderRows}
          headerActions={getHeaderActions()}
          useFilterData
          usePaginationApi
          paginationApi="pedidos/filter"
          paginationUrl="pedidos"
          filterData={localFilter}
          reload={reload}
          hasRowActions
          idAsList
          onEdit={id => {
            setId(id);
            setOpen(true);
          }}
          showDelete={false}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          isEntrada={isEntrada}
        />
      </Grid>
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={() => {
          exportNota(localFilter, false, () => {
            setOpenExport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja exportar as notas filtradas? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openReport}
        handlerCancel={() => setOpenReport(false)}
        handlerConfirm={() => {
          exportNota(localFilter, true, () => {
            setOpenReport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja gerar o relatório das notas filtradas? </DialogContentText>
      </DialogDefault>
    </>
  );
};

export default Pedidos;