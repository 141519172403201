export const ALIMENTADORES_FIND = 'ALIMENTADORES_FIND';
export const ALIMENTADORES_FIND_SUCCESS = 'ALIMENTADORES_FIND_SUCCESS';

export const ALIMENTADORES_DELETE = 'ALIMENTADORES_DELETE';
export const ALIMENTADORES_DELETE_SUCCESS = 'ALIMENTADORES_DELETE_SUCCESS';

export const ALIMENTADORES_SAVE = 'ALIMENTADORES_SAVE';
export const ALIMENTADORES_SAVE_SUCCESS = 'ALIMENTADORES_SAVE_SUCCESS';

export const ALIMENTADORES_UPDATE = 'ALIMENTADORES_UPDATE';
export const ALIMENTADORES_UPDATE_SUCCESS = 'ALIMENTADORES_UPDATE_SUCCESS';

export const ALIMENTADORES_CLEAN = 'ALIMENTADORES_CLEAN';

export const ALIMENTADORES_OPTIONS = 'ALIMENTADORES_OPTIONS';
export const ALIMENTADORES_OPTIONS_SUCCESS = 'ALIMENTADORES_OPTIONS_SUCCESS';