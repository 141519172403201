/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import * as selectorsVeiculos from '../../store/veiculo/reducer';
import * as actionsVeiculos from '../../store/veiculo/actions';
import * as selectorsAuth from '../../store/auth/reducer';

import { Input, DefaultSelect, NumberInput, TrueFalseSelect, ArquivoContainer, DatePicker } from '../../components';

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const InformacaoBasicaTab = ({ values, setValues, errors, handleChange }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={3} className={classes.fieldContainer}>
        <DefaultSelect
          error={errors && errors.categoria ? true : false}
          isRequired
          label="Categoria"
          name="categoria"
          showEmpty
          options={[
            { id: 'CARRO', nome: 'Carro' },
            { id: 'CAMINHAO', nome: 'Caminhão' },
            { id: 'UTILITARIO_CAMINHONETE', nome: 'Caminhonete' },
            { id: 'MAQUINA', nome: 'Máquina' },
            { id: 'MOTO', nome: 'Moto' },
            { id: 'REBOQUE', nome: 'Reboque' },
          ]}
          onSelect={handleChange}
          value={values.categoria}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          error={errors && errors.placa}
          helperText={errors && errors.placa ? errors.placa : null}
          isRequired
          label="Placa"
          name="placa"
          onChange={handleChange}
          placeholder="Placa"
          value={values.placa}
        />
      </Grid>
      {values.id && <>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Criado Por"
            disabled
            value={values.criadoPorName}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Inclusão"
            disabled
            value={values.dataInclusao}
          />
        </Grid>
      </>}
      <Grid item xs={3} className={classes.fieldContainer}>
        <DefaultSelect
          error={errors && errors.tipoVeiculo ? true : false}
          isRequired
          label="Proprietário"
          name="tipoVeiculo"
          showEmpty
          options={[
            { id: 'ELETROPONTES', nome: 'Eletropontes' },
            { id: 'PARTICULAR', nome: 'Particular' },
            { id: 'ALUGADO', nome: 'Alugado' }
          ]}
          onSelect={event => {
            setValues({
              ...values,
              [event.target.name]: event.target.value,
              valorAquisicao: undefined,
              dataAquisicao: undefined,
              transferido: undefined,
              dataTransferencia: undefined,
            });
          }}
          value={values.tipoVeiculo}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <NumberInput
          prefix="R$"
          label="Valor da Aquisição"
          name="valorAquisicao"
          isRequired={values.tipoVeiculo === 'ELETROPONTES'}
          disabled={values.tipoVeiculo !== 'ELETROPONTES'}
          onChange={valor => setValues({
            ...values,
            valorAquisicao: valor
          })}
          value={values.valorAquisicao || undefined}
          decimalScale={2}
          error={errors && errors.valorAquisicao ? true : false}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <DatePicker
          label="Data da Aquisição"
          isRequired={values.tipoVeiculo === 'ELETROPONTES'}
          disabled={values.tipoVeiculo !== 'ELETROPONTES'}
          error={errors && errors.dataAquisicao ? true : false}
          onChange={data => setValues({
            ...values,
            dataAquisicao: data.valueOf()
          })}
          value={values.dataAquisicao}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <TrueFalseSelect
          error={errors && errors.transferido ? true : false}
          isRequired={values.tipoVeiculo === 'ELETROPONTES'}
          disabled={values.tipoVeiculo !== 'ELETROPONTES'}
          label="CRV Transferido"
          name="transferido"
          onChange={handleChange}
          value={values.transferido || undefined}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <DatePicker
          label="Data da Transferência"
          isRequired={values.tipoVeiculo === 'ELETROPONTES' && values.transferido === 'true'}
          disabled={values.tipoVeiculo !== 'ELETROPONTES' || (values.transferido !== 'true' && values.transferido !== true)}
          error={errors && errors.dataTransferencia ? true : false}
          onChange={data => setValues({
            ...values,
            dataTransferencia: data.valueOf()
          })}
          value={values.dataTransferencia}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <NumberInput
          prefix=""
          label="Km Inicial"
          name="kmInicial"
          onChange={kmInicial => setValues({
            ...values,
            kmInicial
          })}
          value={values.kmInicial}
          format="##"
          decimalScale={0}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          disabled
          label="Km Atual"
          name="kmAtual"
          onChange={handleChange}
          value={values.kmAtual}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <TrueFalseSelect
          error={errors && errors.ativo ? true : false}
          isRequired
          label="Ativo"
          name="ativo"
          onChange={handleChange}
          value={values.ativo}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Modelo"
          name="modelo"
          onChange={handleChange}
          value={values.modelo}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Ano Modelo"
          name="ano"
          onChange={handleChange}
          value={values.ano}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Fabricante"
          name="fabricante"
          onChange={handleChange}
          value={values.fabricante}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Ano Fabricação"
          name="anoFabricacao"
          onChange={handleChange}
          value={values.anoFabricacao}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Cor"
          name="cor"
          onChange={handleChange}
          value={values.cor}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="CRLV"
          name="crlv"
          onChange={handleChange}
          value={values.crlv}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Chassi"
          name="chasi"
          onChange={handleChange}
          value={values.chasi}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Renavam"
          name="renavam"
          onChange={handleChange}
          value={values.renavam}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Tipo"
          name="tipo"
          onChange={handleChange}
          value={values.tipo}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Combustível"
          name="combustivel"
          onChange={handleChange}
          value={values.combustivel}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <TrueFalseSelect
          error={errors && errors.possuiRastreador ? true : false}
          isRequired
          label="Possui Rastreador"
          name="possuiRastreador"
          onChange={handleChange}
          value={values.possuiRastreador}
        />
      </Grid>
      {(values.possuiRastreador === "true" || values.possuiRastreador === true) && <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          error={errors && errors.numRastreador ? true : false}
          isRequired={values.possuiRastreador === "true" || values.possuiRastreador === true}
          label="Num. Rastreador"
          name="numRastreador"
          onChange={handleChange}
          value={values.numRastreador}
        />
      </Grid>}
      {values.id && <Grid item xs={6} className={classes.fieldContainer}>
        <Input
          disabled
          label="Último repasse"
          value={values.ultimoRepasse}
        />
      </Grid>}
      <Grid item xs={12} className={classes.fieldContainer}>
        <Input
          label="Descrição"
          name="descricao"
          onChange={handleChange}
          value={values.descricao}
          rows={3}
          multiline
        />
      </Grid>
      <ArquivoContainer
        arquivos={values.arquivos}
        onSave={arquivos => setValues({ ...values, arquivos })}
      />
    </Grid>
  );
};

InformacaoBasicaTab.propTypes = {
  findVeiculoById: PropTypes.func.isRequired,
  saveVeiculo: PropTypes.func.isRequired,
  updateVeiculo: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  selectedVeiculo: PropTypes.object,
  authData: PropTypes.object.isRequired
};

InformacaoBasicaTab.defaultValue = {
  selectedVeiculo: null,
  id: null,
}

const mapStateToProps = state => ({
  selectedVeiculo: selectorsVeiculos.getSelectedVeiculo(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsVeiculos,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InformacaoBasicaTab);
