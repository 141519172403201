import * as Type from './actionsTypes';

const INITIAL_STATE = {
  notas: [],
  selectedNota: null,
};

const ordernarData = (a,b) => new Date(Number(b.data)) - new Date(Number(a.data));

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_NOTAS_SUCCESS: {
      return {
        ...state,
        notas: action.data.sort(ordernarData)
      }
    }
    case Type.DELETE_NOTA_SUCCESS: {
      return {
        ...state,
        notas: state.notas.filter(s => s.id !== action.data).sort(ordernarData)
      }
    }
    case Type.SAVE_NOTA_SUCCESS: {
      return {
        ...state,
        notas: [...state.notas, action.data].sort(ordernarData)
      }
    }
    case Type.UPDATE_NOTA_SUCCESS: {
      return {
        ...state,
        notas: [...state.notas.filter(s => s.id !== action.data.id), action.data].sort(ordernarData)
      }
    }
    case Type.NOTA_VALIDATE_BY_ID_SUCCESS: {
      const nota = state.notas.filter(s => s.id === action.id)[0];
      nota.validate = action.validate;
      return {
        ...state,
        notas: [...state.notas.filter(s => s.id !== action.id), nota].sort(ordernarData)
      }
    }
    case Type.NOTA_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedNota: action.data
      }
    }
    case Type.CLEAR_NOTA: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getNotas = state => state.rootState.nota.notas;
export const getSelectedNota = state => state.rootState.nota.selectedNota;
