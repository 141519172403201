/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import {
  Avatar,
  Grid,
  Divider,
  Button,
  IconButton
} from '@material-ui/core';
import { toBase64 } from '../../../../common/base64';
import { Input, Widget } from '../../../../components';
import * as actionsAuth from '../../../../store/auth/actions';
import * as selectorsAuth from '../../../../store/auth/reducer';
import { masks } from '../../../../helpers';

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  email: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  input: {
    display: 'none'
  },
  avatarContainer: {
    paddingBottom: 5
  },
  button: {
    marginTop: 8
  },
  divider: {
    marginTop: 10,
    background: theme.palette.bar.grayMiddle
  },
  containerItem: {
    paddingTop: '0px !important'
  },
  gridContainer: {
    padding: theme.spacing(2),
    paddingTop: 26,
    paddingBottom: 30
  },
}));

const Perfil = ({ updateProfile, auth }) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const classes = useStyles();

  useEffect(() => {
    setValues({
      email: auth.email,
      nome: auth.nome,
      telefone: auth.telefone,
      avatar: auth.avatar,
      matricula: auth.matricula
    });
  }, [auth]);

  const onChangeImg = async e => {
    if (e.target.files[0]) {
      const base64 = await toBase64(e.target.files[0]);
      setValues({
        ...values,
        avatar: base64
      });
    }
  }

  const onChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  const onChangePhone = event => {
    setValues({
      ...values,
      telefone: masks.removePhoneMask(event.target.value.substring(0, 15))
    })
  }

  const onClick = () => {
    const errors = validate(values, schema);
    setErrors(errors)
    if (!errors) {
      updateProfile({
        nome: values.nome,
        telefone: values.telefone,
        avatar: values.avatar
      });
    }
  }

  const getBase64 = image => image && image.includes('data:image') ? image : `data:image/png;base64,${image}`;

  return (
    <Widget title={"Meus Dados"}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} className={classes.avatarContainer}>
          <Grid container direction="row" justify="center" alignItems="center">
            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={onChangeImg} />
            <label htmlFor="icon-button-file">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <Avatar className={classes.avatar} src={values.avatar? getBase64(values.avatar) : '/images/avatars/default.png'} />
              </IconButton>
            </label>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.containerItem}>
          <Input label="Matricula" value={values.matricula} disabled />
        </Grid>
        <Grid item xs={8} className={classes.containerItem}>
          <Input
            error={errors && errors.nome}
            helperText={errors && errors.nome ? errors.nome : null}
            label="Nome"
            name="nome"
            isRequired
            onChange={onChange}
            value={values.nome}
            placeholder="Nome"
          />
        </Grid>
        <Grid item xs={6} className={classes.containerItem}>
          <Input
            label="Login"
            name="email"
            disabled
            value={values.email}
          />
        </Grid>
        <Grid item xs={6} className={classes.containerItem}>
          <Input
            label="Telefone"
            name="telefone"
            onChange={onChangePhone}
            value={masks.phoneMask(values.telefone)}
            placeholder="(00) 00000-0000"
          />
        </Grid>
        <Grid item xs={12} className={classes.containerItem}>
          <Divider className={classes.divider}/>
          <Button color="primary" variant="contained" onClick={onClick} className={classes.button}> 
            Salvar 
          </Button>
        </Grid>
      </Grid>
    </Widget>
  );
};

Perfil.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: selectorsAuth.getAuth(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsAuth
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Perfil);
