import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import InputGroup from '../InputGroup';
import Select from 'react-select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const customStyles = {
  control: base => ({
    ...base,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  }),
  container: provided => ({
    ...provided,
    marginTop: 8
  }),
  menu: provided => ({
    ...provided,
    marginTop: 1,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  })
}

const useStyles = makeStyles(() => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  error: {
    color: '#e53935',
    margin:'8px 12px 0',
    fontSize: 11,
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: '0.33px'
  }
}));

const MultiSelect = ({ label, onChange, placement, title, useTooltip, isRequired, options, defaultValue, error, helperText }) => {
  const classes = useStyles();
  
  // eslint-disable-next-line react/no-multi-comp
  const DropdownIndicator = () => (<ArrowDropDownIcon className={classes.arrowIcon}/>)
  const IndicatorSeparator = () => '';

  return (
    <InputGroup
      label={label}
      placement={placement}
      title={title}
      useTooltip={useTooltip}
      isRequired={isRequired}
    >
      <Select
        components={{ DropdownIndicator, IndicatorSeparator }}
        isMulti
        styles={customStyles}
        options={options}
        onChange={onChange}
        value={defaultValue}
        placeholder="Selecione..."
        noOptionsMessage={() => 'Sem opções'}
        menuPlacement="auto"
        maxMenuHeight={200}
      />
      {error ? helperText ? helperText : <p className={classes.error}>O Campo é obrigatório</p> : ''}
    </InputGroup>
  );
}

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.array.isRequired,
  placement: PropTypes.string,
  title: PropTypes.string,
  useTooltip: PropTypes.bool,
  isRequired: PropTypes.any,
  error: PropTypes.bool, 
  helperText: PropTypes.string,
};

MultiSelect.defaultProps = {
  placement: '',
  title: '',
  useTooltip: false,
  isRequired: false,
  error: false,
  helperText: null
}

export default MultiSelect;