/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/equipamento/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';

function* load({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'equipamentos/filtro', data: filtro });
    yield put({
      type: TypeActions.FETCH_EQUIPAMENTOS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.post({ url: 'equipamentos', data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield put({
      type: TypeActions.SAVE_EQUIPAMENTO_SUCCESS,
      data: response.data
    });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao salvar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.put({ url: `equipamentos/${data.id}`, data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield put({
      type: TypeActions.UPDATE_EQUIPAMENTO_SUCCESS,
      data: response.data
    });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.get({ url: `equipamentos/${id}` });
    if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
      response.data.arquivos = yield FileService.downloadArquivos(response.data.arquivos);
    }

    yield put({
      type: TypeActions.EQUIPAMENTO_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* exportEquipamento({ filtro, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'equipamentos/export',
      method: 'POST',
      data: filtro,
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório Equipamentos.xlsx');
    yield callback(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar o relatório!'
    });
  }
}

function* deleteById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.delete({ url: `equipamentos/${id}`});
    yield put({
      type: TypeActions.DELETE_EQUIPAMENTO_SUCCESS,
      id
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações removidas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao deletar";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
    }

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: message
    });
  }
}

function* upsertHistoricoEquipamentos({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.post({ url: 'historico-equipamento', data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }
    yield callback(true, response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findHistoricoEquipamentoById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.get({ url: `historico-equipamento/${id}` });
    if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
      response.data.arquivos = yield FileService.downloadArquivos(response.data.arquivos);
    }

    yield put({
      type: TypeActions.HISTORICO_EQUIPAMENTO_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* deleteHistoricoEquipamento({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.delete({ url: `historico-equipamento/${id}` });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_EQUIPAMENTOS, load);
  yield takeLatest(TypeActions.SAVE_EQUIPAMENTO, save);
  yield takeLatest(TypeActions.UPDATE_EQUIPAMENTO, update);
  yield takeLatest(TypeActions.EQUIPAMENTO_FIND_BY_ID, findById);
  yield takeLatest(TypeActions.EQUIPAMENTO_EXPORT, exportEquipamento);
  yield takeLatest(TypeActions.DELETE_EQUIPAMENTO, deleteById);
  yield takeLatest(TypeActions.HISTORICO_EQUIPAMENTO_UPSERT, upsertHistoricoEquipamentos);
  yield takeLatest(TypeActions.HISTORICO_EQUIPAMENTO_FIND_BY_ID, findHistoricoEquipamentoById);
  yield takeLatest(TypeActions.HISTORICO_EQUIPAMENTO_DELETE, deleteHistoricoEquipamento);
}