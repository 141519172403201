import * as Type from './actionsTypes';

const loadNsirs = filter => ({ type: Type.NSIRS_FIND, filter });

const importNsirs = (data, callbackAfterSave) => ({ type: Type.NSIRS_IMPORT, data, callbackAfterSave });

const loadNsirById = id => ({ type: Type.NSIRS_FIND_BY_ID, id });

const deleteNsir = (id, callback) => ({ type: Type.NSIRS_DELETE, id, callback });

const saveNsir  = data => ({ type: Type.NSIRS_SAVE, data });

const updateNsir = data => ({ type: Type.NSIRS_UPDATE, data });

const iniciarDigitacaoNsir = data => ({ type: Type.NSIRS_INICIAR_DIGITACAO, data });

const trocarDigitadorNsir = data => ({ type: Type.NSIRS_TROCAR_DIGITADOR, data });

const cleanSelectedNsir = () => ({ type: Type.NSIRS_CLEAN_SELECTED });

const updateStatus = (data, callback) => ({ type: Type.NSIRS_UPDATE_STATUS, data, callback });

const generateNsirReport = (filter, callback) => ({ type: Type.NSIRS_GENERATE_REPORT, filter, callback });

const generateNsirReportById = data => ({ type: Type.NSIRS_GENERATE_REPORT_BY_ID, data });

const generateInspecaoReport = (filter, callback) => ({ type: Type.NSIRS_GENERATE_INSPECAO_REPORT, filter, callback });

const exportNsirs = (filter, callbackAfterSave) => ({ type: Type.NSIRS_EXPORT, filter, callbackAfterSave });

const exportToEditNsirs = (filter, callbackAfterSave) => ({ type: Type.NSIRS_EXPORT_TO_EDIT, filter, callbackAfterSave });

const exportFinanceiro = (filter, callbackAfterSave) => ({ type: Type.NSIRS_EXPORT_FINANCEIRO, filter, callbackAfterSave });

const importNsirServices = (data, callbackAfterSave) => ({ type: Type.NSIRS_IMPORT_SERVICES, data, callbackAfterSave });

const updateFinanceiro = data => ({ type: Type.NSIRS_UPDATE_FINANCEIRO, data });

const importNsirFinanceiro = (data, callbackAfterSave) => ({ type: Type.NSIRS_IMPORT_FINANCEIRO, data, callbackAfterSave });

const exportCcsm = id => ({ type: Type.NSIRS_EXPORT_CCSM, id });

const nsirExport = (path, fileName, filter, callback) => ({ type: Type.NSIRS_BASE_EXPORT, path, fileName, filter, callback });

const defaultNsirImport = (path, data, callback) => ({ type: Type.NSIRS_BASE_IMPORT, path, data, callback });

export {
  loadNsirs,
  importNsirs,
  loadNsirById,
  deleteNsir,
  saveNsir,
  updateNsir,
  updateStatus,
  cleanSelectedNsir,
  generateNsirReport,
  generateNsirReportById,
  generateInspecaoReport,
  exportNsirs,
  exportToEditNsirs,
  exportFinanceiro,
  iniciarDigitacaoNsir,
  trocarDigitadorNsir,
  importNsirServices,
  updateFinanceiro,
  importNsirFinanceiro,
  exportCcsm,
  nsirExport,
  defaultNsirImport
}
