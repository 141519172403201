import React from 'react';
import DefaultSelect from '../DefaultSelect';

const TrueFalseSelect = ({
  error,
  label,
  name,
  onChange,
  value,
  disabled,
  isRequired,
  showEmpty
}) => (
  <DefaultSelect
    error={error}
    isRequired={isRequired}
    label={label}
    name={name}
    showEmpty={showEmpty}
    onSelect={onChange}
    options={[
      { id: 'true', descricao: 'Sim' },
      { id: 'false', descricao: 'Não' }
    ]}
    value={value}
    handlerOption={option => option.descricao}
    disabled={disabled}
  />
)

export default TrueFalseSelect;
