/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as selectorsProdutos from '../../../store/produto/reducer';
import { saveProduto, updateProduto, loadProdutosOptions, loadProdutoTamanhoOptions } from '../../../store/produto/actions';
import { Input, DialogDefault, DefaultSelect, NumberInput, TrueFalseSelect } from '../../../components';
import { isFalse, isNullOrUndefined, isTrue, moneyFormatter } from 'utils';
import { v4 as uuidv4 } from 'uuid';

const entradaDevolucaoSchema = {
  produto: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  quantidade: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  devolucaoUso: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const entradaSchema = {
  produto: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  quantidade: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  devolucaoUso: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  valorUnitario: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const saidaSchema = {
  produto: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  quantidade: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  buttonContainer: {
    width: '100%',
  },
  error: {
    color: '#e53935',
    marginTop: 10,
    fontSize: 12,
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: '0.33px'
  }
}));

const Formulario = ({ isEntrada, onSave, open, selectedProduto, onClose, loadProdutosOptions, produtoOptions, produtoTamanhoOptions, loadProdutoTamanhoOptions }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [tamanhoProdutos, setTamanhoProdutos] = useState({
    options: [],
    possuiTamanho: false,
  });

  useEffect(() => {
    loadProdutosOptions();
    loadProdutoTamanhoOptions();
  }, []);

  useEffect(() => {
    if (selectedProduto) {
      setValues(selectedProduto);
    } else {
      setValues({});
    }
  }, [selectedProduto]);

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({})
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSaveLocal = () => {
    let erros = {}
    if (isEntrada && isTrue(values.devolucaoUso)) {
      erros = validate(values, entradaDevolucaoSchema)
    } else if (isEntrada) {
      erros = validate(values, entradaSchema)
    } else {
      erros = validate(values, saidaSchema)
    }

    if (tamanhoProdutos.possuiTamanho && (values.tamanho === undefined || values.tamanho === null)) {
      erros = {
        ...erros,
        tamanho: true,
      }
    }

    setErrors(erros);
    if (!erros || Object.keys(erros).length === 0) {
      let data = values;
      if (!data.id) {
        data = { ...data, id: uuidv4() }
      }

      onSave(data);
      setValues({});
    }
  };

  const updateQuantidadeValorUnitario = (quantidade, valorUnitario) => {
    let valorTotal = undefined;
    if (quantidade && valorUnitario) {
      valorTotal = quantidade * valorUnitario;
    }

    setValues({
      ...values,
      quantidade,
      valorUnitario,
      valorTotal
    });
  }

  const setProduto = event => {
    const produtoId = event.target.value;
    let tamanhos = produtoTamanhoOptions || [];
    if (tamanhos.length > 0) {
      tamanhos = tamanhos.filter(t => t.produtoId === produtoId);
    }

    setValues({
      ...values,
      produto: produtoId,
      produtoNome: event.target.value ? produtoOptions.filter(p => p.id === event.target.value)[0].nome : undefined,
      tamanho: undefined,
      tamanhoValue: undefined,
    });

    setTamanhoProdutos({
      options: tamanhos,
      possuiTamanho: tamanhos.length > 0,
    });
  }

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSaveLocal}
      title="Produto"
      confirmMessage={selectedProduto ? "Atualizar" : "Adicionar"}
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={tamanhoProdutos.possuiTamanho ? 4 : 6} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.produto ? true : false}
            isRequired
            label="Produto"
            showEmpty
            options={produtoOptions}
            onSelect={setProduto}
            value={values.produto}
          />
        </Grid>
        {tamanhoProdutos.possuiTamanho && <Grid item xs={2} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.tamanho ? true : false}
            isRequired
            label="Tamanho"
            optionLabel="tamanho"
            showEmpty
            options={tamanhoProdutos.options || []}
            onSelect={event => {
              setValues({
                ...values,
                tamanho: event.target.value || null,
                tamanhoValue: event.target.value ? tamanhoProdutos.options.filter(t => t.id === event.target.value)[0].tamanho : undefined,
              });
            }}
            value={values.tamanho}
          />
        </Grid>}
        {isEntrada && <Grid item xs={3} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.devolucaoUso}
            isRequired
            label="Devolução de uso"
            onChange={event => setValues({
              ...values,
              devolucaoUso: event.target.value || null,
              valorUnitario: event.target.value !== 'false' ? undefined : values.valorUnitario,
            })}
            value={values.devolucaoUso}
          />
        </Grid>}
        <Grid item xs={isEntrada ? 3 : 6} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.quantidade}
            isRequired
            label="Quantidade"
            onChange={quantidade => updateQuantidadeValorUnitario(quantidade, values.valorUnitario)}
            value={values.quantidade}
            format="##"
            prefix=""
            decimalScale={0}
          />
        </Grid>
        {isEntrada && <>
          <Grid item xs={3} className={classes.fieldContainer}>
            <NumberInput
              error={errors && errors.valorUnitario}
              isRequired={isFalse(values.devolucaoUso) && !isNullOrUndefined(values.devolucaoUso)}
              label="Valor Unitário"
              onChange={valorUnitario => updateQuantidadeValorUnitario(values.quantidade, valorUnitario)}
              value={values.valorUnitario}
              format="##,######"
              disabled={isTrue(values.devolucaoUso) || isNullOrUndefined(values.devolucaoUso)}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <Input
              disabled
              label="Valor Total"
              value={moneyFormatter(values.valorTotal)}
            />
          </Grid>
        </>}
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Descrição"
            name="descricao"
            onChange={handleChange}
            value={values.descricao}
            rows={3}
            multiline
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  produtoOptions: selectorsProdutos.getProdutoOptions(state),
  produtoTamanhoOptions: selectorsProdutos.getProdutoTamanhoOptions(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveProduto,
  updateProduto,
  loadProdutosOptions,
  loadProdutoTamanhoOptions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
