/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { 
  AppBar,
  Box, 
  Grid,
  Typography,
  Tab,
  Tabs
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  appBar: {
    background: theme.palette.bar.principal,
    boxShadow: 'none'
  },
  container: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 4,
    backgroundColor: theme.palette.bar.grayLight,
    border: theme.palette.bar.border,
  },
  tabContainer: {
    padding: 8
  },
  button: {
    padding: 0,
    color: theme.palette.textColor.white
  },
  menuItem: {
    color: theme.palette.textColor.gray,
    '&:hover': {
      backgroundColor: theme.palette.bar.darkOrange,
    },
  },
  menuItemSelected: {
    backgroundColor: theme.palette.bar.darkOrange,
    color: theme.palette.textColor.white,
  },
  paper: {
    marginLeft: 5
  },
  list: {
    backgroundColor: theme.palette.bar.principal,
  },
  tab: {
    fontSize: 16
  }
}));

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      aria-labelledby={`simple-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const getWidgets = (widgets, tab) => {
  return widgets.map((t, i) => {
    return (
      <TabPanel key={i} value={tab} index={i}>
        {t.element}
      </TabPanel>
    )
  })
}

const WidgetTab = ({ widgets, classNameAppBar, classNameContainer, currentTab , onChangeTab }) => {
  const [tab, setTab] = useState(currentTab);
  const classes = useStyles();

  useEffect(() => {
    if (Number.isInteger(currentTab) && currentTab !== tab) setTab(currentTab);
  }, [currentTab]);

  return (
    <Grid className={clsx(classes.container, classNameContainer)}>
      <AppBar position="static" className={clsx(classes.appBar, classNameAppBar)}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.tabContainer}
        >
          <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
            {
              widgets.map((t, i) => (
                <Tab label={t.label} disabled={t.disabled} className={classes.tab}/>
              ))
            }
          </Tabs>
        </Grid>
      </AppBar>
      {getWidgets(widgets, tab)}
    </Grid>
  );
};


WidgetTab.propTypes = {
  widgets: PropTypes.array.isRequired,
  currentTab: PropTypes.number,
  onChangeTab: PropTypes.func,
  children: PropTypes.node,
};

WidgetTab.defaultProps = {
  currentTab: 0,
  onChangeTab: () => {},
}

export default WidgetTab;
