import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as selectors from '../../store/usuarioDocumento/reducer';
import * as actions from '../../store/usuarioDocumento/actions';
import DefaultSelect from '../DefaultSelect';
import MultiSelect from '../MultiSelect';

const UsuarioDocumentoSelect = ({ documentoUsuarios, onSelect, value, isRequired, error, isMultiple, findOptions, disabled, label = "Documento", tipoDocumento, filterIds }) => {
  const [options, setOptions] = useState([]);

  const handleChange = (event) => onSelect(event.target.value);

  const handleMultiplo = newValue => {
    let result = null;
    if (newValue) {
      result = newValue.map(v => v.value);
    }
    onSelect(result);
  }

  useEffect(() => {
    findOptions(tipoDocumento);
  }, [findOptions, tipoDocumento]);

  useEffect(() => {
    if (filterIds && filterIds.length > 0 && documentoUsuarios && documentoUsuarios.length > 0) {
      setOptions(documentoUsuarios.filter(d => !filterIds.includes(d.id)));
    } else {
      setOptions(documentoUsuarios || []);
    }
  }, [documentoUsuarios, filterIds]);

  return (
    <>
    { isMultiple ? 
      <MultiSelect
        label={label}
        onChange={newValue => handleMultiplo(newValue)}
        defaultValue={options && value? options.filter(p => value.indexOf(p.id) > -1).map(i => ({ value: i.id, label: `${i['codigo']} - ${i['nome']}` })) : []}
        options={options ? options.map(i => ({ value: i.id, label: `${i['codigo']} - ${i['nome']}` })) : []}
      /> 
      : 
      <DefaultSelect 
        error={error}
        isRequired={isRequired}
        label={label}
        onSelect={handleChange}
        options={options}
        value={value}
        handlerOption={option => option['nome']}
        disabled={disabled}
      />
    }
    </>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
}, dispatch);

const mapStateToProps = state => ({
  documentoUsuarios: selectors.getUsuarioDocumentosOptions(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioDocumentoSelect);