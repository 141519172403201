/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, List, ListItem } from '@material-ui/core';
import { Table } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  message: {
    padding: 0
  }
}));

const AuditTab = ({ auditMessages, nsirTitle }) => {

  const classes = useStyles();

  const getMessage = message => {
    if (message) {
      return (
        <List>
          {message.split("-").map((field, i) => (
            <ListItem className={classes.message}  key={i}>{i === 0? '' : '-'}{field}</ListItem>
          ))}
        </List>
      );
    }

    return message 
  }

  const renderRows = d => {
    return (
      <>
        <TableCell size="small">{d.timestamp}</TableCell>
        <TableCell size="small">{d.username}</TableCell>
        <TableCell>{getMessage(d.message)}</TableCell>
      </>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.root}>
          <Table
            titles={[
              'Data Modificação',
              'Usuário',
              'Descrição',
            ]}
            headerTitle={`Auditoria > ${nsirTitle}`}
            hasAdd={false}
            renderRows={renderRows}
            hasRowActions={false}
            rows={auditMessages || []}
          />
        </Grid>
      </Grid>
    </>
  );
};

AuditTab.propTypes = {
  auditMessages: PropTypes.array,
  nsirTitle: PropTypes.string,
};

export default withRouter(AuditTab);
