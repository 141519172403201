import * as Type from './actionsTypes';

const loadDrones = filtro => ({ type: Type.FETCH_DRONES, filtro });

const deleteDrone = id => ({ type: Type.DELETE_DRONE, id });

const saveDrone = (data, callback) => ({ type: Type.SAVE_DRONE, data, callback });

const updateDrone = (data, callback) => ({ type: Type.UPDATE_DRONE, data, callback });

const findDroneById = id => ({ type: Type.DRONE_FIND_BY_ID, id });

const exportDrone = (filtro, callback) => ({ type: Type.DRONE_EXPORT, filtro, callback });

const findHistoricoById = id => ({ type: Type.HISTORICO_DRONE_FIND_BY_ID, id });

const upsertHistorico = (data, callback) => ({ type: Type.HISTORICO_DRONE_UPSERT, data, callback });

const deleteHistoricoDrone = (id, callback) => ({ type: Type.HISTORICO_DRONE_DELETE, id, callback });

export {
  loadDrones,
  deleteDrone,
  saveDrone,
  updateDrone,
  findDroneById,
  exportDrone,
  findHistoricoById,
  upsertHistorico,
  deleteHistoricoDrone
}
