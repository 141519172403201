export const CONVOCACOES_FIND_ONE = 'CONVOCACOES_FIND_ONE';
export const CONVOCACOES_FIND_ONE_SUCCESS = 'CONVOCACOES_FIND_ONE_SUCCESS';

export const CONVOCACOES_SAVE = 'CONVOCACOES_SAVE';
export const CONVOCACOES_SAVE_SUCCESS = 'CONVOCACOES_SAVE_SUCCESS';

export const CONVOCACOES_UPDATE = 'CONVOCACOES_UPDATE';
export const CONVOCACOES_UPDATE_SUCCESS = 'CONVOCACOES_UPDATE_SUCCESS';

export const CONVOCACOES_CLEAN = 'CONVOCACOES_CLEAN';
export const CONVOCACOES_CLEAR_SELECTED = 'CONVOCACOES_CLEAR_SELECTED';

export const CONVOCACOES_UPDATE_STATUS = 'CONVOCACOES_UPDATE_STATUS';
export const CONVOCACOES_UPDATE_STATUS_SUCCESS = 'CONVOCACOES_UPDATE_STATUS_SUCCESS';

export const CONVOCACOES_REPORT = 'CONVOCACOES_REPORT';