export const FETCH_EQUIPAMENTOS = 'FETCH_EQUIPAMENTOS';
export const FETCH_EQUIPAMENTOS_SUCCESS = 'FETCH_EQUIPAMENTOS_SUCCESS';

export const DELETE_EQUIPAMENTO = 'DELETE_EQUIPAMENTO';
export const DELETE_EQUIPAMENTO_SUCCESS = 'DELETE_EQUIPAMENTO_SUCCESS';

export const SAVE_EQUIPAMENTO = 'SAVE_EQUIPAMENTO';
export const SAVE_EQUIPAMENTO_SUCCESS = 'SAVE_EQUIPAMENTO_SUCCESS';

export const UPDATE_EQUIPAMENTO = 'UPDATE_EQUIPAMENTO';
export const UPDATE_EQUIPAMENTO_SUCCESS = 'UPDATE_EQUIPAMENTO_SUCCESS';

export const CLEAR_EQUIPAMENTO = 'CLEAR_EQUIPAMENTO';

export const EQUIPAMENTO_FIND_BY_ID = 'EQUIPAMENTO_FIND_BY_ID';
export const EQUIPAMENTO_FIND_BY_ID_SUCCESS = 'EQUIPAMENTO_FIND_BY_ID_SUCCESS';

export const EQUIPAMENTO_EXPORT = 'EQUIPAMENTO_EXPORT';

export const HISTORICO_EQUIPAMENTO_UPSERT = 'HISTORICO_EQUIPAMENTO_UPSERT';
export const HISTORICO_EQUIPAMENTO_UPSERT_SUCCESS = 'HISTORICO_EQUIPAMENTO_UPSERT_SUCCESS';

export const HISTORICO_EQUIPAMENTO_FIND_BY_ID = 'HISTORICO_EQUIPAMENTO_FIND_BY_ID';
export const HISTORICO_EQUIPAMENTO_FIND_BY_ID_SUCCESS = 'HISTORICO_EQUIPAMENTO_FIND_BY_ID_SUCCESS';

export const HISTORICO_EQUIPAMENTO_DELETE = 'HISTORICO_EQUIPAMENTO_DELETE';