/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Paper
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import AccountCircle from '@material-ui/icons/AccountCircle';
import { Input } from '../../components';
import * as authActions from '../../store/auth/actions';
import { hideLoader } from '../../store/loader/actions';
import { useClearCache } from 'react-clear-cache';
import NewPasswordForm from './forms/NewPasswordForm';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: { maximum: 64 }
  },
  password: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: { maximum: 128 }
  }
};

const useStyles = makeStyles(theme => ({
  grid: {
    height: '100%',
    backgroundImage: 'url(/images/homeNew.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  paper: {
    padding: '80px 100px',
    minHeight: '100%'
  },
  form: {
    width: 350
  },
  title: {
    marginTop: theme.spacing(3)
  },
  input: {
    marginTop: 15
  },
  loginIcon: {
    paddingRight: 11,
    paddingLeft: 11
  },
  icon: {
    color: '#546e7a'
  },
  logo: {
    width: 275
  },
  logoContainer: {
    paddingLeft: 35
  },
  parceiroContainer: {
    paddingTop: 60
  },
  parceiroLogo: {
    width: 130
  },
  parceiroTitle: {
    fontSize: 18,
    paddingBottom: 10
  }
}));

const Login = ({ login, hideLoader }) => {
  const classes = useStyles();
  let history = useHistory();

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const [viewPassword, setViewPassword] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  useEffect(() => {
    hideLoader()
  }, [hideLoader]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const callback = showPasswordForm => {
    if (showPasswordForm) {
      setShowPasswordForm(showPasswordForm);
    } else {
      history.push('/nsirs');
    }
  }

  const handleLogin = () => {
    try {
      if (!isLatestVersion) {
        console.warn("Cache deve ser limpado");
        emptyCacheStorage();
        console.log("Cache limpo com sucesso");
      } else {
        console.log("Cache não precisa ser limpo. Já está na última versão.");
      }
    } catch(e) {
      console.error("Error ao limpar o cache", e);
    }

    login(formState.values, callback);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Grid className={classes.grid} container>
      <Grid container spacing={0} direction="column" alignItems="flex-end" justify="start">
        {showPasswordForm ? <NewPasswordForm /> : <Paper className={classes.paper} elevation={3}>
          <Grid item className={classes.logoContainer}> 
            <img src="/images/logos/logo.png" alt='logo' className={classes.logo}/>
          </Grid>
          <Grid item className={classes.form}> 
            <Typography className={classes.title} variant="h3">
              Login
            </Typography>
            <Input
              error={hasError('email')}
              classNameInputGroup={classes.input}
              helperText={hasError('email') ? formState.errors.email[0] : null}
              label="Usuário"
              name="email"
              onChange={handleChange}
              isRequired
              value={formState.values.email || ''}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.loginIcon}>
                    <AccountCircle className={classes.icon}/>
                  </InputAdornment>
                ),
              }}
            />
            <Input
              error={hasError('password')}
              classNameInputGroup={classes.input}
              helperText={hasError('password') ? formState.errors.password[0] : null}
              label="Password"
              name="password"
              onChange={handleChange}
              isRequired
              value={formState.values.password || ''}
              type={viewPassword ? 'text' : 'password'}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setViewPassword(!viewPassword)}>
                      {viewPassword ? <Visibility className={classes.icon}/> : <VisibilityOff className={classes.icon}/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              className={classes.input}
              color="primary"
              disabled={!formState.isValid}
              fullWidth
              size="large"
              variant="contained"
              onClick={handleLogin}
            >
              Entrar
            </Button>
          </Grid>
          <Grid item className={classes.parceiroContainer}> 
            <Typography className={classes.parceiroTitle} variant="h3">
              Parceiros:
            </Typography>
            <img src="/images/cemig.png" alt='Cemig' className={classes.parceiroLogo}/>
          </Grid>
        </Paper>}
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...authActions,
  hideLoader
}, dispatch);

export default connect(null, mapDispatchToProps)(Login);
