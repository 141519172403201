import * as Type from './actionsTypes';

const INITIAL_STATE = {
  veiculos: [],
  selectedVeiculo: null,
  options: [],
  selectedHistoricoVeiculo: null,
};

const ordernarPorPlaca = (a, b) => {
  return a.placa.localeCompare(b.placa);
};

const ordernarPorDataHistorico = (a, b) => {
  return a.dataHistorico.localeCompare(b.dataHistorico);
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_VEICULOS_SUCCESS: {
      return {
        ...state,
        veiculos: action.data.sort(ordernarPorPlaca)
      }
    }
    case Type.DELETE_VEICULO_SUCCESS: {
      return {
        ...state,
        veiculos: state.veiculos.filter(s => s.id !== action.data).sort(ordernarPorPlaca)
      }
    }
    case Type.SAVE_VEICULO_SUCCESS: {
      return {
        ...state,
        veiculos: [...state.veiculos, action.data].sort(ordernarPorPlaca)
      }
    }
    case Type.UPDATE_VEICULO_SUCCESS: {
      return {
        ...state,
        veiculos: [...state.veiculos.filter(s => s.id !== action.data.id), action.data].sort(ordernarPorPlaca)
      }
    }
    case Type.VEICULO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedVeiculo: action.data
      }
    }
    case Type.VEICULO_FIND_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data
      }
    }
    case Type.HISTORICO_VEICULO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedHistoricoVeiculo: action.data
      }
    }
    case Type.HISTORICO_VEICULO_UPSERT_SUCCESS: {
      return {
        ...state,
        selectedVeiculo: {
          ...state.selectedVeiculo,
          historico: [...state.selectedVeiculo.historico.filter(s => s.id !== action.data.id), action.data].sort(ordernarPorDataHistorico)
        }
      }
    }
    case Type.CLEAR_VEICULO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getVeiculos = state => state.rootState.veiculo.veiculos;
export const getSelectedVeiculo = state => state.rootState.veiculo.selectedVeiculo;
export const getVeiculosOptions = state => state.rootState.veiculo.options;
export const getSelectedHistoricoVeiculo = state => state.rootState.veiculo.selectedHistoricoVeiculo;
