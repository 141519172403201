import * as Type from './actionsTypes';

const loadProdutos = filtro => ({ type: Type.FETCH_PRODUTOS, filtro });

const loadProdutosOptions = () => ({ type: Type.FETCH_PRODUTOS_OPTIONS });

const loadProdutoTamanhoOptions = () => ({ type: Type.FETCH_PRODUTO_TAMANHO_OPTIONS });

const deleteProduto = id => ({ type: Type.DELETE_PRODUTO, id });

const saveProduto = (data, callback) => ({ type: Type.SAVE_PRODUTO, data, callback });

const updateProduto = (data, callback) => ({ type: Type.UPDATE_PRODUTO, data, callback });

const findProdutoById = id => ({ type: Type.PRODUTO_FIND_BY_ID, id });

const downloadProdutos = (filter, callback) => ({ type: Type.PRODUTO_DOWNLOAD, filter, callback });

const exportProduto = (filter, report, callbackAfterSave) => ({ type: Type.PRODUTO_EXPORT, filter, report, callbackAfterSave });

export {
  loadProdutos,
  loadProdutosOptions,
  loadProdutoTamanhoOptions,
  deleteProduto,
  saveProduto,
  updateProduto,
  findProdutoById,
  downloadProdutos,
  exportProduto
}
