/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid, DialogContentText } from '@material-ui/core';

import * as selectorsControleCaixas from '../../store/controleCaixa/reducer';
import * as actionsControleCaixas from '../../store/controleCaixa/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import moment from 'moment';
import clsx from 'clsx';

import { 
  Input, 
  DialogDefault, 
  NumberInput, 
  ArquivoContainer, 
  DatePicker,
  MalhaSelect,
  DefaultSelect
} from '../../components';

const getSchema = values => {
  let schema = {
    tipo: {
      presence: { allowEmpty: false, message: 'é obrigatório' },
    },
    dataEmissao: {
      presence: { allowEmpty: false, message: 'é obrigatório' },
    },
    dataRecebimento: {
      presence: { allowEmpty: false, message: 'é obrigatório' },
    },
  };

  if (values.tipo === "CONTRATO") {
    schema = {
      ...schema,
      numNotaFiscal: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      contrato: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      malhaId: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      apro: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      hd: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      valorBruto: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      issPercentage: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      inssPercentage: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
    }
  } else {
    schema = {
      ...schema,
      valorRecebido: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
      descricao: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      },
    }
  }

  return schema;
}

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  cancelada: {
    color: "red",
    fontStyle: "italic"
  }
}));

const FormularioDespesa = ({ saveControleCaixa, updateControleCaixa, selectedControleCaixa, open, id, onClose, findControleCaixaById, 
  authData, cancelarControleCaixa, liquidarControleCaixa }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [openCancelar, setOpenCancelar] = useState(false);
  const [openLiquidar, setOpenLiquidar] = useState(false);
  const [openALiquidar, setOpenALiquidar] = useState(false);

  useEffect(() => {
    if (open) {
      setValues({
        criadoPorId: authData.id,
        criadoPorName: authData.nome,
        dataEmissao: moment().valueOf(),
        dataCriacaoFormat: moment().format("DD/MM/YYYY HH:mm"),
        dataRecebimento: moment().add(30, 'days').valueOf(),
        tipo: 'CONTRATO',
        status: 'A_LIQUIDAR'
      });
    }
  }, [open, authData]);

  useEffect(() => {
    if (id) findControleCaixaById(id, "receitas");
  }, [id, findControleCaixaById]);

  useEffect(() => {
    if (id && selectedControleCaixa && id === selectedControleCaixa.id) {
      setValues(selectedControleCaixa);
    }
  }, [id, selectedControleCaixa]);

  const onSave = () => {
    const erros = validate(values, getSchema(values));
    setErrors(erros);
    if (!erros) {
      if (values.id) updateControleCaixa(values, "receitas", saveCallback);
      else saveControleCaixa(values, "receitas", saveCallback);
    }
  }

  const saveCallback = () => {
    onCloseLocal(true);
  }

  const onCloseLocal = isSave => {
    setErrors({});
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleValorBruto = newValorBruto => {
    const valorBruto = newValorBruto || 0;
    const issReal = values.issPercentage ? valorBruto * values.issPercentage / 100 : 0;
    const inssReal = values.inssPercentage ? valorBruto * values.inssPercentage / 100 : 0;
    const valorLiquido = valorBruto - issReal - inssReal;
    const valorRecebido = values.taxaJuros ? valorLiquido - values.taxaJuros : valorLiquido;

    setValues({
      ...values,
      valorBruto,
      issReal,
      inssReal,
      valorLiquido,
      valorRecebido
    });
  }

  const handleIss = newIssPercentage => {
    const valorBruto = values.valorBruto || 0;
    const issPercentage = newIssPercentage || 0;
    const issReal = valorBruto * issPercentage / 100;
    const inssReal = values.inssReal || 0;
    const valorLiquido = valorBruto - issReal - inssReal;
    const valorRecebido = values.taxaJuros ? valorLiquido - values.taxaJuros : valorLiquido;

    setValues({
      ...values,
      issPercentage,
      issReal,
      valorLiquido,
      valorRecebido
    });
  }

  const handleInss = newInssPercentage => {
    const valorBruto = values.valorBruto || 0;
    const issReal = values.issReal || 0;
    const inssPercentage = newInssPercentage || 0;
    const inssReal = valorBruto * inssPercentage / 100;

    const valorLiquido = valorBruto - issReal - inssReal;
    const valorRecebido = values.taxaJuros ? valorLiquido - values.taxaJuros : valorLiquido;

    setValues({
      ...values,
      inssPercentage,
      inssReal,
      valorLiquido,
      valorRecebido
    });
  }

  const handleTaxa = newTaxaJuros => {
    const valorLiquido =  values.valorLiquido || 0;
    const taxaJuros = newTaxaJuros || 0;
    const valorRecebido = valorLiquido - taxaJuros;

    setValues({
      ...values,
      taxaJuros,
      valorRecebido
    });
  }

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Receita"
      cancelMessage="Fechar"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      readOnly={values.cancelada || values.status === "LIQUIDADO"}
      maxWidth="md"
      headerAction={[
        {
          onClick: () => setOpenCancelar(true),
          title: "Cancelar",
          show: (values.cancelada === undefined || values.cancelada === false) && values.id !== undefined && values.status === "A_LIQUIDAR"
        },
        {
          onClick: () => setOpenLiquidar(true),
          title: "Liquidar",
          show: values.status === "A_LIQUIDAR" && values.id !== undefined && values.cancelada === false
        },
        {
          onClick: () => setOpenALiquidar(true),
          title: "A Liquidar",
          show: values.status === "LIQUIDADO" && values.id !== undefined && values.cancelada === false
        },
      ]}
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        {values.cancelada && <Grid item xs={12} className={clsx(classes.fieldContainer, classes.cancelada)}>
          **** Receita Cancelada por {values.modificadoPorName} em {values.dataUltimaAtualizacaoFormat}
        </Grid>}
        {values.status === 'LIQUIDADO' && <Grid item xs={12} className={clsx(classes.fieldContainer, classes.cancelada)}>
          **** Receita Liquidada por {values.modificadoPorName} em {values.dataUltimaAtualizacaoFormat}
        </Grid>}
        <Grid item xs={3} className={classes.fieldContainer}>
          <DefaultSelect
            label="Tipo"
            error={errors && errors.tipo ? true : false}
            isRequired
            options={[
              {id: 'CONTRATO', nome: 'Contrato'},
              {id: 'ESTORNO', nome: 'Estorno'},
              {id: 'OUTROS', nome: 'Outros'},
            ]}
            onSelect={event => {
              setValues({ 
                tipo: event.target.value,
                criadoPorId: authData.id,
                criadoPorName: authData.nome,
                dataEmissao: moment().valueOf(),
                dataCriacaoFormat: moment().format("DD/MM/YYYY HH:mm"),
                dataRecebimento: event.target.value === "CONTRATO" ? moment().add(30, 'days').valueOf() : moment().valueOf(),
                arquivos: values.arquivos,
                descricao: values.descricao,
                observacao: values.observacao,
                status: values.status
              });
              setErrors({});
            }}
            value={values.tipo}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DatePicker
            error={errors && errors.dataEmissao ? true : false}
            isRequired
            label="Data da Emissão"
            onChange={dataEmissao => setValues({
              ...values,
              dataEmissao: dataEmissao.valueOf()
            })}
            value={values.dataEmissao}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DatePicker
            label="Data do Recebimento"
            error={errors && errors.dataRecebimento ? true : false}
            isRequired
            onChange={data => setValues({
              ...values,
              dataRecebimento: data.valueOf()
            })}
            value={values.dataRecebimento}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DefaultSelect
            label="Status"
            options={[
              { id: 'A_LIQUIDAR', nome: 'A Liquidar' },
              { id: 'LIQUIDADO', nome: 'Liquidado' }
            ]}
            value={values.status}
            disabled
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            error={errors && errors.numNotaFiscal ? true : false}
            isRequired={values.tipo === 'CONTRATO'}
            label="Nº Nota Fiscal"
            name="numNotaFiscal"
            onChange={handleChange}
            value={values.numNotaFiscal}
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            error={errors && errors.contrato ? true : false}
            isRequired={values.tipo === 'CONTRATO'}
            label="Nº Contrato"
            name="contrato"
            onChange={handleChange}
            value={values.contrato}
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <MalhaSelect
            error={errors && errors.malhaId ? true : false}
            isRequired={values.tipo === 'CONTRATO'}
            onSelect={value =>
              setValues({
                ...values,
                malhaId: value,
              })
            }
            value={values.malhaId}
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.apro ? true : false}
            isRequired={values.tipo === 'CONTRATO'}
            label="APRO"
            name="apro"
            onChange={valor => setValues({
              ...values,
              apro: valor
            })}
            value={values.apro}
            decimalScale={0}
            prefix=""
            thousandSeparator=""
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.hd ? true : false}
            isRequired={values.tipo === 'CONTRATO'}
            label="HD"
            name="hd"
            onChange={valor => setValues({
              ...values,
              hd: valor
            })}
            value={values.hd}
            decimalScale={0}
            prefix=""
            thousandSeparator=""
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.valorBruto ? true : false}
            isRequired={values.tipo === 'CONTRATO'}
            label="Valor Bruto"
            onChange={handleValorBruto}
            value={values.valorBruto}
            format="##,######"
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.issPercentage ? true : false}
            isRequired={values.tipo === 'CONTRATO'}
            label="ISS (%)"
            onChange={handleIss}
            value={values.issPercentage}
            format="##,######"
            prefix=""
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            label="Valor ISS"
            disabled
            value={values.issReal}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.inssPercentage ? true : false}
            isRequired={values.tipo === 'CONTRATO'}
            label="INSS (%)"
            onChange={handleInss}
            value={values.inssPercentage}
            format="##,######"
            prefix=""
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            label="Valor INSS"
            disabled
            value={values.inssReal}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            label="Valor Líquido"
            disabled
            value={values.valorLiquido}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            label="Taxa/Juros"
            onChange={handleTaxa}
            value={values.taxaJuros}
            format="##,######"
            disabled={values.cancelada || values.tipo !== 'CONTRATO' || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.valorRecebido ? true : false}
            isRequired={values.tipo !== 'CONTRATO'}
            disabled={values.cancelada || values.tipo === 'CONTRATO' || values.status === 'LIQUIDADO'}
            label="Valor Recebido"
            onChange={valorRecebido => {
              setValues({
                ...values,
                valorRecebido: valorRecebido
              });
            }}
            value={values.valorRecebido}
            format="##,######"
          />
        </Grid>
        <Grid item xs={6} className={classes.fieldContainer}>
          <Input
            label="Município"
            name="municipio"
            onChange={handleChange}
            value={values.municipio}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            label="US"
            name="us"
            onChange={valor => setValues({
              ...values,
              us: valor
            })}
            value={values.us}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
            decimalScale={0}
            prefix=""
            thousandSeparator=""
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            label="Valor US"
            onChange={valorUs => {
              setValues({
                ...values,
                valorUs,
              });
            }}
            value={values.valorUs}
            format="##,######"
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Criado Por"
            disabled
            value={values.criadoPorName}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Criação"
            disabled
            value={values.dataCriacaoFormat}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Última Atualização"
            disabled
            value={values.dataUltimaAtualizacaoFormat}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Descrição"
            name="descricao"
            error={errors && errors.descricao ? true : false}
            isRequired={values.tipo !== 'CONTRATO'}
            onChange={handleChange}
            value={values.descricao}
            rows={3}
            multiline
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Observação"
            name="observacao"
            onChange={handleChange}
            value={values.observacao}
            rows={3}
            multiline
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <ArquivoContainer 
          arquivos={values.arquivos}
          onSave={arquivos => setValues({ ...values, arquivos })}
          disabled={values.cancelada || values.status === 'LIQUIDADO'}
        />
      </Grid>
      <DialogDefault
        open={openCancelar}
        useFullScreen={false}
        handlerCancel={() => setOpenCancelar(false)}
        handlerConfirm={() => { setOpenCancelar(false); cancelarControleCaixa(values.id, "receitas", saveCallback); }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente cancelar a receita? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openLiquidar}
        useFullScreen={false}
        handlerCancel={() => setOpenLiquidar(false)}
        handlerConfirm={() => { setOpenLiquidar(false); liquidarControleCaixa(values.id, "receitas", "LIQUIDADO", saveCallback); }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente liquidar a receita? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openALiquidar}
        useFullScreen={false}
        handlerCancel={() => setOpenALiquidar(false)}
        handlerConfirm={() => { setOpenALiquidar(false); liquidarControleCaixa(values.id, "receitas", "A_LIQUIDAR", saveCallback); }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente alterar o status para 'A Liquidar'? </DialogContentText>
      </DialogDefault>
    </DialogDefault>
  );
};

FormularioDespesa.propTypes = {
  findControleCaixaById: PropTypes.func.isRequired, 
  saveControleCaixa: PropTypes.func.isRequired,
  updateControleCaixa: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  selectedControleCaixa: PropTypes.object,
  authData: PropTypes.object.isRequired,
  cancelarControleCaixa: PropTypes.func.isRequired
};

FormularioDespesa.defaultValue = {
  selectedControleCaixa: null,
  id: null,
}

const mapStateToProps = state => ({
  selectedControleCaixa: selectorsControleCaixas.getSelectedControleCaixa(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsControleCaixas,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FormularioDespesa));
