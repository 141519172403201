import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as selectorsCartoes from '../../store/cartao/reducer';
import * as actionsCartoes from '../../store/cartao/actions';
import DefaultSelect from '../DefaultSelect';

const CartaoSelect = ({ cartoesOptions, onSelect, value, error, findCartoesOptions, disabled, isRequired }) => {
  const handleChange = (event) => onSelect(event.target.value);

  useEffect(() => {
    findCartoesOptions()
  }, [findCartoesOptions]);

  return (
    <DefaultSelect
      error={error}
      label="Cartão Corporativo"
      name="cartao"
      optionLabel="codigo"
      showEmpty
      options={cartoesOptions || []}
      onSelect={handleChange}
      value={value}
      disabled={disabled}
      isRequired={isRequired}
    />
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsCartoes,
}, dispatch);

const mapStateToProps = state => ({
  cartoesOptions: selectorsCartoes.getCartoesOptions(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(CartaoSelect);