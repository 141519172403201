/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/inspecaoVeicular/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';

function* findById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `inspecao-veicular/${id}` });
    if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
      response.data.arquivos = yield FileService.downloadArquivos(response.data.arquivos);
    }

    yield put({
      type: TypeActions.INSPECAO_VEICULAR_FIND_ONE_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'inspecao-veicular', data });
    yield put({
      type: TypeActions.INSPECAO_VEICULAR_SAVE_SUCCESS,
      data: response.data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* update({ id, data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `inspecao-veicular/${id}`, data });
    yield put({
      type: TypeActions.INSPECAO_VEICULAR_UPDATE_SUCCESS,
      data: response.data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* inspecaoVeicularReport({ filtro, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'inspecao-veicular/report',
      method: 'POST',
      data: filtro,
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório Convocação.xlsx');
    yield callback(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar o relatório!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.INSPECAO_VEICULAR_SAVE, save);
  yield takeLatest(TypeActions.INSPECAO_VEICULAR_UPDATE, update);
  yield takeLatest(TypeActions.INSPECAO_VEICULAR_FIND_ONE, findById);
  yield takeLatest(TypeActions.INSPECAO_VEICULAR_REPORT, inspecaoVeicularReport);
}