import * as Type from './actionsTypes';

const INITAL_STATE = {
  id: null,
  token: null,
  role: null,
  nome: null,
  avatar: null,
  email: null,
  telefone: null,
};

export default function (state = INITAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_AUTH_SUCCESS: {
      return {
        ...state,
        ...action.data
      }
    }
    case Type.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        ...action.data
      }
    }
    case Type.AUTH_CLEAR: {
      return INITAL_STATE;
    }

    default:
      return state;
  }
}

export const getAuth = state => state.rootState.auth;
export const getRole = state => state.rootState.auth.role;
export const getUserId = state => state.rootState.auth.id;
