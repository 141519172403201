import * as Type from './actionsTypes';

const loadMalhas = filter => ({ type: Type.MALHAS_FIND, filter });

const deleteMalha = id => ({ type: Type.MALHAS_DELETE, id });

const saveMalha  = data => ({ type: Type.MALHAS_SAVE, data });

const updateMalha = data => ({ type: Type.MALHAS_UPDATE, data });

const findOptions = () => ({ type: Type.MALHAS_OPTIONS });

export {
  loadMalhas,
  deleteMalha,
  saveMalha,
  updateMalha,
  findOptions
}
