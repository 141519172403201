import * as Type from './actionsTypes';

const loadUsuarioDocumentos = filter => ({ type: Type.FETCH_USUARIO_DOCUMENTOS, filter });

const deleteUsuarioDocumento = id => ({ type: Type.DELETE_USUARIO_DOCUMENTO, id });

const saveUsuarioDocumento = (data, callback) => ({ type: Type.SAVE_USUARIO_DOCUMENTO, data, callback });

const updateUsuarioDocumento = (data, callback) => ({ type: Type.UPDATE_USUARIO_DOCUMENTO, data, callback });

const findUsuarioDocumentoById = id => ({ type: Type.USUARIO_DOCUMENTO_FIND_BY_ID, id });

const findOptions = (tipoDocumento) => ({ type: Type.USUARIO_DOCUMENTO_FIND_OPTIONS, tipoDocumento });

export {
  deleteUsuarioDocumento,
  saveUsuarioDocumento,
  loadUsuarioDocumentos,
  updateUsuarioDocumento,
  findUsuarioDocumentoById,
  findOptions,
}
