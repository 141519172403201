/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, Filtro, DialogDefault } from '../../components';
import * as selectorsAuth from '../../store/auth/reducer';
import * as selectorsConvocacoes from '../../store/convocacao/reducer';
import * as actionsConvocacoes from '../../store/convocacao/actions';
import Formulario from './Formulario';
import { getMaxColumnLength } from '../../utils';
import * as Roles from '../../constants/role';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Convocacao = ({ convocacacoes, convocacaoReport, authData }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [localFilter, setLocalFilter] = useState({
    inicio: moment().subtract(1, 'month').startOf('month').valueOf(),
    fim: moment().endOf('month').valueOf(),
  });
  const [reload, setReload] = useState(false);
  const [openExport, setOpenExport] = useState(false);

  const classes = useStyles();
  
  const renderRows = d => {
    return (
      <>
        <TableCell>{d.inspetorNome}</TableCell>
        <TableCell>{d.dataInicioValue}</TableCell>
        <TableCell>{d.dataFimValue}</TableCell>
        <TableCell>{d.dataCriacaoValue}</TableCell>
        <TableCell size="medium" title={d.jornada}>{getMaxColumnLength(d.jornada)}</TableCell>
        <TableCell>{d.statusDescricao}</TableCell>
      </>
    )
  }

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = (save) => {
    setOpen(false);
    setId(null);
    if (save) {
      setReload(!reload);
    }
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getHeaderActions = () => {
    let actions = [];

    if (isAdmin()) {
      actions.push({ 
        title: 'Relatório',
        onClick: () => setOpenExport(true)
      });
    }

    return actions;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Filtro 
          onFilter={filterData => setLocalFilter(filterData)}
          useDates
          useInspetor
          useConvocacaoStatus
        />
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={convocacacoes}
          titles={['Inspetor', 'Data Início', 'Data Fim', 'Data Criação', 'Jornada', 'Status']}
          headerTitle="Convocação"
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onAdd={onAdd}
          headerActions={getHeaderActions()}
          showDelete={false}
          usePaginationApi
          paginationApi="convocacoes/filter"
          paginationUrl="convocacoes"
          filterData={localFilter}
          reload={!reload}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          convocacacoes={convocacacoes}
          onReload={() => setReload(!reload)}
        />
        <DialogDefault
          open={openExport}
          useFullScreen={false}
          handlerCancel={() => setOpenExport(false)}
          handlerConfirm={() => {
            convocacaoReport(localFilter, () => {
              setOpenExport(false);
            });
          }}
          title="Confirmação"
          confirmMessage="Sim"
          cancelMessage="Não"
        >
          <DialogContentText> Deseja baixar todas as informações filtradas? </DialogContentText>
        </DialogDefault>
      </Grid>
    </Grid>
  );
};

Convocacao.propTypes = {
  deleteConvocacao: PropTypes.func.isRequired,
  convocacacoes: PropTypes.array,
};

const mapStateToProps = state => ({
  convocacacoes: selectorsConvocacoes.getConvocacoes(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsConvocacoes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Convocacao));