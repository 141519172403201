import * as Type from './actionsTypes';

const loadControleCaixas = filtro => ({ type: Type.FETCH_CONTROLE_CAIXAS, filtro });

const deleteControleCaixa = (id, tipo) => ({ type: Type.DELETE_CONTROLE_CAIXA, id, tipo });

const saveControleCaixa = (data, tipo, callback) => ({ type: Type.SAVE_CONTROLE_CAIXA, data, tipo, callback });

const updateControleCaixa = (data, tipo, callback) => ({ type: Type.UPDATE_CONTROLE_CAIXA, data, tipo, callback });

const findControleCaixaById = (id, tipo) => ({ type: Type.CONTROLE_CAIXA_FIND_BY_ID, id, tipo });

const importControleCaixa = (data, tipo, callback) => ({ type: Type.IMPORT_CONTROLE_CAIXA, data, tipo, callback });

const cancelarControleCaixa = (id, tipo, callback) => ({ type: Type.CANCELAR_CONTROLE_CAIXA, tipo, id, callback });

const liquidarControleCaixa = (id, tipo, status, callback) => ({ type: Type.LIQUIDAR_CONTROLE_CAIXA, tipo, id, status, callback });

const exportControleCaixa = (filtro, callback) => ({ type: Type.EXPORTAR_CONTROLE_CAIXA, filtro, callback });

export {
  loadControleCaixas,
  deleteControleCaixa,
  saveControleCaixa,
  updateControleCaixa,
  findControleCaixaById,
  importControleCaixa,
  cancelarControleCaixa,
  liquidarControleCaixa,
  exportControleCaixa
}
