import * as Type from './actionsTypes';

const loadEquipamentos = filtro => ({ type: Type.FETCH_EQUIPAMENTOS, filtro });

const deleteEquipamento = id => ({ type: Type.DELETE_EQUIPAMENTO, id });

const saveEquipamento = (data, callback) => ({ type: Type.SAVE_EQUIPAMENTO, data, callback });

const updateEquipamento = (data, callback) => ({ type: Type.UPDATE_EQUIPAMENTO, data, callback });

const findEquipamentoById = id => ({ type: Type.EQUIPAMENTO_FIND_BY_ID, id });

const exportEquipamento = (filtro, callback) => ({ type: Type.EQUIPAMENTO_EXPORT, filtro, callback });

const findHistoricoById = id => ({ type: Type.HISTORICO_EQUIPAMENTO_FIND_BY_ID, id });

const upsertHistorico = (data, callback) => ({ type: Type.HISTORICO_EQUIPAMENTO_UPSERT, data, callback });

const deleteHistoricoEquipamento = (id, callback) => ({ type: Type.HISTORICO_EQUIPAMENTO_DELETE, id, callback });

export {
  loadEquipamentos,
  deleteEquipamento,
  saveEquipamento,
  updateEquipamento,
  findEquipamentoById,
  exportEquipamento,
  findHistoricoById,
  upsertHistorico,
  deleteHistoricoEquipamento
}
