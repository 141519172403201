import * as Type from './actionsTypes';

const loadEstoques = filtro => ({ type: Type.FETCH_ESTOQUES, filtro });

const deleteEstoque = id => ({ type: Type.DELETE_ESTOQUE, id });

const saveEstoque = (data, callback) => ({ type: Type.SAVE_ESTOQUE, data, callback });

const updateEstoque = (data, callback) => ({ type: Type.UPDATE_ESTOQUE, data, callback });

const findEstoqueById = id => ({ type: Type.ESTOQUE_FIND_BY_ID, id });

const exportEstoque = (filter, callbackAfterSave) => ({ type: Type.ESTOQUE_EXPORT, filter, callbackAfterSave });

export {
  loadEstoques,
  deleteEstoque,
  saveEstoque,
  updateEstoque,
  findEstoqueById,
  exportEstoque
}
