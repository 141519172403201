import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import InputGroup from '../InputGroup';

const useStyles = makeStyles(theme => ({
  input: {
    '& div': {
      background: theme.palette.white
    }
  },
  disabled: {
    '& div': {
      background: 'none'
    }
  }
}));

const Input = ({ value, label, title, placement, placeholder, useTooltip, onChange, fullWidth, name, isRequired,
  error, helperText, rows, multiline, inputProps, disabled, classNameInputGroup, type, min, step, showHelperText, hideHelperText, showInputGroup }) => {

  const classes = useStyles();
  const defaultHelperText = helperText? helperText : 'O campo é obrigatório';
  
  return (
    showInputGroup ?
      <InputGroup
        label={label}
        placement={placement}
        title={title}
        useTooltip={useTooltip}
        isRequired={isRequired}
        className={classNameInputGroup}
      >
        <TextField
          fullWidth={fullWidth}
          error={error}
          helperText={hideHelperText ? '' : error || showHelperText? defaultHelperText : ''}
          margin="dense"
          name={name}
          onChange={onChange}
          placeholder={placeholder ? placeholder : label}
          value={value}
          variant="outlined"
          rows={rows}
          multiline={multiline}
          InputProps={inputProps}
          disabled={disabled}
          className={clsx(classes.input, disabled && classes.disabled)}
          type={type}
          min={min}
          step={step}
        />
      </InputGroup>
    :
      <TextField
        fullWidth={fullWidth}
        error={error}
        helperText={hideHelperText ? '' : error || showHelperText? defaultHelperText : ''}
        margin="dense"
        name={name}
        onChange={onChange}
        placeholder={placeholder ? placeholder : label}
        value={value}
        variant="outlined"
        rows={rows}
        multiline={multiline}
        InputProps={inputProps}
        disabled={disabled}
        className={clsx(classes.input, disabled && classes.disabled)}
        type={type}
        min={min}
        step={step}
      />
  );
}

Input.propTypes = {
  children: PropTypes.node,
  error: PropTypes.any,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.any,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  placement: PropTypes.string,
  rows: PropTypes.number,
  title: PropTypes.string,
  useTooltip: PropTypes.bool,
  value: PropTypes.string,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  classNameInputGroup: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.string,
  step: PropTypes.string,
  showHelperText: PropTypes.bool,
  hideHelperText: PropTypes.bool,
  showInputGroup: PropTypes.bool
};

Input.defaultProps = {
  error: false,
  fullWidth: true,
  helperText: '',
  label: '',
  multiline: false,
  placement: 'top',
  rows: 1,
  title: '',
  useTooltip: false,
  isRequired: false,
  value: '',
  inputProps: {},
  disabled: false,
  classNameInputGroup: '',
  type: 'text',
  placeholder: null,
  onChange: () => {},
  name: '',
  min: null,
  step: null,
  showHelperText: false,
  hideHelperText: false,
  showInputGroup: true
};

export default Input;