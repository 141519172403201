/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault } from '../../components';
import * as selectorsCartoes from '../../store/cartao/reducer';
import * as actionsCartoes from '../../store/cartao/actions';
import Formulario from './Formulario';

import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Cartoes = ({ cartoes, loadCartoes, deleteCartao, authData, exportCartao }) => {
  
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['Número', 'Nome', 'Ativo']);
  const [localFilter, setLocalFilter] = useState({});
  const [openExport, setOpenExport] = useState(false);

  const classes = useStyles();
  
  const renderRows = d => {
    return (
      <>
        <TableCell>{d.codigo}</TableCell>
        <TableCell>{d.nome}</TableCell>
        <TableCell>{d.ativo === true ? 'Sim' : 'Não'}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadCartoes(localFilter)
  }, [loadCartoes, localFilter]);

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteCartao(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = isSave => {
    if (isSave) loadCartoes(localFilter);
    setOpen(false);
    setId(null);
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getHeaderActions = () => {
    let actions = [];

    if (isAdmin()) {
      actions.push({ 
        title: 'Exportar',
        onClick: () => setOpenExport(true)
      });
    }

    return actions;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useCodigo
            codigoLabel="Número Cartão"
            useNome
            useAtivo
            onFilter={filterData => {
              setLocalFilter(filterData);
              loadCartoes(filterData);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={cartoes}
          titles={titles}
          headerTitle="Cartões"
          deleteMessage="O cartão será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          showDelete={false}
          hasRowActions={isAdmin()}
          hasAdd={isAdmin()}
          headerActions={getHeaderActions()}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
        />
      </Grid>
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={() => {
          exportCartao(localFilter, () => {
            setOpenExport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja baixar todas as informações filtradas? </DialogContentText>
      </DialogDefault>
    </>
  );
};

Cartoes.propTypes = {
  deleteCartao: PropTypes.func.isRequired,
  loadCartoes: PropTypes.func.isRequired,
  cartoes: PropTypes.array,
  exportCartao: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  cartoes: selectorsCartoes.getCartoes(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsCartoes,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cartoes));