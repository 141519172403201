import * as Type from './actionsTypes';

const loadPolos = filter => ({ type: Type.POLOS_FIND, filter });

const deletePolo = id => ({ type: Type.POLOS_DELETE, id });

const savePolo  = data => ({ type: Type.POLOS_SAVE, data });

const updatePolo = data => ({ type: Type.POLOS_UPDATE, data });

const findOptions = (malhaIds, gerenciaIds) => ({ type: Type.POLOS_OPTIONS, malhaIds, gerenciaIds });

export {
  loadPolos,
  deletePolo,
  savePolo,
  updatePolo,
  findOptions
}
