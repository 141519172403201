import * as Type from './actionsTypes';

const INITIAL_STATE = {
  produtos: [],
  selectedProduto: null,
  produtoOptions: [],
  produtoTamanhoOptions: [],
};

const ordernarData = (a,b) => new Date(Number(b.data)) - new Date(Number(a.data));

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_PRODUTOS_SUCCESS: {
      return {
        ...state,
        produtos: action.data.sort(ordernarData)
      }
    }
    case Type.FETCH_PRODUTOS_OPTIONS_SUCCESS: {
      return {
        ...state,
        produtoOptions: action.data.sort(ordernarData)
      }
    }
    case Type.FETCH_PRODUTO_TAMANHO_OPTIONS_SUCCESS: {
      return {
        ...state,
        produtoTamanhoOptions: action.data,
      }
    }
    case Type.DELETE_PRODUTO_SUCCESS: {
      return {
        ...state,
        produtos: state.produtos.filter(s => s.id !== action.data).sort(ordernarData)
      }
    }
    case Type.SAVE_PRODUTO_SUCCESS: {
      return {
        ...state,
        produtos: [...state.produtos, action.data].sort(ordernarData)
      }
    }
    case Type.UPDATE_PRODUTO_SUCCESS: {
      return {
        ...state,
        produtos: [...state.produtos.filter(s => s.id !== action.data.id), action.data].sort(ordernarData)
      }
    }
    case Type.PRODUTO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedProduto: action.data
      }
    }
    case Type.CLEAR_PRODUTO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getProdutos = state => state.rootState.produto.produtos;
export const getProdutoOptions = state => state.rootState.produto.produtoOptions;
export const getProdutoTamanhoOptions = state => state.rootState.produto.produtoTamanhoOptions;
export const getSelectedProduto = state => state.rootState.produto.selectedProduto;
