/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import * as selectorsNsirs from '../../store/nsir/reducer';
import * as actionsNsirs from '../../store/nsir/actions';
import * as selectorsNsmp from '../../store/nsmp/reducer';
import * as actionsNsmp from '../../store/nsmp/actions';
import InformacaoBasicaTab from './InformacaoBasicaTab';
import FinanceiroTab from './FinanceiroTab';
import AuditTab from './AuditTab';
import ServicesTab from './ServicesTab';
import DocumentosTab from './DocumentosTab';
import { WidgetTab } from '../../components';
import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
  buttonBack: {
    fontSize: 12,
    paddingLeft: theme.spacing(3)
  }
}));

const Nsir = ({
  match,
  history,
  loadNsirById,
  nsirSelected,
  saveNsir,
  updateNsir,
  nsmps,
  cleanNsmps,
  authData
}) => {
  const [id, setId] = useState(null);
  const [nsirData, setNsirData] = useState({});
  const [nsmpList, setNsmpList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setNsirData({});
    setNsmpList([]);
    cleanNsmps();
  }, [cleanNsmps]);

  useEffect(() => {
    if (match && match.params && match.params.nsirId) {
      setId(match.params.nsirId);
    }
  }, [match, setId]);

  useEffect(() => {
    if (id) loadNsirById(id);
  }, [id, loadNsirById]);

  useEffect(() => {
    setNsirData(nsirSelected);
    setNsmpList(nsirSelected.nsmps);
  }, [nsirSelected]);

  useEffect(() => {
    setNsmpList(nsmps);
  }, [nsmps]);

  const onSaveNsir = nsir => {
    if (nsir && nsir.id) {
      updateNsir(nsir);
    } else if (nsir) {
      saveNsir(nsir);
    }
  };

  const getTitle = () => {
    let title = 'Nova NSIR';

    if (nsirData.id && nsirData.codigo) {
      title = `NSIR ${nsirData.codigo}`;
    } else if (nsirData.id && nsirData.equipamento) {
      title = `NSIR - Equipamento ${nsirData.equipamento}`;
    }

    return title;
  };

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={2}>
          <Button
            color="secondary"
            className={classes.buttonBack}
            onClick={() => history.goBack()}
          >
            <ArrowLeftIcon /> Voltar
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.root}>
        <WidgetTab
          widgets={
            [{
              element: <InformacaoBasicaTab nsir={nsirData} onSave={onSaveNsir} />,
              label: "Nsir",
              disabled: false
            },
            {
              element: <ServicesTab
                nsirTitle={getTitle()}
                nsmps={nsmpList}
                nsirStatus={nsirData.status}
                nsirId={nsirData.id}
                inspetorId={nsirData.inspetorId}
                digitadorId={nsirData.digitadorId}
              />,
              label: 'Serviços',
              disabled: !nsirData || (nsirData && !nsirData.id)
            },
            {
              element: <FinanceiroTab  nsirTitle={getTitle()} nsir={nsirData}/>,
              label: 'Financeiro',
              disabled: !nsirData || (nsirData && !nsirData.id) || !isAdmin()
            },
            {
              element: <DocumentosTab nsirTitle={getTitle()}/>,
              label: 'Documentos',
            },
            {
              element: <AuditTab nsirTitle={getTitle()} auditMessages={nsirData.auditMessages || []} />,
              label: 'Auditoria',
              disabled: !nsirData || (nsirData && !nsirData.id) || !isAdmin()
            }]
          }
        />
      </Grid>
    </>
  );
};

Nsir.propTypes = {
  match: PropTypes.object.isRequired,
  saveNsir: PropTypes.func.isRequired,
  updateNsir: PropTypes.func.isRequired,
  saveNsmp: PropTypes.func.isRequired,
  updateNsmp: PropTypes.func.isRequired,
  loadNsirById: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  nsirSelected: PropTypes.object.isRequired,
  nsmps: PropTypes.array.isRequired,
  cleanNsmps: PropTypes.func.isRequired,
  authData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  nsirSelected: selectorsNsirs.getNsirSelected(state),
  nsmps: selectorsNsmp.getNsmps(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionsNsirs,
      ...actionsNsmp
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Nsir));
