export const FETCH_CONTROLE_CAIXAS = 'FETCH_CONTROLE_CAIXAS';
export const FETCH_CONTROLE_CAIXAS_SUCCESS = 'FETCH_CONTROLE_CAIXAS_SUCCESS';

export const DELETE_CONTROLE_CAIXA = 'DELETE_CONTROLE_CAIXA';
export const DELETE_CONTROLE_CAIXA_SUCCESS = 'DELETE_CONTROLE_CAIXA_SUCCESS';

export const SAVE_CONTROLE_CAIXA = 'SAVE_CONTROLE_CAIXA';
export const SAVE_CONTROLE_CAIXA_SUCCESS = 'SAVE_CONTROLE_CAIXA_SUCCESS';

export const UPDATE_CONTROLE_CAIXA = 'UPDATE_CONTROLE_CAIXA';
export const UPDATE_CONTROLE_CAIXA_SUCCESS = 'UPDATE_CONTROLE_CAIXA_SUCCESS';

export const CLEAR_CONTROLE_CAIXA = 'CLEAR_CONTROLE_CAIXA';

export const CONTROLE_CAIXA_FIND_BY_ID = 'CONTROLE_CAIXA_FIND_BY_ID';
export const CONTROLE_CAIXA_FIND_BY_ID_SUCCESS = 'CONTROLE_CAIXA_FIND_BY_ID_SUCCESS';

export const IMPORT_CONTROLE_CAIXA = 'IMPORT_CONTROLE_CAIXA';
export const IMPORT_CONTROLE_CAIXA_SUCCESS = 'IMPORT_CONTROLE_CAIXA_SUCCESS';

export const CANCELAR_CONTROLE_CAIXA = 'CANCELAR_CONTROLE_CAIXA';
export const CANCELAR_CONTROLE_CAIXA_SUCCESS = 'CANCELAR_CONTROLE_CAIXA_SUCCESS';

export const LIQUIDAR_CONTROLE_CAIXA = 'LIQUIDAR_CONTROLE_CAIXA';
export const LIQUIDAR_CONTROLE_CAIXA_SUCCESS = 'LIQUIDAR_CONTROLE_CAIXA_SUCCESS';

export const EXPORTAR_CONTROLE_CAIXA = 'EXPORTAR_CONTROLE_CAIXA';