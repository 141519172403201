/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/cartao/actionsTypes';
import BaseService from '../services/BaseService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';

function* load({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'cartoes/filtro', data: filtro });
    yield put({
      type: TypeActions.FETCH_CARTOES_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* findOptions() {
  try {
    const response = yield BaseService.get({ url: 'cartoes' });
    yield put({
      type: TypeActions.CARTAO_FIND_OPTIONS_SUCCESS,
      data: response.data
    });
  } catch (error) {
    yield console.log(error);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'cartoes', data });
    yield put({
      type: TypeActions.SAVE_CARTAO_SUCCESS,
      data: response.data
    });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao salvar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `cartoes/${data.id}`, data });
    yield put({
      type: TypeActions.UPDATE_CARTAO_SUCCESS,
      data: response.data
    });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `cartoes/${id}` });
    yield put({
      type: TypeActions.CARTAO_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* exportCartao({ filter, callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'cartoes/export',
      data: filter,
      method: 'POST',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório Cartão.xlsx');
    yield callbackAfterSave(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar o relatório!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_CARTOES, load);
  yield takeLatest(TypeActions.CARTAO_FIND_OPTIONS, findOptions);
  yield takeLatest(TypeActions.SAVE_CARTAO, save);
  yield takeLatest(TypeActions.UPDATE_CARTAO, update);
  yield takeLatest(TypeActions.CARTAO_FIND_BY_ID, findById);
  yield takeLatest(TypeActions.CARTAO_EXPORT, exportCartao);
}