/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid, DialogContentText } from '@material-ui/core';
import * as Roles from '../../constants/role';
import * as actionsInspecaoVeicular from '../../store/inspecaoVeicular/actions';
import * as selectorsInspecaoVeicular from '../../store/inspecaoVeicular/reducer';
import * as selectorsAuth from '../../store/auth/reducer';
import { Input, DialogDefault, UsuarioSelect, DatePicker, DefaultSelect, VeiculoSelect, ArquivoContainer } from '../../components';
import moment from 'moment';

const schema = {
  inspetorId: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataInicio: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataFim: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  jornada: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  cancelada: {
    color: "red",
    fontStyle: "italic"
  }
}));

const Formulario = ({ saveConvocacao, updateConvocacao, findInspecaoVeicularById, inspecaoVeicularClearSelected, convocacaoUpdateStatus, selectedInspecaoVeicular, open, id, onClose, authData, userRole, onReload }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [openAceitar, setOpenAceitar] = useState(false);
  const [openCancelar, setOpenCancelar] = useState(false);
  const [openNegar, setOpenNegar] = useState(false);

  const getDefaultValues = () => {
    return {
      criadoPorId: authData.id,
      criadoPorNome: authData.nome,
      dataCriacao: moment().valueOf(),
      dataInicio: moment().startOf('month').valueOf(),
      dataFim: moment().endOf('month').valueOf(),
      status: 'AGUARDANDO',
      jornada: 'Segunda a sexta-feira: 08:00 às 12:00 e 13:00 às 17:00 \nSábado: 08:00 às 12:00 \nExceto feriados'
    }
  }

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open && !id) {
      setValues(getDefaultValues());
    }
  }, [id, open]);

  useEffect(() => {
    if (id) {
      findInspecaoVeicularById(id);
    }
  }, [id, findInspecaoVeicularById]);

  useEffect(() => {
    if (selectedInspecaoVeicular && selectedInspecaoVeicular.id) {
      setValues(selectedInspecaoVeicular);
    }
  }, [selectedInspecaoVeicular]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateConvocacao(values.id, values);
      else saveConvocacao(values);
      onCloseLocal(true);
    }
  }

  const onCloseLocal = (isSave) => {
    inspecaoVeicularClearSelected();
    setErrors({});
    setValues(getDefaultValues());
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const isAdmin = () => userRole === Roles.ADMIN || userRole === Roles.SUPER_ADMIN;

  const isDisabled = () => values.cancelada || values.status !== 'AGUARDANDO' || values.criadoPorId !== authData.id;

  return (
    <>
      <DialogDefault
        open={open}
        handlerCancel={() => onCloseLocal(false)}
        handlerConfirm={onSave}
        title="Inspeção Veicular"
        confirmMessage="Salvar"
        contentClassName={classes.contentClassName}
        maxWidth="md"
        readOnly={isDisabled()}
        headerAction={[
          {
            onClick: () => setOpenCancelar(true),
            title: "Cancelar",
            show: isAdmin() && !!values.id && values.status === 'AGUARDANDO'
          },
          {
            onClick: () => setOpenNegar(true),
            title: "Negar",
            show: !!values.id && values.inspetorId === authData.id && values.status === 'AGUARDANDO' && !values.cancelada
          },
          {
            onClick: () => setOpenAceitar(true),
            title: "Aceitar",
            show: !!values.id && values.inspetorId === authData.id && values.status === 'AGUARDANDO' && !values.cancelada
          },
        ]}
      >
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={3} className={classes.fieldContainer}>
            <DatePicker
              label="Data Inspeção"
              isRequired
              error={errors && errors.dataInspecao}
              onChange={data => setValues({
                ...values,
                dataInspecao: data.valueOf()
              })}
              value={values.dataInspecao}
              disabled={isDisabled()}
            />
          </Grid>
          <Grid item xs={6} className={classes.fieldContainer}>
            <UsuarioSelect
              label="Inspetor"
              onSelect={inspetorId => setValues({ ...values, inspetorId })}
              ativo
              grupos={[Roles.ADMIN, Roles.INSPETOR]}
              value={values.inspetorId}
              isRequired
              error={errors && errors.inspetorId}
              disabled={isDisabled()}
              multiSelect={false}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <Input label="Data Criação" value={values.dataCriacaoValue} disabled />
          </Grid>
          <Grid item xs={6} className={classes.fieldContainer}>
            <UsuarioSelect
              label="Inspetor Auxiliar"
              onSelect={inspetorAuxiliarId => setValues({ ...values, inspetorAuxiliarId })}
              ativo
              grupos={[Roles.ADMIN, Roles.INSPETOR]}
              value={values.inspetorAuxiliarId}
              isRequired
              error={errors && errors.inspetorAuxiliarId}
              disabled={isDisabled()}
              multiSelect={false}
            />
          </Grid>
          <Grid item xs={6} className={classes.fieldContainer}>
            <VeiculoSelect
              isRequired
              onSelect={veiculoId => { setValues({ ...values, veiculoId }) }}
              value={values.veiculoId}
              disabled={isDisabled()}
              error={errors && errors.veiculoId}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              isRequired
              error={errors && errors.inspetorId}
              label="Observação"
              name="observacao"
              onChange={handleChange}
              placeholder="observacao"
              value={values.observacao}
              rows={4}
              multiline
              disabled={isDisabled()}
            />
          </Grid>
          <ArquivoContainer
            arquivos={values.arquivos}
            disabled={isDisabled()}
            onSave={arquivos => setValues({ ...values, arquivos })}
          />
        </Grid>
      </DialogDefault>
      <DialogDefault
        open={openAceitar}
        useFullScreen={false}
        handlerCancel={() => setOpenAceitar(false)}
        handlerConfirm={() => {
          setOpenAceitar(false);
          convocacaoUpdateStatus(values.id, "aceitar");
          onReload();
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente aceitar a convocação? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openNegar}
        useFullScreen={false}
        handlerCancel={() => setOpenNegar(false)}
        handlerConfirm={() => {
          setOpenNegar(false);
          convocacaoUpdateStatus(values.id, "negar");
          onReload();
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente não aceitar a convocação? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openCancelar}
        useFullScreen={false}
        handlerCancel={() => setOpenCancelar(false)}
        handlerConfirm={() => { setOpenCancelar(false); convocacaoUpdateStatus(values.id, "cancelar"); }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente cancelar a convocação? </DialogContentText>
      </DialogDefault>
    </>
  );
};


const mapStateToProps = state => ({
  selectedInspecaoVeicular: selectorsInspecaoVeicular.getSelectedInspecaoVeicular(state),
  authData: selectorsAuth.getAuth(state),
  userRole: selectorsAuth.getRole(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsInspecaoVeicular,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
