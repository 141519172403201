import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as selectors from '../../store/alimentador/reducer';
import * as actions from '../../store/alimentador/actions';
import DefaultSelect from '../DefaultSelect';

const AlimentadorSelect = ({ alimentadores, onSelect, value, isRequired, error, helperText, findOptions, useFilter, malhaId, gerenciaId, poloId, subestacaoId, disabled }) => {
  const handleChange = (event) => onSelect(event.target.value);

  useEffect(() => {
    if (useFilter) {
      findOptions({ malhaId, gerenciaId, poloId, subestacaoId });
    } else {
      findOptions();
    }
  }, [useFilter, findOptions, malhaId, gerenciaId, poloId, subestacaoId]);

  return (
    <DefaultSelect 
      error={error}
      helperText={helperText}
      isRequired={isRequired}
      label="Alimentador"
      onSelect={handleChange}
      options={alimentadores}
      value={value}
      disabled={disabled}
    />
  );
}

AlimentadorSelect.propTypes = {
  error: PropTypes.any,
  value: PropTypes.string,
  alimentadores: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  findOptions: PropTypes.func.isRequired,
  useFilter: PropTypes.bool,
  malhaId: PropTypes.string,
  gerenciaId: PropTypes.string,
  poloId: PropTypes.string,
  subestacaoId: PropTypes.string,
  disabled: PropTypes.bool,
};

AlimentadorSelect.defaultProps = {
  error: null,
  helperText: null,
  isRequired: false,
  useFilter: true,
  malhaId: null,
  gerenciaId: null,
  poloId: null,
  subestacaoId: null,
  disabled: false
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
}, dispatch);

const mapStateToProps = state => ({
  alimentadores: selectors.getAlimentadoresOptions(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlimentadorSelect);