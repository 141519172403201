import React from 'react';
import PropTypes from 'prop-types';
import DefaultSelect from '../DefaultSelect';

const SimNaoSelect = ({
  error,
  label,
  name,
  onChange,
  value,
  disabled,
  isRequired
}) => {
  const options = [
    { id: 'SIM', descricao: 'Sim' },
    { id: 'NAO', descricao: 'Não' }
  ];

  return (
    <DefaultSelect
      error={error}
      isRequired={isRequired}
      label={label}
      name={name}
      showEmpty
      onSelect={onChange}
      options={options}
      value={value}
      handlerOption={option => option.descricao}
      disabled={disabled}
    />
  );
};

SimNaoSelect.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool
};

SimNaoSelect.defaultProps = {
  value: ''
};

export default SimNaoSelect;
