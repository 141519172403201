/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import validate from 'validate.js';
import { DialogDefault,  NsirStatusSelect } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  }, 
  fieldContainer: {
    paddingBottom: '0px !important'
  },
}));

const StatusModal = ({ open, handlerCancel, handlerConfirm }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ status: null });
  
  const clearForm = () => {
    setErrors({});
    setValues({ status: null })
  }

  const onCancelLocal = () => {
    clearForm();
    handlerCancel();
  }

  const onConfirmLocal = () => {
    const errors = validate(values, {
      status: {
        presence: { allowEmpty: false, message: 'é obrigatório' },
      }
    });
    setErrors(errors);
    if (!errors || (errors && Object.keys(errors).length === 0)) {
      clearForm();
      handlerConfirm(values.status);
    }
  }
  
  return (
    <DialogDefault
      open={open}
      handlerCancel={onCancelLocal}
      handlerConfirm={onConfirmLocal}
      title={'Reabrir NSIR'}
      confirmMessage="Salvar"
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.fieldContainer}>
          <NsirStatusSelect
            isRequired
            error={errors && errors.status? true : false}
            onSelect={status => setValues({ status })}
            value={values.status}
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};

StatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handlerCancel: PropTypes.func.isRequired, 
  handlerConfirm: PropTypes.func.isRequired,
};

export default StatusModal;