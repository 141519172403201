export const EM_CRIACAO = 'EM_CRIACAO';
export const AGUARDANDO_INSPECAO = 'AGUARDANDO_INSPECAO';
export const EM_INSPECAO = 'EM_INSPECAO';
export const INSPECAO_EM_ENVIO = 'INSPECAO_EM_ENVIO';
export const INSPECAO_ENVIADA_PARCIAL = 'INSPECAO_ENVIADA_PARCIAL';
export const INSPECAO_SYNC_ERROR = 'INSPECAO_SYNC_ERROR';
export const ERRO_AO_ENVIAR_INSPECAO = 'ERRO_AO_ENVIAR_INSPECAO';
export const INSPECAO_FINALIZADA = 'INSPECAO_FINALIZADA';
export const INSPECAO_ENVIADA_COM_SUCESSO = 'INSPECAO_ENVIADA_COM_SUCESSO';
export const AGUARDANDO_DIGITACAO = 'AGUARDANDO_DIGITACAO';
export const EM_DIGITACAO = 'EM_DIGITACAO';
export const DIGITACAO_FINALIZADA = 'DIGITACAO_FINALIZADA';
export const EXECUTADA_GDIS = 'EXECUTADA_GDIS';

export const canDigitar = status => [INSPECAO_FINALIZADA, INSPECAO_ENVIADA_COM_SUCESSO, AGUARDANDO_DIGITACAO].indexOf(status) > -1;