import * as Type from './actionsTypes';

const loadOdometros = filtro => ({ type: Type.FETCH_ODOMETROS, filtro });

const deleteOdometro = id => ({ type: Type.DELETE_ODOMETRO, id });

const saveOdometro = (data, callback) => ({ type: Type.SAVE_ODOMETRO, data, callback });

const updateOdometro = (data, callback) => ({ type: Type.UPDATE_ODOMETRO, data, callback });

const findOdometroById = id => ({ type: Type.ODOMETRO_FIND_BY_ID, id });

const findOptions = grupo => ({ type: Type.ODOMETRO_FIND_OPTIONS, grupo });

const validateOdometro = (id, validate) => ({ type: Type.ODOMETRO_VALIDATE_BY_ID, id, validate });

const findFotoById = (id, callback) => ({ type: Type.ODOMETRO_FIND_FOTO_BY_ID, id, callback });

const exportOdometro = (filter, callbackAfterSave) => ({ type: Type.ODOMETRO_EXPORT, filter, callbackAfterSave });

export {
  loadOdometros,
  deleteOdometro,
  saveOdometro,
  updateOdometro,
  findOdometroById,
  findOptions,
  validateOdometro,
  findFotoById,
  exportOdometro
}
