/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as selectorsProdutos from '../../../store/produto/reducer';
import { saveProduto, updateProduto, findProdutoById } from '../../../store/produto/actions';

import moment from 'moment';

import { Input, DialogDefault, ArquivoContainer, TrueFalseSelect } from '../../../components';
import { codeFormatter } from 'utils';
import ProdutoTamanhoTable from '../ProdutoTamanhoTable';

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  possuiTamanho: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  ativo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  buttonContainer: {
    width: '100%',
  },
  error: {
    color: '#e53935',
    marginTop: 10,
    fontSize: 12,
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: '0.33px'
  }
}));

const Formulario = ({ saveProduto, updateProduto, selectedProduto, open, id, onClose, findProdutoById }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setValues({
        data: moment().valueOf(),
        ativo: true,
      });
    } else {
      setValues({});
    }
  }, [open]);

  useEffect(() => {
    if (id) findProdutoById(id);
  }, [id, findProdutoById]);

  useEffect(() => {
    if (id && selectedProduto && id === selectedProduto.id) {
      if (!selectedProduto.data) selectedProduto.data = moment().valueOf();
      else selectedProduto.data = Number(selectedProduto.data);
      setValues(selectedProduto);
    } else {
      setValues({
        data: moment().valueOf(),
        ativo: true,
      });
    }
  }, [id, selectedProduto]);

  const onSave = () => {
    let erros = validate(values, schema);
    if ((values.possuiTamanho === "true" || values.possuiTamanho === true) && (!values.produtoTamanhos || values.produtoTamanhos.length === 0)) {
      erros = erros ? { ...erros, possuiTamanhoList: true } : { possuiTamanhoList: true };
    }
    setErrors(erros);
    if (!erros) {
      if (values.id) {
        updateProduto(values, saveCallback);
      } else {
        saveProduto(values, saveCallback);
      }
    }
  }

  const saveCallback = success => {
    if (success) {
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({})
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSaveTamanhos = produtoTamanhos => {
    setValues({
      ...values,
      produtoTamanhos
    });
  }

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Produto"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        {id && <Grid item xs={2} className={classes.fieldContainer}>
          <Input
            disabled
            label="Código"
            value={codeFormatter(values.codigo)}
          />
        </Grid>}
        <Grid item xs={id ? 5 : 7} className={classes.fieldContainer}>
          <Input
            label="Nome"
            name="nome"
            onChange={handleChange}
            value={values.nome}
            isRequired
            error={errors && errors.nome ? true : false}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.possuiTamanho ? true : false}
            isRequired
            label="Possui tamanho"
            name="possuiTamanho"
            onChange={handleChange}
            value={values.possuiTamanho}
          />
        </Grid>
        <Grid item xs={2} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.ativo ? true : false}
            isRequired
            label="Ativo"
            name="ativo"
            onChange={handleChange}
            value={values.ativo}
          />
        </Grid>
        {id && <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            disabled
            label="Quantidade total"
            value={values.quantidade}
          />
        </Grid>}
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Descrição"
            name="descricao"
            onChange={handleChange}
            value={values.descricao}
            rows={3}
            multiline
          />
        </Grid>
        {(values.possuiTamanho === "true" || values.possuiTamanho === true) &&
          <ProdutoTamanhoTable
            tamanhos={values.produtoTamanhos}
            onSave={onSaveTamanhos}
            showError={errors && errors.possuiTamanhoList ? true : false}
          />}
        <ArquivoContainer
          arquivos={values.arquivos}
          onSave={arquivos => setValues({ ...values, arquivos })}
        />
      </Grid>
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  selectedProduto: selectorsProdutos.getSelectedProduto(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveProduto,
  updateProduto,
  findProdutoById,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
