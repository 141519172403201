import * as Type from './actionsTypes';

const INITIAL_STATE = {
  documentos: [],
  selectedDocumento: {},
  viewDocument: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.DOCUMENTOS_FIND_SUCCESS: {
      return {
        documentos: action.data
      }
    }
    case Type.DOCUMENTOS_FIND_BY_ID_SUCCESS:
    case Type.DOCUMENTOS_SAVE_SUCCESS: {
      return {
        ...state,
        selectedDocumento: action.data
      }
    }
    case Type.DOCUMENTOS_VIEW_BY_ID_SUCCESS: {
      return {
        ...state,
        viewDocument: action.data
      }
    }
    case Type.DOCUMENTOS_CLEAN_VIEW_BY_ID: {
      return {
        ...state,
        viewDocument: null
      }
    }
    default:
      return state;
  }
}

export const getDocumentos = state => state.rootState.documento.documentos;
export const getSelectedDocument = state => state.rootState.documento.selectedDocumento;
export const getViewDocument = state => state.rootState.documento.viewDocument;
