import * as axios from 'axios';
import { stateRoot } from '../store';
export default class BaseService {
  static async get({ url, useToken = true}) {
    return await this.execute({ url, method: 'GET', useToken });
  }

  static async post({ url, data, useToken = true}) {
    return await this.execute({ url, method: 'POST', data, useToken });
  }

  static async put({ url, data, useToken = true}) {
    return await this.execute({ url, method: 'PUT', data, useToken });
  }

  static async delete({ url, useToken = true}) {
    return await this.execute({ url, method: 'DELETE', useToken });
  }

  static async execute({ url, method, data = {}, useToken = true, options = {} }) {
    const defaultOptions = {
      // eslint-disable-next-line no-undef
      url: `${process.env.REACT_APP_BASE_URL}/${url}`,
      method,
      data,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      ...options
    }
    if (useToken) {
      defaultOptions.headers = {
        ...defaultOptions.headers,
        'Authorization': `Bearer ${stateRoot().auth.token}`,
      }
    }
    return await axios(defaultOptions);
  }

}