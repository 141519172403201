import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import InputGroup from '../InputGroup';
import clsx from 'clsx';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
  datePiker: {
    width: '100%',
    '& label': {
      marginTop: 8
    },
    '& div': {
      height: 40,
      paddingLeft: 0,
      marginTop: 8,
      background: theme.palette.white,
      '& button': {
        marginTop: -8
      }
    }
  },
  datePikerDisabled: {
    '& div': {
      background: 'none'
    }
  },
  errorMessage: {
    color: 'red',
    margin: '8px 12px 0',
    minHeight: '1em',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: 0.33,
    fontSize: 13
  }
}));

const DateTimePickerComponent = ({ onChange, value, label, disabled, isRequired, error }) => {
  const classes = useStyles();
  return (
    <InputGroup
      label={label}
      isRequired={isRequired}
    >
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale="ptBR"
      >
        <DateTimePicker  
          autoOk
          className={clsx(classes.datePiker, disabled && classes.datePikerDisabled )}
          format="DD/MM/YYYY HH:mm"
          inputVariant="outlined"
          onChange={onChange}
          value={value && typeof value === 'function' ? null : value}
          variant="inline"
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
      {error && <p className={classes.errorMessage}> Data é obrigatória </p>}
    </InputGroup>
  );
}

DateTimePickerComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  isRequired: PropTypes.any,
  error: PropTypes.bool
};

DateTimePickerComponent.defaultProps = {
  disabled: false,
  isRequired: false,
  error: false
};

export default DateTimePickerComponent;
