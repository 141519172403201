export const FETCH_CARTOES = 'FETCH_CARTOES';
export const FETCH_CARTOES_SUCCESS = 'FETCH_CARTOES_SUCCESS';

export const DELETE_CARTAO = 'DELETE_CARTAO';
export const DELETE_CARTAO_SUCCESS = 'DELETE_CARTAO_SUCCESS';

export const SAVE_CARTAO = 'SAVE_CARTAO';
export const SAVE_CARTAO_SUCCESS = 'SAVE_CARTAO_SUCCESS';

export const UPDATE_CARTAO = 'UPDATE_CARTAO';
export const UPDATE_CARTAO_SUCCESS = 'UPDATE_CARTAO_SUCCESS';

export const CLEAR_CARTAO = 'CLEAR_CARTAO';

export const CARTAO_FIND_BY_ID = 'CARTAO_FIND_BY_ID';
export const CARTAO_FIND_BY_ID_SUCCESS = 'CARTAO_FIND_BY_ID_SUCCESS';

export const CARTAO_FIND_OPTIONS = 'CARTAO_FIND_OPTIONS';
export const CARTAO_FIND_OPTIONS_SUCCESS = 'CARTAO_FIND_OPTIONS_SUCCESS';

export const CARTAO_EXPORT = 'CARTAO_EXPORT';
