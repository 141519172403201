import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from 'react-select';
import InputGroup from '../InputGroup';

const customStyles = {
  control: base => ({
    ...base,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  }),
  container: provided => ({
    ...provided,
    marginTop: 8
  }),
  menu: provided => ({
    ...provided,
    marginTop: 1,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  })
}

const useStyles = makeStyles(() => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  error: {
    color: '#e53935',
    margin: '8px 12px 0',
    fontSize: 11,
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: 0.33
  },
  option: {
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    color: "inherit",
    cursor: "default",
    display: "block",
    fontSize: "inherit",
    label: "option",
    padding: "8px 12px",
    userSelect: "none",
    width: "100%",
  },
  optionHover: {
    backgroundColor: "#DEEBFF"
  },
  optionSelected: {
    backgroundColor: "#2684FF"
  }
}));

const SearchSelect = ({ onSelect, value, isRequired, error, options, label, disabled, multiSelect }) => {
  const classes = useStyles();

  const onChangeLocal = data => {
    if (multiSelect) {
      onSelect(data.map(u => u.value));
    } else {
      onSelect(data.value);
    }
  }

  // eslint-disable-next-line react/no-multi-comp
  const DropdownIndicator = () => (<ArrowDropDownIcon className={classes.arrowIcon}/>)
  const IndicatorSeparator = () => '';

  const getDefaultValue = () => {
    if (multiSelect && value && options) {
      return options.filter(i => value.includes(i.value));
    }

    if (value && options) {
      const selectedOptions = options.filter(i => i.value === value);
      if (selectedOptions && selectedOptions.length > 0) {
        return selectedOptions[0]
      }
    }

    return null;
  }

  return (
    <InputGroup
      label={label}
      isRequired={isRequired}
    >
      <Select
        components={{ DropdownIndicator, IndicatorSeparator }}
        value={getDefaultValue()}
        styles={customStyles}
        options={options}
        onChange={onChangeLocal}
        placeholder="Selecione..."
        noOptionsMessage={() => 'Sem opções'}
        isMulti={multiSelect}
        isDisabled={disabled}
        filterOption={(candidate, input) => {
          return candidate.data.__isNew__ || candidate.label.toUpperCase().includes(input.toUpperCase()) 
            || (candidate.data && candidate.data.sublabel && candidate.data.sublabel.toUpperCase().includes(input.toUpperCase()));
        }}
        menuPlacement="auto"
      />
      {error && <p class="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense Mui-error Mui-required" className={classes.error}>O campo é obrigatório</p>}
    </InputGroup>
  );
}

SearchSelect.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  grupos: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  ativo: PropTypes.bool,
  multiSelect: PropTypes.bool,
};

SearchSelect.defaultProps = {
  error: false,
  isRequired: false,
  grupos: null,
  disabled: false,
  ativo: null,
  multiSelect: false
};

export default SearchSelect;