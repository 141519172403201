import * as Type from './actionsTypes';

const loadNsmpById = id => ({ type: Type.NSMPS_FIND_BY_ID, id });

const deleteNsmp = (id) => ({ type: Type.NSMPS_DELETE, id });

const deleteCloneNsmp = (id, callback) => ({ type: Type.NSMPS_DELETE_CLONE, id, callback });

const cleanNsmps = () => ({ type: Type.NSMPS_CLEAN });

const saveNsmp  = data => ({ type: Type.NSMPS_SAVE, data });

const updateNsmp = data => ({ type: Type.NSMPS_UPDATE, data });

const cleanSelectedNsmp = () => ({ type: Type.NSMPS_CLEAN_SELECTED });

const cloneNsmp = (id, callback) => ({ type: Type.NSMPS_CLONE, id, callback });

export {
  loadNsmpById,
  deleteNsmp,
  deleteCloneNsmp,
  cleanNsmps,
  saveNsmp,
  updateNsmp,
  cleanSelectedNsmp,
  cloneNsmp
}
