export const FETCH_VEICULOS = 'FETCH_VEICULOS';
export const FETCH_VEICULOS_SUCCESS = 'FETCH_VEICULOS_SUCCESS';

export const DELETE_VEICULO = 'DELETE_VEICULO';
export const DELETE_VEICULO_SUCCESS = 'DELETE_VEICULO_SUCCESS';

export const SAVE_VEICULO = 'SAVE_VEICULO';
export const SAVE_VEICULO_SUCCESS = 'SAVE_VEICULO_SUCCESS';

export const UPDATE_VEICULO = 'UPDATE_VEICULO';
export const UPDATE_VEICULO_SUCCESS = 'UPDATE_VEICULO_SUCCESS';

export const CLEAR_VEICULO = 'CLEAR_VEICULO';

export const VEICULO_FIND_BY_ID = 'VEICULO_FIND_BY_ID';
export const VEICULO_FIND_BY_ID_SUCCESS = 'VEICULO_FIND_BY_ID_SUCCESS';

export const VEICULO_FIND_OPTIONS = 'VEICULO_FIND_OPTIONS';
export const VEICULO_FIND_OPTIONS_SUCCESS = 'VEICULO_FIND_OPTIONS_SUCCESS';

export const VEICULO_EXPORT = 'VEICULO_EXPORT';

export const HISTORICO_VEICULO_UPSERT = 'HISTORICO_VEICULO_UPSERT';
export const HISTORICO_VEICULO_UPSERT_SUCCESS = 'HISTORICO_VEICULO_UPSERT_SUCCESS';

export const HISTORICO_VEICULO_FIND_BY_ID = 'HISTORICO_VEICULO_FIND_BY_ID';
export const HISTORICO_VEICULO_FIND_BY_ID_SUCCESS = 'HISTORICO_VEICULO_FIND_BY_ID_SUCCESS';

export const HISTORICO_VEICULO_DELETE = 'HISTORICO_VEICULO_DELETE';
