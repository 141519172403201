/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/auth/actionsTypes';
import * as ConfigTypeActions from '../store/config/actionsTypes';
import BaseService from '../services/BaseService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';

import { CLEAR_DASHBOARD } from '../store/dashboard/actionsTypes';
import { CLEAR_USER } from '../store/usuario/actionsTypes';
import { NSIRS_CLEAN } from '../store/nsir/actionsTypes';
import { FILTRO_CLEAN } from '../store/filtro/actionsTypes';
import { getEstados, getCidades } from '../utils';

function* login({ authData, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'login', data: authData, useToken: false });
    yield put({
      type: TypeActions.FETCH_AUTH_SUCCESS,
      data: response.data
    });

    const estados = yield getEstados();
    yield put({
      type: ConfigTypeActions.UPDATE_ESTADOS,
      data: estados
    });

    const cidades = yield getCidades();
    yield put({
      type: ConfigTypeActions.UPDATE_CIDADES,
      data: cidades
    });

    yield put({ type: FILTRO_CLEAN });
    yield put({ type: NSIRS_CLEAN });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    callback(response.data.updatePassword);

  } catch (error) {
    console.log(error)
    let message = 'Erro ao logar';
    if (error.response && error.response.status && error.response.status === 401) {
      message = 'Usuário e senha inválidos!';
    } else if (error.response && error.response.status && error.response.status === 403) {
      message = 'O usuário bloqueado temporariamente. Por favor, contacte o administrador do sistema!'
    }
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  }
}

function * forgotPassword({ forgotData }) {
  try {
    yield console.log('forgotPassword');
    // const response = yield AuthService.forgotPassword(forgotData);
    // yield put({
    //     type: TypeActions.FETCH_AUTH_SUCCESS,
    //     data: response.data
    // });
  } catch (error) {
    // yield put({
    //     type: TypeActions.FETCH_AUTH_FAILED,
    //     error
    // });
  }
}

function * updatePassword({ id, data, callback, showMessage = false }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.post({ url: `usuarios/${id}/reset/password`, data });
    yield callback();
    
    if (showMessage) {
      yield put({ type: LoaderTypeActions.LOADER_HIDE });
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
        message: 'Senha atualizada com sucesso!'
      });
    }
  } catch (error) {
    yield console.log(error);

    let message = 'Erro ao atualizar a senha';
    if (error.response && error.response.status && error.response.status === 409) {
      message = error.response.data.mensagem;
    }

    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  }
}

function* updateProfile({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.post({ url: 'usuarios/profile', data });
    yield put({
      type: TypeActions.UPDATE_PROFILE_SUCCESS,
      data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function * logout({ history }) {
  yield put({ type: TypeActions.AUTH_CLEAR });
  yield put({ type: CLEAR_DASHBOARD });
  yield put({ type: CLEAR_USER });

  history.push('/login');
}

export default function * watchAuth() {
  yield takeLatest(TypeActions.FETCH_AUTH, login);
  yield takeLatest(TypeActions.FETCH_FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(TypeActions.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(TypeActions.UPDATE_PROFILE, updateProfile);
  yield takeLatest(TypeActions.AUTH_LOGOUT, logout);
}