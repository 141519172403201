/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, Filtro, DialogDefault } from '../../components';
import * as selectorsAuth from '../../store/auth/reducer';
import * as actionsInspecaoVeicular from '../../store/inspecaoVeicular/actions';
import Formulario from './Formulario';
import { getMaxColumnLength } from '../../utils';
import * as Roles from '../../constants/role';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const InspecaoVeicular = ({ inspecaoVeicularReport, authData }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [localFilter, setLocalFilter] = useState({
    inicio: moment().subtract(1, 'month').startOf('month').valueOf(),
    fim: moment().endOf('month').valueOf(),
  });
  const [reload, setReload] = useState(false);
  const [openExport, setOpenExport] = useState(false);

  const classes = useStyles();
  
  const renderRows = a => {
    return (
      <>
        <TableCell>{a.dataInspecaoValue}</TableCell>
        <TableCell>{a.inspetorNome}</TableCell>
        <TableCell>{a.inspetorAuxiliarNome}</TableCell>
        <TableCell>{a.veiculoPlaca}</TableCell>
        <TableCell size="medium" title={a.observacao}>{getMaxColumnLength(a.observacao)}</TableCell>
      </>
    )
  }

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = (save) => {
    setOpen(false);
    setId(null);
    if (save) {
      setReload(!reload);
    }
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getHeaderActions = () => {
    let actions = [];

    if (isAdmin()) {
      actions.push({ 
        title: 'Relatório',
        onClick: () => setOpenExport(true)
      });
    }

    return actions;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Filtro 
          onFilter={filterData => setLocalFilter(filterData)}
          useDates
          useInspetor
          useInspetorMultiSelect
          useInspetorAuxiliar
          useInspetorAuxiliarMultiSelect
          useVeiculo
        />
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          titles={['Data da Inspeção', 'Inspetor', 'Inspetor Auxiliar', 'Veículo', 'Observação']}
          headerTitle="Convocação"
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onAdd={onAdd}
          headerActions={getHeaderActions()}
          showDelete={false}
          usePaginationApi
          paginationApi="inspecao-veicular/filter"
          paginationUrl="inspecoes-veiculares"
          filterData={localFilter}
          reload={!reload}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          onReload={() => setReload(!reload)}
        />
        <DialogDefault
          open={openExport}
          useFullScreen={false}
          handlerCancel={() => setOpenExport(false)}
          handlerConfirm={() => {
            inspecaoVeicularReport(localFilter, () => {
              setOpenExport(false);
            });
          }}
          title="Confirmação"
          confirmMessage="Sim"
          cancelMessage="Não"
        >
          <DialogContentText> Deseja baixar todas as informações filtradas? </DialogContentText>
        </DialogDefault>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsInspecaoVeicular
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InspecaoVeicular));