/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, TableCell } from '@material-ui/core';
import Viewer from 'react-viewer';
import Table from '../Table';
import DialogDefault from '../DialogDefault';
import { v4 as uuidv4 } from 'uuid';
import { DropzoneDialog } from 'material-ui-dropzone';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import fileDownload from 'js-file-download';
import heic2any from 'heic2any';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  image: {
    height: 50
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  required: {
    color: 'red',
    paddingTop: 5
  }
}));

const ArquivoContainer = ({ arquivos, onSave, disabled, showDescription = false }) => {
  const classes = useStyles();

  const [activeIndex, setActiveIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [noPdfsFiles, setNoPdfsFiles] = useState([]);
  const [openPdfView, setOpenPdfView] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [numPages, setNumPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const getBase64 = image => image && (image.includes('data:image') || image.includes('data:application')) ? image : `data:image/png;base64,${image}`;

  const toBase64 = file => new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => resolve("");
  });

  useEffect(() => {
    if (arquivos) {
      const getArquivos = async () => {
        const files = [];
        const noPdfs = [];
        for (let arquivo of arquivos) {
          if (arquivo.file && arquivo.file.size && arquivo.extensao === "pdf") {
            files.push(arquivo);
          } else {
            if (arquivo.file && arquivo.file.size && arquivo.extensao === "heic") {
              let file = arquivo.file
              try {
                file = await heic2any({ blob: arquivo.file, toType: "image/jpeg", quality: 0.5 });
              } catch(e) {
                console.error(e);
              }
              arquivo["file"] = file;
              arquivo["src"] = await toBase64(file);
            } else if (arquivo.file && arquivo.file.size && !arquivo["src"]) {
              arquivo["src"] = await toBase64(arquivo.file);
            }
            noPdfs.push(arquivo);
            files.push(arquivo);
          }
        }
        setNoPdfsFiles(noPdfs);
        setFiles(files);
      }

      getArquivos();
    }
  }, [arquivos]);

  const onSaveLocal = async filesToSave => {
    const arquivoList = [];

    for (let file of filesToSave) {
      arquivoList.push({
        id: uuidv4(),
        nome: file.name.split('.')[0],
        extensao: file.name.split('.').pop(),
        alt: file.name,
        src: getBase64(await toBase64(file)),
        file,
      });
    }

    setFiles([...files, ...arquivoList]);
    onSave([...files, ...arquivoList]);
    setOpen(false);
  } 

  const renderRows = file => {
    if (showDescription) {
      return (
        <>
          <TableCell>{file.nome}</TableCell>
          <TableCell>{file.extensao}</TableCell>
          <TableCell>{file.description}</TableCell>
        </>
      )
    } else {
      return (
        <>
          <TableCell>{file.nome}</TableCell>
          <TableCell>{file.extensao}</TableCell>
        </>
      )
    }
  }

  const onDeleteLocal = id => {
    const arquivos = files.filter(f => f.id !== id);
    setFiles(arquivos);
    onSave(arquivos);
  }

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={files}
          titles={showDescription ? ['Nome', 'Extensão', 'Descrição']: ['Nome', 'Extensão'] }
          headerTitle="Arquivos"
          deleteMessage="O arquivo será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          hasRowActions
          showEdit={false}
          onDelete={onDeleteLocal}
          hasAdd={disabled === undefined || disabled === false}
          showDelete={disabled === undefined || disabled === false}
          onAdd={() => setOpen(true)}
          actions={
            [{
              key: 'view',
              onClick: id => {
                const currentFile = files.filter(f => f.id === id)[0];
                if (currentFile.extensao === "pdf") {
                  setCurrentPdf(currentFile);
                  setOpenPdfView(true);
                } else {
                  setActiveIndex(noPdfsFiles.map(f => f.id).indexOf(id));
                  setShowModal(true);
                }

              },
              icon: <PhotoCameraIcon fontSize="small" />
            },
            {
              key: 'download',
              onClick: id => {
                const file = files.filter(f => f.id === id)[0];
                fileDownload(file.file, `${file.nome}.${file.extensao}`);
              },
              icon: <CloudDownloadIcon fontSize="small" />
            }]
          }
        />
      </Grid>
      <DropzoneDialog
        open={open}
        onSave={onSaveLocal}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={() => setOpen(false)}
        filesLimit={10}
        previewText="Pré-visualização: "
        dialogTitle="Selecionar arquivos"
        cancelButtonText="Cancelar"
        submitButtonText="Salvar"
        dropzoneText="Arraste e solte um arquivo aqui ou clique"
        maxWidth="md"
        getFileAddedMessage={fileName => `Arquivo ${fileName} adicionado com sucesso`}
        getFileRemovedMessage={fileName => `Arquivo ${fileName} removido`}
        getFileLimitExceedMessage={filesLimit => `Número máximo permitido de arquivos excedido. Apenas ${filesLimit} permitidos`}
      />
      <Viewer
        visible={showModal}
        onClose={() => {
          setActiveIndex(0);
          setShowModal(false);
        }}
        images={noPdfsFiles || []}
        zIndex={3000}
        scalable={false}
        noImgDetails
        zoomSpeed={0.25}
        defaultImg={{
          src: "/images/noImage.jpeg"
        }}
        activeIndex={activeIndex}
      />
      <DialogDefault
        open={openPdfView}
        handlerCancel={() => setOpenPdfView(false)}
        readOnly
        title={currentPdf ? currentPdf.nome : ""}
        maxWidth="md"
      >
        <Document
          file={currentPdf ? currentPdf.file : null}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          <Page pageNumber={numPages} />
        </Document>
      </DialogDefault>
    </>
  );
};

ArquivoContainer.propTypes = {
  arquivos: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showDescription: PropTypes.bool,
};

ArquivoContainer.defaultValue = {
  disabled: false,
  showDescription: false
}

export default ArquivoContainer;
