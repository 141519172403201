/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import * as selectorsDocumentos from '../../store/documento/reducer';
import * as actionsDocumentos from '../../store/documento/actions';

import { DialogDefault, Input, DefaultSelect, Dropzone, TrueFalseSelect
 } from '../../components';

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  modulo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  ativo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  arquivo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  paperScrollPaperDialog: {
    [theme.breakpoints.up('md')]: {
      minWidth: '70vw',
      minHeight: '70vh'
    },
  },
  buttonContainer: {
    width: '100%',
  }
}));

const Formulario = ({ saveDocumento, selectedDocumento, open, id, onClose, findDocumentoById, }) => {

  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [arquivo, setArquivo] = useState(null);

  useEffect(() => {
    if (id) findDocumentoById(id);
  }, [id, findDocumentoById]);

  useEffect(() => {
    if (id && selectedDocumento) {
      setValues(selectedDocumento);
      setArquivo(selectedDocumento.arquivoFile);
    }
  }, [id, selectedDocumento]);

  const onSave = () => {
    const erros = validate({...values, arquivo }, schema);
    setErrors(erros);
    if (!erros) {

      values.arquivo = {
        nome: values.arquivoFile.name.split('.')[0],
        extensao: values.arquivoFile.name.split('.').pop(),
        status: 'NOT_SYNC'
      };

      saveDocumento(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({});
    setArquivo(null);
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Documento"
      confirmMessage="Salvar"
      maxWidth="md"
      paperScrollPaperClassName={classes.paperScrollPaperDialog}
    >
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.fieldContainer}>
          <Input
            error={errors && errors.nome}
            isRequired
            label="Nome"
            name="nome"
            onChange={handleChange}
            value={values.nome}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.modulo ? true : false}
            isRequired
            label="Módulo"
            name="modulo"
            showEmpty
            options={[
              {id: 'NSIR', nome: 'Nsir'},
              {id: 'FINANCEIRO', nome: 'Financeiro'},
              {id: 'USUARIO', nome: 'Usuário'},
            ]}
            onSelect={handleChange}
            value={values.modulo}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.ativo ? true : false}
            isRequired
            label="Ativo"
            name="ativo"
            onChange={handleChange}
            value={values.ativo}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Descrição"
            name="descricao"
            onChange={handleChange}
            value={values.descricao}
            rows={3}
            multiline
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <Dropzone 
            buttonText="Arquivo"
            filesLimit={1}
            acceptedFiles={['.pdf']}
            buttonClassName={classes.buttonContainer}
            error={errors && errors.arquivo}
            onSave={files => {
              if (files != null && files.length > 0) {
                setArquivo(files[0]);
                values.arquivoFile = files[0];
              }
            }}
            canDeleteFiles
            showGridFiles
            files={arquivo != null ? [{
              file: arquivo,
              nome: arquivo.name,
              onDelete: () => {
                setArquivo(null);
                values.arquivoFile = null;
              }
            }] : []}
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};

Formulario.propTypes = {
  findUsuarioById: PropTypes.func.isRequired, 
  saveUsuario: PropTypes.func.isRequired,
  updateUsuario: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  resetPassword: PropTypes.func.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Formulario.defaultValue = {
  selectedDocumento: null,
  id: null,
}

const mapStateToProps = state => ({
  selectedDocumento: selectorsDocumentos.getSelectedDocument(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsDocumentos,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
