/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, TableCell } from '@material-ui/core';
import { Table, DialogDefault } from '../../components';
import * as selectorsDocumentos from '../../store/documento/reducer';
import * as actionsDocumentos from '../../store/documento/actions';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3)
  },
}));

const DocumentoContainer = ({ documentos, modulo, ativo, loadDocumentos, downloadDocumento, headerTitle, viewDocumentById, viewDocument, cleanViewDocumentById }) => {
  const classes = useStyles();

  const [numPages, setNumPages] = useState(1);
  
  const renderRows = s => {
    return (
      <>
        <TableCell>{s.nome}</TableCell>
        <TableCell>{s.descricao}</TableCell>
        <TableCell>{s.modulo}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadDocumentos(modulo, ativo);
  }, [loadDocumentos, modulo, ativo]);

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={documentos}
          titles={['Nome', 'Descrição', 'Modulo']}
          headerTitle={headerTitle}
          showDelete={false}
          showEdit={false}
          showView={false}
          hasAdd={false}
          renderRows={renderRows}
          hasRowActions
          actions={[{
              key: 'view',
              onClick: id => {
                viewDocumentById(documentos.filter(d => d.id === id)[0]);
              },
              icon: <PhotoCameraIcon fontSize="small" />
            },
            {
              key: 'download',
              onClick: id => {
                const documento = documentos.filter(d => d.id === id);
                downloadDocumento(documento.length > 0 ? documento[0] : {});
              },
              icon: <CloudDownloadIcon />
          }]}
        />
      </Grid>
      <DialogDefault
        open={viewDocument ? true : false}
        handlerCancel={() => cleanViewDocumentById()}
        readOnly
        title={viewDocument ? viewDocument.nome : ""}
        maxWidth="md"
      >
        <Document
          file={viewDocument ? viewDocument.file : null}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          <Page pageNumber={numPages} />
        </Document>
      </DialogDefault>
    </>
  );
};

DocumentoContainer.propTypes = {
  downloadDocumento: PropTypes.func.isRequired,
  loadDocumentos: PropTypes.func.isRequired,
  documentos: PropTypes.array.isRequired,
  types: PropTypes.array,
  headerTitle: PropTypes.string
};

DocumentoContainer.defaultValue = {
  headerTitle: "Documentos",
  types: null
}

const mapStateToProps = state => ({
  documentos: selectorsDocumentos.getDocumentos(state),
  viewDocument: selectorsDocumentos.getViewDocument(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsDocumentos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentoContainer));