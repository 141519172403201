import { combineReducers } from 'redux';
import auth from './auth/reducer';
import config from './config/reducer';
import usuario from './usuario/reducer';
import usuarioDocumento from './usuarioDocumento/reducer';
import tag from './tag/reducer';
import dashboard from './dashboard/reducer';
import toast from './toast/reducer';
import loader from './loader/reducer';

import nsir from './nsir/reducer';
import nsmp from './nsmp/reducer';

import malha from './malha/reducer';
import gerencia from './gerencia/reducer';
import polo from './polo/reducer';
import subestacao from './subestacao/reducer';
import alimentador from './alimentador/reducer';
import documento from './documento/reducer';
import filtro from './filtro/reducer';
import veiculo from './veiculo/reducer';
import odometro from './odometro/reducer';
import nota from './nota/reducer';
import cartao from './cartao/reducer';
import aporte from './aporte/reducer';
import controleCaixa from './controleCaixa/reducer';
import convocacao from './convocacao/reducer';
import drone from './drone/reducer';
import controlePonto from './controlePonto/reducer';
import equipamento from './equipamento/reducer';
import produto from './produto/reducer';
import estoque from './estoque/reducer';
import pedido from './pedido/reducer';
import inspecaoVeicular from './inspecaoVeicular/reducer';

export default combineReducers({
  auth,
  config,
  usuario,
  usuarioDocumento,
  tag,
  dashboard,
  toast,
  loader,
  nsir,
  nsmp,
  malha,
  gerencia,
  polo,
  subestacao,
  alimentador,
  documento,
  filtro,
  veiculo,
  odometro,
  nota,
  cartao,
  aporte,
  controleCaixa,
  convocacao,
  drone,
  controlePonto,
  equipamento,
  produto,
  estoque,
  pedido,
  inspecaoVeicular,
});
