/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/nota/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';

function* load({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    if (!filtro.tipoNota) filtro.tipoNota = null;
    const response = yield BaseService.post({ url: 'notas/filtro', data: filtro });
    yield put({
      type: TypeActions.FETCH_NOTAS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'notas', data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });

    yield put({
      type: TypeActions.SAVE_NOTA_SUCCESS,
      data: response.data
    });

    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao salvar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `notas/${data.id}`, data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });

    yield put({
      type: TypeActions.UPDATE_NOTA_SUCCESS,
      data: response.data
    });

    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findById({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `notas/${id}` });
    if (callback) {
      yield callback(response.data);
    } else {
      if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
        response.data.arquivos = yield FileService.downloadArquivos(response.data.arquivos);
      }
    }

    yield put({
      type: TypeActions.NOTA_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* downloadNotas({ filter, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'notas/download-fotos',
      data: filter,
      method: 'POST',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'notas.zip');
    yield callback(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Notas geradas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao gerar as notas!'
    });
  }
}

function* validateNota({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.post({ url: 'notas/validate', data });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* findFotoById({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `notas/${id}/arquivos` });
    
    const images = [];
    if (response.data && response.data.length > 0) {
      for(let image of response.data) {
        try {
          const responseFile = yield FileService.getFile(image.url);
          const imageSrc = yield FileService.getBase64(responseFile.data);
          images.push({src: imageSrc, alt: image.title, downloadUrl: image.url});
        } catch(e) {
          yield console.error(e);
        }
      }
    }

    yield callback(images);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as imagens para a nota!'
    });
  }
}

function* exportNota({ filter, report = false,  callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: `notas/${report ? "report" : "export"}`,
      data: filter,
      method: 'POST',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório Nota.xlsx');
    yield callbackAfterSave(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar o relatório!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_NOTAS, load);
  yield takeLatest(TypeActions.SAVE_NOTA, save);
  yield takeLatest(TypeActions.UPDATE_NOTA, update);
  yield takeLatest(TypeActions.NOTA_DOWNLOAD, downloadNotas);
  yield takeLatest(TypeActions.NOTA_FIND_BY_ID, findById);
  yield takeLatest(TypeActions.NOTA_VALIDATE_BY_ID, validateNota);
  yield takeLatest(TypeActions.NOTA_FIND_FOTO_BY_ID, findFotoById);
  yield takeLatest(TypeActions.NOTA_EXPORT, exportNota);
}