import * as Type from './actionsTypes';

const INITIAL_STATE = {
  pedidos: [],
  selectedPedido: null,
};

const ordernarData = (a,b) => new Date(Number(b.data)) - new Date(Number(a.data));

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_PEDIDOS_SUCCESS: {
      return {
        ...state,
        pedidos: action.data.sort(ordernarData)
      }
    }
    case Type.DELETE_PEDIDO_SUCCESS: {
      return {
        ...state,
        pedidos: state.pedidos.filter(s => s.id !== action.data).sort(ordernarData)
      }
    }
    case Type.SAVE_PEDIDO_SUCCESS: {
      return {
        ...state,
        pedidos: [...state.pedidos, action.data].sort(ordernarData)
      }
    }
    case Type.UPDATE_PEDIDO_SUCCESS: {
      return {
        ...state,
        pedidos: [...state.pedidos.filter(s => s.id !== action.data.id), action.data].sort(ordernarData)
      }
    }
    case Type.PEDIDO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedPedido: action.data
      }
    }
    case Type.CLEAR_PEDIDO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getPedidos = state => state.rootState.pedido.pedidos;
export const getSelectedPedido = state => state.rootState.pedido.selectedPedido;
