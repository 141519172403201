import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import StoreContainer from './config/StoreContainer';
import Toast from './views/Toast';
import Loader from './views/Loader';

import { default as Routes } from './routes';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <StoreContainer>
          <Loader />
          <Toast />
          <BrowserRouter history={browserHistory}>
            <Switch>
              <Routes />
            </Switch>
          </BrowserRouter>
        </StoreContainer>
      </ThemeProvider>
    );
  }
}
