import React from 'react';

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      svgjs="http://svgjs.com/svgjs"
      version="1.1"
      width="22"
      height="22"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <g
          xmlns="http://www.w3.org/2000/svg"
          id="U"
        >
          <path
            d="m337.255 281.038c.015 67.588-1.025 87.876-24.229 105.894-22.163 17.227-82.017 19.79-110.537-1.201-27.246-20.039-28.315-48.384-27.847-96.782l.103-288.949h-128.745v268.557c0 93.472 0 181.758 103.638 226.406 47.595 20.546 154.453 24.243 211.816 1.465 104.546-41.528 104.546-129.477 104.546-231.738v-264.69h-128.745z"
            fill="#ff9800"
            data-original="#000000"
          />
        </g>
      </g>
    </svg>
  );
};

export default Icon;
