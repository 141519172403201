export const DOCUMENTOS_FIND = 'DOCUMENTOS_FIND';
export const DOCUMENTOS_FIND_SUCCESS = 'DOCUMENTOS_FIND_SUCCESS';

export const DOCUMENTOS_FIND_BY_ID = 'DOCUMENTOS_FIND_BY_ID';
export const DOCUMENTOS_FIND_BY_ID_SUCCESS = 'DOCUMENTOS_FIND_BY_ID_SUCCESS';

export const DOCUMENTOS_SAVE = 'DOCUMENTOS_SAVE';
export const DOCUMENTOS_SAVE_SUCCESS = 'DOCUMENTOS_SAVE_SUCCESS';

export const DOCUMENTOS_DOWNLOAD = 'DOCUMENTOS_DOWNLOAD';

export const DOCUMENTOS_VIEW_BY_ID = 'DOCUMENTOS_VIEW_BY_ID';
export const DOCUMENTOS_VIEW_BY_ID_SUCCESS = 'DOCUMENTOS_VIEW_BY_ID_SUCCESS';

export const DOCUMENTOS_CLEAN_VIEW_BY_ID = 'DOCUMENTOS_CLEAN_VIEW_BY_ID';