import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as selectors from '../../store/subestacao/reducer';
import * as actions from '../../store/subestacao/actions';
import DefaultSelect from '../DefaultSelect';
import MultiSelect from '../MultiSelect';

const SubestacaoSelect = ({ subestacoes, onSelect, value, isRequired, error, helperText, findOptions, useFilter, 
  malhaId, gerenciaId, poloId, disabled, isMultiple }) => {

  const handleChange = (event) => onSelect(event.target.value);

  const handleMultiplo = newValue => {
    let result = null;
    if (newValue) {
      result = newValue.map(v => v.value);
    }
    onSelect(result);
  }


  useEffect(() => {
    if (useFilter) {
      findOptions({ malhaId, gerenciaId, poloId });
    } else {
      findOptions();
    }
  }, [useFilter, findOptions, malhaId, gerenciaId, poloId]);

  return <>
    { isMultiple ? 
      <MultiSelect
        isRequired={isRequired}
        error={error}
        helperText={helperText}
        label="Subestações"
        onChange={newValue => handleMultiplo(newValue)}
        defaultValue={subestacoes && value? subestacoes.filter(p => value.indexOf(p.id) > -1).map(i => ({ value: i.id, label: `${i['codigo']} - ${i['nome']}` })) : []}
        options={subestacoes ? subestacoes.map(i => ({ value: i.id, label: `${i['codigo']} - ${i['nome']}` })) : []}
      /> 
      : 
      <DefaultSelect 
        error={error}
        helperText={helperText}
        isRequired={isRequired}
        label="Subestação"
        onSelect={handleChange}
        options={subestacoes}
        value={value}
        disabled={disabled}
      />
    }
  </>
}

SubestacaoSelect.propTypes = {
  error: PropTypes.any,
  value: PropTypes.string,
  subestacoes: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  useFilter: PropTypes.bool,
  malhaId: PropTypes.string,
  gerenciaId: PropTypes.string,
  poloId: PropTypes.string,
  findOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isMultiple: PropTypes.bool
};

SubestacaoSelect.defaultProps = {
  error: null,
  helperText: null,
  isRequired: false,
  useFilter: true,
  malhaId: null,
  gerenciaId: null,
  poloId: null,
  disabled: false,
  isMultiple: false
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
}, dispatch);

const mapStateToProps = state => ({
  subestacoes: selectors.getSubestacoesOptions(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubestacaoSelect);