/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Menu, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    marginRight: 15,
    marginTop: 5
  },
}));

const ButtonMenu = ({ options, title }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={classes.button}
        color="primary" 
        variant="contained" 
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(data => (
          (data.show === undefined || data.show) && <MenuItem onClick={() => {
            handleClose();
            {data.onClick()}
          }}>
            {data.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

ButtonMenu.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ButtonMenu;