import * as Type from './actionsTypes';
import {ordenarPorCodigoString} from '../../utils';

const INITIAL_STATE = {
  malhas: [],
  options: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.MALHAS_FIND_SUCCESS: {
      return {
        ...state,
        malhas: action.data.sort(ordenarPorCodigoString)
      }
    }
    case Type.MALHAS_DELETE_SUCCESS: {
      return {
        ...state,
        malhas: state.malhas.filter(a => a.id !== action.data)
      }
    }
    case Type.MALHAS_SAVE_SUCCESS: {
      return {
        ...state,
        malhas: [...state.malhas, action.data].sort(ordenarPorCodigoString)
      }
    }
    case Type.MALHAS_UPDATE_SUCCESS: {
      return {
        ...state,
        malhas: [...state.malhas.filter(s => s.id !== action.data.id), action.data].sort(ordenarPorCodigoString)
      }
    }
    case Type.MALHAS_USER: {
      return INITIAL_STATE;
    }
    case Type.MALHAS_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data.sort(ordenarPorCodigoString)
      }
    }
    default:
      return state;
  }
}

export const getMalhas = state => state.rootState.malha.malhas;
export const getMalhasOptions = state => state.rootState.malha.options;
