/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/odometro/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';

function* load({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'odometros/filtro', data: filtro });
    yield put({
      type: TypeActions.FETCH_ODOMETROS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* findOptions() {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: 'odometros' });
    yield put({
      type: TypeActions.ODOMETRO_FIND_OPTIONS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'odometros', data });
    yield put({
      type: TypeActions.SAVE_ODOMETRO_SUCCESS,
      data: response.data
    });

    const validateArquivoDTO = [];
    try {
      yield FileService.updateFile(response.data.fotoInicial.url, data.fotoInicialFile);
      validateArquivoDTO.push({id: response.data.fotoInicial.id, valid: true });
    } catch(error) {
      yield console.error(error);
      validateArquivoDTO.push({id: response.data.fotoInicial.id, valid: false });
    }

    try {
      yield FileService.updateFile(response.data.fotoFinal.url, data.fotoFinalFile);
      validateArquivoDTO.push({id: response.data.fotoFinal.id, valid: true });
    } catch(error) {
      yield console.error(error);
      validateArquivoDTO.push({id: response.data.fotoFinal.id, valid: false });
    }

    yield BaseService.post({ url: 'arquivos/validate', data: {arquivos: validateArquivoDTO} });

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao salvar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `odometros/${data.id}`, data });
    yield put({
      type: TypeActions.UPDATE_ODOMETRO_SUCCESS,
      data: response.data
    });

    const validateArquivoDTO = [];
    try {
      yield FileService.updateFile(response.data.fotoInicial.url, data.fotoInicialFile);
      validateArquivoDTO.push({id: response.data.fotoInicial.id, valid: true });
    } catch(error) {
      yield console.error(error);
      validateArquivoDTO.push({id: response.data.fotoInicial.id, valid: false });
    }

    try {
      yield FileService.updateFile(response.data.fotoFinal.url, data.fotoFinalFile);
      validateArquivoDTO.push({id: response.data.fotoFinal.id, valid: true });
    } catch(error) {
      yield console.error(error);
      validateArquivoDTO.push({id: response.data.fotoFinal.id, valid: false });
    }

    yield BaseService.post({ url: 'arquivos/validate', data: {arquivos: validateArquivoDTO} });

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `odometros/${id}` });

    if (response.data && response.data.fotoInicial && response.data.fotoInicial.url) {
      try {
        const responseFile = yield FileService.getFile(response.data.fotoInicial.url);
        if (responseFile) {
          responseFile.data.name = `Foto Inicial.${response.data.fotoInicial.extensao}`;
          response.data.fotoInicialFile = responseFile.data;
        }
      } catch(e) {
        yield console.error(e);
      }
    }

    if (response.data && response.data.fotoFinal && response.data.fotoFinal.url) {
      try {
        const responseFile = yield FileService.getFile(response.data.fotoFinal.url);
        if (responseFile) {
          responseFile.data.name = `Foto Final.${response.data.fotoFinal.extensao}`;
          response.data.fotoFinalFile = responseFile.data;
        }
      } catch(e) {
        yield console.error(e);
      }
    }
    
    yield put({
      type: TypeActions.ODOMETRO_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* validateOdometro({ id, validate }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.post({ url: 'odometros/validate/', data: { id, validate } });
    yield put({
      type: TypeActions.ODOMETRO_VALIDATE_BY_ID_SUCCESS,
      id, 
      validate
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* findFotoById({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `odometros/${id}/arquivos` });
    const images = [];
    if (response.data && response.data.length > 0) {
      for(let image of response.data) {
        try {
          const responseFile = yield FileService.getFile(image.url);
          const imageSrc = yield FileService.getBase64(responseFile.data);
          images.push({src: imageSrc, alt: image.title, downloadUrl: image.url});
        } catch(e) {
          yield console.error(e);
        }
      }
    }
    yield callback(images);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as imagens para a nota!'
    });
  }
}

function* exportOdometro({ filter, callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'odometros/export',
      data: filter,
      method: 'POST',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório Odômetro.xlsx');
    yield callbackAfterSave(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar o relatório!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_ODOMETROS, load);
  yield takeLatest(TypeActions.ODOMETRO_FIND_OPTIONS, findOptions);
  yield takeLatest(TypeActions.SAVE_ODOMETRO, save);
  yield takeLatest(TypeActions.UPDATE_ODOMETRO, update);
  yield takeLatest(TypeActions.ODOMETRO_FIND_BY_ID, findById);
  yield takeLatest(TypeActions.ODOMETRO_VALIDATE_BY_ID, validateOdometro);
  yield takeLatest(TypeActions.ODOMETRO_FIND_FOTO_BY_ID, findFotoById);
  yield takeLatest(TypeActions.ODOMETRO_EXPORT, exportOdometro);
}