import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[500],
    light: colors.orange[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.grey[900],
    main: colors.grey['A400'],
    light: colors.grey['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  grey: {
    dark: colors.grey[700],
    main: colors.grey[500],
    light: colors.grey[350]
  },
  header: {
    background: '#BEBEBE'
  },
  bar: {
    light: '#787c80',
    principal: '#343a40',
    grayLight: '#f1f4f6',
    grayMiddle: '#E0E0E0',
    grayMain: '#BEBEBE',
    darkOrange: '#ff980080',
    white: '#fff',
    border: '0.5px solid #343a40'
  },
  textColor: {
    gray: '#787c80',
    orange: '#ff9800',
    darkOrange: '#ff980080',
    white: '#fff'
  }
};
