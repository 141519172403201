import { all } from 'redux-saga/effects';
import watchAuth from './auth';
import watchUsuarios from './usuario';
import watchUsuarioDocumentos from './usuarioDocumento';
import watchDashboard from './dashboard';
import watchNsir from './nsir';
import watchNsmp from './nsmp';
import watchMalha from './malha';
import watchGerencia from './gerencia';
import watchPolo from './polo';
import watchSubestacao from './subestacao';
import watchAlimentador from './alimentador';
import watchDocumento from './documento';
import watchVeiculo from './veiculo';
import watchOdometro from './odometro';
import watchNota from './nota';
import watchCartao from './cartao';
import watchAporte from './aporte';
import watchControleCaixa from './controleCaixa';
import watchConvocacao from './convocacao';
import watchDrone from './drone';
import watchControlePonto from './controlePonto';
import watchEquipamentos from './equipamento';
import watchProduto from './produto';
import watchEstoque from './estoque';
import watchPedido from './pedido';
import watchInspecaoVeicular from './inspecaoVeicular';

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchUsuarios(),
    watchUsuarioDocumentos(),
    watchDashboard(),
    watchNsir(),
    watchMalha(),
    watchGerencia(),
    watchPolo(),
    watchSubestacao(),
    watchAlimentador(),
    watchNsmp(),
    watchDocumento(),
    watchVeiculo(),
    watchOdometro(),
    watchNota(),
    watchCartao(),
    watchAporte(),
    watchControleCaixa(),
    watchConvocacao(),
    watchDrone(),
    watchControlePonto(),
    watchEquipamentos(),
    watchProduto(),
    watchEstoque(),
    watchPedido(),
    watchInspecaoVeicular(),
  ])
}