export const GERENCIAS_FIND = 'GERENCIAS_FIND';
export const GERENCIAS_FIND_SUCCESS = 'GERENCIAS_FIND_SUCCESS';

export const GERENCIAS_DELETE = 'GERENCIAS_DELETE';
export const GERENCIAS_DELETE_SUCCESS = 'GERENCIAS_DELETE_SUCCESS';

export const GERENCIAS_SAVE = 'GERENCIAS_SAVE';
export const GERENCIAS_SAVE_SUCCESS = 'GERENCIAS_SAVE_SUCCESS';

export const GERENCIAS_UPDATE = 'GERENCIAS_UPDATE';
export const GERENCIAS_UPDATE_SUCCESS = 'GERENCIAS_UPDATE_SUCCESS';

export const GERENCIAS_CLEAN = "GERENCIAS_CLEAN";

export const GERENCIAS_OPTIONS = 'GERENCIAS_OPTIONS';
export const GERENCIAS_OPTIONS_SUCCESS = 'GERENCIAS_OPTIONS_SUCCESS';