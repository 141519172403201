/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, TableCell } from '@material-ui/core';
import { Table } from '../../../components';
import ProdutoTamanhoFormulario from './ProdutoTamanhoFormulario';

const useStyles = makeStyles(() => ({
  image: {
    height: 50
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  required: {
    color: 'red',
    paddingTop: 5
  }
}));

const ProdutoTamanhoTable = ({ tamanhos, onSave, showError }) => {
  const classes = useStyles();

  const [titles] = useState(['Tamanho', 'Ativo', 'Quantidade']);
  const [currentTamanhos, setCurrentTamanhos] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTamanho, setSelectedTamanho] = useState(null);

  useEffect(() => {
    if (tamanhos) {
      setCurrentTamanhos(tamanhos);
    }
  }, [tamanhos]);

  const renderRows = p => {
    return (
      <>
        <TableCell>{p.tamanho}</TableCell>
        <TableCell>{p.ativo === "true" || p.ativo === true? "Sim" : "Não"}</TableCell>
        <TableCell>{p.quantidade || 0}</TableCell>
      </>
    )
  }

  const onDeleteLocal = id => {
    const tamanhos = currentTamanhos.filter(p => p.id !== id);
    setCurrentTamanhos(tamanhos);
    onClose(tamanhos);
  }

  const onClose = tamanhosToRetrieve => {
    setOpen(false);
    setSelectedTamanho(null);
    onSave(tamanhosToRetrieve || currentTamanhos);
  }

  const onUpsertTamanho = data => {
    let newTamanhos = [];

    const existedTamanhos = currentTamanhos.filter(p => p.id === data.id);
    if (existedTamanhos.length > 0) {
      currentTamanhos.forEach(p => {
        if (p.id === data.id) {
          newTamanhos.push(data);
        } else {
          newTamanhos.push(p);
        }
      });
    } else {
      newTamanhos = [...currentTamanhos, data];
    }

    setCurrentTamanhos(newTamanhos);
    onClose(newTamanhos);
  }

  const onEdit = id => {
    setSelectedTamanho(currentTamanhos.filter(p => p.id === id)[0]);
    setOpen(true);
  }

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <Table
          rows={currentTamanhos}
          titles={titles}
          headerTitle="Tamanhos do Produto"
          deleteMessage="Tem certeza que deseja remover o tamanho do produto"
          renderRows={renderRows}
          hasRowActions
          onDelete={onDeleteLocal}
          onEdit={onEdit}
          onAdd={() => setOpen(true)}
          showError={showError}
        />
      </Grid>
      <ProdutoTamanhoFormulario
        selectedTamanho={selectedTamanho}
        open={open}
        onClose={onClose}
        onSave={onUpsertTamanho}
      />
    </>
  );
};

export default ProdutoTamanhoTable;
