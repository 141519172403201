import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, CardActions, Button, Divider, CardHeader, IconButton, Collapse } from '@material-ui/core';
import { getVeiculosOptions } from '../../store/veiculo/reducer';
import { findVeiculosOptions } from '../../store/veiculo/actions';
import { getRole } from '../../store/auth/reducer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from 'moment';
import DatePicker from '../DatePicker';
import UsuarioSelect from '../UsuarioSelect';
import DefaultSelect from '../DefaultSelect';
import SearchSelect from '../SearchSelect';
import ValidateSelect from '../ValidateSelect';
import TrueFalseSelect from '../TrueFalseSelect';
import MultiSelect from '../MultiSelect';
import Input from '../Input';
import clsx from 'clsx';
import * as Role from '../../constants/role';
import TipoEquipamentoSelect from '../TipoEquipamentoSelect';
import { getProdutoOptions } from 'store/produto/reducer';
import { loadProdutosOptions } from 'store/produto/actions';
import CartaoSelect from 'components/CartaoSelect';
import NumberInput from 'components/NumberInput';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.bar.grayLight,
    overflow: 'inherit'
  },
  cardActions: {
    padding: theme.spacing(2)
  },
  actionsButtonContainer: {
    marginLeft: 'auto',
  },
  actionsButton: {
    marginRight: 10
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardHeader: {
    paddingTop: 10,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  divider: {
    backgroundColor: theme.palette.bar.grayMiddle
  },
  breakline: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }
}));

const DefaultFiltro = ({ onFilter, useDates, useInspetor, labelInspetor, useVeiculo, useColaborador, findVeiculosOptions, veiculosOptions,
  userRole, useTipoNota, useRole, useValidate, useWeeklyDate, useCodigo, codigoLabel, useNome, nomeLabel, useAtivo, useCartao,
  useTipoPagamento, disabledInspetorFilter, filterData, useCategoriaVeiculo, useTipoVeiculo, useCancelada, useTipoControleCaixa, useTipoDespesas, useTipoFormaPagamento,
  useControleCaixaStatus, mdInspetor, xsInspetor, mdCodigo, xsCodigo, mdNome, useNumSerieDrone, useGaragem, useTipoEquipamento, useNumSerie, useNumPatrimonio, useProduto, produtosOptions, useLocal, 
  useTipoUsuarioDocumento, usePossuiValidade, useValidade, usePossuiRastreador, useNumRastreador, useTipoPedido, useFornecedor }) => {

  const classes = useStyles();

  const [filter, setFilter] = useState(filterData || {});
  const [expanded, setExpanded] = useState(false);
  const [tipoDespesaOptions] = useState([
    { id: 'ALUGUEL_GARAGEM', nome: ' Aluguel de Garagem' },
    { id: 'ALUGUEL_VEICULOS', nome: ' Aluguel de Veículo' },
    { id: 'APORTE', nome: ' Aporte' },
    { id: 'CONDOMINIO', nome: ' Condominio' },
    { id: 'DARF', nome: ' Darf' },
    { id: 'ENERGIA_ELETRICA', nome: ' Energia Eletrica' },
    { id: 'FOLHA_PAGAMENTO', nome: ' Folha Pagamento' },
    { id: 'HONORARIOS_CONTABEIS', nome: ' Honorarios Contabeis' },
    { id: 'IMPOSTOS', nome: ' Impostos' },
    { id: 'INTERNET', nome: ' Internet' },
    { id: 'MANUTENCAO_DRONE', nome: ' Manutenção Drone' },
    { id: 'MANUTENCAO_VEICULO', nome: ' Manutenção Veículo' },
    { id: 'OUTROS', nome: ' Outros' },
    { id: 'PAGAMENTO_DIGITACAO', nome: ' Pagamento Digitação' },
    { id: 'PAGAMENTO_PRODUCAO', nome: ' Pagamento Produção' },
    { id: 'PLANO_SAUDE', nome: ' Plano de Saude' },
    { id: 'SEGURO_CONTRA_ACIDENTE', nome: ' Seguro Contra Acidente' },
    { id: 'TAXAS', nome: ' Taxas' }
  ]);

  useEffect(() => {
    if (useVeiculo) findVeiculosOptions();
  }, [useVeiculo, findVeiculosOptions]);

  useEffect(() => {
    if (useProduto) loadProdutosOptions();
  }, [useProduto, loadProdutosOptions]);

  useEffect(() => {
    if (!filterData && useDates && !filter.inicio) {
      console.info("setting filter by default");
      setFilter({
        ...filter,
        ...getDefaultDates()
      });
    } else {
      console.info("setting by passed: " + JSON.stringify(filterData));
    }
  }, [useDates, useWeeklyDate, filter]);

  const getDefaultDates = () => ({
    inicio: useWeeklyDate ? moment().startOf('week').valueOf() : moment().subtract(1, 'year').startOf('year').valueOf(),
    fim: moment().endOf('day').valueOf()
  });

  const isAdmin = () => userRole === Role.ADMIN || userRole === Role.SUPER_ADMIN;

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        title="Filtros"
        onClick={() => setExpanded(!expanded)}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2}>
            {useDates && <>
              <Grid item md={2} xs={3}>
                <DatePicker
                  label="Data Início"
                  onChange={data => setFilter({
                    ...filter,
                    inicio: data.valueOf()
                  })}
                  value={filter.inicio}
                />
              </Grid>
              <Grid item md={2} xs={3}>
                <DatePicker
                  label="Data Fim"
                  onChange={data => setFilter({
                    ...filter,
                    fim: data.valueOf()
                  })}
                  value={filter.fim}
                />
              </Grid>
              <Grid className={classes.breakline} item xs={12} />
            </>}
            {useTipoEquipamento && <Grid item xs={3}>
              <TipoEquipamentoSelect
                showEmpty
                onChange={tipoEquipamento => setFilter({
                  ...filter,
                  tipoEquipamento,
                })}
                value={filter.tipoEquipamento}
              />
            </Grid>}
            {Role.INSPETOR !== userRole && Role.DIGITADOR !== userRole && Role.CROQUI !== userRole && useInspetor &&
              <Grid item md={mdInspetor || 2} xs={xsInspetor || 3}>
                <UsuarioSelect
                  label={labelInspetor}
                  onSelect={usuarioId => setFilter({
                    ...filter,
                    usuarioId
                  })}
                  ativo
                  grupos={[Role.ADMIN, Role.INSPETOR]}
                  value={filter.usuarioId}
                  disabled={disabledInspetorFilter}
                />
              </Grid>}
            {useVeiculo && <Grid item md={2} xs={6}>
              <SearchSelect
                label="Veículo"
                multiSelect
                onSelect={veiculoId => setFilter({
                  ...filter,
                  veiculoId
                })}
                options={veiculosOptions ? veiculosOptions.map(v => ({ value: v.id, label: v.placa })) : []}
                value={filter.veiculoId}
              />
            </Grid>}
            {useTipoNota && <Grid item md={2} xs={3}>
              <DefaultSelect
                label="Tipo Nota"
                name="tipoNota"
                showEmpty
                options={[
                  { id: 'ALIMENTACAO', nome: 'Alimentação' },
                  { id: 'ABASTECIMENTO', nome: 'Abastecimento' },
                  { id: 'ADIANTAMENTO', nome: 'Adiantamento' },
                  { id: 'CORREIO', nome: 'Correio' },
                  { id: 'HOSPEDAGEM', nome: 'Hospedagem' },
                  { id: 'MANUTENCAO_VEICULO', nome: 'Manutenção do Veículo' },
                  { id: 'OUTROS', nome: 'Outros' },
                  { id: 'PEDAGIO', nome: 'Pedágio/Balça' }
                ]}
                onSelect={e => setFilter({
                  ...filter,
                  tipoNota: e.target.value || null
                })}
                value={filter.tipoNota}
              />
            </Grid>}
            {useTipoPagamento && <Grid item md={2} xs={3}>
              <DefaultSelect
                label="Tipo Pagamento"
                name="tipoPagamento"
                showEmpty
                options={[
                  { id: 'APORTE', nome: 'Aporte' },
                  { id: 'CARTAO_CORPORATIVO', nome: 'Cartão Corporativo' }
                ]}
                onSelect={e => setFilter({
                  ...filter,
                  tipoPagamento: e.target.value || null
                })}
                value={filter.tipoPagamento}
              />
            </Grid>}
            {useRole && <>
              <Grid item md={2} xs={3}>
                <DefaultSelect
                  label="Função"
                  name="role"
                  showEmpty
                  options={[
                    { id: 'ROLE_ADMIN', nome: 'Administrador' },
                    { id: 'ROLE_CROQUI', nome: 'Croqui' },
                    { id: 'ROLE_INSPETOR', nome: 'Inspetor' },
                    { id: 'ROLE_DIGITADOR', nome: 'Digitador' },
                  ]}
                  onSelect={e => setFilter({
                    ...filter,
                    role: e.target.value
                  })}
                  value={filter.role}
                />
              </Grid>
              {isAdmin() && <Grid item md={2} xs={3}>
                <TrueFalseSelect
                  label="Ativo"
                  onChange={event => setFilter({
                    ...filter,
                    usuarioAtivo: event.target.value || null
                  })}
                  value={filter.usuarioAtivo}
                />
              </Grid>}
              <Grid item md={2} xs={3}>
                <UsuarioSelect
                  grupos={filter.role ? [filter.role] : null}
                  onSelect={usuarioFuncaoId => setFilter({
                    ...filter,
                    usuarioFuncaoId
                  })}
                  value={filter.usuarioFuncaoId}
                  ativo={isAdmin() ? filter.usuarioAtivo : true}
                />
              </Grid>
            </>}
            {useValidate && <Grid item md={2} xs={3}>
              <ValidateSelect
                showEmpty
                onChange={validate => setFilter({
                  ...filter,
                  validate
                })}
                value={filter.validate}
              />
            </Grid>}
            {useCodigo && <Grid item md={mdCodigo || 2} xs={xsCodigo || 6}>
              <Input
                label={codigoLabel ? codigoLabel : 'Código'}
                onChange={event => setFilter({
                  ...filter,
                  codigo: event.target.value
                })}
                placeholder={codigoLabel ? codigoLabel : 'Código'}
                value={filter.codigo}
              />
            </Grid>}
            {useNumSerieDrone && <Grid item md={2} xs={3}>
              <Input
                label={'Série do drone'}
                onChange={event => setFilter({
                  ...filter,
                  numSerieDrone: event.target.value
                })}
                value={filter.numSerieDrone}
              />
            </Grid>}
            {useNome && <Grid item md={mdNome || 2} xs={12}>
              <Input
                label={nomeLabel ? nomeLabel : 'Nome'}
                onChange={event => setFilter({
                  ...filter,
                  nome: event.target.value
                })}
                placeholder={nomeLabel ? nomeLabel : 'Nome'}
                value={filter.nome}
              />
            </Grid>}
            {useCategoriaVeiculo && <Grid item md={2} xs={6}>
              <DefaultSelect
                label="Categoria"
                name="categoria"
                showEmpty
                options={[
                  { id: 'CARRO', nome: 'Carro' },
                  { id: 'CAMINHAO', nome: 'Caminhão' },
                  { id: 'UTILITARIO_CAMINHONETE', nome: 'Caminhonete' },
                  { id: 'MAQUINA', nome: 'Máquina' },
                  { id: 'MOTO', nome: 'Moto' },
                  { id: 'REBOQUE', nome: 'Reboque' },
                ]}
                onSelect={event => setFilter({
                  ...filter,
                  categoriaVeiculo: event.target.value || null
                })}
                value={filter.categoriaVeiculo}
              />
            </Grid>}
            {useTipoVeiculo && <Grid item md={2} xs={6}>
              <DefaultSelect
                label="Proprietário"
                name="tipoVeiculo"
                showEmpty
                options={[
                  { id: 'ELETROPONTES', nome: 'Eletropontes' },
                  { id: 'PARTICULAR', nome: 'Particular' },
                  { id: 'ALUGADO', nome: 'Alugado' }
                ]}
                onSelect={event => setFilter({
                  ...filter,
                  tipoVeiculo: event.target.value || null
                })}
                value={filter.tipoVeiculo}
              />
            </Grid>}
            {useTipoControleCaixa && <Grid item md={2} xs={6}>
              <DefaultSelect
                label="Tipo de Controle"
                showEmpty
                options={[
                  { id: 'DESPESA', nome: 'Despesa' },
                  { id: 'RECEITA', nome: 'Receita' },
                ]}
                onSelect={event => setFilter({
                  ...filter,
                  tipoControle: event.target.value || null
                })}
                value={filter.tipoControle}
              />
            </Grid>}
            {useCancelada && <Grid item md={2} xs={6}>
              <TrueFalseSelect
                label="Cancelada"
                onChange={event => setFilter({
                  ...filter,
                  cancelada: event.target.value || null
                })}
                value={filter.cancelada}
              />
            </Grid>}
            {useTipoFormaPagamento && <Grid item md={2} xs={6}>
              <DefaultSelect
                label="Forma Pagamento"
                showEmpty
                options={[
                  { id: 'BANCO', nome: 'Banco' },
                  { id: 'CAIXA', nome: 'Caixa' },
                ]}
                onSelect={event => setFilter({
                  ...filter,
                  formaPagamento: event.target.value || null
                })}
                value={filter.formaPagamento}
              />
            </Grid>}
            {useControleCaixaStatus && <Grid item md={2} xs={6}>
              <DefaultSelect
                label="Status"
                showEmpty
                options={[
                  { id: 'A_LIQUIDAR', nome: 'A Liquidar' },
                  { id: 'LIQUIDADO', nome: 'Liquidado' },
                  { id: 'CANCELADO', nome: 'Cancelado' }
                ]}
                onSelect={event => setFilter({
                  ...filter,
                  controleCaixaStatus: event.target.value || null
                })}
                value={filter.controleCaixaStatus}
              />
            </Grid>}
            {useTipoDespesas && <Grid item xs={12}>
              <MultiSelect
                label="Tipo Despesa"
                onChange={newValue => {
                  setFilter({
                    ...filter,
                    tipoDespesas: newValue ? newValue.map(v => v.value) : null
                  });
                }}
                defaultValue={tipoDespesaOptions && filter.tipoDespesas ? tipoDespesaOptions.filter(p => filter.tipoDespesas.indexOf(p.id) > -1).map(i => ({ value: i.id, label: i['nome'] })) : []}
                options={tipoDespesaOptions ? tipoDespesaOptions.map(i => ({ value: i.id, label: i['nome'] })) : []}
              />
            </Grid>}
            {useGaragem && <Grid item md={2} xs={6}>
              <TrueFalseSelect
                label="Está na Garagem"
                onChange={event => setFilter({
                  ...filter,
                  garagem: event.target.value || null
                })}
                value={filter.garagem}
              />
            </Grid>}
            {useAtivo && <Grid item md={2} xs={6}>
              <TrueFalseSelect
                label="Ativo"
                onChange={event => setFilter({
                  ...filter,
                  ativo: event.target.value || null
                })}
                value={filter.ativo}
              />
            </Grid>}
            {useNumPatrimonio && <Grid item md={2} xs={3}>
              <Input
                label={'Num. Patrimônio'}
                onChange={event => setFilter({
                  ...filter,
                  numPatrimonio: event.target.value
                })}
                value={filter.numPatrimonio}
              />
            </Grid>}
            {useNumSerie && <Grid item md={2} xs={3}>
              <Input
                label={'Num. de Série'}
                onChange={event => setFilter({
                  ...filter,
                  numSerie: event.target.value
                })}
                value={filter.numSerie}
              />
            </Grid>}
            {useTipoPedido && <Grid item xs={4}>
              <DefaultSelect
                label="Tipo Nota"
                name="tipo"
                showEmpty
                options={[
                  { id: 'ENTRADA', nome: 'Entrada' },
                  { id: 'SAIDA', nome: 'Saida' },
                ]}
                onSelect={e => setFilter({
                  ...filter,
                  tipo: e.target.value || null
                })}
                value={filter.tipo}
              />
            </Grid>}
            {useLocal && <Grid item xs={4}>
              <DefaultSelect
                label="Local"
                showEmpty
                options={[
                  { id: 'Almoxarifado I  C17', nome: 'Almoxarifado I  C17 ' },
                  { id: 'Almoxarifado II  C17', nome: 'Almoxarifado II  C17' },
                  { id: 'Sala Drones', nome: 'Sala Drones' },
                ]}
                onSelect={event => {
                  setFilter({
                    ...filter,
                    local: event.target.value,
                  });
                }}
                value={filter.local}
              />
            </Grid>}
            {useColaborador && <Grid item xs={4}>
              <UsuarioSelect
                grupos={filter.role ? [filter.role] : null}
                onSelect={colaborador => setFilter({
                  ...filter,
                  colaborador
                })}
                label="Colaborador"
                value={filter.colaborador}
                ativo={isAdmin() ? filter.usuarioAtivo : true}
              />
            </Grid>}
            {useProduto && <Grid item xs={4}>
              <DefaultSelect
                label="Produto"
                showEmpty
                options={produtosOptions}
                onSelect={event => {
                  setFilter({
                    ...filter,
                    produto: event.target.value,
                  });
                }}
                value={filter.produto}
              />
            </Grid>}
            {useFornecedor && <Grid item xs={4}>
              <Input
                label="Fornecedor"
                onChange={event => setFilter({
                  ...filter,
                  fornecedor: event.target.value
                })}
                value={filter.fornecedor}
              />
            </Grid>}
            {usePossuiRastreador && <Grid item md={2} xs={6}>
              <TrueFalseSelect
                label="Possui Rastreador"
                onChange={event => setFilter({
                  ...filter,
                  possuiRastreador: event.target.value || null
                })}
                value={filter.possuiRastreador}
              />
            </Grid>}
            {useNumRastreador && <Grid item md={2} xs={3}>
              <Input
                label={'Num. de Rastreador'}
                onChange={event => setFilter({
                  ...filter,
                  numRastreador: event.target.value
                })}
                value={filter.numRastreador}
              />
            </Grid>}
            {useCartao && <Grid item md={2} xs={3}>
              <CartaoSelect
                onSelect={cartao => setFilter({
                  ...filter,
                  cartao
                })}
                label="Colaborador"
                value={filter.cartao}
              />
            </Grid>}
            {useTipoUsuarioDocumento &&<Grid item md={4}>
              <DefaultSelect
                label="Tipo"
                name="tipo"
                isRequired
                options={[
                  { id: 'DOCUMENTO', nome: 'Documento' },
                  { id: 'CERTIFICADO', nome: 'Certificado' },
                ]}
                onSelect={e => setFilter({
                  ...filter,
                  tipo: e.target.value || null
                })}
                value={filter.tipo}
              />
            </Grid>}
            {usePossuiValidade &&<Grid item md={4}>
              <TrueFalseSelect
                label="Possui Validade"
                name="possuiValidade"
                isRequired
                onChange={event => setFilter({
                  ...filter,
                  possuiValidade: event.target.value || null
                })}
                value={filter.possuiValidade}
              />
            </Grid>}
            {useValidade &&<Grid item md={4}>
              <NumberInput
                label="Validade (em anos)"
                onChange={valor => setFilter({
                  ...filter,
                  validadeEmAnos: valor
                })}
                value={filter.validadeEmAnos}
                decimalScale={0}
                prefix=""
                thousandSeparator=""
              />
            </Grid>}
          </Grid>
        </CardContent>
        <Divider className={classes.divider} />
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => {
              return onFilter(filter)
            }}
          >
            Filtrar
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => {
              setFilter({
                ...getDefaultDates()
              })
            }}
          >
            Limpar
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

DefaultFiltro.defaultValue = {
  useDates: false,
  useInspetor: false,
  labelInspetor: "Inspetor",
  useVeiculo: false,
  useTipoNota: false,
  defaultValues: {},
  useRole: false,
  useValidate: false,
  useWeeklyDate: false,
  useCodigo: false,
  codigoLabel: 'Código',
  useNome: false,
  nomeLabel: 'Nome',
  useAtivo: false,
  useTipoPagamento: false,
  disabledInspetorFilter: false,
  filterData: null,
  useCategoriaVeiculo: false,
  useTipoVeiculo: false,
  useCancelada: false,
  useTipoControleCaixa: false,
  useTipoDespesas: false,
  useTipoFormaPagamento: false,
  useControleCaixaStatus: false,
  useNumSerieDrone: false,
  useGaragem: false,
  useTipoEquipamento: false,
  useNumSerie: false,
  useNumPatrimonio: false,
  useProduto: false,
  useLocal: false,
  useColaborador: false,
  useCartao: false,
  useTipoUsuarioDocumento: false,
  usePossuiValidade: false,
  useValidade: false,
  usePossuiRastreador: false,
  useNumRastreador: false,
  useTipoPedido: false,
  useFornecedor: false,
}

const mapStateToProps = state => ({
  veiculosOptions: getVeiculosOptions(state),
  produtosOptions: getProdutoOptions(state),
  userRole: getRole(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  findVeiculosOptions,
  loadProdutosOptions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DefaultFiltro);
