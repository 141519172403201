export const MALHAS_FIND = 'MALHAS_FIND';
export const MALHAS_FIND_SUCCESS = 'MALHAS_FIND_SUCCESS';

export const MALHAS_DELETE = 'MALHAS_DELETE';
export const MALHAS_DELETE_SUCCESS = 'MALHAS_DELETE_SUCCESS';

export const MALHAS_SAVE = 'MALHAS_SAVE';
export const MALHAS_SAVE_SUCCESS = 'MALHAS_SAVE_SUCCESS';

export const MALHAS_UPDATE = 'MALHAS_UPDATE';
export const MALHAS_UPDATE_SUCCESS = 'MALHAS_UPDATE_SUCCESS';

export const MALHAS_USER = 'MALHAS_USER';

export const MALHAS_OPTIONS = 'MALHAS_OPTIONS';
export const MALHAS_OPTIONS_SUCCESS = 'MALHAS_OPTIONS_SUCCESS';