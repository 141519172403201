/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/dashboard/actionsTypes';
import BaseService from '../services/BaseService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';

function* loadDashboard({ filterData }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield console.log('load dashboard');
    const response = yield BaseService.post({ url: 'dashboard', data: filterData });
    yield put({
      type: TypeActions.FETCH_DASHBOARD_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_DASHBOARD, loadDashboard);
}