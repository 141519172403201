import * as Type from './actionsTypes';

const INITIAL_STATE = {
  aportes: [],
  selectedAporte: null,
  aporteByUsuario: {}
};

const ordernarData = (a,b) => new Date(Number(b.data)) - new Date(Number(a.data));

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_APORTES_SUCCESS: {
      return {
        ...state,
        aportes: action.data.sort(ordernarData)
      }
    }
    case Type.SAVE_APORTE_SUCCESS: {
      return {
        ...state,
        aportes: [...state.aportes, action.data].sort(ordernarData)
      }
    }
    case Type.UPDATE_APORTE_SUCCESS: {
      return {
        ...state,
        aportes: [...state.aportes.filter(s => s.id !== action.data.id), action.data].sort(ordernarData)
      }
    }
    case Type.APORTE_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedAporte: action.data
      }
    }
    case Type.CLEAR_APORTE: {
      return INITIAL_STATE;
    }
    case Type.FETCH_APORTES_BY_INSPETOR_SUCCESS: {
      return {
        ...state,
        aporteByUsuario: action.data
      }
    }
    default:
      return state;
  }
}

export const getAportes = state => state.rootState.aporte.aportes;
export const getSelectedAporte = state => state.rootState.aporte.selectedAporte;
export const getAporteByUsuario = state => state.rootState.aporte.aporteByUsuario;
