import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneDialog } from 'material-ui-dropzone';

const UploadDialog = ({ onSave, onClose, open, filesLimit, acceptedFiles, dialogTitle }) => (
  <DropzoneDialog 
    dialogTitle={dialogTitle}
    dropzoneText="Arraste e solte um arquivo aqui ou clique"
    getFileAddedMessage={ fileName => `O arquivo ${fileName} foi adicionado.`}
    getFileRemovedMessage={ fileName => `O arquivo ${fileName} foi removido.`}
    getDropRejectMessage={ fileName => `O arquivo ${fileName} não é permitido.`}
    acceptedFiles={acceptedFiles}
    cancelButtonText={"Cancelar"}
    submitButtonText={"Salvar"}
    maxFileSize={10000000000}
    open={open}
    onClose={onClose}
    filesLimit={filesLimit}
    previewText="Pré-visualização:"
    onSave={onSave}
    showPreviews={true}
    showFileNamesInPreview={true}
  />
)

UploadDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  filesLimit: PropTypes.number,
  acceptedFiles: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string,
};

UploadDialog.defaultProps = {
  filesLimit: 1,
  dialogTitle: 'Importar'
};

export default UploadDialog;