import { stateRoot } from '../store';
import estadosCidades from './estados-cidades.json';

const isLogin = () => {
  if (stateRoot().auth.token) return true;
  return false;
}

const isPublicRouter = path => {
  if (path === '/esqueci-senha') return true;
  return false;
}

const sortArray = (a, b, property = 'nome') => {
  if (a[property] > b[property]) return 1;
  else if (a[property] < b[property]) return -1;
  return 0;
}

const getEstados = () => {
  return estadosCidades.estados.map(data => ({ id: data.sigla, nome: data.nome }));
}

const getCidades = () => {
  let cidades = [];
  estadosCidades.estados.forEach(estado => {
    cidades = [...cidades, ...estado.cidades.map(cidade => ({ id: cidade, nome: cidade, estado: estado.sigla }))];
  });
  
  return cidades.sort((a, b) => {
    if (a.nome > b.nome) return 1;
    else if (a.nome < b.nome) return -1;
    return 0;
  });
}

/* 
* Este método ordena o objeto que possui o atributo codigo de forma crescente
* Obs.: O atributo código é string
*/
const ordenarPorCodigoString = (a, b) => {
  return a.codigo.localeCompare(b.codigo);
};

/* 
* Este método ordena o objeto que possui o atributo codigo de forma crescente
* Obs.: O atributo código é numerico
*/
const ordenarPorCodigoNumerico = (a, b) => {
  return parseInt(a.codigo) - parseInt(b.codigo);
};

const getMaxColumnLength = value => {
  return value && value.length > 79 ? value.substring(0,80) + "..." : value;
}

const codeFormatter = (code, totalLength = 3) => {
  if (code) {
    return `${String(code).padStart(totalLength, '0')}`;
  }
  return "";
}

const moneyFormatter = (value) => {
  if (value) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
  return "";
}

const isTrue = (value) => {
  if (Boolean(value)) {
    return value.toString() === "true";
  }
  return false;
}

const isFalse = (value) => {
  return !isTrue(value);
}

const isNullOrUndefined = (value) => {
  return value === null || value === undefined || (value && value.trim() === "");
}

export {
  isLogin,
  isPublicRouter,
  sortArray,
  getEstados,
  getCidades,
  ordenarPorCodigoString,
  ordenarPorCodigoNumerico,
  getMaxColumnLength,
  codeFormatter,
  moneyFormatter,
  isTrue,
  isFalse,
  isNullOrUndefined,
}
