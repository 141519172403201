export const FETCH_PRODUTOS = 'FETCH_PRODUTOS';
export const FETCH_PRODUTOS_SUCCESS = 'FETCH_PRODUTOS_SUCCESS';

export const FETCH_PRODUTOS_OPTIONS = 'FETCH_PRODUTOS_OPTIONS';
export const FETCH_PRODUTOS_OPTIONS_SUCCESS = 'FETCH_PRODUTOS_OPTIONS_SUCCESS';

export const FETCH_PRODUTO_TAMANHO_OPTIONS = 'FETCH_PRODUTO_TAMANHO_OPTIONS';
export const FETCH_PRODUTO_TAMANHO_OPTIONS_SUCCESS = 'FETCH_PRODUTO_TAMANHO_OPTIONS_SUCCESS';

export const DELETE_PRODUTO = 'DELETE_PRODUTO';
export const DELETE_PRODUTO_SUCCESS = 'DELETE_PRODUTO_SUCCESS';

export const SAVE_PRODUTO = 'SAVE_PRODUTO';
export const SAVE_PRODUTO_SUCCESS = 'SAVE_PRODUTO_SUCCESS';

export const UPDATE_PRODUTO = 'UPDATE_PRODUTO';
export const UPDATE_PRODUTO_SUCCESS = 'UPDATE_PRODUTO_SUCCESS';

export const CLEAR_PRODUTO = 'CLEAR_PRODUTO';

export const PRODUTO_FIND_BY_ID = 'PRODUTO_FIND_BY_ID';
export const PRODUTO_FIND_BY_ID_SUCCESS = 'PRODUTO_FIND_BY_ID_SUCCESS';

export const PRODUTO_DOWNLOAD = 'PRODUTO_DOWNLOAD';

export const PRODUTO_EXPORT = 'PRODUTO_EXPORT';