import * as Type from './actionsTypes';
import {ordenarPorCodigoNumerico} from '../../utils';

const INITIAL_STATE = {
  alimentadores: [],
  options: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.ALIMENTADORES_FIND_SUCCESS: {
      return {
        ...state,
        alimentadores: action.data.sort(ordenarPorCodigoNumerico)
      }
    }
    case Type.ALIMENTADORES_DELETE_SUCCESS: {
      return {
        ...state,
        alimentadores: state.alimentadores.filter(a => a.id !== action.data).sort(ordenarPorCodigoNumerico)
      }
    }
    case Type.ALIMENTADORES_SAVE_SUCCESS: {
      return {
        ...state,
        alimentadores: [...state.alimentadores, action.data].sort(ordenarPorCodigoNumerico)
      }
    }
    case Type.ALIMENTADORES_UPDATE_SUCCESS: {
      return {
        ...state,
        alimentadores: [...state.alimentadores.filter(s => s.id !== action.data.id), action.data].sort(ordenarPorCodigoNumerico)
      }
    }
    case Type.ALIMENTADORES_CLEAN: {
      return INITIAL_STATE;
    }
    case Type.ALIMENTADORES_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data.sort(ordenarPorCodigoNumerico)
      }
    }
    default:
      return state;
  }
}

export const getAlimentadores = state => state.rootState.alimentador.alimentadores;
export const getAlimentadoresOptions = state => state.rootState.alimentador.options;
