import * as Type from './actionsTypes';

const loadAportes = filtro => ({ type: Type.FETCH_APORTES, filtro });

const loadAportesByInspetor = filtro => ({ type: Type.FETCH_APORTES_BY_INSPETOR, filtro });

const saveAporte = (data, callback) => ({ type: Type.SAVE_APORTE, data, callback });

const updateAporte = (data, callback) => ({ type: Type.UPDATE_APORTE, data, callback });

const findAporteById = id => ({ type: Type.APORTE_FIND_BY_ID, id });

const findAporteFotosById = (id, callback) => ({ type: Type.APORTE_FIND_FOTO_BY_ID, id, callback });

export {
  loadAportes,
  loadAportesByInspetor,
  saveAporte,
  updateAporte,
  findAporteById,
  findAporteFotosById
}
