import * as Type from './actionsTypes';

const INITIAL_STATE = {
  usuariodocumentos: [],
  selectedUsuarioDocumento: null,
  options: [],
};

const ordernarPorNome = (a, b) => {
  return a.nome.localeCompare(b.nome);
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_USUARIO_DOCUMENTOS_SUCCESS: {
      return {
        ...state,
        usuarioDocumentos: action.data.sort(ordernarPorNome)
      }
    }
    case Type.DELETE_USUARIO_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        usuarioDocumentos: state.usuarioDocumentos.filter(s => s.id !== action.data).sort(ordernarPorNome)
      }
    }
    case Type.SAVE_USUARIO_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        usuarioDocumentos: [...state.usuarioDocumentos, action.data].sort(ordernarPorNome)
      }
    }
    case Type.UPDATE_USUARIO_DOCUMENTO_SUCCESS: {
      return {
        ...state,
        usuarioDocumentos: [...state.usuarioDocumentos.filter(s => s.id !== action.data.id), action.data].sort(ordernarPorNome)
      }
    }
    case Type.USUARIO_DOCUMENTO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedUsuarioDocumento: action.data
      }
    }
    case Type.USUARIO_DOCUMENTO_FIND_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data
      }
    }
    case Type.CLEAR_USUARIO_DOCUMENTO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getUsuarioDocumentos = state => state.rootState.usuarioDocumento.usuarioDocumentos;
export const getSelectedUsuarioDocumento = state => state.rootState.usuarioDocumento.selectedUsuarioDocumento;
export const getUsuarioDocumentosOptions = state => state.rootState.usuarioDocumento.options;