import * as Type from './actionsTypes';
import {ordenarPorCodigoString} from '../../utils';

const INITIAL_STATE = {
  polos: [],
  options: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.POLOS_FIND_SUCCESS: {
      return {
        ...state,
        polos: action.data.sort(ordenarPorCodigoString)
      }
    }
    case Type.POLOS_DELETE_SUCCESS: {
      return {
        ...state,
        polos: state.polos.filter(a => a.id !== action.data).sort(ordenarPorCodigoString)
      }
    }
    case Type.POLOS_SAVE_SUCCESS: {
      return {
        ...state,
        polos: [...state.polos, action.data].sort(ordenarPorCodigoString)
      }
    }
    case Type.POLOS_UPDATE_SUCCESS: {
      return {
        ...state,
        polos: [...state.polos.filter(s => s.id !== action.data.id), action.data].sort(ordenarPorCodigoString)
      }
    }
    case Type.POLOS_CLEAN: {
      return INITIAL_STATE;
    }
    case Type.POLOS_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data.sort(ordenarPorCodigoString)
      }
    }
    default:
      return state;
  }
}

export const getPolos = state => state.rootState.polo.polos;
export const getPolosOptions = state => state.rootState.polo.options;
