import React from 'react';
import PropTypes from 'prop-types';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@material-ui/core/styles';

const getOptions = (series, tooltipFormatter, theme) => ({
  chart: {
    renderTo: 'container',
    defaultSeriesType: 'scatter',
    backgroundColor: theme.palette.bar.grayLight
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  legend: {
    enabled: false
  },
  tooltip: {
    formatter: function() {
      return tooltipFormatter(this.x, this.y);
    }
  },
  plotOptions: {
    series: {
      shadow: false,
    },
    scatter: {
      marker: {
        symbol: 'circle'
      }
    }
  },
  xAxis: {
    title: {
      text: '(-) Percepção (+)'
    },
    min: -4,
    max: 4,
  },
  yAxis: {
    title: {
      text: '(-) Potencial de Impacto (+)',
    },
    min: 0,
    max: 8,
  },
  series
});

const Pie = ({ series, tooltipFormatter }) => {
  const theme = useTheme();

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getOptions(series, tooltipFormatter, theme)}
    />
  );
}

Pie.propTypes = {
  seriesData: PropTypes.array.isRequired,
  tooltipFormatter: PropTypes.func.isRequired,
};

export default Pie;