import React from 'react';
import PropTypes from 'prop-types';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@material-ui/core/styles';

const getOptions = (seriesData, yAxisTitle, theme) => ({
  chart: {
    type: 'column',
    backgroundColor: theme.palette.bar.grayLight
  },
  title: {
    text: ''
  },
  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: yAxisTitle
    }
  },
  xAxis: {
    categories: seriesData && seriesData.categories? seriesData.categories : [],
    crosshair: true
  },
  credits: {
    enabled: false
  },
  series: seriesData && seriesData.series? seriesData.series : []
});

const BasicColumn = ({ seriesData, yAxisTitle }) => {
  const theme = useTheme();
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getOptions(seriesData, yAxisTitle, theme)}
    />
  );
}

BasicColumn.propTypes = {
  seriesData: PropTypes.object.isRequired
};

export default BasicColumn;