import * as Type from './actionsTypes';

const loadSubestacoes = filter => ({ type: Type.SUBESTACOES_FIND, filter });

const deleteSubestacao = id => ({ type: Type.SUBESTACOES_DELETE, id });

const saveSubestacao  = data => ({ type: Type.SUBESTACOES_SAVE, data });

const updateSubestacao = data => ({ type: Type.SUBESTACOES_UPDATE, data });

const findOptions = ({ malhaId, gerenciaId, poloId }) => ({ type: Type.SUBESTACOES_OPTIONS, malhaId, gerenciaId, poloId });

export {
  loadSubestacoes,
  deleteSubestacao,
  saveSubestacao,
  updateSubestacao,
  findOptions
}
