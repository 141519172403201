/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import InputGroup from '../InputGroup';

const PerfilSelect = ({ label, onChange, value, placement, title, useTooltip, isRequired, error, helperText, disabled }) => {
  return (
    <InputGroup
      label={label}
      placement={placement}
      title={title}
      useTooltip={useTooltip}
      isRequired={isRequired}
    >
      <TextField
        error={error}
        helperText={error ? helperText : ''}
        fullWidth
        margin="dense"
        name={label.toLowerCase()}
        onChange={event => onChange(event.target.value)}
        select
        disabled={disabled}
        SelectProps={{ native: true }}
        value={value}
        variant="outlined"
      >
        {/* <option key="NA" value={null}>Selecione...</option> */}
        <option key="ROLE_ADMIN" value="ROLE_ADMIN">Administrador</option>
        <option key="ROLE_USUARIO" value="ROLE_USUARIO">Usuário</option>
        {/* <option key="ROLE_SUPER_ADMIN" value="ROLE_SUPER_ADMIN">Super Admin</option> */}
        {/* <option key="ROLE_GERENCIAL" value="ROLE_GERENCIAL">Gerencial</option>
        <option key="ROLE_OPERACIONAL" value="ROLE_OPERACIONAL">Operacional</option> */}
      </TextField>
    </InputGroup>
  );
}

PerfilSelect.propTypes = {
  error: PropTypes.any,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placement: PropTypes.string,
  title: PropTypes.string,
  useTooltip: PropTypes.bool,
  isRequired: PropTypes.any,
  disabled: PropTypes.bool,
};

PerfilSelect.defaultProps = {
  error: null,
  helperText: null,
  title: '',
  placement: 'top',
  isRequired: false,
  useTooltip: false,
  value: '',
  disabled: false
};

export default PerfilSelect;