/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/nsmp/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';

function* loadNsmpById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.get({ url: `nsmps/${id}` });
    let fotos = [];

    if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
      fotos = yield FileService.downloadArquivos(response.data.arquivos, false);
    }

    if (response.data && response.data.fotos && response.data.fotos.length > 0) {
      for (let foto of response.data.fotos) {
        let nameSplit = foto.nome.split('.');
        let src = foto.image && (foto.image.includes('data:image') || foto.image.includes('data:application')) ? foto.image : `data:image/png;base64,${foto.image}`;

        fotos.push({
          id: foto.id,
          nome: nameSplit[0],
          extensao: nameSplit.length > 1 ? nameSplit[1] : 'png',
          src: src,
          alt: foto.nome,
          file: yield FileService.base64ToFile(foto.image, foto.nome),
          image: src,
          description: foto.description,
          additions: foto.additions,
        });
      }
    }

    response.data.fotos = fotos;

    yield put({
      type: TypeActions.NSMPS_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data }) {
  try {
    const fotos = data.fotos;
    delete data.fotos;
    const arquivos = [];
    for(let foto of fotos) {
      arquivos.push({ 
        externalId: foto.externalId,
        nome: foto.nome,
        extensao: foto.extensao,
        file: foto.file
      });
    }

    yield put({ type: LoaderTypeActions.LOADER_SHOW });

    let uploadArquivosValid = true;
    const response = yield BaseService.post({ url: 'nsmps', data: { ...data, arquivos: FileService.getArquivosToUpload(arquivos, true) } });
    if (response.data && response.data.arquivos) {
      uploadArquivosValid = yield FileService.uploadArquivos(arquivos, response.data.arquivos, "externalId");
    }

    if (uploadArquivosValid) {
      yield put({
        type: TypeActions.NSMPS_SAVE_SUCCESS,
        data: {
          ...response.data,
          fotos: fotos
        }
      });
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
        message: 'Informações salvas com sucesso!'
      });
    } else {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_ERROR,
        message: 'Erro ao realizar o upload dos arquivos. Por favor, tente novamente!'
      });
    }

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* update({ data }) {
  try {
    const isNotClone = !data.idNsmpReference;
    const arquivos = [];
    const fotos = data.fotos;
    delete data.fotos;

    for (let foto of fotos) {
      arquivos.push({ 
        externalId: foto.externalId ? foto.externalId : foto.id,
        nome: foto.nome,
        extensao: foto.extensao ? foto.nome.split('.').pop() : 'jpeg',
        file: foto.file ? foto.file : FileService.base64ToFile(foto.image, foto.nome)
      });
    }

    yield put({ type: LoaderTypeActions.LOADER_SHOW });

    let uploadArquivosValid = true;
    const response = yield BaseService.put({ url: `nsmps/${data.id}`, data: { ...data, arquivos: FileService.getArquivosToUpload(arquivos, true) } });
    if (data.updateFiles && response.data && response.data.arquivos && isNotClone) {
      uploadArquivosValid = yield FileService.uploadArquivos(arquivos, response.data.arquivos, "externalId");
    }

    if (uploadArquivosValid) {
      yield put({
        type: TypeActions.NSMPS_UPDATE_SUCCESS,
        data: {
          ...response.data,
          fotos: fotos
        }
      });
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
        message: 'Informações salvas com sucesso!'
      });
    } else {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_ERROR,
        message: 'Erro ao realizar o upload dos arquivos. Por favor, tente novamente!'
      });
    }
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* deleteById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.delete({ url: `nsmps/${id}`});
    yield put({
      type: TypeActions.NSMPS_DELETE_SUCCESS,
      data: response.data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações removidas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao remover as informações!'
    });
  }
}

function* deleteCloneById({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.delete({ url: `nsmps/clone/${id}`});
    yield put({ type: TypeActions.NSMPS_DELETE_CLONE_SUCCESS, data: response.data, id });
    yield callback();
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações removidas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao remover as informações!'
    });
  }
}


function* clone({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: `nsmps/${id}/clone` });
    yield put({ type: TypeActions.NSMPS_CLONE_SUCCESS, data: response.data });
    yield callback();
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Nsmp clonada com sucesso!'
    })
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao clonar a nsmp!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.NSMPS_FIND_BY_ID, loadNsmpById);
  yield takeLatest(TypeActions.NSMPS_SAVE, save);
  yield takeLatest(TypeActions.NSMPS_UPDATE, update);
  yield takeLatest(TypeActions.NSMPS_DELETE, deleteById);
  yield takeLatest(TypeActions.NSMPS_DELETE_CLONE, deleteCloneById);
  yield takeLatest(TypeActions.NSMPS_CLONE, clone);
}