export const FETCH_DRONES = 'FETCH_DRONES';
export const FETCH_DRONES_SUCCESS = 'FETCH_DRONES_SUCCESS';

export const DELETE_DRONE = 'DELETE_DRONE';
export const DELETE_DRONE_SUCCESS = 'DELETE_DRONE_SUCCESS';

export const SAVE_DRONE = 'SAVE_DRONE';
export const SAVE_DRONE_SUCCESS = 'SAVE_DRONE_SUCCESS';

export const UPDATE_DRONE = 'UPDATE_DRONE';
export const UPDATE_DRONE_SUCCESS = 'UPDATE_DRONE_SUCCESS';

export const CLEAR_DRONE = 'CLEAR_DRONE';

export const DRONE_FIND_BY_ID = 'DRONE_FIND_BY_ID';
export const DRONE_FIND_BY_ID_SUCCESS = 'DRONE_FIND_BY_ID_SUCCESS';

export const DRONE_EXPORT = 'DRONE_EXPORT';

export const HISTORICO_DRONE_UPSERT = 'HISTORICO_DRONE_UPSERT';
export const HISTORICO_DRONE_UPSERT_SUCCESS = 'HISTORICO_DRONE_UPSERT_SUCCESS';

export const HISTORICO_DRONE_FIND_BY_ID = 'HISTORICO_DRONE_FIND_BY_ID';
export const HISTORICO_DRONE_FIND_BY_ID_SUCCESS = 'HISTORICO_DRONE_FIND_BY_ID_SUCCESS';

export const HISTORICO_DRONE_DELETE = 'HISTORICO_DRONE_DELETE';