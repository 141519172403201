const phoneMask = (v) => {
  if (v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
    v = v.replace(/(\d{5}|\d{4})(\d{4})$/, '$1-$2');
  }

  return v;
}

const removePhoneMask = (v) => {
  return typeof v === 'string' ? v.replace(/\D/g, '') : v;
};

export default {
  removePhoneMask,
  phoneMask
}