import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DefaultSelect from '../DefaultSelect';

const NsirSyncStatusSelect = ({ onSelect, value, isRequired, disabled }) => {

  const [options] = useState([
    {'id': 'NOT_SYNC', nome: 'Não sincronizado'},
    {'id': 'SYNC_TO_APP', nome: 'Sincronizado para o app'},
    {'id': 'SYNC_FROM_APP', nome: 'Sincronizado do app'}
  ]);

  return (
    <DefaultSelect 
      isRequired={isRequired}
      label="Status Sincronização"
      onSelect={event => onSelect(event.target.value)}
      options={options}
      value={value}
      disabled={disabled}
    />
  );
}

NsirSyncStatusSelect.propTypes = {
  value: PropTypes.string,
  isRequired: PropTypes.any,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
};

NsirSyncStatusSelect.defaultProps = {
  disabled: false,
  isRequired: false,
  onSelect: () => {},
  value: null
};

export default NsirSyncStatusSelect;