/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import * as Roles from '../../constants/role';
import * as selectorsOdometros from '../../store/odometro/reducer';
import * as actionsOdometros from '../../store/odometro/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import moment from 'moment';

import { Input, DialogDefault, Dropzone, DatePicker, UsuarioSelect, TrueFalseSelect, ValidateSelect, VeiculoSelect } from '../../components';

const schema = {
  odometroInicial: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  odometroFinal: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  veiculoId: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  inspecao: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  fotoInicialFile: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  fotoFinalFile: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  usuarioId: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  buttonContainer: {
    width: '100%',
  }
}));

const Formulario = ({ saveOdometro, updateOdometro, selectedOdometro, open, id, onClose, 
  findOdometroById, authData, isAdmin }) => {

  const classes = useStyles();

  const [values, setValues] = useState({ data: moment().valueOf() });
  const [errors, setErrors] = useState({});
  const [fotoInicialFile, setFotoInicialFile] = useState(null);
  const [fotoFinalFile, setFotoFinalFile] = useState(null);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (id) findOdometroById(id);
  }, [id, findOdometroById]);

  useEffect(() => {
    if (id && selectedOdometro && id === selectedOdometro.id) {
      if (selectedOdometro.fotoInicialFile) setFotoInicialFile(selectedOdometro.fotoInicialFile);
      if (selectedOdometro.fotoFinalFile) setFotoFinalFile(selectedOdometro.fotoFinalFile);
      if (!selectedOdometro.data) selectedOdometro.data = moment().valueOf();
      else selectedOdometro.data = Number(selectedOdometro.data);
      setValues(selectedOdometro);
      setReadOnly(selectedOdometro.validate === 'VALIDADO' || selectedOdometro.validate === 'INVALIDADO');
    } else {
      setFotoInicialFile(null);
      setFotoFinalFile(null);
      setValues({ data: moment().valueOf() });
      setReadOnly(false);
    }
  }, [id, selectedOdometro]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);

    if (!erros && Number(values.odometroFinal) < Number(values.odometroInicial)) {
      setErrors({ odometroFinal: "Odômetro final menor que inicial" });
    } else if (!erros) {

      values.fotoInicial = {
        nome: values.fotoInicialFile.name.split('.')[0],
        extensao: values.fotoInicialFile.name.split('.').pop(),
        status: 'NOT_SYNC'
      }

      values.fotoFinal = {
        nome: values.fotoFinalFile.name.split('.')[0],
        extensao: values.fotoFinalFile.name.split('.').pop(),
        status: 'NOT_SYNC'
      }

      if (values.id) updateOdometro(values, saveCallback);
      else saveOdometro(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({})
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const canEdit = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);
  
  const calculateKmRodado = () => values.odometroInicial && values.odometroFinal? values.odometroFinal - values.odometroInicial : '';

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Odômetro"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={3} className={classes.fieldContainer}>
          <VeiculoSelect
            isRequired
            onSelect={veiculoId => {
              setValues({
                ...values,
                veiculoId
              });
            }}
            value={values.veiculoId}
            disabled={readOnly}
            error={errors && errors.veiculoId}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            error={errors && errors.odometroInicial}
            helperText={errors && errors.odometroInicial ? errors.odometroInicial : null}
            isRequired
            label="Km Inicial"
            name="odometroInicial"
            onChange={handleChange}
            value={values.odometroInicial}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            error={errors && errors.odometroFinal}
            helperText={errors && errors.odometroFinal ? errors.odometroFinal : null}
            isRequired
            label="Km Final"
            name="odometroFinal"
            onChange={handleChange}
            value={values.odometroFinal}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Km Rodado"
            value={calculateKmRodado()}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DatePicker
            label="Data"
            onChange={data => setValues({
              ...values,
              data: data.valueOf()
            })}
            value={values.data}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Inclusão"
            disabled
            value={values.dataInclusao}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <UsuarioSelect
            isRequired
            error={errors && errors.usuarioId}
            label="Inspetor"
            ativo
            grupos={[Roles.ADMIN, Roles.INSPETOR]}
            onSelect={usuarioId => setValues({
              ...values,
              usuarioId
            })}
            value={values.usuarioId}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <UsuarioSelect
            label="Ajudante/Observador"
            ativo
            grupos={[Roles.ADMIN, Roles.INSPETOR]}
            onSelect={inspetorAuxiliar => setValues({
              ...values,
              inspetorAuxiliar
            })}
            value={values.inspetorAuxiliar}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.inspecao ? true : false}
            isRequired
            label="É Inspeção"
            name="inspecao"
            onChange={handleChange}
            value={values.inspecao}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <ValidateSelect
             onChange={validate => setValues({
              ...values,
              validate
            })}
            value={values.validate}
            disabled={!readOnly && isAdmin}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            error={errors && errors.justificativa ? true : false}
            label="Justificativa"
            name="justificativa"
            onChange={handleChange}
            value={values.justificativa}
            rows={3}
            multiline
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6} className={classes.fieldContainer}>
          <Dropzone 
            buttonText="Foto Inicial"
            filesLimit={1}
            acceptedFiles={['.png', '.pdf', '.jpeg', '.jpg']}
            buttonClassName={classes.buttonContainer}
            error={errors && errors.fotoInicialFile}
            onSave={files => {
              if (files != null && files.length > 0) {
                setFotoInicialFile(files[0]);
                values.fotoInicialFile = files[0];
              }
            }}
            disabled={readOnly || !canEdit()}
            showGridFiles
            files={fotoInicialFile != null ? [{
              file: fotoInicialFile,
              nome: fotoInicialFile.name,
              onDelete: () => {
                setFotoInicialFile(null);
                values.fotoInicialFile = null;
              }
            }] : []}
            canDeleteFiles={canEdit()}
          />
        </Grid>
        <Grid item xs={6} className={classes.fieldContainer}>
          <Dropzone 
            buttonText="Foto Final"
            filesLimit={1}
            acceptedFiles={['.png', '.pdf', '.jpeg', '.jpg']}
            buttonClassName={classes.buttonContainer}
            error={errors && errors.fotoFinalFile}
            onSave={files => {
              if (files != null && files.length > 0) {
                setFotoFinalFile(files[0]);
                values.fotoFinalFile = files[0];
              }
            }}
            disabled={readOnly || !canEdit()}
            showGridFiles
            files={fotoFinalFile != null ? [{
              file: fotoFinalFile,
              nome: fotoFinalFile.name,
              onDelete: () => {
                setFotoFinalFile(null);
                values.fotoFinalFile = null;
              }
            }] : []}
            canDeleteFiles={canEdit()}
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};

Formulario.propTypes = {
  findOdometroById: PropTypes.func.isRequired, 
  saveOdometro: PropTypes.func.isRequired,
  updateOdometro: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  selectedOdometro: PropTypes.object
};

Formulario.defaultValue = {
  selectedOdometro: null,
  id: null,
}

const mapStateToProps = state => ({
  selectedOdometro: selectorsOdometros.getSelectedOdometro(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsOdometros,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
