/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, TableCell } from '@material-ui/core';
import { Table } from '../../../components';
import ProdutoFormulario from './ProdutoFormulario';
import { isTrue, moneyFormatter } from 'utils';

const useStyles = makeStyles(() => ({
  image: {
    height: 50
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  required: {
    color: 'red',
    paddingTop: 5
  }
}));

const ProdutosContainer = ({ produtos, onSave, disabled, isEntrada, showError }) => {
  const classes = useStyles();

  const [titles] = useState(isEntrada ? ['Produto', 'Tamanho', 'Quantidade', 'Valor Unitário', 'Devolução de uso'] : ['Produto', 'Tamanho', 'Quantidade']);
  const [currentProdutos, setCurrentProdutos] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProduto, setSelectedProduto] = useState(null);

  useEffect(() => {
    if (produtos) {
      setCurrentProdutos(produtos);
    }
  }, [produtos]);

  const renderRows = p => {
    if (isEntrada) {
      return (
        <>
          <TableCell>{p.produtoNome}</TableCell>
          <TableCell>{p.tamanhoValue ? p.tamanhoValue : "N/A"}</TableCell>
          <TableCell>{p.quantidade || 0}</TableCell>
          <TableCell>{moneyFormatter(p.valorUnitario)}</TableCell>
          <TableCell>{isTrue(p.devolucaoUso)? "Sim" : "Não"}</TableCell>
        </>
      )
    }

    return (
      <>
        <TableCell>{p.produtoNome}</TableCell>
        <TableCell>{p.tamanhoValue ? p.tamanhoValue : "N/A"}</TableCell>
        <TableCell>{p.quantidade || 0}</TableCell>
      </>
    )
  }

  const onDeleteLocal = id => {
    const products = currentProdutos.filter(p => p.id !== id);
    setCurrentProdutos(products);
    onClose(products);
  }

  const onClose = productsToRetrieve => {
    setOpen(false);
    setSelectedProduto(null);
    onSave(productsToRetrieve || currentProdutos);
  }

  const onUpsertProduto = data => {
    let newProdutos = [];

    const existedProducts = currentProdutos.filter(p => p.id === data.id);
    if (existedProducts.length > 0) {
      currentProdutos.forEach(p => {
        if (p.id === data.id) {
          newProdutos.push(data);
        } else {
          newProdutos.push(p);
        }
      });
    } else {
      newProdutos = [...currentProdutos, data];
    }

    setCurrentProdutos(newProdutos);
    onClose(newProdutos);
  }

  const onEdit = id => {
    setSelectedProduto(currentProdutos.filter(p => p.id === id)[0]);
    setOpen(true);
  }

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <Table
          rows={currentProdutos}
          titles={titles}
          headerTitle="Produtos"
          deleteMessage="Tem certeza que deseja remover o produto"
          renderRows={renderRows}
          hasRowActions
          onDelete={onDeleteLocal}
          onEdit={onEdit}
          hasAdd={disabled === undefined || disabled === false}
          showDelete={disabled === undefined || disabled === false}
          showEdit={disabled === undefined || disabled === false}
          onAdd={() => setOpen(true)}
          showError={showError}
        />
      </Grid>
      <ProdutoFormulario
        selectedProduto={selectedProduto}
        open={open}
        onClose={onClose}
        isEntrada={isEntrada}
        onSave={onUpsertProduto}
      />
    </>
  );
};

export default ProdutosContainer;
