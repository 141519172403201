import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as selectors from '../../store/polo/reducer';
import * as actions from '../../store/polo/actions';
import DefaultSelect from '../DefaultSelect';
import MultiSelect from '../MultiSelect';

const PoloSelect = ({ polos, onSelect, value, isRequired, error, helperText, isMultiple, useFilter, malhaId, gerenciaId, findOptions, disabled }) => {
  const handleChange = (event) => onSelect(event.target.value);

  const handleMultiplo = newValue => {
    let result = null;
    if (newValue) {
      result = newValue.map(v => v.value);
    }
    onSelect(result);
  }

  useEffect(() => {
    if (useFilter) {
      findOptions(isMultiple ? malhaId : [malhaId], isMultiple ? gerenciaId : [gerenciaId]);
    } else {
      findOptions();
    }
  }, [useFilter, findOptions, malhaId, gerenciaId, isMultiple]);

  return (
    <>
    { isMultiple ? 
      <MultiSelect
        label="Polos"
        onChange={newValue => handleMultiplo(newValue)}
        defaultValue={polos && value? polos.filter(p => value.indexOf(p.id) > -1).map(i => ({ value: i.id, label: `${i['codigo']} - ${i['nome']}` })) : []}
        options={polos ? polos.map(i => ({ value: i.id, label: `${i['codigo']} - ${i['nome']}` })) : []}
      /> 
      : 
      <DefaultSelect 
        error={error}
        helperText={helperText}
        isRequired={isRequired}
        label="Polo"
        onSelect={handleChange}
        options={polos}
        value={value}
        handlerOption={option => option['nome']}
        disabled={disabled}
      />
    }
    </>
  );
}

PoloSelect.propTypes = {
  error: PropTypes.any,
  value: PropTypes.string,
  polos: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
  useFilter: PropTypes.bool,
  malhaId: PropTypes.string, 
  gerenciaId: PropTypes.string,
  findOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PoloSelect.defaultProps = {
  error: null,
  helperText: null,
  isRequired: false,
  isMultiple: false,
  useFilter: true,
  malhaId: null,
  gerenciaId: null,
  disabled: false
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
}, dispatch);

const mapStateToProps = state => ({
  polos: selectors.getPolosOptions(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(PoloSelect);