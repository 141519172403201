/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.textColor.gray,
  },
  subItem: {
    marginLeft: 5
  },
  menuItem: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.textColor.gray,
    padding: '6px 5px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      cursor: 'default'
    }
  },
  buttonMenu: {
    '&:hover': {
      borderRadius: 4,
      color: theme.palette.textColor.orange,
      cursor: 'pointer',
      '& $icon': {
        color: theme.palette.textColor.orange
      }
    }
  },
  icon: {
    color: theme.palette.textColor.gray,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.textColor.white,
    backgroundColor: theme.palette.textColor.orange,
    '& $icon': {
      color: theme.palette.textColor.white
    }
  },
  buttonIcon: {
    marginLeft: 15
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div 
    ref={ref} 
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = ({ pages }) => {
  const classes = useStyles();

  return (
    <List>
      {pages.map((page, i) => (
        <>
          {!page.show ? '' : page.options && Array.isArray(page.options) ?
            <ListItem
              className={classes.menuItem}
              disableGutters
              key={i}
            >
              <Button
                activeClassName={classes.active}
                className={classes.button}
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
            : 
            <ListItem
              className={classes.item}
              disableGutters
              key={i}
            >
              <Button
                activeClassName={classes.active}
                className={clsx(classes.button, classes.buttonMenu)}
                component={CustomRouterLink}
                to={page.href}
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
          }
          {page.show && page.options && Array.isArray(page.options) && page.options.map((option, j) => {
            return option.show && <ListItem
              className={clsx(classes.item, classes.subItem)}
              disableGutters
              key={j}
            >
              <Button
                activeClassName={classes.active}
                className={clsx(classes.button, classes.buttonMenu)}
                component={CustomRouterLink}
                to={option.href}
                isActive={option.isActive ? option.isActive : null}
                key={j}
              >
                <div className={classes.icon}>{option.icon}</div>
                {option.title}
              </Button>
            </ListItem>
          })}
        </>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;