/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid } from '@material-ui/core';
import { Table, Filtro } from '../../components';
import * as selectorsAlimentadores from '../../store/alimentador/reducer';
import * as actionsAlimentadores from '../../store/alimentador/actions';
import Formulario from './Formulario';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Alimentadores = ({ alimentadores, loadAlimentadores, deleteAlimentador }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  
  const classes = useStyles();
  
  const renderRows = d => {
    return (
      <>
        <TableCell>{d.codigo}</TableCell>
        <TableCell>{d.nome}</TableCell>
        <TableCell>{d.subestacaoCodigos && Array.isArray(d.subestacaoCodigos)? d.subestacaoCodigos.join(', ') : ''}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadAlimentadores()
  }, [loadAlimentadores]);

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteAlimentador(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = () => {
    setOpen(false);
    setId(null);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Filtro 
          onFilter={filterData => loadAlimentadores(filterData)}
          useCodigo
          useMalha
          useGerencia
          usePolo
          useSubestacao
        />
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={alimentadores}
          titles={['Código', 'Nome', 'Subestações']}
          headerTitle="Alimentadores"
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          showDelete={false}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          alimentadores={alimentadores}
        />
      </Grid>
    </Grid>
  );
};

Alimentadores.propTypes = {
  deleteAlimentador: PropTypes.func.isRequired,
  loadAlimentadores: PropTypes.func.isRequired,
  alimentadores: PropTypes.array,
};

const mapStateToProps = state => ({
  alimentadores: selectorsAlimentadores.getAlimentadores(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsAlimentadores
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Alimentadores));