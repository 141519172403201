/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import * as selectorsVeiculos from '../../store/veiculo/reducer';
import * as actionsVeiculos from '../../store/veiculo/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import moment from 'moment';
import InformacaoBasicaTab from './InformacaoBasicaTab';

import { WidgetTab, DialogDefault } from '../../components';
import HistoricoTab from './HistoricoTab';

const schema = {
  placa: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  tipoVeiculo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  ativo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  categoria: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  possuiRastreador: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const tipoVeiculoEletropontesSchema = {
  valorAquisicao: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataAquisicao: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  transferido: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  }
};

const transferidoSchema = {
  dataTransferencia: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const Formulario = ({ saveVeiculo, updateVeiculo, selectedVeiculo, open, id, onClose, findVeiculoById, authData }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setValues({
        criadoPor: authData.id,
        criadoPorName: authData.nome,
        dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss"),
        possuiRastreador: false,
        ativo: true,
      });
    } else {
      setValues({
        possuiRastreador: false,
        ativo: true,
      });
    }
  }, [authData, open]);

  useEffect(() => {
    if (id) findVeiculoById(id);
  }, [id, findVeiculoById]);

  useEffect(() => {
    if (id && selectedVeiculo && id === selectedVeiculo.id) {
      setValues(selectedVeiculo);
    }
  }, [id, selectedVeiculo]);

  const onSave = () => {
    let validateSchema = schema;
    if (values.tipoVeiculo === 'ELETROPONTES') {
      validateSchema = {
        ...validateSchema,
        ...tipoVeiculoEletropontesSchema
      }

      if (values.transferido === 'true' || values.transferido === true) {
        validateSchema = {
          ...validateSchema,
          ...transferidoSchema,
        }
      }
    }

    if (values.possuiRastreador === "true" || values.possuiRastreador === true) {
      validateSchema = {
        ...validateSchema,
        numRastreador: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      }
    }

    const erros = validate(values, validateSchema);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateVeiculo(values, saveCallback);
      else saveVeiculo(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      setValues({});
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Veículo"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <WidgetTab
        widgets={[
          {
            element: <InformacaoBasicaTab id={id} values={values} setValues={setValues} errors={errors} handleChange={handleChange} />,
            label: "Informações Básicas",
            disabled: false
          },
          {
            element: <HistoricoTab idVeiculo={id} historico={values.historico? values.historico : []} />,
            label: "Histórico",
            disabled: !id
          },
        ]}
      />
    </DialogDefault>
  );
};

Formulario.propTypes = {
  findVeiculoById: PropTypes.func.isRequired, 
  saveVeiculo: PropTypes.func.isRequired,
  updateVeiculo: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  selectedVeiculo: PropTypes.object,
  authData: PropTypes.object.isRequired
};

Formulario.defaultValue = {
  selectedVeiculo: null,
  id: null,
}

const mapStateToProps = state => ({
  selectedVeiculo: selectorsVeiculos.getSelectedVeiculo(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsVeiculos,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
