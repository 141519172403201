
const toBase64 = async file => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    }
  });

}

export {
  toBase64
};