import * as Type from './actionsTypes';

const loadNotas = filtro => ({ type: Type.FETCH_NOTAS, filtro });

const deleteNota = id => ({ type: Type.DELETE_NOTA, id });

const saveNota = (data, callback) => ({ type: Type.SAVE_NOTA, data, callback });

const updateNota = (data, callback) => ({ type: Type.UPDATE_NOTA, data, callback });

const findNotaById = (id, callback) => ({ type: Type.NOTA_FIND_BY_ID, id, callback });

const downloadNotas = (filter, callback) => ({ type: Type.NOTA_DOWNLOAD, filter, callback });

const validateNota = (data, callback) => ({ type: Type.NOTA_VALIDATE_BY_ID, data, callback });

const findFotoById = (id, callback) => ({ type: Type.NOTA_FIND_FOTO_BY_ID, id, callback });

const exportNota = (filter, report, callbackAfterSave) => ({ type: Type.NOTA_EXPORT, filter, report, callbackAfterSave });

export {
  loadNotas,
  deleteNota,
  saveNota,
  updateNota,
  findNotaById,
  downloadNotas,
  validateNota,
  findFotoById,
  exportNota
}
