export const FETCH_USUARIO_DOCUMENTOS = 'FETCH_USUARIO_DOCUMENTOS';
export const FETCH_USUARIO_DOCUMENTOS_SUCCESS = 'FETCH_USUARIO_DOCUMENTOS_SUCCESS';

export const DELETE_USUARIO_DOCUMENTO = 'DELETE_USUARIO_DOCUMENTO';
export const DELETE_USUARIO_DOCUMENTO_SUCCESS = 'DELETE_USUARIO_DOCUMENTO_SUCCESS';

export const SAVE_USUARIO_DOCUMENTO = 'SAVE_USUARIO_DOCUMENTO';
export const SAVE_USUARIO_DOCUMENTO_SUCCESS = 'SAVE_USUARIO_DOCUMENTO_SUCCESS';

export const UPDATE_USUARIO_DOCUMENTO = 'UPDATE_USUARIO_DOCUMENTO';
export const UPDATE_USUARIO_DOCUMENTO_SUCCESS = 'UPDATE_USUARIO_DOCUMENTO_SUCCESS';

export const CLEAR_USUARIO_DOCUMENTO = 'CLEAR_USUARIO_DOCUMENTO';

export const USUARIO_DOCUMENTO_FIND_BY_ID = 'USUARIO_DOCUMENTO_FIND_BY_ID';
export const USUARIO_DOCUMENTO_FIND_BY_ID_SUCCESS = 'USUARIO_DOCUMENTO_FIND_BY_ID_SUCCESS';

export const USUARIO_DOCUMENTO_FIND_OPTIONS = 'USUARIO_DOCUMENTO_FIND_OPTIONS';
export const USUARIO_DOCUMENTO_FIND_OPTIONS_SUCCESS = 'USUARIO_DOCUMENTO_FIND_OPTIONS_SUCCESS';
