export const FETCH_ODOMETROS = 'FETCH_ODOMETROS';
export const FETCH_ODOMETROS_SUCCESS = 'FETCH_ODOMETROS_SUCCESS';

export const DELETE_ODOMETRO = 'DELETE_ODOMETRO';
export const DELETE_ODOMETRO_SUCCESS = 'DELETE_ODOMETRO_SUCCESS';

export const SAVE_ODOMETRO = 'SAVE_ODOMETRO';
export const SAVE_ODOMETRO_SUCCESS = 'SAVE_ODOMETRO_SUCCESS';

export const UPDATE_ODOMETRO = 'UPDATE_ODOMETRO';
export const UPDATE_ODOMETRO_SUCCESS = 'UPDATE_ODOMETRO_SUCCESS';

export const CLEAR_ODOMETRO = 'CLEAR_ODOMETRO';

export const ODOMETRO_FIND_BY_ID = 'ODOMETRO_FIND_BY_ID';
export const ODOMETRO_FIND_BY_ID_SUCCESS = 'ODOMETRO_FIND_BY_ID_SUCCESS';

export const ODOMETRO_FIND_OPTIONS = 'ODOMETRO_FIND_OPTIONS';
export const ODOMETRO_FIND_OPTIONS_SUCCESS = 'ODOMETRO_FIND_OPTIONS_SUCCESS';

export const ODOMETRO_VALIDATE_BY_ID = 'ODOMETRO_VALIDATE_BY_ID';
export const ODOMETRO_VALIDATE_BY_ID_SUCCESS = 'ODOMETRO_VALIDATE_BY_ID_SUCCESS';

export const ODOMETRO_FIND_FOTO_BY_ID = 'ODOMETRO_FIND_FOTO_BY_ID';

export const ODOMETRO_EXPORT = 'ODOMETRO_EXPORT';
