import * as Type from './actionsTypes';

const INITIAL_STATE = {
  estoques: [],
  selectedEstoque: null,
};

const ordernarData = (a,b) => new Date(Number(b.data)) - new Date(Number(a.data));

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_ESTOQUES_SUCCESS: {
      return {
        ...state,
        estoques: action.data.sort(ordernarData)
      }
    }
    case Type.DELETE_ESTOQUE_SUCCESS: {
      return {
        ...state,
        estoques: state.estoques.filter(s => s.id !== action.data).sort(ordernarData)
      }
    }
    case Type.SAVE_ESTOQUE_SUCCESS: {
      return {
        ...state,
        estoques: [...state.estoques, action.data].sort(ordernarData)
      }
    }
    case Type.UPDATE_ESTOQUE_SUCCESS: {
      return {
        ...state,
        estoques: [...state.estoques.filter(s => s.id !== action.data.id), action.data].sort(ordernarData)
      }
    }
    case Type.ESTOQUE_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedEstoque: action.data
      }
    }
    case Type.CLEAR_ESTOQUE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getEstoques = state => state.rootState.estoque.estoques;
export const getSelectedEstoque = state => state.rootState.estoque.selectedEstoque;
