import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import InputGroup from '../InputGroup';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
  input: {
    '& div': {
      background: theme.palette.white
    }
  },
  disabled: {
    '& div': {
      background: 'none'
    }
  }
}));

const NumberInput = ({ value, label, title, placement, placeholder, useTooltip, onChange, fullWidth, name, isRequired,
  error, helperText, rows, multiline, inputProps, disabled, classNameInputGroup, type, showHelperText, prefix, decimalScale,
  decimalSeparator, thousandSeparator }) => {

  const classes = useStyles();
  const defaultHelperText = helperText? helperText : 'O campo é obrigatório';

  return (
    <InputGroup
      label={label}
      placement={placement}
      title={title}
      useTooltip={useTooltip}
      isRequired={isRequired}
      className={classNameInputGroup}
    >
      <NumberFormat 
        customInput={TextField} 
        prefix={prefix}
        fullWidth={fullWidth}
        error={error}
        helperText={error || showHelperText? defaultHelperText : ''}
        margin="dense"
        name={name}
        onValueChange={data => onChange(data.floatValue)}
        placeholder={placeholder ? placeholder : label}
        value={value}
        variant="outlined"
        rows={rows}
        multiline={multiline}
        InputProps={inputProps}
        disabled={disabled}
        className={clsx(classes.input, disabled && classes.disabled)}
        type={type}
        decimalScale={decimalScale}
        decimalSeparator={decimalSeparator}
        thousandSeparator={thousandSeparator}
      />
    </InputGroup>
  );
}

NumberInput.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.any,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  placement: PropTypes.string,
  rows: PropTypes.number,
  title: PropTypes.string,
  useTooltip: PropTypes.bool,
  value: PropTypes.string,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  classNameInputGroup: PropTypes.string,
  type: PropTypes.string,
  showHelperText: PropTypes.bool,
  prefix: PropTypes.string,
  decimalScale: PropTypes.number,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string
};

NumberInput.defaultProps = {
  error: false,
  fullWidth: true,
  helperText: '',
  label: '',
  multiline: false,
  placement: 'top',
  rows: 1,
  title: '',
  useTooltip: false,
  isRequired: false,
  value: '',
  inputProps: {},
  disabled: false,
  classNameInputGroup: '',
  type: 'text',
  placeholder: null,
  onChange: () => {},
  name: '',
  showHelperText: false,
  prefix: "R$ ",
  decimalScale: 2,
  decimalSeparator: ",",
  thousandSeparator: "."
};

export default NumberInput;