/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell } from '@material-ui/core';
import { Table } from '../../components';
import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';
import * as selectorsDocumentos from '../../store/documento/reducer';
import * as actionsDocumentos from '../../store/documento/actions';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Formulario from './Formulario';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Documentos = ({ documentos, loadDocumentos, downloadDocumento, authData }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const classes = useStyles();

  const getModulo = modulo => {
    if (modulo === 'NSIR') {
      return 'Nsir';
    } else if (modulo === 'FINANCEIRO') {
      return 'Financeiro';
    } else if (modulo === 'USUARIO') {
      return 'Usuário';
    }

    return 'Configuração';
  }
  
  const renderRows = s => {
    return (
      <>
        <TableCell>{s.nome}</TableCell>
        <TableCell>{s.descricao}</TableCell>
        <TableCell>{getModulo(s.modulo)}</TableCell>
        <TableCell>{s.ativo ? 'Sim' : 'Não'}</TableCell>
      </>
    )
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  useEffect(() => {
    loadDocumentos();
  }, [loadDocumentos]);

  const onClose = (isSave) => {
    if (isSave) {
      loadDocumentos();
    }
    setOpen(false);
    setId(null);
  }

  const getHeaderActions = () => {
    if (isAdmin()) {
      return [{ 
        title: 'Novo',
        onClick: () => setOpen(true)
      }];
    }

    return [];
  }

  return (
    <div className={classes.root}>
      <Table 
        rows={documentos}
        titles={['Nome', 'Descrição', 'Módulo', 'Ativo']}
        headerTitle="Documentos"
        showDelete={false}
        showView={false}
        hasAdd={false}
        renderRows={renderRows}
        headerActions={getHeaderActions()}
        hasRowActions
        showEdit
        showEditRow={row => isAdmin() && row.modulo !== 'CONFIGURACAO'}
        onEdit={id => {setId(id); setOpen(true);}}
        actions={[
          {
            onClick: id => {
              const documento = documentos.filter(d => d.id === id);
              downloadDocumento(documento.length > 0 ? documento[0] : {});
            },
            icon: <CloudDownloadIcon />
          }
        ]}
      />
      <Formulario
        id={id}
        open={open}
        onClose={onClose}
      />
    </div>
  );
};

Documentos.propTypes = {
  downloadDocumento: PropTypes.func.isRequired,
  loadDocumentos: PropTypes.func.isRequired,
  documentos: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  documentos: selectorsDocumentos.getDocumentos(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsDocumentos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Documentos));