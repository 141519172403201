/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import * as actionsMalhas from '../../store/malha/actions';

import { Input, DialogDefault } from '../../components';

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  codigo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const Formulario = ({ saveMalha, updateMalha, malhas, open, id, onClose }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) setValues(malhas.filter(a => a.id === id)[0]);
  }, [id, malhas]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateMalha(values);
      else saveMalha(values);
      onCloseLocal(true);
    }
  }

  const onCloseLocal = (isSave) => {
    setErrors({});
    setValues({})
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Malha"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <form autoComplete="off" noValidate>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={4} className={classes.fieldContainer}>
            <Input
              error={errors && errors.codigo}
              helperText={errors && errors.codigo ? errors.codigo : null}
              isRequired
              label="Código"
              name="codigo"
              onChange={handleChange}
              placeholder="Código"
              value={values.codigo}
            />
          </Grid>
          <Grid item xs={8} className={classes.fieldContainer}>
            <Input
              error={errors && errors.nome}
              helperText={errors && errors.nome ? errors.nome : null}
              isRequired
              label="Nome"
              name="nome"
              onChange={handleChange}
              placeholder="Nome"
              value={values.nome}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              label="Descrição"
              name="descricao"
              onChange={handleChange}
              placeholder="descricao"
              value={values.descricao}
              rows={4}
              multiline
            />
          </Grid>
        </Grid>
      </form>
    </DialogDefault>
  );
};

Formulario.propTypes = {
  saveMalha: PropTypes.func.isRequired,
  updateMalha: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  malhas: PropTypes.array
};

Formulario.defaultValue = {
  id: null,
  malhas: []
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsMalhas,
}, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(Formulario));
