import * as Type from './actionsTypes';

const loadDocumentos = (modulo, ativo) => ({ type: Type.DOCUMENTOS_FIND, modulo, ativo });

const downloadDocumento = documento => ({ type: Type.DOCUMENTOS_DOWNLOAD, documento });

const findDocumentoById = id => ({ type: Type.DOCUMENTOS_FIND_BY_ID, id });

const saveDocumento = (documento, saveCallback) => ({ type: Type.DOCUMENTOS_SAVE, documento, saveCallback });

const viewDocumentById = documento => ({ type: Type.DOCUMENTOS_VIEW_BY_ID, documento });

const cleanViewDocumentById = () => ({ type: Type.DOCUMENTOS_CLEAN_VIEW_BY_ID });

export {
  loadDocumentos,
  downloadDocumento,
  findDocumentoById,
  saveDocumento,
  viewDocumentById,
  cleanViewDocumentById
}
