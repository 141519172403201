import * as Type from './actionsTypes';

const loadAlimentadores = filter => ({ type: Type.ALIMENTADORES_FIND, filter });

const deleteAlimentador = id => ({ type: Type.ALIMENTADORES_DELETE, id });

const saveAlimentador  = data => ({ type: Type.ALIMENTADORES_SAVE, data });

const updateAlimentador = data => ({ type: Type.ALIMENTADORES_UPDATE, data });

const findOptions = ({ malhaId, gerenciaId, poloId, subestacaoId }) => ({ type: Type.ALIMENTADORES_OPTIONS, malhaId, gerenciaId, poloId, subestacaoId });

export {
  loadAlimentadores,
  deleteAlimentador,
  saveAlimentador,
  updateAlimentador,
  findOptions
}
