import * as Type from './actionsTypes';
import moment from 'moment';

const INITIAL_STATE = {
  nsirs: [],
  selected: {}
};

const ordenarPorNsirEquipamento = (a, b) => {
  const aVencimento = moment(a.vencimento).endOf('day');
  const bVencimento = moment(b.vencimento).endOf('day');
  return (
    (a.nsmpUrgencia ? -2 : 1) - (b.nsmpUrgencia ? -2 : 1) ||
    aVencimento.diff(bVencimento) ||
    parseInt(a.codigo) - parseInt(b.codigo) ||
    a.equipamento.localeCompare(b.equipamento)
  );
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.NSIRS_FIND_SUCCESS: {
      return {
        ...state,
        nsirs: action.data.sort(ordenarPorNsirEquipamento)
      };
    }
    case Type.NSIRS_DELETE_SUCCESS: {
      return {
        ...state,
        nsirs: state.nsirs.filter(a => a.id !== action.id)
      };
    }
    case Type.NSIRS_SAVE_SUCCESS: {
      return {
        ...state,
        nsirs: [...state.nsirs, action.data].sort(ordenarPorNsirEquipamento),
        selected: action.data
      };
    }
    case Type.NSIRS_IMPORT_SUCCESS: {
      return {
        ...state,
        nsirs: [...state.nsirs, ...action.data].sort(ordenarPorNsirEquipamento),
        selected: action.data
      };
    }
    case Type.NSIRS_UPDATE_SUCCESS: {
      return {
        ...state,
        nsirs: [
          ...state.nsirs.filter(s => s.id !== action.data.id),
          action.data
        ].sort(ordenarPorNsirEquipamento),
        selected: action.data
      };
    }
    case Type.NSIRS_UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: action.data.status,
          inspetorId: action.data.inspetorId,
          dataConclusaoValue: action.data.dataConclusaoValue
        }
      };
    }
    case Type.NSIRS_INICIAR_DIGITACAO_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: 'EM_DIGITACAO',
          digitadorId: action.data.digitadorId
        }
      };
    }
    case Type.NSIRS_TROCAR_DIGITADOR_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          status: action.data.status,
          digitadorId: action.data.digitadorId
        }
      };
    }
    case Type.NSIRS_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selected: action.data
      };
    }
    case Type.NSIRS_CLEAN_SELECTED: {
      return {
        ...state,
        selected: {}
      };
    }
    case Type.NSIRS_UPDATE_FINANCEIRO_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.data
        }
      };
    }
    case Type.NSIRS_CLEAN: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getNsirs = state => state.rootState.nsir.nsirs;
export const getNsirSelected = state => state.rootState.nsir.selected;
export const getNsirStatus = state => state.rootState.nsir.selected.status;
