import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as selectors from '../../store/gerencia/reducer';
import * as actions from '../../store/gerencia/actions';
import DefaultSelect from '../DefaultSelect';
import MultiSelect from '../MultiSelect';

const GerenciaSelect = ({ gerencias, onSelect, value, isRequired, error, helperText, malhaId, useFilter, findOptions, disabled, multiSelect }) => {
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState([]);
  const handleChange = (event) => onSelect(event.target.value);

  useEffect(() => {
    if (useFilter) {
      findOptions(multiSelect ? malhaId : [malhaId]);
    } else {
      findOptions();
    }
  }, [useFilter, findOptions, malhaId]);

  useEffect(() => {
    if (gerencias) {
      setOptions(gerencias.map(m => ({value: m.id, label: m.nome})));
      setValues(value ? gerencias.filter(i => value.includes(i.id)).map(m => ({value: m.id, label: m.nome})) : []);
    } else {
      setOptions([]);
      setValues(value ? value : []);
    }
  }, [gerencias, value]);

  return (
    multiSelect ?
      <MultiSelect
        label="Gerências"
        onChange={values => onSelect(values ? values.map(v => v.value) : null)}
        defaultValue={values}
        options={options}
      /> 
      :
      <DefaultSelect 
        error={error}
        helperText={helperText}
        isRequired={isRequired}
        label="Gerência"
        onSelect={handleChange}
        options={gerencias}
        value={value}
        handlerOption={option => option['nome']}
        disabled={disabled}
      />
  );
}

GerenciaSelect.propTypes = {
  error: PropTypes.any,
  value: PropTypes.string,
  gerencias: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  useFilter: PropTypes.bool,
  malhaId: PropTypes.string,
  findOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  multiSelect: PropTypes.bool
};

GerenciaSelect.defaultProps = {
  error: null,
  helperText: null,
  isRequired: false,
  useFilter: true,
  malhaId: null,
  disabled: false,
  multiSelect: false
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
}, dispatch);

const mapStateToProps = state => ({
  gerencias: selectors.getGerenciasOptions(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(GerenciaSelect);