/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault } from '../../components';
import Formulario from './Pedidos/Formulario';
import moment from 'moment';
import { codeFormatter } from 'utils';
import * as actionsEstoque from '../../store/estoque/actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  iconValidate: {
    marginRight: theme.spacing(1),
    color: 'green'
  },
  iconInvalidate: {
    marginRight: theme.spacing(1),
    color: 'red'
  }
}));

const Estoque = ({ exportEstoque }) => {
  const [isEntrada, setIsEntrada] = useState(false);
  const [open, setOpen] = useState(false);

  const [id, setId] = useState(null);
  const [titles] = useState(['Produto', 'Tamanho', 'Local', 'Quantidade', 'Data última entrada', 'Data última saída']);
  const [localFilter, setLocalFilter] = useState({
    inicio: moment().subtract(2, 'months').startOf('month').valueOf(),
    fim: moment().endOf('day').valueOf()
  });
  const [openExport, setOpenExport] = useState(false);
  const [reload, setReload] = useState(null);

  const classes = useStyles();
  
  const renderRows = d => {
    return (
      <>
        <TableCell size="small">{d.produtoNome}<br/>({codeFormatter(d.produtoCodigo)})</TableCell>
        <TableCell size="small">{d.produtoTamanhoNome || '-'}</TableCell>
        <TableCell size="small">{d.local}</TableCell>
        <TableCell size="small">{d.quantidade}</TableCell>
        <TableCell size="small">{d.dataUltimaEntrada ? moment(d.dataUltimaEntrada).format("DD/MM/YYYY HH:mm") : ""}</TableCell>
        <TableCell size="small">{d.dataUltimaSaida ? moment(d.dataUltimaSaida).format("DD/MM/YYYY HH:mm") : ""}</TableCell>
      </>
    )
  }

  const onClose = isSave => {
    if (isSave) {
      setReload(!reload);
    }

    setOpen(false);
  }

  const getHeaderActions = () => {
    let actions = [
      { 
        title: 'Nova Entrada',
        onClick: () => {
          setIsEntrada(true);
          setOpen(true);
        } 
      },
      { 
        title: 'Nova Saída',
        onClick: () => {
          setIsEntrada(false);
          setOpen(true);
        } 
      },
      { 
        title: 'Exportar',
        onClick: () => {
          setOpenExport(true);
        } 
      },
    ];

    return actions;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useProduto
            useLocal
            filterData={localFilter}
            onFilter={filter => {
              setLocalFilter(filter);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          titles={titles}
          headerTitle="Estoque"
          hasAdd={false}
          renderRows={renderRows}
          headerActions={getHeaderActions()}
          useFilterData
          usePaginationApi
          paginationApi="produtos/estoques/locais/filter"
          paginationUrl="estoques"
          filterData={localFilter}
          reload={reload}
          hasRowActions={false}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          isEntrada={isEntrada}
        />
      </Grid>
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={() => {
          exportEstoque(localFilter, () => {
            setOpenExport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja exportar os estoques filtradas? </DialogContentText>
      </DialogDefault>
    </>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsEstoque
}, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(Estoque));