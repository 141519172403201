import * as Type from './actionsTypes';

const saveConvocacao  = data => ({ type: Type.CONVOCACOES_SAVE, data });

const updateConvocacao = (id, data) => ({ type: Type.CONVOCACOES_UPDATE, id, data });

const findConvocacaoById = id => ({ type: Type.CONVOCACOES_FIND_ONE, id });

const convocacaoClearSelected = () => ({ type: Type.CONVOCACOES_CLEAR_SELECTED });

const convocacaoUpdateStatus = (id, status) => ({ type: Type.CONVOCACOES_UPDATE_STATUS, id, status });

const convocacaoReport = (filtro, callback) => ({ type: Type.CONVOCACOES_REPORT, filtro, callback });

export {
  saveConvocacao,
  updateConvocacao,
  findConvocacaoById,
  convocacaoClearSelected,
  convocacaoUpdateStatus,
  convocacaoReport
}
