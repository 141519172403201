export const FETCH_ESTOQUES = 'FETCH_ESTOQUES';
export const FETCH_ESTOQUES_SUCCESS = 'FETCH_ESTOQUES_SUCCESS';

export const DELETE_ESTOQUE = 'DELETE_ESTOQUE';
export const DELETE_ESTOQUE_SUCCESS = 'DELETE_ESTOQUE_SUCCESS';

export const SAVE_ESTOQUE = 'SAVE_ESTOQUE';
export const SAVE_ESTOQUE_SUCCESS = 'SAVE_ESTOQUE_SUCCESS';

export const UPDATE_ESTOQUE = 'UPDATE_ESTOQUE';
export const UPDATE_ESTOQUE_SUCCESS = 'UPDATE_ESTOQUE_SUCCESS';

export const CLEAR_ESTOQUE = 'CLEAR_ESTOQUE';

export const ESTOQUE_FIND_BY_ID = 'ESTOQUE_FIND_BY_ID';
export const ESTOQUE_FIND_BY_ID_SUCCESS = 'ESTOQUE_FIND_BY_ID_SUCCESS';

export const ESTOQUE_EXPORT = 'ESTOQUE_EXPORT';