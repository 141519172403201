export const FETCH_NOTAS = 'FETCH_NOTAS';
export const FETCH_NOTAS_SUCCESS = 'FETCH_NOTAS_SUCCESS';

export const DELETE_NOTA = 'DELETE_NOTA';
export const DELETE_NOTA_SUCCESS = 'DELETE_NOTA_SUCCESS';

export const SAVE_NOTA = 'SAVE_NOTA';
export const SAVE_NOTA_SUCCESS = 'SAVE_NOTA_SUCCESS';

export const UPDATE_NOTA = 'UPDATE_NOTA';
export const UPDATE_NOTA_SUCCESS = 'UPDATE_NOTA_SUCCESS';

export const CLEAR_NOTA = 'CLEAR_NOTA';

export const NOTA_FIND_BY_ID = 'NOTA_FIND_BY_ID';
export const NOTA_FIND_BY_ID_SUCCESS = 'NOTA_FIND_BY_ID_SUCCESS';

export const NOTA_DOWNLOAD = 'NOTA_DOWNLOAD';

export const NOTA_VALIDATE_BY_ID = 'NOTA_VALIDATE_BY_ID';
export const NOTA_VALIDATE_BY_ID_SUCCESS = 'NOTA_VALIDATE_BY_ID_SUCCESS';

export const NOTA_FIND_FOTO_BY_ID = 'NOTA_FIND_FOTO_BY_ID';

export const NOTA_EXPORT = 'NOTA_EXPORT';