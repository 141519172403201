import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DefaultSelect from '../DefaultSelect';
import MultiSelect from '../MultiSelect';

const NsirStatusSelect = ({ onSelect, value, isRequired, disabled, isMultiple, options }) => {
  const handleMultiplo = newValue => {
    let result = null;
    if (newValue) {
      result = newValue.map(v => v.value);
    }
    onSelect(result);
  }

  const [defaultOptions, setDefaultOptions] = useState([
    {'id': 'EM_CRIACAO', nome: 'Em Criação'},
    {'id': 'AGUARDANDO_INSPECAO', nome: 'Aguardando Inspeção'},
    {'id': 'INSPECAO_EM_ENVIO', nome: 'Inspeção em Envio'},
    {'id': 'EM_INSPECAO', nome: 'Em Inspeção'},
    {'id': 'INSPECAO_ENVIADA_PARCIAL', nome: 'Inspeção Enviada Parcial'},
    {'id': 'INSPECAO_SYNC_ERROR', nome: 'Inspeção com Erro de Envio'},
    {'id': 'ERRO_AO_ENVIAR_INSPECAO', nome: 'Erro ao enviar Inspeção'},
    {'id': 'INSPECAO_FINALIZADA', nome: 'Inspeção Finalizada'},
    {'id': 'AGUARDANDO_DIGITACAO', nome: 'Aguardando Digitação'},
    {'id': 'EM_DIGITACAO', nome: 'Em Digitação'},
    {'id': 'DIGITACAO_FINALIZADA', nome: 'Digitação Finalizada'},
    {'id': 'EXECUTADA_GDIS', nome: 'Executada GDIS'}
  ]);

  useEffect(() => {
    if (options) setDefaultOptions(options);
  }, [options]);

  return (
    <>
      { isMultiple ? 
        <MultiSelect
          label="Status"
          onChange={newValue => handleMultiplo(newValue)}
          defaultValue={defaultOptions && value? defaultOptions.filter(p => value.indexOf(p.id) > -1).map(i => ({ value: i.id, label: i['nome'] })) : []}
          options={defaultOptions ? defaultOptions.map(i => ({ value: i.id, label: i['nome'] })) : []}
        /> 
        :
        <DefaultSelect 
          isRequired={isRequired}
          label="Status"
          onSelect={event => onSelect(event.target.value)}
          options={defaultOptions}
          value={value}
          disabled={disabled}
        />
      }
    </>
  );
}

NsirStatusSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  isRequired: PropTypes.any,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  isMultiple: PropTypes.bool,
  options: PropTypes.array
};

NsirStatusSelect.defaultProps = {
  value: null,
  disabled: false,
  isRequired: false,
  isMultiple: false,
  onSelect: () => {},
  options: null
};

export default NsirStatusSelect;