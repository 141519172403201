/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Input, DialogDefault, TrueFalseSelect } from '../../../components';
import { v4 as uuidv4 } from 'uuid';

const schema = {
  tamanho: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  ativo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  buttonContainer: {
    width: '100%',
  },
  error: {
    color: '#e53935',
    marginTop: 10,
    fontSize: 12,
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: '0.33px'
  }
}));

const ProdutoTamanhoFormulario = ({ selectedTamanho, open, onClose, onSave }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setValues({
        ativo: true,
      });
    } else {
      setValues({});
    }
  }, [open]);

  useEffect(() => {
    if (selectedTamanho) {
      setValues(selectedTamanho);
    } else {
      setValues({
        ativo: true,
      });
    }
  }, [selectedTamanho]);

  const onSaveLocal = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      onSave({
        ...values,
        id: values.id || uuidv4(),
      });
      setErrors({});
      setValues({});
    }
  }

  const onCloseLocal = () => {
    setErrors({});
    setValues({});
    onClose();
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSaveLocal}
      title="Tamanho do Produto"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={8} className={classes.fieldContainer}>
          <Input
            label="Tamanho"
            name="tamanho"
            onChange={handleChange}
            value={values.tamanho}
            isRequired
            error={errors && errors.tamanho ? true : false}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.ativo ? true : false}
            isRequired
            label="Ativo"
            name="ativo"
            onChange={handleChange}
            value={values.ativo}
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};


export default ProdutoTamanhoFormulario;
