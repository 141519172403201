export const INSPECAO_VEICULAR_FIND_ONE = 'INSPECAO_VEICULAR_FIND_ONE';
export const INSPECAO_VEICULAR_FIND_ONE_SUCCESS = 'INSPECAO_VEICULAR_FIND_ONE_SUCCESS';

export const INSPECAO_VEICULAR_SAVE = 'INSPECAO_VEICULAR_SAVE';
export const INSPECAO_VEICULAR_SAVE_SUCCESS = 'INSPECAO_VEICULAR_SAVE_SUCCESS';

export const INSPECAO_VEICULAR_UPDATE = 'INSPECAO_VEICULAR_UPDATE';
export const INSPECAO_VEICULAR_UPDATE_SUCCESS = 'INSPECAO_VEICULAR_UPDATE_SUCCESS';

export const INSPECAO_VEICULAR_CLEAN = 'INSPECAO_VEICULAR_CLEAN';
export const INSPECAO_VEICULAR_CLEAR_SELECTED = 'INSPECAO_VEICULAR_CLEAR_SELECTED';

export const INSPECAO_VEICULAR_REPORT = 'INSPECAO_VEICULAR_REPORT';