/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  Widget,
  Filtro,
  WidgetDescription,
} from '../../components';

import {
  BasicColumnGraph 
} from '../../components/Graphs';

import {
  EventAvailable as EventAvailableIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ImportantDevices as ImportantDevicesIcon,
  MyLocation as MyLocationIcon
} from '@material-ui/icons';

import * as selectorsDashboard from '../../store/dashboard/reducer';
import * as dashboardActions from '../../store/dashboard/actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  icon: {
    height: 32,
    width: 32
  },
  emAtraso: {
    backgroundColor: theme.palette.error.main,
  },
  executadasGdis: {
    backgroundColor: 'green',
  },
}));

const Dashboard = ({ loadDashboard, dashboardData }) => {
  useEffect(() => {
    loadDashboard();
  }, [loadDashboard]);

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Filtro
          onFilter={loadDashboard}
          codigoLabel="Nsir"
          useDates
          useMalha
          useGerencia
          usePolo
          useSubestacao
          useAlimentador
          useNsirStatus
          multipleStatus
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <WidgetDescription
                title="NSIR CONCLUÍDAS"
                description={dashboardData && dashboardData.executadasGdis? dashboardData.executadasGdis : 0}
                avatarClassName={classes.executadasGdis}
                icon={<CheckCircleOutlineIcon className={classes.icon}/>}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <WidgetDescription
                title="NSIR EM INSPEÇÃO"
                description={dashboardData && dashboardData.emInspecao? dashboardData.emInspecao : 0}
                icon={<MyLocationIcon className={classes.icon}/>}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <WidgetDescription
                title="NSIR EM DIGITAÇÃO"
                description={dashboardData && dashboardData.emDigitacao? dashboardData.emDigitacao : 0}
                icon={<ImportantDevicesIcon className={classes.icon}/>}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <WidgetDescription
                title="NSIR EM ATRASO"
                description={dashboardData && dashboardData.emAtraso? dashboardData.emAtraso : 0}
                avatarClassName={classes.emAtraso}
                icon={<EventAvailableIcon className={classes.icon}/>}
              />
            </Grid>
            <Grid item xs={12}>
              <Widget title="Nsir a Vencer">
                <BasicColumnGraph seriesData={dashboardData && dashboardData.graph? dashboardData.graph : null } yAxisTitle="N° de Nsir a Vencer" />
              </Widget>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

Dashboard.propTypes = {
  loadDashboard: PropTypes.func.isRequired,
  dashboardData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  dashboardData: selectorsDashboard.getDashboardData(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...dashboardActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
