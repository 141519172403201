/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText, Typography } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault, TrueFalseSelect } from '../../components';
import * as selectorsAuth from '../../store/auth/reducer';
import * as actionsNotas from '../../store/nota/actions';
import Formulario from './Formulario';
import moment from 'moment';
import * as Role from '../../constants/role';
import { Warning, CheckCircleOutline, NotInterested, CameraEnhance } from '@material-ui/icons';
import Viewer from 'react-viewer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  iconValidate: {
    marginRight: theme.spacing(1),
    color: 'green'
  },
  iconInvalidate: {
    marginRight: theme.spacing(1),
    color: 'red'
  }
}));

const Notas = ({ downloadNotas, userRole, validateNota, findFotoById, exportNota, findNotaById }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['', 'Data', 'Tipo Nota', 'Valor', 'Inspetor', 'Tipo Pagamento', 'Observador/Ajudante', 'Validação']);
  const [openDownloadNotas, setOpenDownloadNotas] = useState(false);
  const [openValidate, setOpenValidate] = useState(false);
  const [validateData, setValidateData] = useState({});
  const [localFilter, setLocalFilter] = useState({
    inicio: moment().subtract(2, 'months').startOf('month').valueOf(),
    fim: moment().endOf('day').valueOf()
  });
  const [openNotFotoModal, setOpenNotFotoModal] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openFotoViewer, setOpenFotoViewer] = useState(false);
  const [images, setImages] = useState([]);
  const [openExport, setOpenExport] = useState(false);
  const [reload, setReload] = useState(null);

  const classes = useStyles();
  
  const getTipo = tipo => {
    if ("ABASTECIMENTO" === tipo) {
      return "Abastecimento"; 
    } else if ("ADIANTAMENTO" === tipo) {
      return "Adiantamento"; 
    } else if ("ALIMENTACAO" === tipo) {
      return "Alimentação"; 
    } else if ("HOSPEDAGEM" === tipo) {
      return "Hospedagem"; 
    } else if ("MANUTENCAO_VEICULO" === tipo) {
      return "Manutenção do Veículo";
    } else if ("PEDAGIO" === tipo) {
      return "Pedágio/Balça";
    } else if ("CORREIO" === tipo) {
      return "Correios";
    }

    return "Outros"; 
  }

  const getTipoPagamento = tipoPagamento => {
    if ("CARTAO_CORPORATIVO" === tipoPagamento) {
      return "Cartão Corporativo"; 
    } else if ("APORTE" === tipoPagamento) {
      return "Aporte"; 
    }

    return "N/A"; 
  }

  const renderRows = d => {
    return (
      <>
        <TableCell size="small">
          {d.validate === 'INVALIDADO' && <NotInterested className={classes.iconInvalidate} />}
          {(d.validate === null || d.validate === 'AGUARDANDO_VALIDACAO') && <Warning className={classes.icon} />}
          {d.validate === 'VALIDADO' && <CheckCircleOutline className={classes.iconValidate} />}
        </TableCell>
        <TableCell>{moment(Number(d.data)).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{getTipo(d.tipo)}</TableCell>
        <TableCell>{d.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </TableCell>
        <TableCell>{d.usuarioNome}</TableCell>
        <TableCell>{getTipoPagamento(d.tipoPagamento)}</TableCell>
        <TableCell>{d.inspetorAuxiliarNome}</TableCell>
        <TableCell>{d.validate === null || d.validate === 'AGUARDANDO_VALIDACAO'? 'Aguardando Validação' : d.validate === 'INVALIDADO' ? 'Invalidado' : 'Validado'}</TableCell>
      </>
    )
  }

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = () => {
    setOpen(false);
    setId(null);
  }

  const isAdmin = () => Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole;

  const getHeaderActions = () => {
    let actions = [
      { 
        title: 'Exportar',
        onClick: () => setOpenExport(true)
      },
      { 
        title: 'Relatório',
        onClick: () => setOpenReport(true)
      }
    ];

    if (isAdmin()) {
      actions.push({ 
        title: 'Download Notas',
        onClick: () => setOpenDownloadNotas(true)
      });
    }

    return actions;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useDates
            useInspetor
            useTipoNota
            useTipoPagamento
            useValidate
            useCartao
            filterData={localFilter}
            onFilter={filter => {
              setLocalFilter(filter);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          titles={titles}
          headerTitle="Notas"
          showDelete={false}
          renderRows={renderRows}
          onEdit={onEdit}
          hasRowActions
          onAdd={onAdd}
          headerActions={getHeaderActions()}
          useFilterData
          usePaginationApi
          paginationApi="notas/filter"
          paginationUrl="notas"
          filterData={localFilter}
          reload={reload}
          actions={
            [
              {
                key: 'view',
                icon: <CameraEnhance />,
                onClick: id => {
                  findFotoById(id, files => {
                    if (files && files.length > 0) {
                      setImages(files);
                      setOpenFotoViewer(true);
                    } else {
                      setOpenNotFotoModal(true);
                    }
                  });
                }
              },
              {
                key: 'validate',
                icon: <CheckCircleOutline />,
                show: row => isAdmin() && (row.validate === null || row.validate === 'AGUARDANDO_VALIDACAO'),
                onClick: id => {
                  findNotaById(id, nota => {
                    setValidateData({ id, validate: 'VALIDADO', message: "Deseja validar a nota?", showDespesaFatura: nota.tipoPagamento === "CARTAO_CORPORATIVO" });
                    setOpenValidate(true);
                  });
                }
              },
              {
                key: 'invalidate',
                icon: <NotInterested />,
                show: row => isAdmin() && (row.validate === null || row.validate === 'AGUARDANDO_VALIDACAO'),
                onClick: id => {
                  setValidateData({ id, validate: 'INVALIDADO', message: "Deseja invalidar a nota?", showDespesaFatura: false, title: "Confirmação" });
                  setOpenValidate(true);
                }
              },
            ]
          }
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          isAdmin={isAdmin()}
        />
      </Grid>
      <DialogDefault
        open={openDownloadNotas}
        handlerCancel={() => setOpenDownloadNotas(false)}
        handlerConfirm={() => {
          downloadNotas(localFilter, () => {
            setOpenDownloadNotas(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja baixar todas os arquivos das notas filtradas? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openValidate}
        handlerCancel={() => {
          setValidateData({});
          setOpenValidate(false);
        }}
        handlerConfirm={() => {
          validateNota(validateData, () => setReload(!reload));
          setOpenValidate(false);
        }}
        title="Confimação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> 
          <Grid>
            <Typography variant="h4">
              {validateData.message}
            </Typography>
          </Grid>
          {validateData.showDespesaFatura && <Grid style={{ paddingTop: 10 }}>
            <TrueFalseSelect
              label="Despesa na Fatura"
              name="despesaFatura"
              onChange={event => {
                setValidateData({
                  ...validateData,
                  despesaFatura: event.target.value
                })
              }}
              value={validateData.despesaFatura}
            />
          </Grid>}
        </DialogContentText>
      </DialogDefault>
      <Viewer
        visible={openFotoViewer}
        onClose={() => {
          setOpenFotoViewer(false);
          setImages([]);
        }}
        images={images}
        zIndex={3000}
        scalable={false}
        noImgDetails
        zoomSpeed={0.25}
        downloadable
      />
      <DialogDefault
        open={openNotFotoModal}
        handlerCancel={() => setOpenNotFotoModal(false)}
        readOnly
      >
        <DialogContentText> Nota não possui foto </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={() => {
          exportNota(localFilter, false, () => {
            setOpenExport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja exportar as notas filtradas? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openReport}
        handlerCancel={() => setOpenReport(false)}
        handlerConfirm={() => {
          exportNota(localFilter, true, () => {
            setOpenReport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja gerar o relatório das notas filtradas? </DialogContentText>
      </DialogDefault>
    </>
  );
};

Notas.propTypes = {
  deleteNota: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  exportNota: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userRole: selectorsAuth.getRole(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsNotas,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notas));