/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid } from '@material-ui/core';
import { Table, DefaultFiltro } from '../../components';
import * as selectorsAuth from '../../store/auth/reducer';
import * as selectorsAportes from '../../store/aporte/reducer';
import * as actionsAportes from '../../store/aporte/actions';
import Formulario from './Formulario';
import moment from 'moment';
import * as Role from '../../constants/role';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  iconValidate: {
    marginRight: theme.spacing(1),
    color: 'green'
  },
  iconInvalidate: {
    marginRight: theme.spacing(1),
    color: 'red'
  }
}));

const Aportes = ({ aportes, loadAportes, userRole, history, authData }) => {
  const [open, setOpen] = useState(false);
  const [titles] = useState(['Inspetor/Observador', 'Valor em Caixa', 'Último Aporte', 'Último Gasto']);
  const [localFilter, setLocalFilter] = useState({
    inicio: moment().startOf('week').valueOf(),
    fim: moment().endOf('day').valueOf()
  });

  const classes = useStyles();

  useEffect(() => {
    if (!isAdmin()) history.push(`/aporte/${authData.id}`);
  }, [authData]);

  useEffect(() => {
    loadAportes(localFilter)
  }, [loadAportes]);

  const onEdit = id => {
    history.push(`/aporte/${id}`);
  }

  const onAdd = () => {
    setOpen(true);
  }

  const onClose = isSave => {
    if (isSave) loadAportes(localFilter)
    setOpen(false);
  }

  const isAdmin = () => Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole;

  const renderRows = d => {
    return (
      <>
        <TableCell>{d.usuarioName}</TableCell>
        <TableCell>{d.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </TableCell>
        <TableCell>{d.dataUltimoAporte ? moment(Number(d.dataUltimoAporte)).format("DD/MM/YYYY") : "---"}</TableCell>
        <TableCell>{d.dataUltimoGasto ? moment(Number(d.dataUltimoGasto)).format("DD/MM/YYYY") : "---"}</TableCell>
      </>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useInspetor
            onFilter={filterData => {
              setLocalFilter(filterData);
              loadAportes(filterData);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={aportes}
          titles={titles}
          headerTitle="Aportes"
          deleteMessage="A aporte será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onAdd={onAdd}
          showDelete={false}
        />
        <Formulario
          id={null}
          open={open}
          onClose={onClose}
          isAdmin={isAdmin()}
        />
      </Grid>
    </>
  );
};

Aportes.propTypes = {
  deleteAporte: PropTypes.func.isRequired,
  loadAportes: PropTypes.func.isRequired,
  aportes: PropTypes.array,
  userRole: PropTypes.string.isRequired,
  authData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userRole: selectorsAuth.getRole(state),
  authData: selectorsAuth.getAuth(state),
  aportes: selectorsAportes.getAportes(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsAportes,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Aportes));