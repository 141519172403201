import * as Type from './actionsTypes';

const loadGerencias = filter => ({ type: Type.GERENCIAS_FIND, filter });

const deleteGerencia = id => ({ type: Type.GERENCIAS_DELETE, id });

const saveGerencia  = data => ({ type: Type.GERENCIAS_SAVE, data });

const updateGerencia = data => ({ type: Type.GERENCIAS_UPDATE, data });

const findOptions = malhaIds => ({ type: Type.GERENCIAS_OPTIONS, malhaIds });

export {
  loadGerencias,
  deleteGerencia,
  saveGerencia,
  updateGerencia,
  findOptions
}
