/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid } from '@material-ui/core';
import { Table, DefaultFiltro } from '../../components';
import * as selectorsUsuarioDocumentos from '../../store/usuarioDocumento/reducer';
import * as actionsUsuarioDocumentos from '../../store/usuarioDocumento/actions';
import Formulario from './Formulario';
import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';
import { getMaxColumnLength } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const validade = row => {
  if (row.possuiValidade) {
    return `Válido por ${row.validadeEmAnos} anos`
  } else {
    return "-"
  }
}

const UsuarioDocumentos = ({ usuarioDocumentos, loadUsuarioDocumentos, deleteUsuarioDocumento, authData }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['Nome', 'Tipo', 'Validade', 'Descrição']);
  const [localFilter, setLocalFilter] = useState({});

  const classes = useStyles();
  
  const renderRows = s => {
    return (
      <>
        <TableCell>{s.nome}</TableCell>
        <TableCell>{s.tipo === "DOCUMENTO" ? "Documento" : "Certificado"}</TableCell>
        <TableCell>{validade(s)}</TableCell>
        <TableCell>{getMaxColumnLength(s.descricao)}</TableCell>
      </>
    )
  }

  useEffect(() => {
    if (isAdmin()) {
      loadUsuarioDocumentos(localFilter);
    } else {
      loadUsuarioDocumentos({ ...localFilter, usuarioAtivo : true });  
    }

  }, [loadUsuarioDocumentos, localFilter, authData]);


  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteUsuarioDocumento(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = (isSave) => {
    if (isSave) {
      loadUsuarioDocumentos(localFilter);
    }
    setOpen(false);
    setId(null);
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <DefaultFiltro
          useTipoUsuarioDocumento
          usePossuiValidade
          useValidade
          onFilter={filterData => {
            setLocalFilter(filterData);
            loadUsuarioDocumentos(filterData);
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={usuarioDocumentos}
          titles={titles}
          headerTitle="Documentos de Usuário"
          deleteMessage="O documento será removido e não poderá ser mais recuperado."
          defaultRowsPerPage={15}
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          showDelete={false}
          showEdit={isAdmin()}
          showView={!isAdmin()}
          onView={onEdit}
          hasAdd={isAdmin()}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          readOnly={!isAdmin()}
          isAdmin={isAdmin()}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  usuarioDocumentos: selectorsUsuarioDocumentos.getUsuarioDocumentos(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsUsuarioDocumentos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsuarioDocumentos));