/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/usuarioDocumento/actionsTypes';
import BaseService from '../services/BaseService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import FileService from 'services/FileService';

function* loadUsuarioDocumentos({ filter }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'usuario-documentos/filtro', data: filter });
    yield put({
      type: TypeActions.FETCH_USUARIO_DOCUMENTOS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* findOptions({ tipoDocumento }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    let url = 'usuario-documentos/options';
    if (tipoDocumento) {
      url = `${url}?tipoDocumento=${tipoDocumento}`;
    }

    const response = yield BaseService.get({ url });
    yield put({
      type: TypeActions.USUARIO_DOCUMENTO_FIND_OPTIONS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'usuario-documentos', data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    yield put({
      type: TypeActions.SAVE_USUARIO_DOCUMENTO_SUCCESS,
      data: response.data
    });

    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Documento salvo com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao salvar o documento!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `usuario-documentos/${data.id}`, data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    yield put({
      type: TypeActions.UPDATE_USUARIO_DOCUMENTO_SUCCESS,
      data: response.data
    });

    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Usuário salvo com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = 'Erro ao salvar o usuário!';
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* deleteUsuarioDocumento({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.delete({ url: `usuario-documentos/${id}` });
    yield put({
      type: TypeActions.DELETE_USUARIO_DOCUMENTO_SUCCESS,
      id
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Usuário deletado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao deletar o usuário!'
    });
  }
}

function* findUsuarioDocumentoById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `usuario-documentos/${id}` });
    yield put({
      type: TypeActions.USUARIO_DOCUMENTO_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar o usuário!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_USUARIO_DOCUMENTOS, loadUsuarioDocumentos);
  yield takeLatest(TypeActions.USUARIO_DOCUMENTO_FIND_OPTIONS, findOptions);
  yield takeLatest(TypeActions.SAVE_USUARIO_DOCUMENTO, save);
  yield takeLatest(TypeActions.UPDATE_USUARIO_DOCUMENTO, update);
  yield takeLatest(TypeActions.DELETE_USUARIO_DOCUMENTO, deleteUsuarioDocumento);
  yield takeLatest(TypeActions.USUARIO_DOCUMENTO_FIND_BY_ID, findUsuarioDocumentoById);
}