import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import InputGroup from '../InputGroup';
import CreatableSelect from 'react-select/creatable';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const customStyles = {
  control: base => ({
    ...base,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  }),
  container: provided => ({
    ...provided,
    marginTop: 8
  }),
  menu: provided => ({
    ...provided,
    marginTop: 1,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  })
}

const useStyles = makeStyles(() => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
}));

const CreatableMultiSelect = ({ label, onChange, placement, title, useTooltip, required, options, defaultValue }) => {
  const classes = useStyles();

  // eslint-disable-next-line react/no-multi-comp
  const DropdownIndicator = () => (<ArrowDropDownIcon className={classes.arrowIcon}/>)
  const IndicatorSeparator = () => '';

  return (
    <InputGroup
      label={label}
      placement={placement}
      title={title}
      useTooltip={useTooltip}
      isRequired={required}
    >
      <CreatableSelect
        components={{ DropdownIndicator, IndicatorSeparator }}
        isMulti
        styles={customStyles}
        options={options}
        onChange={onChange}
        value={defaultValue}
        placeholder="Selecione..."
        noOptionsMessage={() => 'Sem opções'}
        formatCreateLabel={(inputText) => `Criar "${inputText}"`}
        menuPlacement="auto"
      />
    </InputGroup>
  );
}

CreatableMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.array.isRequired,
  placement: PropTypes.string,
  title: PropTypes.string,
  useTooltip: PropTypes.bool,
  required: PropTypes.bool
};

CreatableMultiSelect.defaultProps = {
  placement: '',
  title: '',
  useTooltip: false,
  required: false
}

export default CreatableMultiSelect;