import * as Type from './actionsTypes';

const INITIAL_STATE = {
  selectedInspecaoVeicular: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.INSPECAO_VEICULAR_FIND_ONE_SUCCESS:
    case Type.INSPECAO_VEICULAR_UPDATE_SUCCESS:
    case Type.INSPECAO_VEICULAR_SAVE_SUCCESS: {
      return {
        ...state,
        selectedInspecaoVeicular: action.data
      }
    }
    case Type.INSPECAO_VEICULAR_CLEAR_SELECTED: {
      return {
        ...state,
        selectedInspecaoVeicular: {}
      }
    }
    case Type.INSPECAO_VEICULAR_CLEAN: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getSelectedInspecaoVeicular = state => state.rootState.inspecaoVeicular.selectedInspecaoVeicular;
