/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import InputGroup from '../InputGroup';

const NivelSelect = ({ label, onChange, value, placement, title, useTooltip, required }) => {
  return (
    <InputGroup
      label={label}
      placement={placement}
      title={title}
      useTooltip={useTooltip}
      isRequired={required}
    >
      <TextField
        fullWidth
        margin="dense"
        name={label.toLowerCase()}
        onChange={event => onChange(event.target.value)}
        select
        SelectProps={{ native: true }}
        value={value}
        variant="outlined"
      >
        <option key="NA" value={null}>Selecione...</option>
        <option key="BAIXO" value="BAIXO">Baixo</option>
        <option key="MEDIO" value="MEDIO">Médio</option>
        <option key="ALTO" value="ALTO">Alto</option>
      </TextField>
    </InputGroup>
  );
}

NivelSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placement: PropTypes.string,
  title: PropTypes.string,
  useTooltip: PropTypes.bool,
  required: PropTypes.bool,
};

NivelSelect.defaultProps = {
  title: '',
  placement: 'top',
  required: false,
  useTooltip: false,
  value: ''
};

export default NivelSelect;