/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as Roles from '../../constants/role';
import * as selectorsAportes from '../../store/aporte/reducer';
import * as actionsAportes from '../../store/aporte/actions';

import * as selectorsAuth from '../../store/auth/reducer';
import moment from 'moment';

import { Input, DialogDefault, DatePicker, NumberInput, UsuarioSelect, ArquivoContainer } from '../../components';

const schema = {
  valor: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  inspetorId: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  buttonContainer: {
    width: '100%',
  }
}));

const Formulario = ({ saveAporte, updateAporte, selectedAporte, open, id, onClose, findAporteById, authData, inspetorId, isEdit }) => {

  const classes = useStyles();

  const [values, setValues] = useState({ 
    data: moment().valueOf(),
    dataTimestamp: moment().valueOf(),
    dataInclusao: moment().valueOf(),
    dataInclusaoTimestamp: moment().valueOf(),
    usuarioId: authData.id,
    usuarioName: authData.nome,
    arquivos: []
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) findAporteById(id);
  }, [id, findAporteById]);

  useEffect(() => {
    if (id && selectedAporte && id === selectedAporte.id) {
      setValues(selectedAporte);
    } else {
      setValues({ 
        data: moment().valueOf(),
        dataTimestamp: moment().valueOf(),
        dataInclusao: moment().valueOf(),
        dataInclusaoTimestamp: moment().valueOf(),
        usuarioName: authData.nome,
        usuarioId: authData.id,
        arquivos: []
      });
    }
  }, [id, selectedAporte, authData]);

  useEffect(() => {
    if (inspetorId) setValues({...values, inspetorId });
  }, [inspetorId]);

  const onSave = () => {
    const erros = validate(values, schema) || {};

    setErrors(erros);
    if (!erros || Object.keys(erros).length === 0) {
      if (values.id) updateAporte(values, saveCallback);
      else saveAporte(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({})
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Aporte"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={6} className={classes.fieldContainer}>
          <UsuarioSelect
            label="Inspetor/Ajudante"
            onSelect={inspetorId => setValues({
              ...values,
              inspetorId
            })}
            ativo
            grupos={[Roles.ADMIN, Roles.INSPETOR]}
            value={values.inspetorId}
            isRequired
            disabled={!isEdit && inspetorId}
            error={errors && errors.inspetorId}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.valor}
            isRequired
            label="Valor"
            onChange={valor => setValues({
              ...values,
              valor
            })}
            value={values.valor}
            format="##,######"
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DatePicker
            label="Data"
            onChange={data => setValues({
              ...values,
              data: data.valueOf()
            })}
            value={values.data}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Criado Por"
            value={values.usuarioName}
            disabled
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Inclusão"
            value={values.dataInclusaoTimestamp ? 
              moment(Number(values.dataInclusaoTimestamp)).format("DD/MM/YYYY HH:mm") : moment().format("DD/MM/YYYY HH:mm")}
            disabled
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Observação"
            name="descricao"
            onChange={handleChange}
            value={values.descricao}
            rows={3}
            multiline
          />
        </Grid>
        <ArquivoContainer 
          arquivos={values.arquivos}
          onSave={arquivos => setValues({ ...values, arquivos })}
        />
      </Grid>
    </DialogDefault>
  );
};

Formulario.propTypes = {
  findAporteById: PropTypes.func.isRequired,
  saveAporte: PropTypes.func.isRequired,
  updateAporte: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  inspetorId: PropTypes.string,
  isEdit: PropTypes.bool.isRequired
};

Formulario.defaultValue = {
  selectedAporte: null,
  id: null,
  inspetorId: null,
}

const mapStateToProps = state => ({
  selectedAporte: selectorsAportes.getSelectedAporte(state),
  authData: selectorsAuth.getAuth(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsAportes,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
