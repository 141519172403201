import * as Type from './actionsTypes';

const saveInspecaoVeicular  = data => ({ type: Type.INSPECAO_VEICULAR_SAVE, data });

const updateInspecaoVeicular = (id, data) => ({ type: Type.INSPECAO_VEICULAR_UPDATE, id, data });

const findInspecaoVeicularById = id => ({ type: Type.INSPECAO_VEICULAR_FIND_ONE, id });

const inspecaoVeicularClearSelected = () => ({ type: Type.INSPECAO_VEICULAR_CLEAR_SELECTED });

const inspecaoVeicularReport = (filtro, callback) => ({ type: Type.INSPECAO_VEICULAR_REPORT, filtro, callback });

export {
  saveInspecaoVeicular,
  updateInspecaoVeicular,
  findInspecaoVeicularById,
  inspecaoVeicularClearSelected,
  inspecaoVeicularReport
}
