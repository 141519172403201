import * as Type from './actionsTypes';

const loadPedidos = filtro => ({ type: Type.FETCH_PEDIDOS, filtro });

const deletePedido = id => ({ type: Type.DELETE_PEDIDO, id });

const savePedido = (data, callback) => ({ type: Type.SAVE_PEDIDO, data, callback });

const updatePedido = (data, callback) => ({ type: Type.UPDATE_PEDIDO, data, callback });

const findPedidoById = id => ({ type: Type.PEDIDO_FIND_BY_ID, id });

const exportPedido = (filter, report, callbackAfterSave) => ({ type: Type.PEDIDO_EXPORT, filter, report, callbackAfterSave });

export {
  loadPedidos,
  deletePedido,
  savePedido,
  updatePedido,
  findPedidoById,
  exportPedido
}
