/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, Button, Menu, MenuItem, DialogContentText } from '@material-ui/core';
import { Table, UploadDialog, DefaultFiltro, DialogDefault } from '../../components';
import * as selectorsControleCaixas from '../../store/controleCaixa/reducer';
import * as actionsControleCaixas from '../../store/controleCaixa/actions';
import * as actionsToast from '../../store/toast/actions';
import FormularioReceita from './FormularioReceita';
import FormularioDespesas from './FormularioDespesas';
import { MonetizationOn } from '@material-ui/icons';
import moment from 'moment';
import { getMaxColumnLength } from '../../utils';
import * as XLSX from 'xlsx';

import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  button: {
    marginRight: 15,
    marginTop: 5
  },
}));

const ControleCaixa = ({ controleCaixas, loadControleCaixas, deleteControleCaixa, authData, importControleCaixa, messageError, exportControleCaixa }) => {
  const [openDespesas, setOpenDespesas] = useState(false);
  const [openReceitas, setOpenReceitas] = useState(false);
  const [openImportDespesa, setOpenImportDespesa] = useState(false);
  const [openImportReceita, setOpenImportReceita] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [idDespesa, setIdDespesa] = useState(null);
  const [id, setId] = useState(null);
  const [titles] = useState(['', 'Data da Emissão/Pagamento', 'Valor Líquido', 'Status', 'Forma Pagamento', 'Tipo Despesa', 'Descrição']);
  const classes = useStyles();
  const [localFilter, setLocalFilter] = useState({
    inicio: moment().subtract(1, 'year').startOf('year').valueOf(),
    fim: moment().endOf('day').valueOf()
  });
  const [anchorNovo, setAnchorNovo] = useState(null);
  const [anchorImportar, setAnchorImportar] = useState(null);

  const renderRows = r => {
    const background = r.tipo === "DESPESA"? "#CC0000" : "green";
    return (
      <>
        <TableCell size="small">
          <div style={{ display: 'flex' }}>
            <MonetizationOn style={{color: background}} />
          </div>
        </TableCell>
        <TableCell size="small">{moment(Number(r.dataDocumento)).format("DD/MM/YYYY")}</TableCell>
        <TableCell size="small">{r.valorLiquido.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </TableCell>
        <TableCell size="small">{r.status}</TableCell>
        <TableCell size="small">{r.formaPagamento}</TableCell>
        <TableCell size="small">{r.tipoDespesa}</TableCell>
        <TableCell size="medium" title={r.descricao}>{getMaxColumnLength(r.descricao)}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadControleCaixas({
      inicio: moment().subtract(1, 'year').startOf('year').valueOf(),
      fim: moment().endOf('day').valueOf()
    })
  }, [loadControleCaixas]);

  const onEdit = id => {
    const controleCaixaSelected = controleCaixas.filter(a => a.id === id)[0];
    if (controleCaixaSelected.tipo === "DESPESA") {
      setIdDespesa(id);
      setOpenDespesas(true);
    } else {
      setId(id);
      setOpenReceitas(true);
    }
  }

  const onDelete = id => deleteControleCaixa(id);

  const onClose = (isSave) => {
    if (isSave) {
      loadControleCaixas(localFilter);
    } else {
      setOpenDespesas(false);
      setOpenReceitas(false);
      setId(null);
      setIdDespesa(null);
    }
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getHeaderActions = () => {
    let actions = [];

    if (isAdmin()) {
      actions = [
        { 
          isComponent: true,
          component: (<>
            <Button color="primary"  variant="contained"  className={classes.button} onClick={(event) => setAnchorNovo(event.currentTarget)}>
              NOVO
            </Button>
            <Menu anchorEl={anchorNovo} keepMounted open={Boolean(anchorNovo)} onClose={() => setAnchorNovo(null)}>
              <MenuItem onClick={() => { setOpenDespesas(true); setAnchorNovo(null); }}>
                Despesa
              </MenuItem>
              <MenuItem onClick={() => { setOpenReceitas(true); setAnchorNovo(null); }}>
                Receita
              </MenuItem>
            </Menu>
          </>)
        },
        { 
          isComponent: true,
          component: (<>
            <Button color="primary"  variant="contained"  className={classes.button} onClick={(event) => setAnchorImportar(event.currentTarget)}>
              IMPORTAR
            </Button>
            <Menu anchorEl={anchorImportar} keepMounted open={Boolean(anchorImportar)} onClose={() => setAnchorImportar(null)}>
              <MenuItem onClick={() => { setOpenImportDespesa(true); setAnchorImportar(null); }}>
                Despesa
              </MenuItem>
              <MenuItem onClick={() => { setOpenImportReceita(true); setAnchorImportar(null); }}>
                Receita
              </MenuItem>
            </Menu>
          </>)
        },
        {
          title: 'Exportar',
          onClick: () => setOpenExport(true)
        }
      ]
    }

    return actions;
  }

  const onImportDespesas = files => {
    const reader = new FileReader();

    reader.onload = e => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });

      if (workbook.SheetNames.includes('Despesas')) {
        let controles = [];
        let excelData = XLSX.utils.sheet_to_json(workbook.Sheets['Despesas'], {header:1}).slice(1);
        for (let index in excelData) {
          const row = excelData[index];
          if (row.length === 0) continue;
          controles.push(row);
        }
        importControleCaixa({ controles }, "despesas", () => { setOpenImportDespesa(false); loadControleCaixas(localFilter); });
      } else {
        messageError({ message: "Planilha inválida. Por favor, utilize a planilha 'Planilha Importação de Despesas' que se encontra na aba de Documentos!" });
      }
    };

    reader.onerror = function(ex) {
      messageError({ message: 'Erro ao abrir a planilha. Por favor, tente novamente!' });
    };
  
    reader.readAsBinaryString(files[0]);
  }

  const onImportReceitas = files => {
    const reader = new FileReader();

    reader.onload = e => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });

      if (workbook.SheetNames.includes('Receitas')) {
        let controles = [];
        let excelData = XLSX.utils.sheet_to_json(workbook.Sheets['Receitas'], {header:1}).slice(1);
        for (let index in excelData) {
          const row = excelData[index];
          if (row.length === 0) continue;
          controles.push(row);
        }
        importControleCaixa({ controles }, "receitas", () => { 
          loadControleCaixas(localFilter); 
          setOpenImportReceita(false); 
        });
      } else {
        messageError({ message: "Planilha inválida. Por favor, utilize a planilha 'Planilha Importação de Receitas' que se encontra na aba de Documentos!" });
      }
    };

    reader.onerror = function(ex) {
      messageError({ message: 'Erro ao abrir a planilha. Por favor, tente novamente!' });
    };
  
    reader.readAsBinaryString(files[0]);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useDates
            useTipoControleCaixa
            useTipoDespesas
            useTipoFormaPagamento
            useControleCaixaStatus
            onFilter={filterData => {
              setLocalFilter(filterData);
              loadControleCaixas(filterData);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={controleCaixas}
          titles={titles}
          headerTitle="Controle de Caixa"
          deleteMessage="A informação será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          onEdit={onEdit}
          onDelete={onDelete}
          showDelete={false}
          hasRowActions={isAdmin()}
          hasAdd={false}
          headerActions={getHeaderActions()}
        />
        <FormularioDespesas
          id={idDespesa}
          open={openDespesas}
          onClose={onClose}
        />
        <FormularioReceita
          id={id}
          open={openReceitas}
          onClose={onClose}
        />
        <UploadDialog
          onClose={() => setOpenImportDespesa(false)}
          onSave={onImportDespesas}
          open={openImportDespesa}
          acceptedFiles={['.xls','.xlsx']}
          dialogTitle="Importar Despesa"
        />
        <UploadDialog
          onClose={() => setOpenImportReceita(false)}
          onSave={onImportReceitas}
          open={openImportReceita}
          acceptedFiles={['.xls','.xlsx']}
          dialogTitle="Importar Receita"
        />
        <DialogDefault
          open={openExport}
          useFullScreen={false}
          handlerCancel={() => setOpenExport(false)}
          handlerConfirm={() => {
            exportControleCaixa(localFilter, () => setOpenExport(false));
          }}
          title="Confirmação"
          confirmMessage="Sim"
          cancelMessage="Não"
        >
          <DialogContentText> Deseja exportar todas as informações de receitas e despesas filtradas? </DialogContentText>
        </DialogDefault>
      </Grid>
    </>
  );
};

ControleCaixa.propTypes = {
  deleteControleCaixa: PropTypes.func.isRequired,
  loadControleCaixas: PropTypes.func.isRequired,
  controleCaixas: PropTypes.array,
  importControleCaixa: PropTypes.func.isRequired,
  messageError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  controleCaixas: selectorsControleCaixas.getControleCaixas(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsControleCaixas,
  ...actionsToast
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ControleCaixa));