import * as Type from './actionsTypes';

const loadControlePontoUsuarios = filtro => ({ type: Type.FETCH_CONTROLE_PONTO_USUARIOS, filtro });

const loadControlePontosByUsuarioId = (usuarioId, filtro) => ({ type: Type.FETCH_CONTROLE_PONTOS_BY_USUARIO_ID, usuarioId, filtro });

const cleanSelectedControlePonto = () => ({ type: Type.CLEAR_SELECTED_CONTROLE_PONTO });

const saveControlePontos = (data, callback) => ({ type: Type.SAVE_CONTROLE_PONTOS, data, callback });

const exportControlePonto = (filtro, callback) => ({ type: Type.EXPORT_CONTROLE_PONTOS, filtro, callback });

const reportControlePontoByUsuarioId = (usuarioId, filtro, callback) => ({ type: Type.REPORT_CONTROLE_PONTOS_USUARIO_ID, usuarioId, filtro, callback });

export {
  loadControlePontoUsuarios,
  loadControlePontosByUsuarioId,
  cleanSelectedControlePonto,
  saveControlePontos,
  exportControlePonto,
  reportControlePontoByUsuarioId
}
