/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/documento/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';

function* loadDocumentos({ modulo = null, ativo = null }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `documentos?modulo=${modulo || ''}&ativo=${ativo || ''}` });
    yield put({
      type: TypeActions.DOCUMENTOS_FIND_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar os documentos!'
    });
  }
}

function* download({ documento }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    if (documento.modulo === 'CONFIGURACAO') {
      const response =  yield BaseService.execute({ 
        url: `documentos/${documento.id}/download`,
        method: 'GET',
        options: {
          responseType: 'blob'
        }
      });
      yield fileDownload(response.data, `${documento.nome}.${documento.extensao}`);
    } else {

      const response =  yield BaseService.get({ url: `documentos/${documento.id}/download/arquivo` });
      const responseFile = yield FileService.getFile(response.data.url);

      yield fileDownload(responseFile.data, response.data.title);
    }

    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: `Donwload do documento ${documento.nome}.${documento.extensao} iniciado com sucesso!`
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: `Erro ao fazer o download do documento ${documento.nome}.${documento.extensao}`
    });
  }
}

function* viewById({ documento }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });

    let file = null
    if (documento.modulo === 'CONFIGURACAO') {
      const response =  yield BaseService.execute({ 
        url: `documentos/${documento.id}/download`,
        method: 'GET',
        options: {
          responseType: 'blob'
        }
      });
      file = response.data
    } else {

      const response =  yield BaseService.get({ url: `documentos/${documento.id}/download/arquivo` });
      file = yield FileService.getFile(response.data.url);
      file = yield FileService.compress(file.data);
    }

    yield put({ type: TypeActions.DOCUMENTOS_VIEW_BY_ID_SUCCESS, data: { file, nome: documento.nome } });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: `Erro ao fazer o download do documento ${documento.nome}.${documento.extensao}`
    });
  }
}

function* saveDocumento({ documento, saveCallback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'documentos', data: documento });

    const validateArquivoDTO = [];
    try {
      yield FileService.updateFile(response.data.arquivo.url, documento.arquivoFile);
      validateArquivoDTO.push({id: response.data.arquivo.id, valid: true });
    } catch(error) {
      yield console.error(error);
      validateArquivoDTO.push({id: response.data.arquivo.id, valid: false });
    }

    yield BaseService.post({ url: 'arquivos/validate', data: {arquivos: validateArquivoDTO} });

    yield saveCallback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });

    yield put({
      type: TypeActions.DOCUMENTOS_SAVE_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar os documentos!'
    });
  }
}

function* findById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `documentos/${id}` });

    if (response.data && response.data.arquivo && response.data.arquivo.url) {
      try {
        const responseFile = yield FileService.getFile(response.data.arquivo.url);
        if (responseFile) {
          responseFile.data.name = `${response.data.arquivo.nome}.${response.data.arquivo.extensao}`;
          response.data.arquivoFile = responseFile.data;
        }
      } catch(e) {
        yield console.error(e);
      }
    }
    
    yield put({
      type: TypeActions.DOCUMENTOS_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.DOCUMENTOS_FIND, loadDocumentos);
  yield takeLatest(TypeActions.DOCUMENTOS_DOWNLOAD, download);
  yield takeLatest(TypeActions.DOCUMENTOS_SAVE, saveDocumento);
  yield takeLatest(TypeActions.DOCUMENTOS_FIND_BY_ID, findById);
  yield takeLatest(TypeActions.DOCUMENTOS_VIEW_BY_ID, viewById);
}