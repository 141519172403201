/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/alimentador/actionsTypes';
import BaseService from '../services/BaseService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import { sortArray } from '../utils';

function* load({ filter }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'alimentadores/filter', data: filter });
    yield put({
      type: TypeActions.ALIMENTADORES_FIND_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* findOptions({ malhaId, gerenciaId, poloId, subestacaoId }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `alimentadores/options?malhaId=${malhaId}&gerenciaId=${gerenciaId}&poloId=${poloId}&subestacaoId=${subestacaoId}` });
    yield put({
      type: TypeActions.ALIMENTADORES_OPTIONS_SUCCESS,
      data: response.data.sort((a, b) => sortArray(a, b, 'codigo'))
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'alimentadores', data });
    yield put({
      type: TypeActions.ALIMENTADORES_SAVE_SUCCESS,
      data: response.data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* update({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `alimentadores/${data.id}`, data });
    yield put({
      type: TypeActions.ALIMENTADORES_UPDATE_SUCCESS,
      data: response.data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* deleteById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.delete({ url: `alimentadores/${id}`});
    yield put({
      type: TypeActions.ALIMENTADORES_DELETE_SUCCESS,
      id
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações removidas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao deletar deletar!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.ALIMENTADORES_OPTIONS, findOptions);
  yield takeLatest(TypeActions.ALIMENTADORES_FIND, load);
  yield takeLatest(TypeActions.ALIMENTADORES_SAVE, save);
  yield takeLatest(TypeActions.ALIMENTADORES_UPDATE, update);
  yield takeLatest(TypeActions.ALIMENTADORES_DELETE, deleteById);
}