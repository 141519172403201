/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault } from '../../../components';
import * as selectorsNotas from '../../../store/nota/reducer';
import * as actionsNotas from '../../../store/nota/actions';
import ProdutoFormulario from './ProdutoFormulario';
import moment from 'moment';
import { codeFormatter } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  iconValidate: {
    marginRight: theme.spacing(1),
    color: 'green'
  },
  iconInvalidate: {
    marginRight: theme.spacing(1),
    color: 'red'
  }
}));

const Produtos = ({ notas, deleteNota, exportNota }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['Produto', 'Quantidade', 'Possui Tamanho', 'Descrição']);
  const [localFilter, setLocalFilter] = useState({
    codigo: undefined
  });
  const [openReport, setOpenReport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [reload, setReload] = useState(null);

  const classes = useStyles();
  
  const renderRows = d => {
    return (
      <>
        <TableCell size="small">{d.nome}<br/>({codeFormatter(d.codigo)})</TableCell>
        <TableCell size="small">{d.estoqueTotal || '0'}</TableCell>
        <TableCell size="small">{d.possuiTamanho === "true" || d.possuiTamanho === true ? "Sim" : "Não"}</TableCell>
        <TableCell size="small">{d.descricao}</TableCell>
      </>
    )
  }

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteNota(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = (isSave) => {
    setOpen(false);
    setId(null);

    if (isSave) {
      setReload(true);
    }
  }

  const getHeaderActions = () => {
    let actions = [
      { 
        title: 'Exportar',
        onClick: () => setOpenExport(true)
      },
      { 
        title: 'Relatório',
        onClick: () => setOpenReport(true)
      }
    ];

    return actions;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useCodigo
            codigoLabel="Código do Produto"
            mdCodigo="6"
            useNome
            nomeLabel="Nome do Produto"
            mdNome="6"
            filterData={localFilter}
            onFilter={filter => {
              setLocalFilter(filter);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={notas}
          titles={titles}
          headerTitle="Produtos"
          deleteMessage="O produto será removido e não poderá ser mais recuperado."
          showDelete={false}
          onDelete={onDelete}
          renderRows={renderRows}
          onEdit={onEdit}
          hasRowActions
          onAdd={onAdd}
          // headerActions={getHeaderActions()}
          useFilterData
          usePaginationApi
          paginationApi="produtos/filter"
          paginationUrl="produtos"
          filterData={localFilter}
          reload={reload}
        />
        <ProdutoFormulario
          id={id}
          open={open}
          onClose={onClose}
        />
      </Grid>
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={() => {
          exportNota(localFilter, false, () => {
            setOpenExport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja exportar as notas filtradas? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openReport}
        handlerCancel={() => setOpenReport(false)}
        handlerConfirm={() => {
          exportNota(localFilter, true, () => {
            setOpenReport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja gerar o relatório das notas filtradas? </DialogContentText>
      </DialogDefault>
    </>
  );
};

Produtos.propTypes = {
  deleteNota: PropTypes.func.isRequired,
  notas: PropTypes.array,
  userRole: PropTypes.string.isRequired,
  exportNota: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notas: selectorsNotas.getNotas(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsNotas,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Produtos));