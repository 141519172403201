export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const SAVE_USER = 'SAVE_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const CLEAR_USER = 'CLEAR_USER';

export const USUARIO_FIND_BY_ID = 'USUARIO_FIND_BY_ID';
export const USUARIO_FIND_BY_ID_SUCCESS = 'USUARIO_FIND_BY_ID_SUCCESS';

export const USUARIO_FIND_OPTIONS = 'USUARIO_FIND_OPTIONS';
export const USUARIO_FIND_OPTIONS_SUCCESS = 'USUARIO_FIND_OPTIONS_SUCCESS';

export const USUARIO_FIND_INSPETORES_OPTIONS = 'USUARIO_FIND_INSPETORES_OPTIONS';
export const USUARIO_FIND_INSPETORES_OPTIONS_SUCCESS = 'USUARIO_FIND_INSPETORES_OPTIONS_SUCCESS';

export const USUARIO_FIND_DIGITADORES_OPTIONS = 'USUARIO_FIND_DIGITADORES_OPTIONS';
export const USUARIO_FIND_DIGITADORES_OPTIONS_SUCCESS = 'USUARIO_FIND_DIGITADORES_OPTIONS_SUCCESS';

export const USUARIO_RESET_PASSWORD = 'USUARIO_RESET_PASSWORD';

export const USUARIO_PROXIMA_MATRICULA = 'USUARIO_PROXIMA_MATRICULA';
export const USUARIO_PROXIMA_MATRICULA_SUCCESS = 'USUARIO_PROXIMA_MATRICULA_SUCCESS';

export const USUARIO_SET_PROXIMA_MATRICULA = 'USUARIO_SET_PROXIMA_MATRICULA';
export const USUARIO_EXPORT = 'USUARIO_EXPORT';