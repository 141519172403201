/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropzoneDialog } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/styles';
import { Grid, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import fileDownload from 'js-file-download';
import Viewer from 'react-viewer';

const useStyles = makeStyles(theme => ({
  image: {
    height: 50
  },
  modalImg: {
    height: 150,
    [theme.breakpoints.up('sm')]: {
      height: 250
    },
    [theme.breakpoints.up('md')]: {
      height: 350
    },
    [theme.breakpoints.up('lg')]: {
      height: 500
    }
  },
  modalRoot: {
    height: '100%'
  },
  file: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline'
  },
  error: {
    color: '#e53935',
    fontSize: 11,
    margin: '8px 12px 0',
    minHeight: '1em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: 0.33
  }
}));

const Dropzone = ({ onSave, acceptedFiles, showPreviews, maxFileSize, buttonText, errorMessage, filesLimit, 
  disabled, showGridFiles, files, canDeleteFiles, renderFotos, error, buttonClassName, showImageViewer }) => {
    
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  
  const onSaveLocal = files => {
    setOpen(false);
    onSave(files);
  }

  return (
    <>
      <Grid item xs={12}>
        <Button 
          color="secondary"
          variant="contained"
          onClick={() => setOpen(true)}
          disabled={disabled}
          className={buttonClassName}
        >
          {buttonText}
        </Button>
      </Grid>
      {showGridFiles && <Grid item xs={12}>
        {files && files.map((file, index) => {
          return (
            <>
            {renderFotos ?
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={4} key={index}> 
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12}> 
                      <Grid container direction="row" justify="center" alignItems="center">
                        <img alt="" src={file.imageSrc} className={classes.image} onClick={() => {
                          if (showImageViewer) {
                            setOpenImageViewer(true);
                          } else {
                            file.onClick(file);
                          }
                        }} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}> 
                      <Grid container direction="row" justify="center" alignItems="center">
                        <p> 
                          {file.nome}
                          {canDeleteFiles && <IconButton onClick={() => file.onDelete(file)}> <DeleteIcon/> </IconButton>}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid item xs={12}> 
                <Grid container direction="row" justify="left" alignItems="center">
                  <p> 
                    * <span className={classes.file} onClick={() => {
                          if (showImageViewer) {
                            setOpenImageViewer(true);
                          } else {
                            fileDownload(file.file, file.nome)
                          }
                        }}> {file.nome} </span>
                    {!disabled && canDeleteFiles && <IconButton onClick={() => file.onDelete(file)}> <DeleteIcon/> </IconButton>}
                  </p>
                </Grid>
              </Grid>
            }
          </>
          )
        })}
        {error && <Grid item xs={12}> 
          <Grid container direction="row" justify="left" alignItems="center">
            <p className={classes.error}> 
              {errorMessage ? errorMessage : `${buttonText} é obrigatório`}
            </p>
          </Grid>
        </Grid>}
      </Grid>}
      <DropzoneDialog
        open={open}
        onSave={onSaveLocal}
        acceptedFiles={acceptedFiles}
        showPreviews={showPreviews}
        maxFileSize={maxFileSize}
        onClose={() => setOpen(false)}
        filesLimit={filesLimit}
        previewText="Pré-visualização: "
        dialogTitle="Selecionar arquivos"
        cancelButtonText="Cancelar"
        submitButtonText="Salvar"
        dropzoneText="Arraste e solte um arquivo aqui ou clique"
        maxWidth="md"
        getFileAddedMessage={fileName => `Arquivo ${fileName} adicionado com sucesso`}
        getFileRemovedMessage={fileName => `Arquivo ${fileName} removido`}
        getFileLimitExceedMessage={filesLimit => `Número máximo permitido de arquivos excedido. Apenas ${filesLimit} permitidos`}
        getDropRejectMessage={rejectedFile => `Arquivo ${rejectedFile.name} foi rejeitado. Arquivo não suportado.`}
      />
      <Viewer
        visible={openImageViewer}
        onClose={() => setOpenImageViewer(false)}
        images={files}
        zIndex={3000}
        scalable={false}
        noImgDetails
        zoomSpeed={0.25}
      />
    </>
  );
}

Dropzone.propTypes = {
  acceptedFiles: PropTypes.array,
  showPreviews: PropTypes.bool,
  maxFileSize: PropTypes.number,
  buttonText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  filesLimit: PropTypes.number,
  disabled: PropTypes.bool,
  showGridFiles: PropTypes.bool,
  files: PropTypes.array,
  canDeleteFiles: PropTypes.bool,
  renderFotos: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  showImageViewer: PropTypes.bool
};

Dropzone.defaultProps = {
  acceptedFiles: ['image/jpeg', 'image/png', 'image/bmp'],
  showPreviews: true,
  maxFileSize: 5000000,
  buttonText: 'Adicionar Imagens',
  filesLimit: 10,
  disabled: false,
  showGridFiles: false,
  files: [],
  canDeleteFiles: false,
  renderFotos: false,
  error: false,
  errorMessage: null,
  showImageViewer: false
};

export default Dropzone;