import * as Type from './actionsTypes';

const login = (authData, callback) => ({ type: Type.FETCH_AUTH, authData, callback });

const forgotPassword = forgotData => ({ type: Type.FETCH_FORGOT_PASSWORD, forgotData });

const updatePassword = (id, data, callback, showMessage) => ({ type: Type.UPDATE_PASSWORD, id, data, callback, showMessage });

const updateProfile = data => ({ type: Type.UPDATE_PROFILE, data });

const logout = history => ({ type: Type.AUTH_LOGOUT, history });

export {
  login,
  forgotPassword,
  updatePassword,
  updateProfile,
  logout,
}