import * as Type from './actionsTypes';
import {ordenarPorCodigoString} from '../../utils';

const INITIAL_STATE = {
  gerencias: [],
  options: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.GERENCIAS_FIND_SUCCESS: {
      return {
        ...state,
        gerencias: action.data.sort(ordenarPorCodigoString)
      }
    }
    case Type.GERENCIAS_DELETE_SUCCESS: {
      return {
        ...state,
        gerencias: state.gerencias.filter(a => a.id !== action.data).sort(ordenarPorCodigoString)
      }
    }
    case Type.GERENCIAS_SAVE_SUCCESS: {
      return {
        ...state,
        gerencias: [...state.gerencias, action.data].sort(ordenarPorCodigoString)
      }
    }
    case Type.GERENCIAS_UPDATE_SUCCESS: {
      return {
        ...state,
        gerencias: [...state.gerencias.filter(s => s.id !== action.data.id), action.data].sort(ordenarPorCodigoString)
      }
    }
    case Type.GERENCIAS_CLEAN: {
      return INITIAL_STATE;
    }
    case Type.GERENCIAS_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data.sort(ordenarPorCodigoString)
      }
    }
    default:
      return state;
  }
}

export const getGerencias = state => state.rootState.gerencia.gerencias;
export const getGerenciasOptions = state => state.rootState.gerencia.options;
