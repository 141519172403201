/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { 
  CidadeInput, 
  EstadoInput, 
  Input,
} from '../index';

const EnderecoForm = ({ entity, onChange }) => {
  const [values, setValues] = useState({});
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [estadoChanged, setEstadoChanged] = useState(false);

  useEffect(() => {
    if (entity && entity.endereco) setValues(entity.endereco);
  }, [entity]);

  useEffect(() => {
    if (values) {
      setEstado(values.estado);
      setCidade(values.cidade);
    } 
  }, [values]);

  useEffect(() => {
    if (estadoChanged) setCidade(null);
  }, [estado, estadoChanged]);

  const handlerEstado = value => {
    setEstado(value);
    setEstadoChanged(true);
    onChange({
      ...entity,
      endereco: {
        ...values,
        estado: value
      }
    });
  }
  const handlerCidade = value => {
    setCidade(value);
    onChange({
      ...entity,
      endereco: {
        ...values,
        cidade: value
      }
    });
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onChange({
      ...entity,
      endereco: {
        ...values,
        [event.target.name]: event.target.value
      }
    });
  };

  return (
    <form autoComplete="off" noValidate>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Input
            label="Rua"
            name="rua"
            onChange={handleChange}
            value={values.rua}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label="Número"
            name="numero"
            onChange={handleChange}
            value={values.numero}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label="Complemento"
            name="complemento"
            onChange={handleChange}
            value={values.complemento}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label="CEP"
            name="cep"
            onChange={handleChange}
            value={values.cep}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <EstadoInput
            estado={estado}
            onSelect={handlerEstado}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CidadeInput
            cidade={cidade}
            estado={estado}
            onSelect={handlerCidade}
          />
        </Grid>
      </Grid>
    </form>
  );
};

EnderecoForm.propTypes = {
  onChange: PropTypes.func,
  entity: PropTypes.object
};

export default EnderecoForm;