/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as Roles from '../../constants/role';
import * as selectorsNotas from '../../store/nota/reducer';
import * as actionsNotas from '../../store/nota/actions';

import * as selectorsAuth from '../../store/auth/reducer';
import moment from 'moment';

import {
  Input, DefaultSelect, TrueFalseSelect, DialogDefault, ArquivoContainer,
  DatePicker, NumberInput, UsuarioSelect, ValidateSelect, VeiculoSelect,
  CartaoSelect
} from '../../components';

const schema = {
  valor: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  tipo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  possuiCupom: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  tipoPagamento: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  inspecao: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  buttonContainer: {
    width: '100%',
  },
  error: {
    color: '#e53935',
    marginTop: 10,
    fontSize: 12,
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: '0.33px'
  }
}));

const Formulario = ({ saveNota, updateNota, selectedNota, open, id, onClose, findNotaById, authData, isAdmin }) => {

  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (open) {
      setValues({
        data: moment().valueOf(),
        usuarioNome: authData.nome,
        dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss"),
      });
    } else {
      setValues({});
    }
  }, [authData, open]);

  useEffect(() => {
    if (id) findNotaById(id);
  }, [id, findNotaById]);

  useEffect(() => {
    if (id && selectedNota && id === selectedNota.id) {
      if (!selectedNota.data) selectedNota.data = moment().valueOf();
      else selectedNota.data = Number(selectedNota.data);
      setValues(selectedNota);
      setReadOnly(isAdmin ? false : (selectedNota.validate === 'VALIDADO' || selectedNota.validate === 'INVALIDADO'));
    } else {
      setValues({
        data: moment().valueOf(),
        usuarioNome: authData.nome
      });
      setReadOnly(false);
    }
  }, [id, selectedNota, authData]);

  const onSave = () => {
    const erros = validate(values, schema) || {};
    if (validateCartaoCorporativo()) erros.cartao = true;
    if (validateJustificativa()) erros.justificativa = true;
    if (validateArquivo()) erros.arquivo = true;

    setErrors(erros);
    if (!erros || Object.keys(erros).length === 0) {

      if (values.file) {
        values.arquivo = {
          nome: values.file.name.split('.')[0],
          extensao: values.file.name.split('.').pop(),
          status: 'NOT_SYNC'
        }
      }

      if (values.id) updateNota(values, saveCallback);
      else saveNota(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({})
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const validateCartaoCorporativo = () => values.tipoPagamento === 'CARTAO_CORPORATIVO' && !values.cartao;
  const validateArquivo = () => values.possuiCupom === 'true' && (!values.arquivos || values.arquivos.length == 0);
  const validateJustificativa = () => values.possuiCupom === 'false' && !values.justificativa;

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Nota"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
      readOnly={isAdmin ? false : (values && values.validate === 'VALIDADO')}
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.valor}
            helperText={errors && errors.valor ? errors.notaInicialvalor : null}
            isRequired
            label="Valor"
            onChange={valor => setValues({
              ...values,
              valor
            })}
            value={values.valor}
            format="##,######"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DatePicker
            label="Data"
            onChange={data => setValues({
              ...values,
              data: data.valueOf()
            })}
            value={values.data}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Criado Por"
            value={values.usuarioNome}
            disabled
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Inclusão"
            value={values.dataInclusao}
            disabled
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.tipo ? true : false}
            isRequired
            label="Tipo da Nota"
            name="tipo"
            showEmpty
            options={[
              { id: 'ALIMENTACAO', nome: 'Alimentação' },
              { id: 'ABASTECIMENTO', nome: 'Abastecimento' },
              { id: 'ADIANTAMENTO', nome: 'Adiantamento' },
              { id: 'CORREIO', nome: 'Correios' },
              { id: 'HOSPEDAGEM', nome: 'Hospedagem' },
              { id: 'MANUTENCAO_VEICULO', nome: 'Manutenção do Veículo' },
              { id: 'OUTROS', nome: 'Outros' },
              { id: 'PEDAGIO', nome: 'Pedágio/Balça' }
            ]}
            onSelect={event => {
              setValues({
                ...values,
                [event.target.name]: event.target.value,
                veiculoId: event.target.value !== "MANUTENCAO_VEICULO" && event.target.value !== "ABASTECIMENTO" ? null : values.veiculoId,
                usuarioAdiantamentoId: event.target.value !== "ADIANTAMENTO" ? null : values.usuarioAdiantamentoId
              });
            }}
            value={values.tipo}
            disabled={readOnly}
          />
        </Grid>
        {(values.tipo === "MANUTENCAO_VEICULO" || values.tipo === "ABASTECIMENTO") && <Grid item xs={3} className={classes.fieldContainer}>
          <VeiculoSelect
            onSelect={veiculoId => {
              setValues({
                ...values,
                veiculoId
              });
            }}
            value={values.tipo === "MANUTENCAO_VEICULO" || values.tipo === "ABASTECIMENTO" ? values.veiculoId : ""}
            disabled={readOnly || (!values || (values.tipo !== "MANUTENCAO_VEICULO" && values.tipo !== "ABASTECIMENTO"))}
          />
        </Grid>}
        {values.tipo === "ADIANTAMENTO" && <Grid item xs={3} className={classes.fieldContainer}>
          <UsuarioSelect
            ativo
            onSelect={usuarioAdiantamentoId => {
              setValues({
                ...values,
                usuarioAdiantamentoId
              });
            }}
            value={values.tipo === "ADIANTAMENTO" ? values.usuarioAdiantamentoId : ""}
            label="Usuário Adiantamento"
            disabled={!values || values.tipo !== "ADIANTAMENTO"}
          />
        </Grid>}
        <Grid item xs={3} className={classes.fieldContainer}>
          <ValidateSelect
            onChange={validate => setValues({
              ...values,
              validate
            })}
            value={values.validate}
            disabled={isAdmin ? false : true}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.possuiCupom ? true : false}
            isRequired
            label="Cumpo Fiscal/NF"
            name="possuiCupom"
            onChange={handleChange}
            value={values.possuiCupom}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.tipoPagamento ? true : false}
            isRequired
            label="Tipo do Pagamento"
            name="tipoPagamento"
            showEmpty
            options={[
              { id: 'APORTE', nome: 'Aporte' },
              { id: 'CARTAO_CORPORATIVO', nome: 'Cartão Corporativo' },
            ]}
            onSelect={event => setValues({
              ...values,
              tipoPagamento: event.target.value,
              cartao: undefined,
            })}
            value={values.tipoPagamento}
            disabled={readOnly}
          />
        </Grid>
        {values.tipoPagamento === "CARTAO_CORPORATIVO" && <>
          <Grid item xs={3} className={classes.fieldContainer}>
            <CartaoSelect
              error={errors && errors.cartao ? true : false}
              onSelect={cartao => {
                setValues({ ...values, cartao });
              }}
              value={values.cartao}
              disabled={readOnly || (values && values.tipoPagamento !== 'CARTAO_CORPORATIVO')}
              isRequired={values.tipoPagamento === "CARTAO_CORPORATIVO"}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <TrueFalseSelect
              error={errors && errors.despesaFatura ? true : false}
              label="Despesa na Fatura"
              name="despesaFatura"
              onChange={handleChange}
              value={values.despesaFatura}
              disabled={readOnly}
            />
          </Grid>
        </>}
        <Grid item xs={3} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.inspecao ? true : false}
            isRequired
            label="É Inspeção"
            name="inspecao"
            onChange={handleChange}
            value={values.inspecao}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <UsuarioSelect
            label="Ajudante/Observador"
            onSelect={inspetorAuxiliar => setValues({
              ...values,
              inspetorAuxiliar
            })}
            ativo
            grupos={[Roles.ADMIN, Roles.INSPETOR]}
            value={values.inspetorAuxiliar}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            error={errors && errors.justificativa ? true : false}
            label="Justificativa"
            name="justificativa"
            onChange={handleChange}
            value={values.justificativa}
            rows={3}
            multiline
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <p>**Caso não possua cupom fiscal deve se preencher o campo de justificativa</p>
          {errors && errors.arquivo ? <p className={classes.error}>***Arquivo(s) é obrigatório(s)</p> : ''}
        </Grid>
        <ArquivoContainer
          arquivos={values.arquivos}
          onSave={arquivos => setValues({ ...values, arquivos })}
        />
      </Grid>
    </DialogDefault>
  );
};

Formulario.propTypes = {
  findNotaById: PropTypes.func.isRequired,
  saveNota: PropTypes.func.isRequired,
  updateNota: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  selectedNota: PropTypes.object,
  cartoesOptions: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

Formulario.defaultValue = {
  selectedNota: null,
  id: null,
}

const mapStateToProps = state => ({
  selectedNota: selectorsNotas.getSelectedNota(state),
  authData: selectorsAuth.getAuth(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsNotas,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
