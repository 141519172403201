import * as Type from './actionsTypes';

const INITIAL_STATE = {
  odometros: [],
  selectedOdometro: null,
  options: [],
};

const ordernarData = (a, b) => {
  return Number(a.data) > Number(b.data);
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_ODOMETROS_SUCCESS: {
      return {
        ...state,
        odometros: action.data.sort(ordernarData)
      }
    }
    case Type.DELETE_ODOMETRO_SUCCESS: {
      return {
        ...state,
        odometros: state.odometros.filter(s => s.id !== action.data).sort(ordernarData)
      }
    }
    case Type.SAVE_ODOMETRO_SUCCESS: {
      return {
        ...state,
        odometros: [...state.odometros, action.data].sort(ordernarData)
      }
    }
    case Type.UPDATE_ODOMETRO_SUCCESS: {
      return {
        ...state,
        odometros: [...state.odometros.filter(s => s.id !== action.data.id), action.data].sort(ordernarData)
      }
    }
    case Type.ODOMETRO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedOdometro: action.data
      }
    }
    case Type.ODOMETRO_VALIDATE_BY_ID_SUCCESS: {
      const odometro = state.odometros.filter(s => s.id === action.id)[0];
      odometro.validate = action.validate;
      return {
        ...state,
        odometros: [...state.odometros.filter(s => s.id !== action.id), odometro].sort(ordernarData)
      }
    }
    case Type.ODOMETRO_FIND_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data
      }
    }
    case Type.CLEAR_ODOMETRO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getOdometros = state => state.rootState.odometro.odometros;
export const getSelectedOdometro = state => state.rootState.odometro.selectedOdometro;
export const getOdometrosOptions = state => state.rootState.odometro.options;
