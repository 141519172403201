/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { Grid, TableCell } from '@material-ui/core';
import * as actionsVeiculos from '../../store/veiculo/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import * as selectorsVeiculos from '../../store/veiculo/reducer';
import * as Role from '../../constants/role';
import { getMaxColumnLength } from '../../utils';
import { Table } from '../../components';
import HistoricoVeiculoModal from './HistoricoVeiculoModal';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  tableContainer: {
    minWidth: 886
  },
}));

const tipoHistoricoNome = tipoHistorico => {
  switch (tipoHistorico) {
    case 'MANUTENCAO':
      return 'Manutenção'
    case 'PECAS':
      return 'Peças'
    case 'MULTA':
      return 'Multa'
    case 'AUTUACAO':
      return 'Autuação'
    case 'REPASSE_COLABORADOR':
      return 'Repasse'
    case 'VENDA_TROCA':
      return 'Venda/Troca'
    default:
      return tipoHistorico
  }
}

const ordernarPorDataHistorico = (a, b) => {
  return b.dataHistorico.localeCompare(a.dataHistorico);
};

const HistoricoTab = ({ idVeiculo, historico, userRole, deleteHistoricoVeiculo }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState(false);
  const [historicoVeiculoId, setHistoricoVeiculoId] = useState(false);

  const isAdmin = () => Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole;

  const renderRows = h => {
    return (
      <>
        <TableCell>{moment(h.dataHistorico).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{tipoHistoricoNome(h.tipoHistorico)}</TableCell>
        <TableCell size="medium" title={h.observacao}>{getMaxColumnLength(h.observacao)}</TableCell>
      </>
    )
  }

  const onDelete = id => {
    deleteHistoricoVeiculo(id, () => {
      setRows([ ...rows.filter(s => s.id !== id) ]);
    });
  } 

  const onEdit = id => {
    setHistoricoVeiculoId(id);
    setOpen(true);
  }

  const onAdd = () => {
    setHistoricoVeiculoId(null);
    setOpen(true);
  }

  const afterSave = data => {
    setRows([ ...rows.filter(s => s.id !== data.id), data ].sort(ordernarPorDataHistorico));
  }

  useEffect(() => {
    setRows(historico);
  }, [historico]);

  return (
    <>
      <Grid container spacing={3} className="teste">
        <Table
          rows={rows}
          titles={['Data', 'Tipo', 'Descrição']}
          deleteMessage="A informação será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          hasRowActions={isAdmin()}
          hasAdd={isAdmin()}
          classWidgetCard={classes.tableContainer}
        />
      </Grid>
      <HistoricoVeiculoModal
        open={open}
        onClose={() => {
          setHistoricoVeiculoId(null);
          setOpen(false);
        }}
        idVeiculo={idVeiculo}
        id={historicoVeiculoId}
        afterSave={afterSave}
      />
    </>
  );
};

const mapStateToProps = state => ({
  userRole: selectorsAuth.getRole(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsVeiculos,
  ...selectorsVeiculos,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HistoricoTab);
