/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

const CustomSwitch = withStyles({
  switchBase: {
    color: '#ff9800',
    '&$checked': {
      color: '#ff9800',
    },
    '&$checked + $track': {
      backgroundColor: '#ff9800',
    },
  },
  checked: {},
  track: {},
})(Switch);

const SwitchComponent = ({ checked, handleChange, label }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<CustomSwitch checked={checked} onChange={handleChange} name="checkedA" />}
        label={<span style={{color: '#ff9800'}}> {label} </span>}
      />
    </FormGroup>
  );
}


export default SwitchComponent;