/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import * as actionsSubestacoes from '../../store/subestacao/actions';

import { Input, DialogDefault, PoloSelect } from '../../components';

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  codigo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  poloIds: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  contentClassName: {
    overflow: 'visible'
  }
}));

const Formulario = ({ saveSubestacao, updateSubestacao, subestacoes, open, id, onClose }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) setValues(subestacoes.filter(a => a.id === id)[0]);
  }, [id, subestacoes]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateSubestacao(values);
      else saveSubestacao(values);
      onCloseLocal(true);
    }
  }

  const onCloseLocal = (isSave) => {
    setErrors({});
    setValues({})
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Subestacao"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={4} className={classes.fieldContainer}>
          <Input
            error={errors && errors.codigo}
            helperText={errors && errors.codigo ? errors.codigo : null}
            isRequired
            label="Código"
            name="codigo"
            onChange={handleChange}
            placeholder="Código"
            value={values.codigo}
          />
        </Grid>
        <Grid item xs={8} className={classes.fieldContainer}>
          <Input
            error={errors && errors.nome}
            helperText={errors && errors.nome ? errors.nome : null}
            isRequired
            label="Nome"
            name="nome"
            onChange={handleChange}
            placeholder="Nome"
            value={values.nome}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <PoloSelect
            error={errors && errors.poloIds}
            isMultiple
            onSelect={value => {
              setValues({
                ...values,
                poloIds: value
              });
            }}
            value={values.poloIds}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Descrição"
            name="descricao"
            onChange={handleChange}
            placeholder="descricao"
            value={values.descricao}
            rows={4}
            multiline
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};

Formulario.propTypes = {
  saveSubestacao: PropTypes.func.isRequired,
  updateSubestacao: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  subestacoes: PropTypes.array.isRequired,
};

Formulario.defaultValue = {
  id: null,
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsSubestacoes,
}, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(Formulario));
