import React from 'react';
import PropTypes from 'prop-types';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@material-ui/core/styles';

const getOptions = (seriesData, colors, theme) => ({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    backgroundColor: theme.palette.bar.grayLight
  },
  title: {
    text: ''
  },
  tooltip: {
    pointFormat: '<b>{point.percentage:.1f}%</b>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors,
      dataLabels: {
        enabled: false
      },
      showInLegend: true
    }
  },
  credits: {
    enabled: false
  },
  series: [{
    colorByPoint: true,
    data: seriesData
  }]
});

const Pie = ({ seriesData, colors }) => {
  const theme = useTheme();

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getOptions(seriesData, colors, theme)}
    />
  );
}

Pie.propTypes = {
  seriesData: PropTypes.array,
  colors: PropTypes.array,
};

Pie.defaultProps = {
  seriesData: [],
  colors: []
}

export default Pie;