/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid, DialogContentText } from '@material-ui/core';
import * as Roles from '../../constants/role';
import * as actionsConvocacoes from '../../store/convocacao/actions';
import * as selectorsConvocacoes from '../../store/convocacao/reducer';
import * as selectorsAuth from '../../store/auth/reducer';
import { Input, DialogDefault, UsuarioSelect, DatePicker, DefaultSelect } from '../../components';
import moment from 'moment';
import clsx from 'clsx';

const schema = {
  inspetorId: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataInicio: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataFim: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  jornada: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  cancelada: {
    color: "red",
    fontStyle: "italic"
  }
}));

const Formulario = ({ saveConvocacao, updateConvocacao, findConvocacaoById, convocacaoClearSelected, convocacaoUpdateStatus, selectedConvocacao, open, id, onClose, authData, userRole, onReload }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [openAceitar, setOpenAceitar] = useState(false);
  const [openCancelar, setOpenCancelar] = useState(false);
  const [openNegar, setOpenNegar] = useState(false);

  const getDefaultValues = () => {
    return {
      criadoPorId: authData.id,
      criadoPorNome: authData.nome,
      dataCriacao: moment().valueOf(),
      dataInicio: moment().startOf('month').valueOf(),
      dataFim: moment().endOf('month').valueOf(),
      status: 'AGUARDANDO',
      jornada: 'Segunda a sexta-feira: 08:00 às 12:00 e 13:00 às 17:00 \nSábado: 08:00 às 12:00 \nExceto feriados'
    } 
  }

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open && !id) {
      setValues(getDefaultValues());
    }
  }, [id, open]);

  useEffect(() => {
    if (id) {
      findConvocacaoById(id);
    }
  }, [id, findConvocacaoById]);

  useEffect(() => {
    if (selectedConvocacao && selectedConvocacao.id) {
      setValues({
        ...selectedConvocacao,
        dataCriacao: moment(selectedConvocacao.dataCriacao).valueOf(),
      });
    } 
  }, [selectedConvocacao]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateConvocacao(values.id, values);
      else saveConvocacao(values);
      onCloseLocal(true);
    }
  }

  const onCloseLocal = (isSave) => {
    convocacaoClearSelected();
    setErrors({});
    setValues(getDefaultValues());
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const isAdmin = () => userRole === Roles.ADMIN || userRole === Roles.SUPER_ADMIN;

  const isDisabled = () => values.cancelada || values.status !== 'AGUARDANDO' || values.criadoPorId !== authData.id;

  return (
    <>
      <DialogDefault
        open={open}
        handlerCancel={() => onCloseLocal(false)}
        handlerConfirm={onSave}
        title="Convocação"
        confirmMessage="Salvar"
        contentClassName={classes.contentClassName}
        maxWidth="md"
        readOnly={isDisabled()}
        headerAction={[
          {
            onClick: () => setOpenCancelar(true),
            title: "Cancelar",
            show: isAdmin() && !!values.id && values.status === 'AGUARDANDO'
          },
          {
            onClick: () => setOpenNegar(true),
            title: "Negar",
            show: !!values.id && values.inspetorId === authData.id && values.status === 'AGUARDANDO' && !values.cancelada
          },
          {
            onClick: () => setOpenAceitar(true),
            title: "Aceitar",
            show: !!values.id && values.inspetorId === authData.id && values.status === 'AGUARDANDO' && !values.cancelada
          },
        ]}
      >
        <Grid container spacing={3} className={classes.gridContainer}>
          {values.cancelada && <Grid item xs={12} className={clsx(classes.fieldContainer, classes.cancelada)}>
            **** Convocação cancelada por {values.canceladaPorNome} em {values.dataCanceladaValue}
          </Grid>}
          <Grid item xs={9} className={classes.fieldContainer}>
            <UsuarioSelect
              label="Inspetor"
              onSelect={inspetorId => setValues({ ...values, inspetorId}) }
              ativo
              grupos={[Roles.ADMIN, Roles.INSPETOR]}
              value={values.inspetorId}
              isRequired
              error={errors && errors.inspetorId}
              disabled={isDisabled()}
              multiSelect={!values.id}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <DefaultSelect
              error={errors && errors.status ? true : false}
              isRequired
              label="Status"
              name="status"
              showEmpty
              options={[
                {id: 'AGUARDANDO', nome: 'Aguardando'},
                {id: 'ACEITO', nome: 'Aceito'},
                {id: 'NEGADO', nome: 'Negado'},
                {id: 'CANCELADA', nome: 'Cancelada'},
                {id: 'EXPIRADA', nome: 'Expirada'},
              ]}
              onSelect={event => setValues({
                ...values,
                status: event.target.value
              })}
              value={values.status}
              disabled={true}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <DatePicker
              label="Data Início"
              isRequired
              error={errors && errors.dataInicio}
              onChange={data => setValues({
                ...values,
                dataInicio: data.valueOf()
              })}
              value={values.dataInicio}
              disabled={isDisabled()}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <DatePicker
              label="Data Fim"
              isRequired
              error={errors && errors.dataFim}
              onChange={data => setValues({
                ...values,
                dataFim: data.valueOf()
              })}
              value={values.dataFim}
              disabled={isDisabled()}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <Input label="Criado Por" value={values.criadoPorNome} disabled/>
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <Input label="Data Criação" value={values.dataCriacao ?  moment(Number(values.dataCriacao)).format("DD/MM/YYYY HH:mm") : moment().format("DD/MM/YYYY HH:mm")} disabled />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              isRequired
              error={errors && errors.inspetorId}
              label="Jornada"
              name="jornada"
              onChange={handleChange}
              placeholder="jornada"
              value={values.jornada}
              rows={4}
              multiline
              disabled={isDisabled()}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              label="Descrição"
              name="descricao"
              onChange={handleChange}
              placeholder="descricao"
              value={values.descricao}
              rows={4}
              multiline
              disabled={isDisabled()}
            />
          </Grid>
        </Grid>
      </DialogDefault>
      <DialogDefault
        open={openAceitar}
        useFullScreen={false}
        handlerCancel={() => setOpenAceitar(false)}
        handlerConfirm={() => { 
          setOpenAceitar(false); 
          convocacaoUpdateStatus(values.id, "aceitar"); 
          onReload();
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente aceitar a convocação? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openNegar}
        useFullScreen={false}
        handlerCancel={() => setOpenNegar(false)}
        handlerConfirm={() => {
          setOpenNegar(false); 
          convocacaoUpdateStatus(values.id, "negar");
          onReload();
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente não aceitar a convocação? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openCancelar}
        useFullScreen={false}
        handlerCancel={() => setOpenCancelar(false)}
        handlerConfirm={() => { setOpenCancelar(false); convocacaoUpdateStatus(values.id, "cancelar"); }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente cancelar a convocação? </DialogContentText>
      </DialogDefault>
    </>
  );
};

Formulario.propTypes = {
  saveConvocacao: PropTypes.func.isRequired,
  updateConvocacao: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  convocacoes: PropTypes.array.isRequired,
  onReload: PropTypes.func.isRequired,
};

Formulario.defaultValue = {
  id: null,
}

const mapStateToProps = state => ({
  selectedConvocacao: selectorsConvocacoes.getSelectedConvocacao(state),
  authData: selectorsAuth.getAuth(state),
  userRole: selectorsAuth.getRole(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsConvocacoes,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
