import React, { useEffect, useState } from 'react';
import DefaultSelect from '../DefaultSelect';
import MultiSelect from '../MultiSelect';
import BaseService from '../../services/BaseService';

const OptionSelect = ({ onSelect, value, isRequired, error, helperText, disabled, codigo, multiSelect, label }) => {
  const [optionsByCode, setOptionsByCode] = useState([]);

  const getOptions = async () => {
    try {
      const response = await BaseService.get({ url: `options/${codigo}` });
      if (multiSelect) {
        setOptionsByCode(response.data.map(o => ({value: o.id, label: o.value})));
      } else {
        setOptionsByCode(response.data.map(o => ({id: o.id, nome: o.value})));
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getOptions(codigo);
  }, [codigo]);

  if (multiSelect) {
    return (
      <MultiSelect
        label={label}
        onChange={values => {
          onSelect(values ? values.map(v => v.value) : null)
        }}
        value={value}
        options={optionsByCode}
      />
    )
  }

  return (
    <DefaultSelect 
      error={error}
      helperText={helperText}
      isRequired={isRequired}
      label={label}
      onSelect={event => onSelect(event.target.value)}
      options={optionsByCode}
      value={value}
      disabled={disabled}
    />
  );
}

export default OptionSelect;