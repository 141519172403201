export const FETCH_APORTES = 'FETCH_APORTES';
export const FETCH_APORTES_SUCCESS = 'FETCH_APORTES_SUCCESS';

export const SAVE_APORTE = 'SAVE_APORTE';
export const SAVE_APORTE_SUCCESS = 'SAVE_APORTE_SUCCESS';

export const UPDATE_APORTE = 'UPDATE_APORTE';
export const UPDATE_APORTE_SUCCESS = 'UPDATE_APORTE_SUCCESS';

export const CLEAR_APORTE = 'CLEAR_APORTE';

export const APORTE_FIND_BY_ID = 'APORTE_FIND_BY_ID';
export const APORTE_FIND_BY_ID_SUCCESS = 'APORTE_FIND_BY_ID_SUCCESS';

export const APORTE_FIND_FOTO_BY_ID = 'APORTE_FIND_FOTO_BY_ID';

export const FETCH_APORTES_BY_INSPETOR = 'FETCH_APORTES_BY_INSPETOR';
export const FETCH_APORTES_BY_INSPETOR_SUCCESS = 'FETCH_APORTES_BY_INSPETOR_SUCCESS';
