/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Divider, Button, TableCell, DialogContentText } from '@material-ui/core';

import * as selectorsControlePontos from '../../store/controlePonto/reducer';
import * as actionsControlePontos from '../../store/controlePonto/actions';
import moment from 'moment';

import { DialogDefault, Input, InputFormat, Table } from '../../components';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  divider: {
    backgroundColor: theme.palette.bar.grayMiddle
  },
  button: {
    marginTop: 22,
    padding: '8px 16px'
  },
  row: {
    padding: '5px 40px 5px 16px'
  }
}));

const Formulario = ({ open, id, onClose, loadControlePontosByUsuarioId, controlePonto, controlePontoUsuarios, saveControlePontos,
  reportControlePontoByUsuarioId }) => {
  const classes = useStyles();

  const [filter, setFilter] = useState({ year: moment().format("YYYY") });
  const [values, setValues] = useState({
    usuarioId: "",
    usuario: "",
    pontos: [],
    saldo: 0,
    saldoOutrosAnos: 0
  });
  const [openReport, setOpenReport] = useState(false);

  useEffect(() => {
    if (id && controlePontoUsuarios && controlePontoUsuarios.length > 0) {
      const usuarioControlePonto = controlePontoUsuarios.filter(u => u.id === id)[0];
      setValues({
        usuarioId: id,
        usuario: usuarioControlePonto.nome,
        saldo: usuarioControlePonto.saldo || 0,
        pontos: [],
        saldoOutrosAnos: 0
      });
    }
  }, [controlePontoUsuarios, id]);

  useEffect(() => {
    if (controlePonto && controlePonto.pontos && controlePonto.pontos.length > 0) {
      setValues({
        usuarioId: id,
        usuario: controlePonto.usuario,
        pontos: controlePonto.pontos,
        saldo: controlePonto.saldo,
        saldoOutrosAnos: controlePonto.saldoOutrosAnos
      });
    }
  }, [controlePonto]);

  const onSave = () => {
    saveControlePontos(values, () => onClose());
  }

  const toNumeric = value => {
    if (value != null) {
      return Number(value);
    }
    return 0;
  }

  const parseToMinutes = hora => {
    const isNegative = hora.includes("-");
    let minutes = 0;
    let value = hora.replace("-", "");

    if (value.includes(":")) {
      const splited = value.split(":");
      minutes = toNumeric(splited[0]) * 60 + toNumeric(splited[1] || 0);
    } else {
      minutes = toNumeric(value) * 60;
    }

    return isNegative ? minutes * -1 : minutes;
  }

  const format = minutes => {
    let isNegative = false;

    if (minutes < 0) {
      isNegative = true;
      minutes = minutes * -1;
    }

    let hoursValue = Math.floor(minutes / 60);
    let minutesValue = minutes % 60;

    return `${isNegative ? "-" : ""}${String(hoursValue).padStart(2, '0')}:${String(minutesValue).padStart(2, '0')}`
  }

  const calculateHoraExtra = ponto => {
    const horasCumpridas = parseToMinutes(ponto.horasCumpridas);
    const horasEsperadas = parseToMinutes(ponto.horasEsperadas);
    const horasPagas = parseToMinutes(ponto.horasPagas);
    const horasDescontadas = parseToMinutes(ponto.horasDescontadas);

    const minutes = horasCumpridas - horasEsperadas + horasPagas - horasDescontadas;
    return format(minutes);
  }

  const calculateCurrentSaldo = (currentSaldo, horaExtra) => {
    const saldoParse = parseToMinutes(currentSaldo);
    const horaExtraParse = parseToMinutes(horaExtra);

    const minutes = saldoParse + horaExtraParse;
    return format(minutes);
  }

  const calculateSaldoTotal = (saldoOutrosAnos, currentSaldo, horaExtra) => {
    
    const saldoOutrosAnosParse = parseToMinutes(saldoOutrosAnos);
    const saldoParse = parseToMinutes(currentSaldo);
    const horaExtraParse = parseToMinutes(horaExtra);

    const minutes = saldoOutrosAnosParse + saldoParse + horaExtraParse;
    return format(minutes);
  }

  const onChange = async (e, field) => {
    const id = e.currentTarget.closest('tr').getAttribute('id');
    const value = e.target.value;
    const ponto = values.pontos.filter(p => p.id === id)[0];

    ponto[field] = value;
    ponto.horaExtra = calculateHoraExtra(ponto)

    let currentSaldo = "00:00"
    values.pontos.filter(p => p.id !== id).forEach(p => {
      currentSaldo = calculateCurrentSaldo(currentSaldo, p.horaExtra);
    });

    setValues({
      ...values,
      pontos: [...values.pontos.filter(p => p.id !== id), ponto].sort((a, b) => new Date(a.dia) - new Date(b.dia)),
      saldo: calculateSaldoTotal(values.saldoOutrosAnos, currentSaldo, ponto.horaExtra)
    })
  }

  const renderRows = r => {
    return (
      <>
        <TableCell size="small">{r.mes}</TableCell>
        <TableCell size="small">
          <Input
            showInputGroup={false}
            value={r.horasCumpridas}
            onChange={e => onChange(e, "horasCumpridas")}
            placeholder="00:00"
          />
        </TableCell>
        <TableCell size="small">
          <Input
            showInputGroup={false}
            value={r.horasEsperadas}
            onChange={e => onChange(e, "horasEsperadas")}
            format="###"
            placeholder="00:00"
          />
        </TableCell>
        <TableCell size="small">
          <Input
            showInputGroup={false}
            value={r.horasPagas}
            onChange={e => onChange(e, "horasPagas")}
            format="###"
            placeholder="00:00"
          />
        </TableCell>
        <TableCell size="small">
          <Input
            showInputGroup={false}
            value={r.horasDescontadas}
            onChange={e => onChange(e, "horasDescontadas")}
            format="###"
            placeholder="00:00"
          />
        </TableCell>
        <TableCell size="small">{r.horaExtra}</TableCell>
      </>
    )
  }

  return (
    <>
      <DialogDefault
        open={open}
        handlerCancel={onClose}
        handlerConfirm={onSave}
        title="Folha de Ponto"
        confirmMessage="Salvar"
        contentClassName={classes.contentClassName}
        maxWidth="md"
        headerAction={[
          {
            title: "Relatório",
            onClick: () => setOpenReport(true)
          }
        ]}
      >
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={6} className={classes.fieldContainer}>
            <Input label="Usuário" disabled value={values.usuario} />
          </Grid>
          <Grid item xs={6} className={classes.fieldContainer}>
            <Input label="Saldo" disabled value={values.saldo}/>
          </Grid>
          <Grid item xs={4}>
            <InputFormat
              label="Ano"
              value={filter.year}
              onChange={event => setFilter({ year: event.target.value })}
              format="####"
              placeholder="AAAA"
            />
          </Grid>
          <Grid item xs={4}>
            <Button 
              color="primary" 
              size="small" 
              variant="contained"
              className={classes.button}
              onClick={() => loadControlePontosByUsuarioId(id, filter)}
            >
              Buscar
            </Button>
          </Grid>
          <Grid className={classes.breakline} item xs={12}>
            <Divider className={classes.divider}/>
          </Grid>
          <Grid item xs={12} className={classes.root}>
            <Table
              rows={values.pontos}
              titles={['Mês', 'Qtd. Horas Cumpridas', 'Qtd. Horas Esperadas', 'Horas Pagas', 'Horas Descontadas', 'Hora Extra']}
              headerTitle="Saldos"
              renderRows={renderRows}
              hasAdd={false}
              size="small"
              defaultRowsPerPage={12}
            />
          </Grid>
        </Grid>
      </DialogDefault>
      <DialogDefault
        open={openReport}
        handlerCancel={() => setOpenReport(false)}
        handlerConfirm={() => {
          reportControlePontoByUsuarioId(id, filter, () => setOpenReport(false))
        } }
        title="Confirmação"
        confirmMessage="Gerar"
      >
        <DialogContentText> Deseja gerar o relatório para o usuário? </DialogContentText>
      </DialogDefault>
    </>
  );
};

const mapStateToProps = state => ({
  controlePontoUsuarios: selectorsControlePontos.getControlePontosUsuarios(state),
  controlePonto: selectorsControlePontos.getSelectedControlePonto(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsControlePontos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
