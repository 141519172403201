/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid } from '@material-ui/core';
import { Table, Filtro } from '../../components';
import * as selectorsSubestacoes from '../../store/subestacao/reducer';
import * as actionsSubestacoes from '../../store/subestacao/actions';
import Formulario from './Formulario';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Subestacoes = ({ subestacoes, loadSubestacoes, deleteSubestacao }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  
  const classes = useStyles();
  
  const renderRows = d => {
    return (
      <>
        <TableCell>{d.codigo}</TableCell>
        <TableCell>{d.nome}</TableCell>
        <TableCell>{d.polosCodigos && Array.isArray(d.polosCodigos)? d.polosCodigos.join(', ') : ''}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadSubestacoes()
  }, [loadSubestacoes]);

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteSubestacao(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = () => {
    setOpen(false);
    setId(null);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Filtro 
          onFilter={loadSubestacoes}
          useCodigo
          useMalha
          useGerencia
          usePolo
        />
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={subestacoes}
          titles={['Código', 'Nome', 'Polos']}
          headerTitle="Subestações"
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          showDelete={false}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          subestacoes={subestacoes}
        />
      </Grid>
    </Grid>
  );
};

Subestacoes.propTypes = {
  deleteSubestacao: PropTypes.func.isRequired,
  loadSubestacoes: PropTypes.func.isRequired,
  subestacoes: PropTypes.array,
};

const mapStateToProps = state => ({
  subestacoes: selectorsSubestacoes.getSubestacoes(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsSubestacoes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Subestacoes));