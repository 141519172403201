/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault } from '../../components';
import * as selectorsControlePontos from '../../store/controlePonto/reducer';
import * as actionsControlePontos from '../../store/controlePonto/actions';
import Formulario from './Formulario';

import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const ControlePonto = ({ controlePontoUsuarios, loadControlePontoUsuarios, authData, exportControlePonto }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['Nome', 'Matricula', 'Saldo']);
  const classes = useStyles();
  const [localFilter, setLocalFilter] = useState({});
  const [openExport, setOpenExport] = useState(false);

  const renderRows = r => {
    return (
      <>
        <TableCell>{r.nome}</TableCell>
        <TableCell>{r.matricula}</TableCell>
        <TableCell>{r.saldo}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadControlePontoUsuarios(localFilter)
  }, [loadControlePontoUsuarios]);

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onClose = () => {
    setOpen(false);
    setId(null);
    loadControlePontoUsuarios(localFilter);
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getHeaderActions = () => {
    return [
      {
        title: "Exportar",
        onClick: () => setOpenExport(true)
      }
    ]
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useInspetor
            mdInspetor={4}
            labelInspetor="Usuário"
            onFilter={filterData => {
              setLocalFilter(filterData);
              loadControlePontoUsuarios(filterData);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table
          rows={controlePontoUsuarios}
          titles={titles}
          headerTitle="Controle Pontos"
          renderRows={renderRows}
          hasAdd={false}
          onEdit={onEdit}
          hasRowActions={isAdmin()}
          showDelete={false}
          headerActions={getHeaderActions()}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
        />
      </Grid>
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={() => exportControlePonto(localFilter, () => setOpenExport(false))}
        title="Confirmação"
        confirmMessage="Exportar"
      >
        <DialogContentText> Deseja exportar todas as informações filtradas? </DialogContentText>
      </DialogDefault>
    </>
  );
};

const mapStateToProps = state => ({
  controlePontoUsuarios: selectorsControlePontos.getControlePontosUsuarios(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsControlePontos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ControlePonto);