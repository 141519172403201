import * as Type from './actionsTypes';

const loadCartoes = filtro => ({ type: Type.FETCH_CARTOES, filtro });

const deleteCartao = id => ({ type: Type.DELETE_CARTAO, id });

const saveCartao = (data, callback) => ({ type: Type.SAVE_CARTAO, data, callback });

const updateCartao = (data, callback) => ({ type: Type.UPDATE_CARTAO, data, callback });

const findCartaoById = id => ({ type: Type.CARTAO_FIND_BY_ID, id });

const findCartoesOptions = () => ({ type: Type.CARTAO_FIND_OPTIONS });

const exportCartao = (filter, callbackAfterSave) => ({ type: Type.CARTAO_EXPORT, filter, callbackAfterSave });

export {
  loadCartoes,
  deleteCartao,
  saveCartao,
  updateCartao,
  findCartaoById,
  findCartoesOptions,
  exportCartao
}
