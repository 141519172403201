import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectorsConfig from '../../store/config/reducer';
import DefaultSelect from '../DefaultSelect';

const CidadeInput = ({ cidades, estado, cidade, onSelect, isRequired, error, helperText, disabled }) => {
  const [cidadeSelected, setCidadeSelected] = useState('');
  const [cidadeOptions, setCidadeOptions] = useState([]);

  useEffect(() => {
    setCidadeSelected(cidade);
  }, [cidade]);

  useEffect(() => {
    if (estado && cidades) {
      setCidadeOptions(cidades.filter(c => c.estado === estado));
      setCidadeSelected(cidade);
    }
  }, [estado, cidade, cidades]);

  return (
    <DefaultSelect 
      error={error}
      helperText={helperText}
      isRequired={isRequired}
      label="Cidade"
      onSelect={event => onSelect(event.target.value)}
      options={cidadeOptions}
      value={cidadeSelected}
      disabled={disabled}
    />
  );
}

CidadeInput.propTypes = {
  cidade: PropTypes.string,
  cidades: PropTypes.array.isRequired,
  error: PropTypes.any,
  estado: PropTypes.string,
  helperText: PropTypes.string,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
};

CidadeInput.defaultProps = {
  error: null,
  helperText: null,
  isRequired: false
};

const mapStateToProps = state => ({
  cidades: selectorsConfig.getCidades(state),
})

export default connect(mapStateToProps, null)(CidadeInput);