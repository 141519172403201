/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault } from '../../components';
import * as selectorsAuth from '../../store/auth/reducer';
import * as selectorsOdometros from '../../store/odometro/reducer';
import * as actionsOdometros from '../../store/odometro/actions';
import Formulario from './Formulario';
import moment from 'moment';
import * as Role from '../../constants/role';
import { Warning, CheckCircleOutline, NotInterested, CameraEnhance } from '@material-ui/icons';
import Viewer from 'react-viewer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  iconValidate: {
    marginRight: theme.spacing(1),
    color: 'green'
  },
  iconInvalidate: {
    marginRight: theme.spacing(1),
    color: 'red'
  }
}));

const Odometros = ({ odometros, loadOdometros, deleteOdometro, userRole, validateOdometro, findFotoById, exportOdometro }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['', 'Data', 'Veículo', 'Inicial', 'Final', 'Usuário', 'Observador/Ajudante', 'Validação']);
  const [openValidate, setOpenValidate] = useState(false);
  const [validateData, setValidateData] = useState({});
  const [localFilter, setLocalFilter] = useState({
    inicio: moment().subtract(1, 'year').startOf('year').valueOf(),
    fim: moment().endOf('day').valueOf()
  });
  const [openNotFotoModal, setOpenNotFotoModal] = useState(false);
  const [openFotoViewer, setOpenFotoViewer] = useState(false);
  const [images, setImages] = useState([]);
  const [openExport, setOpenExport] = useState(false);

  const classes = useStyles();
  
  const isAdmin = () => Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole;

  const renderRows = d => {
    return (
      <>
        <TableCell size="small">
            {d.validate === 'INVALIDADO' && <NotInterested className={classes.iconInvalidate} />}
            {(d.validate === null || d.validate === 'AGUARDANDO_VALIDACAO') && <Warning className={classes.icon} />}
            {d.validate === 'VALIDADO' && <CheckCircleOutline className={classes.iconValidate} />}
        </TableCell>
        <TableCell>{moment(Number(d.data)).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{d.veiculoPlaca}</TableCell>
        <TableCell>{d.odometroInicial}</TableCell>
        <TableCell>{d.odometroFinal}</TableCell>
        <TableCell>{d.usuarioNome}</TableCell>
        <TableCell>{d.inspetorAuxiliarNome}</TableCell>
        <TableCell>{d.validate === null || d.validate === 'AGUARDANDO_VALIDACAO'? 'Aguardando Validação' : d.validate === 'INVALIDADO' ? 'Invalidado' : 'Validado'}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadOdometros(localFilter)
  }, [loadOdometros, localFilter]);

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteOdometro(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = isSave => {
    if (isSave) {
      loadOdometros(localFilter)
    }
    setOpen(false);
    setId(null);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useDates
            useInspetor
            useVeiculo
            useValidate
            xsInspetor={6}
            onFilter={filterData => {
              setLocalFilter(filterData);
              loadOdometros(filterData);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={odometros}
          titles={titles}
          headerTitle="Odômetros"
          deleteMessage="O odometro será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          showDelete={false}
          headerActions={[{ 
            title: 'Exportar',
            onClick: () => setOpenExport(true)
          }]}
          actions={
            [
              {
                key: 'view',
                icon: <CameraEnhance />,
                onClick: id => {
                  findFotoById(id, files => {
                    if (files && files.length > 0) {
                      setImages(files);
                      setOpenFotoViewer(true);
                    } else {
                      setOpenNotFotoModal(true);
                    }
                  });
                }
              },
              {
                key: 'validate',
                icon: <CheckCircleOutline />,
                show: row => isAdmin() && (row.validate === null || row.validate === 'AGUARDANDO_VALIDACAO'),
                onClick: id => {
                  setValidateData({ id, validate: 'VALIDADO', 
                    message: "Deseja validar a nota?"
                  });
                  setOpenValidate(true);
                }
              },
              {
                key: 'invalidate',
                icon: <NotInterested />,
                show: row => isAdmin() && (row.validate === null || row.validate === 'AGUARDANDO_VALIDACAO'),
                onClick: id => {
                  setValidateData({ id, validate: 'INVALIDADO',
                    message: "Deseja invalidar a nota?"
                  });
                  setOpenValidate(true);
                }
              },
            ]
          }
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
        />
        <DialogDefault
          open={openValidate}
          handlerCancel={() => {
            setValidateData({});
            setOpenValidate(false);
          }}
          handlerConfirm={() => {
            validateOdometro(validateData.id, validateData.validate);
            setOpenValidate(false);
          }}
          title="Confirmação"
          confirmMessage="Sim"
          cancelMessage="Não"
        >
          <DialogContentText> {validateData.message} </DialogContentText>
        </DialogDefault>
      </Grid>
      <Viewer
        visible={openFotoViewer}
        onClose={() => {
          setOpenFotoViewer(false);
          setImages([]);
        }}
        images={images}
        zIndex={3000}
        scalable={false}
        noImgDetails
        zoomSpeed={0.25}
        downloadable
      />
      <DialogDefault
        open={openNotFotoModal}
        handlerCancel={() => setOpenNotFotoModal(false)}
        readOnly
      >
        <DialogContentText> Nota não possui foto </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={() => {
          exportOdometro(localFilter, () => {
            setOpenExport(false);
          });
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja baixar todas as informações filtradas? </DialogContentText>
      </DialogDefault>
    </>
  );
};

Odometros.propTypes = {
  deleteOdometro: PropTypes.func.isRequired,
  loadOdometros: PropTypes.func.isRequired,
  odometros: PropTypes.array,
  userRole: PropTypes.string.isRequired,
  exportOdometro: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  odometros: selectorsOdometros.getOdometros(state),
  userRole: selectorsAuth.getRole(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsOdometros,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Odometros));