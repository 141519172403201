import * as Type from './actionsTypes';

const INITAL_STATE = {
  estados: [],
  cidades: []
};

export default function (state = INITAL_STATE, action) {
  switch (action.type) {
    case Type.UPDATE_ESTADOS: {
      return {
        ...state,
        estados: action.data
      }
    }

    case Type.UPDATE_CIDADES: {
      return {
        ...state,
        cidades: action.data
      }
    }

    default:
      return state;
  }
}

export const getConfig = (state) => state.rootState.config;
export const getEstados = (state) => state.rootState.config.estados;
export const getCidades = (state) => state.rootState.config.cidades;