/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/controleCaixa/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';

function* load({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'controleCaixa/filtro', data: filtro });
    yield put({
      type: TypeActions.FETCH_CONTROLE_CAIXAS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, tipo, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.post({ url: `controleCaixa/${tipo}`, data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    // yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao salvar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, tipo, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.put({ url: `controleCaixa/${tipo}/${data.id}`, data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    // yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findById({ id, tipo }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `controleCaixa/${tipo}/${id}` });
    if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
      response.data.arquivos = yield FileService.downloadArquivos(response.data.arquivos);
    }

    yield put({
      type: TypeActions.CONTROLE_CAIXA_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* importControleCaixa({ data, tipo, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: `controleCaixa/${tipo}/import`, data });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: `Importação realizada com sucesso!`
    });
    yield callback(response.data);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* cancelarControleCaixa({ tipo, id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.put({ url: `controleCaixa/${tipo}/${id}/cancelar` });
    yield put({ type: TypeActions.CANCELAR_CONTROLE_CAIXA_SUCCESS });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao atualizar as informações!'
    });
  }
}

function* liquidarControleCaixa({ tipo, id, status, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.put({ url: `controleCaixa/${tipo}/${id}/${status}` });
    yield put({ type: TypeActions.LIQUIDAR_CONTROLE_CAIXA_SUCCESS, status });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao atualizar as informações!'
    });
  }
}

function* exportControleCaixa({ filtro, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'controleCaixa/export',
      method: 'POST',
      data: filtro,
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório Controle Caixa.xlsx');
    yield callback(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar o relatório!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_CONTROLE_CAIXAS, load);
  yield takeLatest(TypeActions.SAVE_CONTROLE_CAIXA, save);
  yield takeLatest(TypeActions.UPDATE_CONTROLE_CAIXA, update);
  yield takeLatest(TypeActions.CONTROLE_CAIXA_FIND_BY_ID, findById);
  yield takeLatest(TypeActions.IMPORT_CONTROLE_CAIXA, importControleCaixa);
  yield takeLatest(TypeActions.CANCELAR_CONTROLE_CAIXA, cancelarControleCaixa);
  yield takeLatest(TypeActions.LIQUIDAR_CONTROLE_CAIXA, liquidarControleCaixa);
  yield takeLatest(TypeActions.EXPORTAR_CONTROLE_CAIXA, exportControleCaixa);
}