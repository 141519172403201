import * as Type from './actionsTypes';

const INITIAL_STATE = {
  equipamentos: [],
  selectedEquipamento: null,
  options: [],
  selectedHistoricoEquipamento: null,
};

const ordernarPorDataHistorico = (a, b) => {
  return a.dataHistorico.localeCompare(b.dataHistorico);
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_EQUIPAMENTOS_SUCCESS: {
      return {
        ...state,
        equipamentos: action.data
      }
    }
    case Type.DELETE_EQUIPAMENTO_SUCCESS: {
      return {
        ...state,
        equipamentos: state.equipamentos.filter(s => s.id !== action.id)
      }
    }
    case Type.SAVE_EQUIPAMENTO_SUCCESS: {
      return {
        ...state,
        equipamentos: [...state.equipamentos, action.data]
      }
    }
    case Type.UPDATE_EQUIPAMENTO_SUCCESS: {
      return {
        ...state,
        equipamentos: [...state.equipamentos.filter(s => s.id !== action.data.id), action.data]
      }
    }
    case Type.EQUIPAMENTO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedEquipamento: action.data
      }
    }
    case Type.HISTORICO_EQUIPAMENTO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedHistoricoEquipamento: action.data
      }
    }
    case Type.HISTORICO_EQUIPAMENTO_UPSERT_SUCCESS: {
      return {
        ...state,
        selectedEquipamento: {
          ...state.selectedEquipamento,
          historico: [...state.selectedEquipamento.historico.filter(s => s.id !== action.data.id), action.data].sort(ordernarPorDataHistorico)
        }
      }
    }
    case Type.CLEAR_EQUIPAMENTO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getEquipamentos = state => state.rootState.equipamento.equipamentos;
export const getSelectedEquipamento = state => state.rootState.equipamento.selectedEquipamento;
export const getEquipamentosOptions = state => state.rootState.equipamento.options;
export const getSelectedHistoricoEquipamento = state => state.rootState.equipamento.selectedHistoricoEquipamento;
