import * as Type from './actionsTypes';
import {ordenarPorCodigoString} from '../../utils';

const INITIAL_STATE = {
  subestacoes: [],
  options: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.SUBESTACOES_FIND_SUCCESS: {
      return {
        ...state,
        subestacoes: action.data.sort(ordenarPorCodigoString)
      }
    }
    case Type.SUBESTACOES_DELETE_SUCCESS: {
      return {
        ...state,
        subestacoes: state.subestacoes.filter(a => a.id !== action.data).sort(ordenarPorCodigoString)
      }
    }
    case Type.SUBESTACOES_SAVE_SUCCESS: {
      return {
        ...state,
        subestacoes: [...state.subestacoes, action.data].sort(ordenarPorCodigoString)
      }
    }
    case Type.SUBESTACOES_UPDATE_SUCCESS: {
      return {
        ...state,
        subestacoes: [...state.subestacoes.filter(s => s.id !== action.data.id), action.data].sort(ordenarPorCodigoString)
      }
    }
    case Type.SUBESTACOES_CLEAN: {
      return INITIAL_STATE;
    }
    case Type.SUBESTACOES_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data.sort(ordenarPorCodigoString)
      }
    }
    default:
      return state;
  }
}

export const getSubestacoes = state => state.rootState.subestacao.subestacoes;
export const getSubestacoesOptions = state => state.rootState.subestacao.options;
