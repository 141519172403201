import * as Type from './actionsTypes';

const INITIAL_STATE = {
  convocacoes: [],
  selectedConvocacao: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.CONVOCACOES_UPDATE_STATUS_SUCCESS:
    case Type.CONVOCACOES_FIND_ONE_SUCCESS:
    case Type.CONVOCACOES_UPDATE_SUCCESS:
    case Type.CONVOCACOES_SAVE_SUCCESS: {
      return {
        ...state,
        selectedConvocacao: action.data
      }
    }
    case Type.CONVOCACOES_CLEAR_SELECTED: {
      return {
        ...state,
        selectedConvocacao: {}
      }
    }
    case Type.CONVOCACOES_CLEAN: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getConvocacoes = state => state.rootState.convocacao.convocacoes;
export const getSelectedConvocacao = state => state.rootState.convocacao.selectedConvocacao;
