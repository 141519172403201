/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

import * as selectorsUsuarios from '../../store/usuario/reducer';
import * as actionsUsuarios from '../../store/usuario/actions';
import InformacaoBasica from './InformacaoBasica';
import ArquivosTab from './ArquivosTab';
import InformacaoPessoalTab from './InformacaoPessoalTab';

import { DialogDefault, WidgetTab } from '../../components';

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  email: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  role: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  paperScrollPaperDialog: {
    [theme.breakpoints.up('md')]: {
      minWidth: '70vw',
      minHeight: '70vh'
    },
  }
}));

const Formulario = ({ saveUsuario, updateUsuario, selectedUsuario, open, id, onClose, findUsuarioById, 
  resetPassword, readOnly, isAdmin, setProximaMatricula, userEmail }) => {

  const classes = useStyles();

  const [values, setValues] = useState({ ativo: true, additions: {} });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) findUsuarioById(id);
  }, [id, findUsuarioById]);

  useEffect(() => {
    if (id && selectedUsuario) {
      setValues(selectedUsuario);
    }
  }, [id, selectedUsuario]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateUsuario(values, saveCallback);
      else saveUsuario(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({ ativo: true, additions: {} });
    setProximaMatricula();
    onClose(isSave);
  }

  const readOnlyTabs = () => ["REGIANE MARIA DE OLIVEIRA", "MARIA"].includes(userEmail)
  const enableTabs = () => ["REGIANE MARIA DE OLIVEIRA", "MARIA", "JORDANIA FLORENCIA MOTA", "vinicius_admin"].includes(userEmail)

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Usuário"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
      headerAction={id && isAdmin? {
        onClick: () => resetPassword(id),
        title: 'Resetar Senha'
      } : null}
      readOnly={readOnly}
      paperScrollPaperClassName={classes.paperScrollPaperDialog}
    >
      <WidgetTab
        widgets={[
          {
            element: <InformacaoBasica id={id} values={values} setValues={setValues} errors={errors} readOnly={readOnly} isAdmin={isAdmin} />,
            label: "Informações Básicas",
            disabled: false
          },
          {
            element: <InformacaoPessoalTab values={values} setValues={setValues} readOnly={readOnlyTabs()} />,
            label: "Infomações Pessoais",
            disabled: !enableTabs()
          },
          {
            element: <ArquivosTab values={values} setValues={setValues} readOnly={readOnlyTabs()} />,
            label: "Documentos/Certificados",
            disabled: !enableTabs()
          },
        ]}
      />
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  selectedUsuario: selectorsUsuarios.getSelectedUsuario(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsUsuarios,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
