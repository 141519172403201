import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneDialog } from 'material-ui-dropzone';

const DropzoneModal = ({ onSave, acceptedFiles, showPreviews, maxFileSize, filesLimit, onClose, open }) => {
  const onSaveLocal = files => {
    onSave(files);
  }

  return (
    <>
      <DropzoneDialog
        open={open}
        onSave={onSaveLocal}
        acceptedFiles={acceptedFiles}
        showPreviews={showPreviews}
        maxFileSize={maxFileSize}
        onClose={onClose}
        filesLimit={filesLimit}
        previewText="Pré-visualização: "
        dialogTitle="Selecionar arquivos"
        cancelButtonText="Cancelar"
        submitButtonText="Salvar"
        dropzoneText="Arraste e solte um arquivo aqui ou clique"
        maxWidth="md"
        getFileAddedMessage={fileName => `Arquivo ${fileName} adicionado com sucesso`}
        getFileRemovedMessage={fileName => `Arquivo ${fileName} removido`}
        getFileLimitExceedMessage={filesLimit => `Número máximo permitido de arquivos excedido. Apenas ${filesLimit} permitidos`}
      />
    </>
  );
}

DropzoneModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  acceptedFiles: PropTypes.array,
  showPreviews: PropTypes.bool,
  maxFileSize: PropTypes.number,
  filesLimit: PropTypes.number,
  files: PropTypes.array,
};

DropzoneModal.defaultProps = {
  acceptedFiles: ['image/jpeg', 'image/png', 'image/bmp'],
  showPreviews: true,
  maxFileSize: 5000000,
  filesLimit: 10,
  files: [],
};

export default DropzoneModal;