import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Collapse,
  IconButton
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 4,
    border: theme.palette.bar.border,
    backgroundColor: theme.palette.bar.grayLight,
  },
  cardHeader: {
    background: theme.palette.bar.principal,
  },
  cardHeaderTitle: {
    color: theme.palette.textColor.white,
  },
  cardHeaderTitleTab: {
    fontSize: 14,
  },
  button: {
    marginRight: 15,
    marginTop: 5
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0px !important'
  },
  expand: {
    color: 'white',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const Widget = ({ children, title, actions, classNameCard, classNameContent, collapse, insideTab }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  // eslint-disable-next-line react/no-multi-comp
  const getHeaderActions = () => {
    return <>
      {
        actions.filter(a => (a.show === undefined || !!a.show) && a.isComponent).map(a => a.component)
      }
      {
        actions.filter(a => (a.show === undefined || !!a.show) && !a.isComponent).map(a => (
          <Button
            className={classes.button}
            color="primary" 
            variant="contained" 
            onClick={a.onClick}
          > 
            {a.title}
          </Button>
        ))
      }
      { collapse && 
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      }
    </>
  }

  return (
    <Card className={clsx(classes.root, classNameCard)}>
      <CardHeader 
        title={title} 
        classes={{root: classes.cardHeader, title: clsx(classes.cardHeaderTitle, insideTab && classes.cardHeaderTitleTab)}}
        action={getHeaderActions()}
      />
      <Collapse 
        in={expanded} 
        timeout="auto" 
        unmountOnExit
      >
        <CardContent className={clsx(classes.cardContent, classNameContent)}>
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
};

Widget.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  actions: PropTypes.array,
  classNameCard: PropTypes.object,
  classNameContent: PropTypes.object,
  collapse: PropTypes.bool,
  insideTab: PropTypes.bool,
};

Widget.defaultProps = {
  actions: [],
  classNameCard: {},
  classNameContent: {},
  collapse: false,
  insideTab: false
}

export default Widget;
