/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as selectorsPedido from '../../../store/pedido/reducer';
import { savePedido, updatePedido, findPedidoById } from '../../../store/pedido/actions';

import * as selectorsAuth from '../../../store/auth/reducer';
import moment from 'moment';

import {
  Input, DefaultSelect, DialogDefault, ArquivoContainer, DatePicker, UsuarioSelect
} from '../../../components';
import ProdutosContainer from '../ProdutosContainer.js';

const entradaSchema = {
  data: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  local: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  produtos: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const saidaSchema = {
  data: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  local: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  usuario: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  produtos: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
}));

const Formulario = ({ savePedido, updatePedido, selectedPedido, open, id, onClose, findPedidoById, authData, isEntrada }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isEntradaLocal, setIsEntradaLocal] = useState(isEntrada);

  useEffect(() => {
    if (open) {
      setValues({
        data: moment().valueOf(),
        usuarioNome: authData.nome,
        dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss"),
      });
    } else {
      setValues({});
    }
  }, [authData, open]);

  useEffect(() => {
    if (id) findPedidoById(id);
  }, [id]);

  useEffect(() => {
    if (!id) {
      setIsEntradaLocal(isEntrada);
    }
  }, [id, isEntrada]);

  useEffect(() => {
    if (id && selectedPedido && id === selectedPedido.id) {
      setValues({
        ...selectedPedido,
        data: moment(selectedPedido.data).valueOf(),
      });
      setIsEntradaLocal(selectedPedido.tipo === "ENTRADA");
    } else {
      setValues({
        data: moment().valueOf(),
        usuarioNome: authData.nome
      });
    }
  }, [id, selectedPedido, authData]);

  const onSave = () => {
    const dataToSave = {
      ...values,
      tipo: isEntradaLocal ? "ENTRADA" : "SAIDA", 
    }

    let erros = {}
    if (isEntradaLocal) {
      erros = validate(dataToSave, entradaSchema)
    } else {
      erros = validate(dataToSave, saidaSchema)
    }

    setErrors(erros);
    if (!erros || Object.keys(erros).length === 0) {
      if (dataToSave.file) {
        dataToSave.arquivo = {
          nome: dataToSave.file.name.split('.')[0],
          extensao: dataToSave.file.name.split('.').pop(),
          status: 'NOT_SYNC'
        }
      }

      if (dataToSave.id) updatePedido(dataToSave, saveCallback);
      else savePedido(dataToSave, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({});
    setIsEntradaLocal(false);
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title={`Pedido de ${isEntradaLocal ? "Entrada" : "Saída"}`}
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DatePicker
            label="Data do Pedido"
            onChange={data => setValues({
              ...values,
              data: data.valueOf()
            })}
            value={values.data}
            isRequired
            error={errors && errors.data ? true : false}
          />
        </Grid>
        {!id && <Grid item xs={9} className={classes.fieldContainer} />}
        {id && <>
          <Grid item xs={3} className={classes.fieldContainer}>
            <Input
              label="Criado Por"
              value={values.usuarioNome}
              disabled
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <Input
              label="Data Inclusão"
              value={values.dataInclusao}
              disabled
            />
          </Grid>
        </>}
        <Grid item xs={6} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.local ? true : false}
            isRequired
            label="Local"
            showEmpty
            options={[
              { id: 'Almoxarifado I  C17', nome: 'Almoxarifado I  C17 ' },
              { id: 'Almoxarifado II  C17', nome: 'Almoxarifado II  C17' },
              { id: 'Sala Drones', nome: 'Sala Drones' },
            ]}
            onSelect={event => {
              setValues({
                ...values,
                local: event.target.value,
              });
            }}
            value={values.local}
          />
        </Grid>
        {isEntradaLocal && <Grid item xs={6} className={classes.fieldContainer}>
          <Input
            label="Fornecedor"
            name='fornecedor'
            value={values.fornecedor}
            onChange={event => {
              setValues({
                ...values,
                fornecedor: event.target.value,
              });
            }}
          />
        </Grid>}
        {!isEntradaLocal && <Grid item xs={6} className={classes.fieldContainer}>
          <UsuarioSelect
            label="Colaborador"
            onSelect={usuario => setValues({
              ...values,
              usuario
            })}
            ativo
            value={values.usuario}
            isRequired={!isEntradaLocal}
            error={errors && errors.usuario}
          />
        </Grid>}
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Descrição"
            name="descricao"
            onChange={handleChange}
            value={values.descricao}
            rows={3}
            multiline
          />
        </Grid>
        <ProdutosContainer
          produtos={values.produtos}
          onSave={produtos => setValues({ ...values, produtos })}
          isEntrada={isEntradaLocal}
          showError={errors && errors.produtos}
        />
        <ArquivoContainer
          arquivos={values.arquivos}
          onSave={arquivos => setValues({ ...values, arquivos })}
        />
      </Grid>
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  selectedPedido: selectorsPedido.getSelectedPedido(state),
  authData: selectorsAuth.getAuth(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  savePedido,
  updatePedido,
  findPedidoById,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
