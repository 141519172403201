import * as Type from './actionsTypes';

const INITIAL_STATE = {
  cartoes: [],
  selectedCartao: null,
  options: [],
};

const ordernarData = (a, b) => {
  return a.codigo > b.codigo;
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_CARTOES_SUCCESS: {
      return {
        ...state,
        cartoes: action.data.sort(ordernarData)
      }
    }
    case Type.DELETE_CARTAO_SUCCESS: {
      return {
        ...state,
        cartoes: state.cartoes.filter(s => s.id !== action.data).sort(ordernarData)
      }
    }
    case Type.SAVE_CARTAO_SUCCESS: {
      return {
        ...state,
        cartoes: [...state.cartoes, action.data].sort(ordernarData)
      }
    }
    case Type.UPDATE_CARTAO_SUCCESS: {
      return {
        ...state,
        cartoes: [...state.cartoes.filter(s => s.id !== action.data.id), action.data].sort(ordernarData)
      }
    }
    case Type.CARTAO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedCartao: action.data
      }
    }
    case Type.CARTAO_FIND_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data
      }
    }
    case Type.CLEAR_CARTAO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getCartoes = state => state.rootState.cartao.cartoes;
export const getSelectedCartao = state => state.rootState.cartao.selectedCartao;
export const getCartoesOptions = state => state.rootState.cartao.options;
