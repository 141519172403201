import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 4,
    border: theme.palette.bar.border,
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const WidgetDescription = ({ title, description, icon, avatarClassName, rootStyle, avatarStyle }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} style={rootStyle}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {title}
            </Typography>
            <Typography variant="h3">{description}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={clsx(classes.avatar, avatarClassName)} style={avatarStyle}>
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

WidgetDescription.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  avatarClassName: PropTypes.node,
  rootStyle: PropTypes.object,
  avatarStyle: PropTypes.object,
};

WidgetDescription.defaultProps = {
  avatarClassName: null,
  rootStyle: {},
  avatarStyle: {}
}

export default WidgetDescription;
