/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import * as selectorsCartoes from '../../store/cartao/reducer';
import * as actionsCartoes from '../../store/cartao/actions';
import * as selectorsAuth from '../../store/auth/reducer';

import moment from 'moment';

import { Input, DialogDefault, TrueFalseSelect } from '../../components';

const schema = {
  codigo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  ativo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  buttonContainer: {
    width: '100%',
  }
}));

const Formulario = ({ saveCartao, updateCartao, selectedCartao, open, id, onClose, findCartaoById, authData }) => {

  const classes = useStyles();

  const [values, setValues] = useState({ 
    criadoPor: authData.id,
    criadoPorName: authData.nome,
    dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss")
  });

  useEffect(() => {
    setValues({
      criadoPor: authData.id,
      criadoPorName: authData.nome,
      dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss")
    });
  }, [authData]);


  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) findCartaoById(id);
  }, [id, findCartaoById]);

  useEffect(() => {
    if (id && selectedCartao && id === selectedCartao.id) {
      setValues(selectedCartao);
    } 
  }, [id, selectedCartao]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);

    if (!erros) {
      if (values.id) updateCartao(values, saveCallback);
      else saveCartao(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({
      criadoPor: authData.id,
      criadoPorName: authData.nome,
      dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss")
    });
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Cartão"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={4} className={classes.fieldContainer}>
          <Input
            error={errors && errors.codigo}
            helperText={errors && errors.codigo ? errors.codigo : null}
            isRequired
            label="Número"
            name="codigo"
            onChange={handleChange}
            value={values.codigo}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <Input
            error={errors && errors.nome}
            helperText={errors && errors.nome ? errors.nome : null}
            isRequired
            label="Nome"
            name="nome"
            onChange={handleChange}
            value={values.nome}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.ativo ? true : false}
            isRequired
            label="Ativo"
            name="ativo"
            onChange={handleChange}
            value={values.ativo}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <Input
            label="Criado Por"
            disabled
            value={values.criadoPorName}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <Input
            label="Data Inclusão"
            disabled
            value={values.dataInclusao}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
        <Input
            label="Descrição"
            name="descricao"
            onChange={handleChange}
            value={values.descricao}
            rows={3}
            multiline
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};

Formulario.propTypes = {
  findCartaoById: PropTypes.func.isRequired, 
  saveCartao: PropTypes.func.isRequired,
  updateCartao: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  authData: PropTypes.object.isRequired
};

Formulario.defaultValue = {
  id: null,
}

const mapStateToProps = state => ({
  selectedCartao: selectorsCartoes.getSelectedCartao(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsCartoes,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
