import * as Type from './actionsTypes';

const INITIAL_STATE = {
  tags: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.data
      }
    }
    case Type.CLEAR_TAGS: {
      return INITIAL_STATE
    }
    default:
      return state;
  }
}

export const getTags = state => state.rootState.tag.tags;