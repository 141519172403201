/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import {
  Input,
  EstadoInput,
  CidadeInput,
  Widget,
  DatePicker,
} from '../../components';

const useStyles = makeStyles(() => ({
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  gridContainer: {
    padding: 24
  },
}));

const InformacaoPessoalTab = ({ values, setValues, readOnly }) => {
  const classes = useStyles();

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlerEstado = value => {
    setValues({
      ...values,
      estado: value,
      cidade: '',
    });
  }
  const handlerCidade = value => {
    setValues({
      ...values,
      cidade: value
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} className={classes.fieldContainer}>
        <Input
          label="RG"
          name="rg"
          onChange={handleChange}
          value={values.rg}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4} className={classes.fieldContainer}>
        <Input
          label="CPF"
          name="cpf"
          onChange={handleChange}
          value={values.cpf}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4} className={classes.fieldContainer}>
        <DatePicker
          label="Data de Nascimento"
          onChange={data => setValues({
            ...values,
            dataNascimento: data,
          })}
          value={values.dataNascimento}
          disabled={readOnly}
          showInput
        />
      </Grid>
      <Grid item xs={4} className={classes.fieldContainer}>
        <Input
          label="Matrícula CEMIG"
          name="matriculaCemig"
          onChange={handleChange}
          value={values.matriculaCemig}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4} className={classes.fieldContainer}>
        <Input
          label="CTPS"
          name="ctps"
          onChange={handleChange}
          value={values.ctps}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4} className={classes.fieldContainer}>
        <Input
          label="CTPS Serie"
          name="ctpsSerie"
          onChange={handleChange}
          value={values.ctpsSerie}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} className={classes.fieldContainer}>
        <Widget title={"Endereço"} classNameCard={classes.classNameCard}>
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={6} className={classes.fieldContainer}>
              <Input
                label="Rua"
                name="rua"
                onChange={handleChange}
                value={values.rua}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={2} className={classes.fieldContainer}>
              <Input
                label="Número"
                name="numero"
                onChange={handleChange}
                value={values.numero}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={4} className={classes.fieldContainer}>
              <Input
                label="Complemento"
                name="complemento"
                onChange={handleChange}
                value={values.complemento}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <Input
                label="Bairro"
                name="bairro"
                onChange={handleChange}
                value={values.bairro}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <Input
                label="CEP"
                name="cep"
                onChange={handleChange}
                value={values.cep}
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={3} xs={12} className={classes.fieldContainer}>
              <EstadoInput
                estado={values.estado}
                onSelect={handlerEstado}
                disabled={readOnly}
              />
            </Grid>
            <Grid item md={3} xs={12} className={classes.fieldContainer}>
              <CidadeInput
                cidade={values.cidade}
                estado={values.estado}
                onSelect={handlerCidade}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Widget>
      </Grid>
    </Grid>
  );
};

export default InformacaoPessoalTab;
