/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid, DialogContentText } from '@material-ui/core';

import * as selectorsControleCaixas from '../../store/controleCaixa/reducer';
import * as actionsControleCaixas from '../../store/controleCaixa/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import moment from 'moment';
import clsx from 'clsx';

import { 
  Input, 
  DefaultSelect, 
  DialogDefault, 
  NumberInput, 
  TrueFalseSelect, 
  ArquivoContainer, 
  DatePicker,
  UsuarioSelect
} from '../../components';

const schema = {
  data: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  possuiNotaFiscal: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  tipoDespesa: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  formaPagamento: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  valor: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  cancelada: {
    color: "red",
    fontStyle: "italic"
  }
}));

const FormularioDespesa = ({ saveControleCaixa, updateControleCaixa, selectedControleCaixa, open, id, onClose, findControleCaixaById, authData, cancelarControleCaixa, liquidarControleCaixa }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [openCancelar, setOpenCancelar] = useState(false);
  const [openLiquidar, setOpenLiquidar] = useState(false);
  const [openALiquidar, setOpenALiquidar] = useState(false);

  useEffect(() => {
    if (open) {
      setValues({
        criadoPorId: authData.id,
        criadoPorName: authData.nome,
        data: moment().valueOf(),
        dataCriacaoFormat: moment().format("DD/MM/YYYY HH:mm"),
        status: 'A_LIQUIDAR'
      });
    }
  }, [open, authData]);

  useEffect(() => {
    if (id) findControleCaixaById(id, "despesas");
  }, [id, findControleCaixaById]);

  useEffect(() => {
    if (id && selectedControleCaixa && id === selectedControleCaixa.id) {
      setValues(selectedControleCaixa);
    }
  }, [id, selectedControleCaixa]);

  const onSave = () => {
    const erros = validate(values, schema);
    if (['APORTE', 'FOLHA_PAGAMENTO', 'PAGAMENTO_DIGITACAO', 'PAGAMENTO_PRODUCAO'].includes(values.tipoDespesa) && !values.usuarioId) {
      erros['usuarioId'] = true;
    }

    setErrors(erros);
    if (!erros) {
      if (values.id) updateControleCaixa(values, "despesas", saveCallback);
      else saveControleCaixa(values, "despesas", saveCallback);
    }
  }

  const saveCallback = () => {
    onCloseLocal(true);
  }

  const onCloseLocal = isSave => {
    setErrors({});
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Despesa"
      cancelMessage="Fechar"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      readOnly={values.cancelada || values.status === "LIQUIDADO"}
      maxWidth="md"
      headerAction={[
        {
          onClick: () => setOpenCancelar(true),
          title: "Cancelar",
          show: (values.cancelada === undefined || values.cancelada === false) && values.id !== undefined && values.status === "A_LIQUIDAR"
        },
        {
          onClick: () => setOpenLiquidar(true),
          title: "Liquidar",
          show: values.status === "A_LIQUIDAR" && values.id !== undefined && values.cancelada === false
        },
        {
          onClick: () => setOpenALiquidar(true),
          title: "A Liquidar",
          show: values.status === "LIQUIDADO" && values.id !== undefined && values.cancelada === false
        },
      ]}
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        {values.cancelada && <Grid item xs={12} className={clsx(classes.fieldContainer, classes.cancelada)}>
          **** Despesa Cancelada por {values.modificadoPorName} em {values.dataUltimaAtualizacaoFormat}
        </Grid>}
        {values.status === 'LIQUIDADO' && <Grid item xs={12} className={clsx(classes.fieldContainer, classes.cancelada)}>
          **** Despesa Liquidada por {values.modificadoPorName} em {values.dataUltimaAtualizacaoFormat}
        </Grid>}
        <Grid item xs={4} className={classes.fieldContainer}>
          <DatePicker
            error={errors && errors.data ? true : false}
            isRequired
            label="Data da Despesa"
            onChange={data => setValues({
              ...values,
              data: data.valueOf()
            })}
            value={values.data}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <TrueFalseSelect
            error={errors && errors.possuiNotaFiscal ? true : false}
            isRequired
            label="Possui Nota Fiscal"
            name="possuiNotaFiscal"
            onChange={handleChange}
            value={values.possuiNotaFiscal}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <DefaultSelect
            label="Status"
            options={[
              { id: 'A_LIQUIDAR', nome: 'A Liquidar' },
              { id: 'LIQUIDADO', nome: 'Liquidado' }
            ]}
            value={values.status}
            disabled
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.tipoDespesa ? true : false}
            isRequired
            label="Tipo"
            name="tipoDespesa"
            showEmpty
            options={[
              {id: 'ALUGUEL_GARAGEM', nome: ' Aluguel de Garagem'}, 
              {id: 'ALUGUEL_VEICULOS', nome: ' Aluguel de Veículo'}, 
              {id: 'APORTE', nome: ' Aporte'},
              {id: 'CONDOMINIO', nome: ' Condominio'},
              {id: 'DARF', nome: ' Darf'}, 
              {id: 'ENERGIA_ELETRICA', nome: ' Energia Eletrica'}, 
              {id: 'FOLHA_PAGAMENTO', nome: ' Folha Pagamento'}, 
              {id: 'HONORARIOS_CONTABEIS', nome: ' Honorarios Contabeis'}, 
              {id: 'IMPOSTOS', nome: ' Impostos'},
              {id: 'INTERNET', nome: ' Internet'}, 
              {id: 'MANUTENCAO_DRONE', nome: ' Manutenção Drone'}, 
              {id: 'MANUTENCAO_VEICULO', nome: ' Manutenção Veículo'}, 
              {id: 'OUTROS', nome: ' Outros'},
              {id: 'PAGAMENTO_DIGITACAO', nome: ' Pagamento Digitação'},
              {id: 'PAGAMENTO_PRODUCAO', nome: ' Pagamento Produção'},
              {id: 'PLANO_SAUDE', nome: ' Plano de Saude'},
              {id: 'SEGURO_CONTRA_ACIDENTE', nome: ' Seguro Contra Acidente'},
              {id: 'TAXAS', nome: ' Taxas'}
            ]}
            onSelect={event => {
              setValues({
                ...values,
                [event.target.name]: event.target.value,
                usuarioId: null
              });
            }}
            value={values.tipoDespesa}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <UsuarioSelect
            ativo
            error={errors && errors.usuarioId ? true : false}
            onSelect={usuarioId => setValues({ ...values, usuarioId })}
            value={values.usuarioId}
            disabled={values.cancelada || values.status === 'LIQUIDADO' || !values.tipoDespesa || (values.tipoDespesa && !['APORTE', 'FOLHA_PAGAMENTO', 'PAGAMENTO_DIGITACAO', 'PAGAMENTO_PRODUCAO'].includes(values.tipoDespesa)) }
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.formaPagamento ? true : false}
            isRequired
            label="Forma Pagamento"
            name="formaPagamento"
            showEmpty
            options={[
              {id: 'BANCO', nome: 'Banco'},
              {id: 'CAIXA', nome: 'Caixa'},
            ]}
            onSelect={handleChange}
            value={values.formaPagamento}
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <NumberInput
            error={errors && errors.valor ? true : false}
            isRequired
            label="Valor"
            onChange={valor => setValues({
              ...values,
              valor
            })}
            value={values.valor}
            format="##,######"
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Criado Por"
            disabled
            value={values.criadoPorName}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Criação"
            disabled
            value={values.dataCriacaoFormat}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Última Atualização"
            disabled
            value={values.dataUltimaAtualizacaoFormat}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Descrição"
            name="descricao"
            onChange={handleChange}
            value={values.descricao}
            rows={3}
            multiline
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Observação"
            name="observacao"
            onChange={handleChange}
            value={values.observacao}
            rows={3}
            multiline
            disabled={values.cancelada || values.status === 'LIQUIDADO'}
          />
        </Grid>
        <ArquivoContainer 
          arquivos={values.arquivos}
          onSave={arquivos => setValues({ ...values, arquivos })}
          disabled={values.cancelada || values.status === 'LIQUIDADO'}
        />
      </Grid>
      <DialogDefault
        open={openCancelar}
        useFullScreen={false}
        handlerCancel={() => setOpenCancelar(false)}
        handlerConfirm={() => { setOpenCancelar(false); cancelarControleCaixa(values.id, "despesas", saveCallback); }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente cancelar a despesa? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openLiquidar}
        useFullScreen={false}
        handlerCancel={() => setOpenLiquidar(false)}
        handlerConfirm={() => { setOpenLiquidar(false); liquidarControleCaixa(values.id, "despesas", "LIQUIDADO", saveCallback); }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente liquidar a despesa? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openALiquidar}
        useFullScreen={false}
        handlerCancel={() => setOpenALiquidar(false)}
        handlerConfirm={() => { setOpenALiquidar(false); liquidarControleCaixa(values.id, "despesas", "A_LIQUIDAR", saveCallback); }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja realmente alterar o status para 'A Liquidar'? </DialogContentText>
      </DialogDefault>
    </DialogDefault>
  );
};

FormularioDespesa.propTypes = {
  findControleCaixaById: PropTypes.func.isRequired, 
  saveControleCaixa: PropTypes.func.isRequired,
  updateControleCaixa: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired, 
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  selectedControleCaixa: PropTypes.object,
  authData: PropTypes.object.isRequired,
  cancelarControleCaixa: PropTypes.func.isRequired
};

FormularioDespesa.defaultValue = {
  selectedControleCaixa: null,
  id: null,
}

const mapStateToProps = state => ({
  selectedControleCaixa: selectorsControleCaixas.getSelectedControleCaixa(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsControleCaixas,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FormularioDespesa));
