import BaseService from './BaseService';

export default class UsuarioService extends BaseService {
  static async getAll() {
    return await this.execute({
      url: 'usuarios',
      method: 'GET'
    });
  }

  static async findById(id) {
    return await this.execute({
      url: `usuarios/${id}`,
      method: 'GET'
    });
  }

  static async save(data) {
    return await this.execute({
      url: 'usuarios',
      method: 'POST',
      data
    });
  }

  static async update(id, data) {
    return await this.execute({
      url: `usuarios/${id}`,
      method: 'PUT',
      data
    });
  }

  static async delete(id) {
    return await this.execute({
      url: `usuarios/${id}`,
      method: 'DELETE'
    });
  }

  static async resetPassword(usuarioId) {
    return await this.execute({
      url: 'usuarios/reset/password',
      method: 'POST',
      data: {
        usuarioId
      }
    });
  }
}