import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Password, Perfil } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const Settings = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} md={7}>
        <Perfil />
      </Grid>
      <Grid item xs={12} md={5}>
        <Password />
      </Grid>
    </Grid>
  );
};

export default Settings;