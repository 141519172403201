/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText, Button } from '@material-ui/core';
import { Table, DialogDefault } from '../../components';
import * as selectorsAuth from '../../store/auth/reducer';
import * as selectorsAportes from '../../store/aporte/reducer';
import * as actionsAportes from '../../store/aporte/actions';
import { findFotoById } from '../../store/nota/actions';
import Formulario from './Formulario';
import FormularioNota from '../Notas/Formulario';
import moment from 'moment';
import * as Role from '../../constants/role';
import { CameraEnhance, MonetizationOn, ArrowLeft } from '@material-ui/icons';
import Viewer from 'react-viewer';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  resume: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  resumeItem: {
    paddingBottom: "0px !important"
  },
  resumeTitle: {
    fontWeight: "bold",
    fontSize: 18
  },
  redTitle: {
    color: "red"
  },
  back: {
    paddingTop: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  iconValidate: {
    marginRight: theme.spacing(1),
    color: 'green'
  },
  iconInvalidate: {
    marginRight: theme.spacing(1),
    color: 'red'
  },
  buttonBack: {
    fontSize: 12,
    paddingLeft: theme.spacing(3)
  },
}));

const Aportes = ({ aporteByUsuario, loadAportesByInspetor, userRole, findAporteFotosById, match, history, findFotoById }) => {
  const [open, setOpen] = useState(false);
  const [openNota, setOpenNota] = useState(false);
  const [id, setId] = useState(null);
  const [idNota, setIdNota] = useState(null);
  const [usuarioId, setUsuarioId] = useState(null);
  const [titles] = useState(['', 'Data', 'Valor', 'Inspetor/Observador/Ajudante']);
  const [openNotFotoModal, setOpenNotFotoModal] = useState(false);
  const [openFotoViewer, setOpenFotoViewer] = useState(false);
  const [images, setImages] = useState([]);
  const [aporteByUsuarioLocal, setAporteByUsuarioLocal] = useState({});

  const classes = useStyles();

  useEffect(() => {
    if (match && match.params && match.params.usuarioId) {
      setUsuarioId(match.params.usuarioId);
    }
  }, [match]);

  useEffect(() => {
    if (aporteByUsuario) setAporteByUsuarioLocal(aporteByUsuario);
  }, [aporteByUsuario]);

  useEffect(() => {
    loadAportesByInspetor({ usuarioId: match.params.usuarioId })
  }, [usuarioId]);

  const onEdit = id => {
    const currentRow = aporteByUsuarioLocal.aportes.filter(s => s.id === id)[0];
    if (currentRow.isAporte) {
      setOpen(true);
      setId(id);
    } else {
      setOpenNota(true);
      setIdNota(id);
    } 
  }

  const onAdd = () => {
    setOpen(true);
    setId(null);
    setIdNota(null);
  }

  const onClose = isSave => {
    if (isSave) {
      loadAportesByInspetor({ usuarioId: match.params.usuarioId })
    }
    setOpen(false);
    setOpenNota(false);
    setId(null);
    setIdNota(null);
  }

  const isAdmin = () => Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole;

  const renderRows = d => {
    return (
      <>
        <TableCell size="small">
          <div style={{ display: 'flex' }}>
            <MonetizationOn style={{color: d.background}} />
          </div>
        </TableCell>
        <TableCell>{moment(Number(d.dataTimestamp)).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{d.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </TableCell>
        <TableCell>{d.inspetorName}</TableCell>
      </>
    )
  }

  return (
    <>
      {isAdmin() && <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.back}>
        <Grid item xs={2}>
          <Button
            color="secondary"
            className={classes.buttonBack}
            onClick={() => history.goBack()}
          >
            <ArrowLeft /> Voltar
          </Button>
        </Grid>
      </Grid>}
      <Grid item xs={12} className={classes.resume}>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} className={classes.resumeItem}><span className={classes.resumeTitle}>Inspetor/Ajudante:</span> <span>{aporteByUsuarioLocal.usuarioName}</span></Grid>
          <Grid item xs={12} className={classes.resumeItem}><span className={classes.resumeTitle}>Balanço: </span> <span className={clsx(aporteByUsuarioLocal.valor && aporteByUsuarioLocal.valor < 0 && classes.redTitle)}>{aporteByUsuarioLocal.valor ? aporteByUsuarioLocal.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "-"}</span> </Grid>
          <Grid item xs={12} className={classes.resumeItem}><span className={classes.resumeTitle}>Data do Último Aporte: </span> <span>{aporteByUsuarioLocal.dataUltimoAporte ? moment(Number(aporteByUsuarioLocal.dataUltimoAporte)).format("DD/MM/YYYY") : "-"}</span> </Grid>
          <Grid item xs={12} className={classes.resumeItem}><span className={classes.resumeTitle}>Data do Último Gasto: </span> <span>{aporteByUsuarioLocal.dataUltimoGasto ? moment(Number(aporteByUsuarioLocal.dataUltimoGasto)).format("DD/MM/YYYY") : "-"}</span> </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={aporteByUsuarioLocal && aporteByUsuarioLocal.aportes ? aporteByUsuarioLocal.aportes : []}
          titles={titles}
          headerTitle="Aportes - Entrada e Saída"
          deleteMessage="O aporte será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onAdd={onAdd}
          showDelete={false}
          hasAdd={isAdmin()}
          actions={
            [
              {
                key: 'view',
                icon: <CameraEnhance />,
                onClick: id => {
                  const currentRow = aporteByUsuarioLocal.aportes.filter(s => s.id === id)[0];
                  if (currentRow.isAporte) {
                    findAporteFotosById(id, files => {
                      if (files && files.length > 0) {
                        setImages(files);
                        setOpenFotoViewer(true);
                      } else {
                        setOpenNotFotoModal(true);
                      }
                    });
                  } else {
                    findFotoById(id, files => {
                      if (files && files.length > 0) {
                        setImages(files);
                        setOpenFotoViewer(true);
                      } else {
                        setOpenNotFotoModal(true);
                      }
                    });
                  }

                }
              },
            ]
          }
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          isAdmin={isAdmin()}
          inspetorId={usuarioId}
          isEdit={id}
        />
        <FormularioNota 
          id={idNota}
          open={openNota}
          onClose={onClose}
          isAdmin={isAdmin()}
        />
      </Grid>
      <Viewer
        visible={openFotoViewer}
        onClose={() => {
          setOpenFotoViewer(false);
          setImages([]);
        }}
        images={images}
        zIndex={3000}
        scalable={false}
        noImgDetails
        zoomSpeed={0.25}
        downloadable
      />
      <DialogDefault
        open={openNotFotoModal}
        handlerCancel={() => setOpenNotFotoModal(false)}
        readOnly
      >
        <DialogContentText> Aporte não possui foto </DialogContentText>
      </DialogDefault>
    </>
  );
};

Aportes.propTypes = {
  deleteAporte: PropTypes.func.isRequired,
  loadAportesByInspetor: PropTypes.func.isRequired,
  aporteByUsuario: PropTypes.array,
  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  userRole: selectorsAuth.getRole(state),
  aporteByUsuario: selectorsAportes.getAporteByUsuario(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsAportes,
  findFotoById
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Aportes));