export const SUBESTACOES_FIND = 'SUBESTACOES_FIND';
export const SUBESTACOES_FIND_SUCCESS = 'SUBESTACOES_FIND_SUCCESS';

export const SUBESTACOES_DELETE = 'SUBESTACOES_DELETE';
export const SUBESTACOES_DELETE_SUCCESS = 'SUBESTACOES_DELETE_SUCCESS';

export const SUBESTACOES_SAVE = 'SUBESTACOES_SAVE';
export const SUBESTACOES_SAVE_SUCCESS = 'SUBESTACOES_SAVE_SUCCESS';

export const SUBESTACOES_UPDATE = 'SUBESTACOES_UPDATE';
export const SUBESTACOES_UPDATE_SUCCESS = 'SUBESTACOES_UPDATE_SUCCESS';

export const SUBESTACOES_CLEAN = 'SUBESTACOES_CLEAN';

export const SUBESTACOES_OPTIONS = 'SUBESTACOES_OPTIONS';
export const SUBESTACOES_OPTIONS_SUCCESS = 'SUBESTACOES_OPTIONS_SUCCESS';