export const FETCH_PEDIDOS = 'FETCH_PEDIDOS';
export const FETCH_PEDIDOS_SUCCESS = 'FETCH_PEDIDOS_SUCCESS';

export const DELETE_PEDIDO = 'DELETE_PEDIDO';
export const DELETE_PEDIDO_SUCCESS = 'DELETE_PEDIDO_SUCCESS';

export const SAVE_PEDIDO = 'SAVE_PEDIDO';
export const SAVE_PEDIDO_SUCCESS = 'SAVE_PEDIDO_SUCCESS';

export const UPDATE_PEDIDO = 'UPDATE_PEDIDO';
export const UPDATE_PEDIDO_SUCCESS = 'UPDATE_PEDIDO_SUCCESS';

export const CLEAR_PEDIDO = 'CLEAR_PEDIDO';

export const PEDIDO_FIND_BY_ID = 'PEDIDO_FIND_BY_ID';
export const PEDIDO_FIND_BY_ID_SUCCESS = 'PEDIDO_FIND_BY_ID_SUCCESS';

export const PEDIDO_EXPORT = 'PEDIDO_EXPORT';