import * as Type from './actionsTypes';

const INITIAL_STATE = {
  controleCaixas: [],
  selectedControleCaixa: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_CONTROLE_CAIXAS_SUCCESS: {
      return {
        ...state,
        controleCaixas: action.data
      }
    }
    case Type.DELETE_CONTROLE_CAIXA_SUCCESS: {
      return {
        ...state,
        controleCaixas: state.controleCaixas.filter(s => s.id !== action.data)
      }
    }
    case Type.SAVE_CONTROLE_CAIXA_SUCCESS: {
      return {
        ...state,
        controleCaixas: [...state.controleCaixas, action.data]
      }
    }
    case Type.UPDATE_CONTROLE_CAIXA_SUCCESS: {
      return {
        ...state,
        controleCaixas: [...state.controleCaixas.filter(s => s.id !== action.data.id), action.data]
      }
    }
    case Type.CONTROLE_CAIXA_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedControleCaixa: action.data
      }
    }
    case Type.CANCELAR_CONTROLE_CAIXA_SUCCESS: {
      return {
        ...state,
        selectedControleCaixa: {
          ...state.selectedControleCaixa,
          cancelada: true,
          status: 'CANCELADO'
        }
      }
    }
    case Type.LIQUIDAR_CONTROLE_CAIXA_SUCCESS: {
      return {
        ...state,
        selectedControleCaixa: {
          ...state.selectedControleCaixa,
          status: action.status
        }
      }
    }
    case Type.CLEAR_CONTROLE_CAIXA: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getControleCaixas = state => state.rootState.controleCaixa.controleCaixas;
export const getSelectedControleCaixa = state => state.rootState.controleCaixa.selectedControleCaixa;
