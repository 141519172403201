/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as selectorsDrones from '../../store/drone/reducer';
import * as actionsDrones from '../../store/drone/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import moment from 'moment';

import { Input, DefaultSelect, DialogDefault, NumberInput, TrueFalseSelect, ArquivoContainer, DatePicker, UsuarioSelect } from '../../components';

const schema = {
  tipoHistorico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataHistorico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const schemaRepasse = {
  tipoHistorico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataHistorico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  tipoRepasse: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  posseEletropontes: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  usuario: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const schemaManutencao = {
  tipoHistorico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataHistorico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  valorManutencao: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  observacao: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const schemaVendaTroca = {
  tipoHistorico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataHistorico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  valorRecebido: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  observacao: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const getSchema = tipoHistorico => {
  switch (tipoHistorico) {
    case 'MANUTENCAO':
      return schemaManutencao
    case 'REPASSE':
      return schemaRepasse
    case 'VENDA_TROCA':
      return schemaVendaTroca
    default:
      return schema
  }
}

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const repasse = (values, handleChange, setValues, errors, classes) => {
  return (
    <>
      <Grid item xs={6} className={classes.fieldContainer}>
        <UsuarioSelect
          label="Colaborador"
          onSelect={usuario => setValues({
            ...values,
            usuario
          })}
          ativo
          value={values.usuario}
          error={errors && errors.usuario ? true : false}
        />
      </Grid>
      <Grid item xs={6} className={classes.fieldContainer}>
        <Input
          label="Região"
          name="regiao"
          onChange={handleChange}
          value={values.regiao}
        />
      </Grid>
      <Grid item xs={6} className={classes.fieldContainer}>
        <DefaultSelect
          error={errors && errors.tipoRepasse ? true : false}
          isRequired
          label="Tipo"
          name="tipoRepasse"
          showEmpty
          options={[
            { id: 'ENTREGA', nome: 'Entrega' },
            { id: 'DEVOLUCAO', nome: 'Devolução' },
          ]}
          onSelect={handleChange}
          value={values.tipoRepasse}
        />
      </Grid>
      <Grid item xs={6} className={classes.fieldContainer}>
        <TrueFalseSelect
          error={errors && errors.posseEletropontes ? true : false}
          isRequired
          label="Está com Eletropontes"
          name="posseEletropontes"
          onChange={handleChange}
          value={values.posseEletropontes}
        />
      </Grid>
    </>
  )
}

const manutencao = (values, handleChange, setValues, errors, classes) => {
  return (
    <>
      <Grid item xs={4} className={classes.fieldContainer}>
        <NumberInput
          prefix="R$"
          label="Valor da Manutenção"
          name="valorManutencao"
          onChange={valor => setValues({
            ...values,
            valorManutencao: valor
          })}
          value={values.valorManutencao}
          decimalScale={2}
          error={errors && errors.valorManutencao ? true : false}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <TrueFalseSelect
          label="Foi Pago"
          name="pago"
          onChange={handleChange}
          value={values.pago}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <TrueFalseSelect
          label="Emitiu Nota Fiscal"
          onChange={handleChange}
          name="notaFiscal"
          value={values.notaFiscal}
        />
      </Grid>

      <Grid item xs={6} className={classes.fieldContainer}>
        <Input
          label="Profissional Responsável"
          name="profissionalResponsavel"
          onChange={handleChange}
          value={values.profissionalResponsavel}
        />
      </Grid>
      <Grid item xs={6} className={classes.fieldContainer}>
        <Input
          label="Empresa"
          name="empresa"
          onChange={handleChange}
          value={values.empresa}
        />
      </Grid>
    </>
  )
}

const vendaTroca = (values, setValues, errors, classes) => {
  return (
    <>
      <Grid item xs={12} className={classes.fieldContainer}>
        <NumberInput
          prefix="R$"
          label="Valor Recebido"
          name="valorRecebido"
          onChange={valor => setValues({
            ...values,
            valorRecebido: valor
          })}
          value={values.valorRecebido}
          decimalScale={2}
          error={errors && errors.valorRecebido ? true : false}
        />
      </Grid>
    </>
  )
}

const Formulario = ({ upsertHistorico, selectedHistoricoDrone, open, id, onClose, findHistoricoById, authData, idDrone, afterSave }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setValues({
        criadoPor: authData.id,
        criadoPorName: authData.nome,
        dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss"),
      });
    } else {
      setValues({});
    }
  }, [authData, open]);

  useEffect(() => {
    if (id) findHistoricoById(id);
  }, [id, findHistoricoById]);

  useEffect(() => {
    if (id && selectedHistoricoDrone && id === selectedHistoricoDrone.id) {
      setValues(selectedHistoricoDrone);
    }
  }, [id, selectedHistoricoDrone]);

  const onSave = () => {
    const erros = validate(values, getSchema(values.tipoHistorico));
    setErrors(erros);
    if (!erros) {
      upsertHistorico({
        ...values,
        drone: idDrone,
      }, saveCallback);
    }
  }

  const saveCallback = (success, data) => {
    if (success) {
      setValues({});
      onCloseLocal(true);
      afterSave(data);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeTipo = event => {
    setValues({ tipoHistorico: event.target.value })
    setErrors({});
  };

  const titleData = () => {
    switch (values.tipoHistorico) {
      case 'MANUTENCAO':
        return 'Data da Manutenção'
      case 'REPASSE':
        return 'Data da Entrega'
      case 'VENDA_TROCA':
        return 'Data da Transferência'
      default:
        return 'Data'
    }
  }

  const titleObservacao = () => {
    switch (values.tipoHistorico) {
      case 'MANUTENCAO':
        return 'Manutenção Realizada'
      case 'VENDA_TROCA':
        return 'Negociação (Observação)'
      default:
        return 'Observação'
    }
  }

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Histórico de Drones"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DefaultSelect
            error={errors && errors.tipoHistorico ? true : false}
            isRequired
            label="Tipo do Histórico"
            name="tipoHistorico"
            showEmpty
            options={[
              { id: 'MANUTENCAO', nome: 'Manutenção' },
              { id: 'REPASSE', nome: 'Repasse' },
              { id: 'VENDA_TROCA', nome: 'Venda/Troca' },
            ]}
            onSelect={handleChangeTipo}
            value={values.tipoHistorico}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <DatePicker
            label={titleData()}
            error={errors && errors.dataHistorico ? true : false}
            onChange={data => setValues({
              ...values,
              dataHistorico: data.valueOf()
            })}
            value={values.dataHistorico}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Criado Por"
            disabled
            value={values.criadoPorName}
          />
        </Grid>
        <Grid item xs={3} className={classes.fieldContainer}>
          <Input
            label="Data Inclusão"
            disabled
            value={values.dataInclusao}
          />
        </Grid>
        {values.tipoHistorico === 'MANUTENCAO' && manutencao(values, handleChange, setValues, errors, classes)}
        {values.tipoHistorico === 'REPASSE' && repasse(values, handleChange, setValues, errors, classes)}
        {values.tipoHistorico === 'VENDA_TROCA' && vendaTroca(values, setValues, errors, classes)}
        {values.tipoHistorico && <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label={titleObservacao()}
            name="observacao"
            onChange={handleChange}
            value={values.observacao}
            rows={3}
            multiline
            error={errors && errors.observacao && (values.tipoHistorico === 'MANUTENCAO' || values.tipoHistorico === 'VENDA_TROCA')  ? true : false}
          />
        </Grid>}
        <ArquivoContainer
          arquivos={values.arquivos}
          onSave={arquivos => setValues({ ...values, arquivos })}
        />
      </Grid>
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  selectedHistoricoDrone: selectorsDrones.getSelectedHistoricoDrone(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsDrones,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
