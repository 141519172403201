export const POLOS_FIND = 'POLOS_FIND';
export const POLOS_FIND_SUCCESS = 'POLOS_FIND_SUCCESS';

export const POLOS_DELETE = 'POLOS_DELETE';
export const POLOS_DELETE_SUCCESS = 'POLOS_DELETE_SUCCESS';

export const POLOS_SAVE = 'POLOS_SAVE';
export const POLOS_SAVE_SUCCESS = 'POLOS_SAVE_SUCCESS';

export const POLOS_UPDATE = 'POLOS_UPDATE';
export const POLOS_UPDATE_SUCCESS = 'POLOS_UPDATE_SUCCESS';

export const POLOS_CLEAN = 'POLOS_CLEAN';

export const POLOS_OPTIONS = 'POLOS_OPTIONS';
export const POLOS_OPTIONS_SUCCESS = 'POLOS_OPTIONS_SUCCESS';