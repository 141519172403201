import * as Type from './actionsTypes';

const INITIAL_STATE = {
  data: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_SUCCESS: {
      return {
        ...state,
        data: action.data
      }
    }
    case Type.CLEAR_DASHBOARD: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getDashboardData = state => state.rootState.dashboard.data;