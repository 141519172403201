/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { 
  AppBar,
  Box, 
  Grid,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  appBar: {
    background: theme.palette.bar.principal,
    boxShadow: 'none'
  },
  container: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 4,
    background: theme.palette.bar.white,
    border: theme.palette.bar.border,
  },
  indicator: {
    display: 'none',
  },
  root: {
    '&:not(:first-of-type)': {
      marginLeft: -1,
    },
    background: theme.palette.bar.principal,
    color: theme.palette.textColor.gray,
    opacity: 1
  },
  selected: {
    borderBottomWidth: 0,
    background: theme.palette.bar.darkOrange,
    color: theme.palette.textColor.white,
  },
  wrapper: {
    opacity: 0.7,
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      aria-labelledby={`simple-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const getTabPanels = (tabPanels, tab) => {
  return tabPanels.map((t, i) => {
    return (
      <TabPanel key={i} value={tab} index={i}>
        {t.element}
      </TabPanel>
    )
  })
}

const getTabs = (classes, tabPanels) => {
  return tabPanels.map((t, i) => (
    <Tab 
      key={i} 
      label={t.label} 
      id={`simple-tab-${i}`}
      classes={{
        root: classes.root,
        selected: classes.selected,
        wrapper: classes.wrapper
      }}
    />
  ))
}

const TabForm = ({ tabPanels, currentTab , onChangeTab }) => {
  const [tab, setTab] = useState(currentTab);
  const handleChange = (event, newValue) => {
    onChangeTab(newValue);
    setTab(newValue);
  }

  const classes = useStyles();

  useEffect(() => {
    if(Number.isInteger(currentTab) && currentTab !== tab) setTab(currentTab);
  }, [currentTab]);

  return (
    <Grid className={classes.container}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs 
          value={tab} 
          onChange={handleChange}
          classes={{
            indicator: classes.indicator
          }}
        >
          {getTabs(classes, tabPanels)}
        </Tabs>
      </AppBar>
      {getTabPanels(tabPanels, tab)}
    </Grid>
  );
};

TabForm.propTypes = {
  tabPanels: PropTypes.array.isRequired,
  currentTab: PropTypes.bool,
  onChangeTab: PropTypes.func
};

TabForm.defaultProps = {
  currentTab: 0,
  onChangeTab: () => {}
}

export default TabForm;
