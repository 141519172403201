import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { logout } from '../../../../../../store/auth/actions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.bar.principal
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    fontSize: 20,
    color: '#fff',
    fontWeight: 600
  }
}));

const Topbar = ({ className, onSidebarOpen, history, logout }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={onSidebarOpen}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <RouterLink to="/nsirs">
          <div className={classes.logo}>
            EletroPontes
          </div>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Tooltip title="Sair">
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={() => logout(history)}
          >
            <InputIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  history: PropTypes.object
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logout
}, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(Topbar));
