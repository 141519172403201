/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/veiculo/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';

function* load({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'veiculos/filtro', data: filtro });
    yield put({
      type: TypeActions.FETCH_VEICULOS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* findOptions({ ativo = "" }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `veiculos?ativo=${ativo}` });
    yield put({
      type: TypeActions.VEICULO_FIND_OPTIONS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.post({ url: 'veiculos', data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield put({
      type: TypeActions.SAVE_VEICULO_SUCCESS,
      data: response.data
    });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao salvar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.put({ url: `veiculos/${data.id}`, data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield put({
      type: TypeActions.UPDATE_VEICULO_SUCCESS,
      data: response.data
    });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.get({ url: `veiculos/${id}` });
    if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
      response.data.arquivos = yield FileService.downloadArquivos(response.data.arquivos);
    }

    yield put({
      type: TypeActions.VEICULO_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* exportVeiculo({ filtro, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'veiculos/export',
      method: 'POST',
      data: filtro,
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório Veiculos.xlsx');
    yield callback(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar o relatório!'
    });
  }
}

function* upsertHistoricoVeiculo({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.post({ url: 'historico-veiculos', data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });
    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }
    yield callback(true, response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findHistoricoVeiculoById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const response = yield BaseService.get({ url: `historico-veiculos/${id}` });
    if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
      response.data.arquivos = yield FileService.downloadArquivos(response.data.arquivos);
    }

    yield put({
      type: TypeActions.HISTORICO_VEICULO_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* deleteHistoricoVeiculo({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.delete({ url: `historico-veiculos/${id}` });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_VEICULOS, load);
  yield takeLatest(TypeActions.VEICULO_FIND_OPTIONS, findOptions);
  yield takeLatest(TypeActions.SAVE_VEICULO, save);
  yield takeLatest(TypeActions.UPDATE_VEICULO, update);
  yield takeLatest(TypeActions.VEICULO_FIND_BY_ID, findById);
  yield takeLatest(TypeActions.VEICULO_EXPORT, exportVeiculo);
  yield takeLatest(TypeActions.HISTORICO_VEICULO_UPSERT, upsertHistoricoVeiculo);
  yield takeLatest(TypeActions.HISTORICO_VEICULO_FIND_BY_ID, findHistoricoVeiculoById);
  yield takeLatest(TypeActions.HISTORICO_VEICULO_DELETE, deleteHistoricoVeiculo);
}