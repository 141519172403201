import * as Type from './actionsTypes';

const INITIAL_STATE = {
  usuarios: [],
  selectedUsuario: null,
  options: [],
  digitadores: [],
  inspetores: [],
  proximaMatricula: null
};

const ordernarPorNome = (a, b) => {
  return a.nome.localeCompare(b.nome);
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        usuarios: action.data.sort(ordernarPorNome)
      }
    }
    case Type.DELETE_USER_SUCCESS: {
      return {
        ...state,
        usuarios: state.usuarios.filter(s => s.id !== action.data).sort(ordernarPorNome)
      }
    }
    case Type.SAVE_USER_SUCCESS: {
      return {
        ...state,
        usuarios: [...state.usuarios, action.data].sort(ordernarPorNome)
      }
    }
    case Type.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        usuarios: [...state.usuarios.filter(s => s.id !== action.data.id), action.data].sort(ordernarPorNome)
      }
    }
    case Type.USUARIO_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedUsuario: action.data
      }
    }
    case Type.USUARIO_FIND_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data
      }
    }
    case Type.USUARIO_FIND_INSPETORES_OPTIONS_SUCCESS: {
      return {
        ...state,
        inspetores: action.data
      }
    }
    case Type.USUARIO_FIND_DIGITADORES_OPTIONS_SUCCESS: {
      return {
        ...state,
        digitadores: action.data
      }
    }
    case Type.USUARIO_PROXIMA_MATRICULA_SUCCESS: {
      return {
        ...state,
        proximaMatricula: action.proximaMatricula
      }
    }
    case Type.USUARIO_SET_PROXIMA_MATRICULA: {
      return {
        ...state,
        proximaMatricula: null
      }
    }
    case Type.CLEAR_USER: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getUsuarios = state => state.rootState.usuario.usuarios;
export const getSelectedUsuario = state => state.rootState.usuario.selectedUsuario;
export const getUsuariosOptions = state => state.rootState.usuario.options;
export const getInspetoresOptions = state => state.rootState.usuario.inspetores;
export const getDigitadoresOptions = state => state.rootState.usuario.digitadores;
export const getProximaMatricula = state => state.rootState.usuario.proximaMatricula;
