import BaseService from './BaseService';

export default class TableService extends BaseService {
  static async request(url, filter) {
    return await this.execute({
      url,
      method: 'POST',
      data: filter
    });
  }
}