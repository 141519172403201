import * as Type from './actionsTypes';

const INITIAL_STATE = {
  controlePontoUsuarios: [],
  selectedControlePonto: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_CONTROLE_PONTO_USUARIOS_SUCCESS: {
      return {
        ...state,
        controlePontoUsuarios: action.data
      }
    }
    case Type.FETCH_CONTROLE_PONTOS_BY_USUARIO_ID_SUCCESS: {
      return {
        ...state,
        selectedControlePonto: action.data
      }
    }
    case Type.CLEAR_SELECTED_CONTROLE_PONTO: {
      return {
        ...state,
        selectedControlePonto: {}
      }
    }
    case Type.CLEAR_CONTROLE_PONTO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getControlePontosUsuarios = state => state.rootState.controlePonto.controlePontoUsuarios;
export const getSelectedControlePonto = state => state.rootState.controlePonto.selectedControlePonto;