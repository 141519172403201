/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paperScrollPaper: {
    overflowY: 'visible'
  },
  dialogTitle: {
    background: theme.palette.bar.principal,
    '& h2': {
      color: theme.palette.white
    },
    '& button': {
      float: 'right',
      marginLeft: 15
    }
  },
  actions: {
    background: theme.palette.bar.grayLight,
  },
  content: {
    background: theme.palette.bar.grayLight,
    paddingBottom: 30,
    borderBottom: theme.palette.bar.border,
  }
}));

const DialogDefault = ({ open, handlerCancel, handlerConfirm,  title, cancelMessage, fullScreen, useFullScreen,
  confirmMessage, fullWidth, maxWidth, children, contentClassName, disableBackdropClick, readOnly, headerAction, paperScrollPaperClassName }) => {
  
  const classes = useStyles();
  const theme = useTheme();
  const shouldFullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getHeaderAction = () => {
    if (headerAction) {
      if (Array.isArray(headerAction)) {
        return headerAction.map(action => (
          (action.show === undefined || action.show === true) && <Button onClick={action.onClick} color="primary" variant="contained"> {action.title} </Button>
        ));
      } 
      return (headerAction.show === undefined || headerAction.show === true) && <Button onClick={headerAction.onClick} color="primary" variant="contained"> {headerAction.title} </Button>;
    }
    return <></>
  }

  return (
    <Dialog
      fullScreen={shouldFullscreen && useFullScreen? true : fullScreen ? fullScreen : false}
      open={open}
      onClose={handlerCancel}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
      classes={{
        paperScrollPaper: clsx(classes.paperScrollPaper, paperScrollPaperClassName)
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {title}
        {getHeaderAction()}
      </DialogTitle>
      <DialogContent dividers className={clsx(classes.content, contentClassName)}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {readOnly ? 
          <Button onClick={handlerCancel} color="primary" variant="contained"> Fechar </Button> 
          :
          <>
            <Button onClick={handlerCancel} color="primary" variant="contained"> {cancelMessage} </Button>
            <Button onClick={handlerConfirm} color="primary" variant="contained" autoFocus> {confirmMessage} </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
}

DialogDefault.defaultProps = {
  cancelMessage: 'Cancelar',
  confirmMessage: 'Confirmar',
  fullWidth: false,
  maxWidth: 'sm',
  disableBackdropClick: true,
  readOnly: false,
  fullScreen: false,
  title: '',
  headerAction: null,
  useFullScreen: true,
  paperScrollPaperClassName: ''
};

DialogDefault.propTypes = {
  cancelMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  handlerCancel: PropTypes.func.isRequired,
  handlerConfirm: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullScreen: PropTypes.bool,
  headerAction: PropTypes.object,
  useFullScreen: PropTypes.bool,
  paperScrollPaperClassName: PropTypes.string
};

export default DialogDefault;