/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/nsir/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import * as NsmpTypeActions from '../store/nsmp/actionsTypes';
import fileDownload from 'js-file-download';

function* load({ filter }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'nsirs/filter', data: filter });
    yield put({
      type: TypeActions.NSIRS_FIND_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* importNsirs({ data, callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'nsirs/import', data });
    yield put({
      type: TypeActions.NSIRS_IMPORT_SUCCESS,
      data: response.data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: `Importação realizada com sucesso. Foram importadas ${response.data.length} nsirs!`
    });
    yield callbackAfterSave(response.data);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao importar as nsirs!'
    });
  }
}

function* importFinanceiro({ data, callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'nsirs/import/financeiro', data });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: `Importação realizada com sucesso. Foram atualizadas os campos do financeiro para ${response.data.length} nsirs!`
    });
    yield callbackAfterSave(response.data);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao atualizar o financeiro para as nsirs!'
    });
  }
}

function* loadNsirById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `nsirs/${id}` });
    if (response.data && response.data.documentoKmz && response.data.documentoKmzLink) {

      try {
        const responseFile = yield FileService.getFile(response.data.documentoKmzLink);
        if (responseFile) {
          responseFile.data.name = response.data.documentoKmz;
  
          const extension = response.data.documentoKmz.split('.').pop();
          if (!extension) {
            responseFile.data.name = `${response.data.documentoKmz}.kmz`;
          }
          responseFile.data.lastModifiedDate = new Date();
          response.data.documentoKmzFile = responseFile.data;
        }
      } catch(error) {
        yield console.log("Error to get the kmz file.", error);
      }
    }
    yield put({
      type: TypeActions.NSIRS_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({
      type: NsmpTypeActions.NSMPS_FIND_SUCCESS,
      data: response.data.nsmps
    });

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'nsirs', data });

    if (data.existDocumentoKmz) {

      yield FileService.updateFile(response.data.documentoKmzLink, data.documentoKmzFile);
      const documentoKmzFile = new File([data.documentoKmzFile], response.data.documentoKmz);
      response.data.documentoKmzFile = documentoKmzFile;
      response.data.existDocumentoKmz = true;
    }

    yield put({
      type: TypeActions.NSIRS_SAVE_SUCCESS,
      data: response.data
    });

    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* update({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `nsirs/${data.id}`, data });

    if (data.existDocumentoKmz) {

      yield FileService.updateFile(response.data.documentoKmzLink, data.documentoKmzFile);
      const documentoKmzFile = new File([data.documentoKmzFile], response.data.documentoKmz);
      response.data.documentoKmzFile = documentoKmzFile;
      response.data.existDocumentoKmz = true;
    }

    yield put({
      type: TypeActions.NSIRS_UPDATE_SUCCESS,
      data: response.data
    });

    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* updateStatus({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `nsirs/${data.id}/status`, data });
    yield put({
      type: TypeActions.NSIRS_UPDATE_STATUS_SUCCESS,
      data: response.data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    if (error.response && error.response.status === 400 && callback) {
      yield callback(true, error.response.data.mensagem);
    } else {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_ERROR,
        message: 'Erro ao salvar as informações!'
      });
    }
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  }
}

function* iniciarDigitacaoNsir({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.put({ url: `nsirs/${data.id}/status/iniciar-digitacao` });
    yield put({
      type: TypeActions.NSIRS_INICIAR_DIGITACAO_SUCCESS,
      data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    if (error.response && error.response.status === 409) {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_ERROR,
        message: error.response.data.mensagem
      });
    } else {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_ERROR,
        message: 'Erro ao iniciar digitação!'
      });
    }
  }
}

function* trocarDigitadorNsir({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.put({ url: `nsirs/${data.id}/trocar-digitador`, data });
    yield put({ 
      type: TypeActions.NSIRS_TROCAR_DIGITADOR_SUCCESS,
      data: {
        status: data.digitadorId ? 'AGUARDANDO_DIGITACAO' : 'INSPECAO_FINALIZADA',
        digitadorId: data.digitadorId
      }
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Digitador atualizado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    if (error.response && error.response.status === 409) {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_ERROR,
        message: error.response.data.mensagem
      });
    } else {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_ERROR,
        message: 'Erro ao iniciar digitação!'
      });
    }
  }
}

function* deleteById({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.delete({ url: `nsirs/${id}`});
    yield put({
      type: TypeActions.NSIRS_DELETE_SUCCESS,
      id
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações removidas com sucesso!'
    });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao deletar!'
    });
  }
}

function* exportNsirs({ filter, callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'nsirs/export',
      data: filter,
      method: 'POST',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório.xlsx');
    yield callbackAfterSave(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar as nsirs!'
    });
  }
}

function* exportToEditNsirs({ filter, callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'nsirs/export-to-edit',
      data: filter,
      method: 'POST',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'NSIRs para Edição.xlsx');
    yield callbackAfterSave(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'NSIRs para Edição gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar as nsirs!'
    });
  }
}

function* exportFinanceiro({ filter, callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: 'nsirs/export-financeiro',
      data: filter,
      method: 'POST',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'NSIRs Financeiro.xlsx');
    yield callbackAfterSave(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'NSIRs para financeiro gerado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar as nsirs!'
    });
  }
}

function* generateReportById({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: `nsirs/${data.id}/report/servicos`, 
      method: 'GET',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, data.codigo ? `NSIR ${data.codigo}.xlsx` : `NSIR Equiapmento ${data.equipamento}.xlsx`);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado para a nsir!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao gerar relatório!'
    });
  }
}

function* generateReport({ filter, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: `nsirs/report/servicos`, 
      method: 'POST',
      data: filter,
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, "Relatório NSIR Serviços.xlsx");
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado para a nsir!'
    });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao gerar relatório!'
    });
  }
}

function* generateInspecaoReport({ filter, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: `nsirs/report/inspecao`, 
      method: 'POST',
      data: filter,
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, "Relatório Inspeção.xlsx");
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao gerar relatório!'
    });
  }
}

function* importNsirServices({ data, callbackAfterSave }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    
    const fotos = data.fotos;
    delete data.fotos;
    
    const response = yield BaseService.post({ url: 'nsirs/import/nsmps', data });

    if (fotos) {
      yield FileService.importFotosByNsmps(fotos);
    }

    yield put({
      type: TypeActions.NSIRS_UPDATE_SUCCESS,
      data: response.data.nsirDTO
    });

    if (response.data.hasProblem) {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_WARNING,
        message: 'Importação não realizada! A NSIR foi removida ou não está mais no seu nome. Contacte o administrador para mais informações!'
      });
    } else {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
        message: `Importação dos serviços realizada com sucesso. Foram importados ${response.data.numServices} serviços!`
      });
      yield callbackAfterSave(response.data);
    }

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao importar os serviços!'
    });
  }
}

function* updateFinanceiro({ data }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.put({ url: `nsirs/${data.id}/financeiro`, data });
    yield put({
      type: TypeActions.NSIRS_UPDATE_FINANCEIRO_SUCCESS,
      data: response.data
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações financeiras salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações financeiras!'
    });
  }
}

function* exportCcsm({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({
      url: `nsirs/${id}/report/ccsm`,
      method: 'GET',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'CCSM.xlsx');
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório exportado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar o relatório!'
    });
  }
}

function* nsirExport({ path, fileName,  filter, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({ 
      url: `nsirs/${path}`,
      data: filter,
      method: 'POST',
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, fileName);
    yield callback(response.data);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Exportação realizada com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar as informações!'
    });
  }
}


function* defaultNsirImport({ data, path, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: `nsirs/${path}`, data });

    if (response.data.imported) {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
        message: `Importação realizada com sucesso. Foi importado ${response.data.quantidade} linhas!`
      });
    } else {
      yield put({
        type: ToastTypeActions.TOAST_MESSAGE_ERROR,
        message: `Importação com problemas: ${response.data.message}`
      });
    }
    yield callback(response.data.imported);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao importar as informações!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.NSIRS_FIND, load);
  yield takeLatest(TypeActions.NSIRS_IMPORT, importNsirs);
  yield takeLatest(TypeActions.NSIRS_EXPORT, exportNsirs);
  yield takeLatest(TypeActions.NSIRS_EXPORT_TO_EDIT, exportToEditNsirs);
  yield takeLatest(TypeActions.NSIRS_EXPORT_FINANCEIRO, exportFinanceiro);
  yield takeLatest(TypeActions.NSIRS_FIND_BY_ID, loadNsirById);
  yield takeLatest(TypeActions.NSIRS_SAVE, save);
  yield takeLatest(TypeActions.NSIRS_UPDATE, update);
  yield takeLatest(TypeActions.NSIRS_UPDATE_STATUS, updateStatus);
  yield takeLatest(TypeActions.NSIRS_INICIAR_DIGITACAO, iniciarDigitacaoNsir);
  yield takeLatest(TypeActions.NSIRS_TROCAR_DIGITADOR, trocarDigitadorNsir);
  yield takeLatest(TypeActions.NSIRS_DELETE, deleteById);
  yield takeLatest(TypeActions.NSIRS_GENERATE_REPORT, generateReport);
  yield takeLatest(TypeActions.NSIRS_GENERATE_REPORT_BY_ID, generateReportById);
  yield takeLatest(TypeActions.NSIRS_GENERATE_INSPECAO_REPORT, generateInspecaoReport);
  yield takeLatest(TypeActions.NSIRS_IMPORT_SERVICES, importNsirServices);
  yield takeLatest(TypeActions.NSIRS_UPDATE_FINANCEIRO, updateFinanceiro);
  yield takeLatest(TypeActions.NSIRS_IMPORT_FINANCEIRO, importFinanceiro);
  yield takeLatest(TypeActions.NSIRS_EXPORT_CCSM, exportCcsm);
  yield takeLatest(TypeActions.NSIRS_BASE_EXPORT, nsirExport);
  yield takeLatest(TypeActions.NSIRS_BASE_IMPORT, defaultNsirImport);
}