import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { findVeiculosOptions } from '../../store/veiculo/actions';
import { getVeiculosOptions } from '../../store/veiculo/reducer';
import DefaultSelect from '../DefaultSelect';

const VeiculoSelect = ({ veiculosOptions, onSelect, value, isRequired, error, helperText, findVeiculosOptions, disabled }) => {
  const handleChange = (event) => onSelect(event.target.value);

  useEffect(() => {
    findVeiculosOptions(true);
  }, [findVeiculosOptions]);

  return (
    <DefaultSelect 
      error={error}
      helperText={helperText}
      isRequired={isRequired}
      label="Veículos"
      optionLabel="placa"
      onSelect={handleChange}
      options={veiculosOptions}
      value={value}
      disabled={disabled}
    />
  );
}

VeiculoSelect.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
  veiculosOptions: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  findVeiculosOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

VeiculoSelect.defaultProps = {
  error: false,
  helperText: null,
  isRequired: false,
  disabled: false
};

const mapDispatchToProps = dispatch => bindActionCreators({
  findVeiculosOptions,
}, dispatch);

const mapStateToProps = state => ({
  veiculosOptions: getVeiculosOptions(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(VeiculoSelect);