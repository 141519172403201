export const FETCH_CONTROLE_PONTO_USUARIOS = 'FETCH_CONTROLE_PONTO_USUARIOS';
export const FETCH_CONTROLE_PONTO_USUARIOS_SUCCESS = 'FETCH_CONTROLE_PONTO_USUARIOS_SUCCESS';

export const FETCH_CONTROLE_PONTOS_BY_USUARIO_ID = 'FETCH_CONTROLE_PONTOS_BY_USUARIO_ID';
export const FETCH_CONTROLE_PONTOS_BY_USUARIO_ID_SUCCESS = 'FETCH_CONTROLE_PONTOS_BY_USUARIO_ID_SUCCESS';

export const SAVE_CONTROLE_PONTOS = 'SAVE_CONTROLE_PONTOS';

export const CLEAR_SELECTED_CONTROLE_PONTO = 'CLEAR_SELECTED_CONTROLE_PONTO';
export const CLEAR_CONTROLE_PONTO = 'CLEAR_CONTROLE_PONTO';

export const EXPORT_CONTROLE_PONTOS = 'EXPORT_CONTROLE_PONTOS';
export const REPORT_CONTROLE_PONTOS_USUARIO_ID = 'REPORT_CONTROLE_PONTOS_USUARIO_ID';