export const FETCH_AUTH = 'FETCH_AUTH';
export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS';
export const FETCH_AUTH_FAILED  = 'FETCH_AUTH_FAILED';

export const FETCH_FORGOT_PASSWORD = 'FETCH_FORGOT_PASSWORD';
export const FETCH_FORGOT_PASSWORD_SUCCESS = 'FETCH_FORGOT_PASSWORD_SUCCESS';
export const FETCH_FORGOT_PASSWORD_FAILED = 'FETCH_FORGOT_PASSWORD_FAILED';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CLEAR = 'AUTH_CLEAR';