import * as Type from './actionsTypes';

const INITIAL_STATE = {
  cacheFiltroNsir: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FILTRO_CACHE_NSIR: {
      return {
        ...state,
        cacheFiltroNsir: action.filtro
      }
    }
    case Type.FILTRO_CLEAN: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
}

export const getCacheFiltroNsir = state => state.rootState.filtro.cacheFiltroNsir;
