export const NSMPS_FIND_BY_ID = 'NSMPS_FIND_BY_ID';
export const NSMPS_FIND_BY_ID_SUCCESS = 'NSMPS_FIND_BY_ID_SUCCESS';

export const NSMPS_FIND_SUCCESS = 'NSMPS_FIND_SUCCESS';

export const NSMPS_DELETE = 'NSMPS_DELETE';
export const NSMPS_DELETE_SUCCESS = 'NSMPS_DELETE_SUCCESS';

export const NSMPS_SAVE = 'NSMPS_SAVE';
export const NSMPS_SAVE_SUCCESS = 'NSMPS_SAVE_SUCCESS';

export const NSMPS_UPDATE = 'NSMPS_UPDATE';
export const NSMPS_UPDATE_SUCCESS = 'NSMPS_UPDATE_SUCCESS';

export const NSMPS_CLEAN = 'NSMPS_CLEAN';
export const NSMPS_CLEAN_SELECTED = 'NSMPS_CLEAN_SELECTED';

export const NSMPS_CLONE = 'NSMPS_CLONE';
export const NSMPS_CLONE_SUCCESS = 'NSMPS_CLONE_SUCCESS';

export const NSMPS_DELETE_CLONE = 'NSMPS_DELETE_CLONE';
export const NSMPS_DELETE_CLONE_SUCCESS = 'NSMPS_DELETE_CLONE_SUCCESS';