import * as Type from './actionsTypes';

const loadUsuarios = filter => ({ type: Type.FETCH_USERS, filter });

const deleteUsuario = id => ({ type: Type.DELETE_USER, id });

const saveUsuario = (data, callback) => ({ type: Type.SAVE_USER, data, callback });

const updateUsuario = (data, callback) => ({ type: Type.UPDATE_USER, data, callback });

const findUsuarioById = id => ({ type: Type.USUARIO_FIND_BY_ID, id });

const findOptions = (grupo, ativo) => ({ type: Type.USUARIO_FIND_OPTIONS, grupo, ativo });

const findInspetores = () => ({ type: Type.USUARIO_FIND_INSPETORES_OPTIONS });

const findDigitadores = () => ({ type: Type.USUARIO_FIND_DIGITADORES_OPTIONS });

const resetPassword = (usuarioId) => ({ type: Type.USUARIO_RESET_PASSWORD, usuarioId });

const findProximaMatricula = () => ({ type: Type.USUARIO_PROXIMA_MATRICULA });

const setProximaMatricula = () => ({ type: Type.USUARIO_SET_PROXIMA_MATRICULA });

const exportUsuarios = (filtro, callback) => ({ type: Type.USUARIO_EXPORT, filtro, callback });

export {
  deleteUsuario,
  saveUsuario,
  loadUsuarios,
  updateUsuario,
  findUsuarioById,
  findOptions,
  findInspetores,
  findDigitadores,
  resetPassword,
  findProximaMatricula,
  setProximaMatricula,
  exportUsuarios,
}
