/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/aporte/actionsTypes';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';

function* load({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield put({ type: TypeActions.CLEAR_APORTE });
    if (!filtro.tipoAporte) filtro.tipoAporte = null;
    const response = yield BaseService.post({ url: 'aportes/filtro', data: filtro });
    yield put({
      type: TypeActions.FETCH_APORTES_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* loadByInspetor({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield put({ type: TypeActions.CLEAR_APORTE });
    if (!filtro.tipoAporte) filtro.tipoAporte = null;
    const response = yield BaseService.post({ url: 'aportes/inspetor/filtro', data: filtro });
    yield put({
      type: TypeActions.FETCH_APORTES_BY_INSPETOR_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}


function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });

    const response = yield BaseService.post({ url: 'aportes', data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });

    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield put({
      type: TypeActions.SAVE_APORTE_SUCCESS,
      data: response.data
    });

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.error(error);
    let message = "Erro ao salvar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });

    const response = yield BaseService.put({ url: `aportes/${data.id}`, data: { ...data, arquivos: FileService.getArquivosToUpload(data.arquivos) } });

    if (response.data && response.data.arquivos) {
      yield FileService.uploadArquivos(data.arquivos, response.data.arquivos);
    }

    yield put({
      type: TypeActions.UPDATE_APORTE_SUCCESS,
      data: response.data
    });

    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações atualizadas salvas com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao atualizar as informações!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* findById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `aportes/${id}` });

    if (response.data && response.data.arquivos && response.data.arquivos.length > 0) {
      response.data.arquivos = yield FileService.downloadArquivos(response.data.arquivos);
    }

    yield put({
      type: TypeActions.APORTE_FIND_BY_ID_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as informações!'
    });
  }
}

function* findFotoById({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `aportes/${id}/arquivos` });
    
    const images = [];
    if (response.data && response.data.length > 0) {
      for(let image of response.data) {
        try {
          const responseFile = yield FileService.getFile(image.url);
          const imageSrc = yield FileService.getBase64(responseFile.data);
          images.push({src: imageSrc, alt: image.title, downloadUrl: image.url});
        } catch(e) {
          yield console.error(e);
        }
      }
    }

    yield callback(images);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar as imagens para a aporte!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_APORTES, load);
  yield takeLatest(TypeActions.SAVE_APORTE, save);
  yield takeLatest(TypeActions.UPDATE_APORTE, update);
  yield takeLatest(TypeActions.APORTE_FIND_BY_ID, findById);
  yield takeLatest(TypeActions.APORTE_FIND_FOTO_BY_ID, findFotoById);
  yield takeLatest(TypeActions.FETCH_APORTES_BY_INSPETOR, loadByInspetor);
}